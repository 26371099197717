import React from 'react';
import { Button } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}));

const DeleteButton = (props: any) => {
  const { onClick, loading, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      label='ra.action.delete'
      onClick={onClick}
      className={classes.root}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <CircularProgress size={18} thickness={2} classes={{ svg: classes.root }} />
      ) : (
        <DeleteIcon />
      )}
    </Button>
  );
};

export default DeleteButton;
