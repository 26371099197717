import React, { Fragment, useEffect } from 'react';
import { Tab, useTranslate, useShowController } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import PricingIcon from '@material-ui/icons/AttachMoney';

import { Show, PaperTabbedShowLayout } from '~/layout';
import { Aside, PerFeature, PerFees, PerLimits, PerLoyalty } from './components'; // Local components
import { Pricing } from '~/types';
import { getPricingSchema, PricingSchema } from '~/api/pricings';
import { useApiLazy, useSolution } from '~/hooks';
import { UpdateContext } from '~/components';

export const l = (key: string): string => `resources.pricings.${key}`;

const PricingShow = (props: any) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const showController = useShowController(props);
  const showRecord: Pricing = showController.record;

  const { data: solution } = useSolution(showRecord?.solutionId);

  // Get pricing schema
  const [schemaQuery, { data: pricingSchema, loading }] = useApiLazy<PricingSchema>(
    getPricingSchema
  );
  useEffect(() => {
    if (showRecord?.id) {
      schemaQuery(showRecord.id);
    }
  }, [schemaQuery, showRecord]);

  const hasLoyaltySupport = solution?.features?.includes('veengu_individual_loyalty');

  return (
    <Show
      icon={
        <Avatar>
          <PricingIcon />
        </Avatar>
      }
      {...props}
    >
      {showRecord && <UpdateContext data={{ solutionId: showRecord.solutionId }} />}
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.perFeatures')}>
          <PerFeature {...showController} pricingSchema={pricingSchema} />
        </Tab>
        <Tab label={t('tabs.fees')} path='fees'>
          <PerFees
            record={showRecord}
            loading={loading || showController.loading}
            pricingSchema={pricingSchema}
          />
        </Tab>
        <Tab label={t('tabs.taxes')} path='taxes'>
          <PerFees
            record={showRecord}
            loading={loading || showController.loading}
            pricingSchema={pricingSchema}
            tax
          />
        </Tab>
        {showRecord?.productId && (
          <Tab label={t('tabs.limits')} path='limits'>
            <PerLimits
              record={showRecord}
              loading={loading || showController.loading}
              pricingSchema={pricingSchema}
            />
          </Tab>
        )}
        {hasLoyaltySupport && (
          <Tab label={t('tabs.loyalty')} path='loyalty'>
            <PerLoyalty
              record={showRecord}
              loading={loading || showController.loading}
              pricingSchema={pricingSchema}
            />
          </Tab>
        )}
      </PaperTabbedShowLayout>
      <Fragment>
        <div>
          <Aside {...showController} />
        </div>
      </Fragment>
    </Show>
  );
};

export default PricingShow;
