import * as React from 'react';
import { usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { MaterialTable } from '~/components';
import * as hb from '~/types/Handbook';
import { validateId, alignCenter } from './utils';

export interface Props {
  document: hb.BusinessDocument;
  setDocument: hb.SetBusinessDocument;
}

const BusinessDocumentParameterTable = ({ document, setDocument }: Props) => {
  const { parameters: parametersFromState } = document;
  const [parameters] = React.useState(parametersFromState);

  const isValid = (
    newParam: hb.DocumentParameter,
    oldParam: hb.DocumentParameter | undefined = undefined
  ) => !validateId(parameters, newParam.name, (oldParam || {}).name, true);

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Name',
          field: 'name',
          required: true,
          validate: validateId,
          cellStyle: {
            minWidth: 180,
          },
          // disabled: (a: any) => a?.rowData?.name === 'dateOfExpiry',
        },
        {
          title: 'Mask',
          field: 'mask',
          width: 175,
          // disabled: (a: any) => a?.rowData?.name === 'dateOfExpiry',
        },
        {
          title: 'Type',
          field: 'type',
          type: 'select',
          options: [
            { value: 'text', text: 'Text' },
            { value: 'number', text: 'Number' },
            { value: 'country', text: 'Country' },
            { value: 'date', text: 'Date' },
            { value: 'pastDate', text: 'Past date' },
            { value: 'futureDate', text: 'Future date' },
          ],
          defaultValue: 'text',
          initialEditValue: 'text',
          width: 175,
          // disabled: (a: any) => a?.rowData?.name === 'dateOfExpiry',
        },
        {
          title: 'Mandatory',
          field: 'mandatory',
          type: 'boolean',
          initialEditValue: false,
          width: 100,
          ...alignCenter,
        },
      ]}
      components={{
        Container: (props: any) => (
          <Paper variant='outlined' style={{ borderRightWidth: 0 }}>
            {props.children}
          </Paper>
        ),
      }}
      data={parameters}
      editable={
        permissions?.includes('solution.handbook.update') && {
          isEditable: () => true, // !(param.name === 'number' && !!param.type),
          isDeletable: () => true, // !(param.name === 'number' || param.name === 'dateOfExpiry'),
          onRowAdd: (newParam: hb.DocumentParameter) => {
            if (isValid(newParam)) {
              parameters.push(newParam);
              return setDocument({ ...document, parameters });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newParam: hb.DocumentParameter, oldParam: hb.DocumentParameter) => {
            if (isValid(newParam, oldParam)) {
              const index = parameters.indexOf(oldParam);
              parameters[index] = newParam;
              return setDocument({ ...document, parameters });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldParam: hb.DocumentParameter) => {
            const index = parameters.indexOf(oldParam);
            parameters.splice(index, 1);
            return setDocument({ ...document, parameters });
          },
        }
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        search: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default BusinessDocumentParameterTable;
