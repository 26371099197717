import React, { useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import { Confirmation, EditButton } from '~/components';
import Button from '@material-ui/core/Button';
import UndoIcon from '@material-ui/icons/Undo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HeadTransaction } from '~/types/HeadTransaction';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

import { getTransaction } from '~/api/headTransactions';
import { createConsent as approvalCreateConsent } from '~/api/transactionApproval';
import { createTransaction as approvalCreateTransaction } from '~/api/transactionApproval';
import DetailsTab from '../DetailsTab';

interface Props {
  transaction: HeadTransaction;
  history?: any;
}

const ShowSuccess = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  return <Alert severity='success'>{t('approveSuccess')} </Alert>;
};

const ShowFailure = (props: any) => {
  const error = props.error;
  return <Alert severity='error'>{error}</Alert>;
};

const ShowDanger = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);
  return <Alert severity='warning'>{t('approveDanger')}</Alert>;
};

const ApprovalAction = (props: Props) => {
  const { transaction, history } = props;
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  const [approvalConsent, setApprovalConsent] = useState<HeadTransaction>();
  const [approvalOpen, setApprovalOpen] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [approvalTransaction, setApprovalTransaction] = useState<HeadTransaction>();

  const onApproveTransaction = () => {
    console.log(`trying to reject transaction ${transaction.id}`);
    setError(undefined);
    setInProgress(true);
    setApprovalOpen(true);
    approvalCreateConsent({ transactionId: transaction.id, comment: '' })
      .then(({ data: consent }) => {
        console.log('consent created:', consent);
        setApprovalConsent(consent);
        setInProgress(false);
      })
      .catch((e) => {
        let errorText = e?.body?.message || 'Unknown error occurred';
        setError(errorText);
        setInProgress(false);
      });
  };

  const checkForTransaction = (pendingTransaction: HeadTransaction) => {
    getTransaction({
      id: pendingTransaction.id,
    })
      .then(() => {
        setApprovalTransaction(pendingTransaction);
        setInProgress(false);
      })
      .catch(() => {
        setTimeout(() => {
          checkForTransaction(pendingTransaction);
        }, 2000);
      });
  };

  const onConfirmApproval = () => {
    if (approvalConsent) {
      console.log(`confirm approval consent ${approvalConsent.id}`);
      setInProgress(true);
      approvalCreateTransaction({ consentId: approvalConsent.id })
        .then(({ data: transaction }) => {
          console.log('transaction created:', transaction);
          setTimeout(() => {
            checkForTransaction(transaction);
          }, 2000);
        })
        .catch((e) => {
          const errorText = e?.response?.data?.message || 'Unknown error occurred';
          setError(errorText);
          setInProgress(false);
        });
    }
  };

  const isApprovalAllowed =
    transaction.status === 'REJECTED' && !transaction.details.approvalTransaction;

  return (
    <>
      {isApprovalAllowed && (
        <EditButton
          onClick={onApproveTransaction}
          label={t('approve')}
          icon={<UndoIcon />}
          disabled={!permissions?.includes('headTransaction.approve')}
          red
        />
      )}
      <Dialog
        open={approvalOpen}
        onClose={() => {
          setApprovalOpen(false);
        }}
        aria-labelledby='approval-dialog-title'
        aria-describedby='approval-dialog-description'
        fullWidth
        maxWidth={inProgress || approvalTransaction || error ? 'xs' : 'lg'}
      >
        <DialogTitle id='approval-dialog-title'>
          {inProgress || approvalTransaction || error ? t('approveDialogTitle') : <ShowDanger />}
        </DialogTitle>
        <DialogContent>
          {inProgress ? (
            <Box mt={1} mb={3}>
              <LinearProgress />
            </Box>
          ) : error ? (
            <ShowFailure error={error} />
          ) : approvalTransaction ? (
            <ShowSuccess />
          ) : approvalConsent ? (
            <DetailsTab record={approvalConsent} />
          ) : null}
        </DialogContent>
        {!inProgress && (
          <DialogActions>
            {error ? (
              <Button
                onClick={() => {
                  setApprovalOpen(false);
                }}
              >
                {t(`closeDialog`)}
              </Button>
            ) : approvalTransaction ? (
              <>
                <Button
                  onClick={() => {
                    setApprovalOpen(false);
                  }}
                >
                  {t(`closeDialog`)}
                </Button>
                <Button
                  onClick={() => {
                    history && history.push(`/headTransactions/${approvalTransaction.id}/show`);
                  }}
                  color='primary'
                >
                  {t(`goToTransaction`)}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setApprovalOpen(false);
                  }}
                >
                  {t('cancel')}
                </Button>
                <Confirmation
                  onConfirm={onConfirmApproval}
                  confirmationSettings={{
                    variant: 'modal',
                    modal: {
                      content: <ShowDanger />,
                    },
                  }}
                >
                  <Button color='primary'>{t('confirm')}</Button>
                </Confirmation>
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ApprovalAction;
