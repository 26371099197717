import React, { useState, useEffect } from 'react';
import { FormDataConsumer, Query, Loading, useInput } from 'react-admin';

import JsonSchemaForm from '@rjsf/material-ui';
import inputs from './inputs';
import uiSchemaOf from './uiSchemaOf';
import useLocalizer from './useLocalizer';
import { UpdateContext } from '~/components';
//import { UpdateContext } from '~/components';

export interface Props {
  schema: any;
  edit?: boolean;
  [key: string]: any;
}

interface State {
  value: any | unknown;
  errors: any;
}

interface FormBodyProps {
  schema: any;
  [key: string]: any;
}

const FormBody = ({ data, schema, ...rest }: FormBodyProps) => {
  const localizer = useLocalizer(rest.resource);

  const [state, setState] = useState<State>({ value: data, errors: {} });

  const {
    input: { onChange },
    meta: { error },
  } = useInput({
    validate: () => {
      const hasError = Object.keys(state.errors).find((k) => !!state.errors[k]);
      return hasError ? { message: 'error' } : null;
    },
    ...rest,
  });

  useEffect(() => {
    if (state.value !== data) {
      onChange({
        target: {
          value: state.value,
        },
      });
    }
  }, [data, onChange, state]);

  const uiSchema = uiSchemaOf(schema, localizer);
  const onValidate = (key: string, error: any): void => {
    setTimeout(() => {
      setState((prevState: State) => {
        if (!!prevState.errors[key] !== error) {
          return {
            ...prevState,
            errors: {
              ...state.errors,
              [key]: error,
            },
          };
        } else {
          console.log('not changed');
        }
        return prevState;
      });
    }, 1);
  };

  return (
    <JsonSchemaForm
      schema={schema}
      formData={state.value}
      fields={inputs}
      uiSchema={uiSchema}
      tagName='div'
      noHtml5Validate
      formContext={{ onValidate, error }}
      onChange={({ formData }) => {
        if (state.value !== formData) {
          setState({ ...state, value: formData });
        }
      }}
    >
      <br />
    </JsonSchemaForm>
  );
};

const Form = ({ schema, edit = false, ...rest }: Props) => {
  return (
    <FormDataConsumer {...rest}>
      {({ formData }: { formData: any; [key: string]: any }) => {
        if (formData) {
          if (formData.parentId) {
            return (
              <Query
                type='getOne'
                resource={`configurations/${formData.type}`}
                payload={{ id: formData.parentId }}
              >
                {({ loading, error }: { loading: boolean; error?: any }) => {
                  if (loading) {
                    return <Loading />;
                  }
                  if (error) {
                    // FIXME provide valid error message
                    return <p>Failed to load parent configuration</p>;
                  }
                  return null;
                  /*
                  return buildInputs({
                    context: 'configuration',
                    defaultData: data,
                    objectSchema: configurationSchema,
                    sourcePrefix: 'data',
                  });
                   */
                }}
              </Query>
            );
          } else if (edit) {
            /*
            return buildInputs({
              context: 'configuration',
              objectSchema: configurationSchema,
              sourcePrefix: 'data',
            });
             */
            return (
              <>
                <UpdateContext data={{ solutionId: formData.solutionId }} />
                <FormBody data={formData.data} schema={schema} {...rest} />
              </>
            );
          } else {
            // FIXME provide valid message
            return <p>Choose parent configuration first.</p>;
          }
        } else {
          // FIXME handle this?
          return null;
        }
      }}
    </FormDataConsumer>
  );
};

export default Form;
