import React, { useState, Fragment } from 'react';
import { useTranslate, TextInput, FormDataConsumer, required } from 'react-admin';

import { EditButton, Drawer, BlockOrUnblockProfile } from '~/components';
import { BusinessLegalTypeInput, IndustryInput } from '~/resources/businesses/components';
import { MerchantCategoryCodeInput, EditForm } from '~/components/ra';
import { Business } from '~/types/businesses';
import { KYCLevelCheck } from './index';
import { useHistory } from 'react-router-dom';

type FormData = {
  formData: Business;
  [x: string]: any;
};

interface Props {
  record: Business;
  actionsId?: string; // used to apply some css from BusinessShow
  resource: string;
  permissions: string[];
}

const EditAccountInfo = (props: Props) => {
  const { record, actionsId = 'section-actions', resource, permissions } = props;
  const label = (key: string): string => `resources.businesses.fields.${key}`;
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();

  return (
    <Fragment>
      <div id={actionsId}>
        {permissions?.includes('business.update') && <EditButton onClick={handleOpen} />}
        {permissions?.includes('business.block') && record?.status === 'ACTIVE' && (
          <BlockOrUnblockProfile profileId={record.id} profileType='BUSINESS' action='block' />
        )}
        {permissions?.includes('business.unblock') && record?.status === 'BLOCKED' && (
          <BlockOrUnblockProfile profileId={record.id} profileType='BUSINESS' action='unblock' />
        )}
        <KYCLevelCheck record={record} resource={resource} permissions={permissions} />
      </div>
      <Drawer heading={translateLabel('edit.profileInfo')} open={open} onClose={handleClose}>
        <EditForm
          record={record}
          resource='businesses'
          withDelete={permissions?.includes('business.delete')}
          withDeleteConfirmation
          onDeleteSuccess={() => {
            history && history.push('/businesses');
          }}
        >
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return (
                <Fragment>
                  <TextInput
                    source='legalName'
                    label={label('legalName')}
                    validate={required()}
                    fullWidth
                  />
                  <BusinessLegalTypeInput
                    solutionId={formData.solutionId}
                    country={formData.country}
                    source='legalType'
                    validate={required()}
                    label={label('legalType')}
                    disabled={!!record.legalType}
                    fullWidth
                  />
                  <TextInput source='tradeName' label={label('tradeName')} fullWidth />
                  <TextInput
                    source='tradeNameLocalized'
                    label={label('tradeNameLocalized')}
                    fullWidth
                  />
                  <IndustryInput
                    solutionId={formData.solutionId}
                    source='industry'
                    label={label('industry')}
                    disabled={!formData.legalName}
                    allowEmpty
                    fullWidth
                  />
                  <MerchantCategoryCodeInput
                    solutionId={formData.solutionId}
                    source='merchantCategoryCode'
                    label={label('merchantCategoryCode')}
                    disabled={!formData.legalName}
                    allowEmpty
                    fullWidth
                  />
                  <TextInput
                    source='description'
                    label={label('description')}
                    multiline
                    style={{ minHeight: '4em' }}
                    disabled={!formData.legalName}
                    fullWidth
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default EditAccountInfo;
