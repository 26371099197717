import { ExternalSource } from '~/types';
import { Balance } from '~/types/account';
import axios from '~/utils/axios';
import { ThirdPartyData } from '~/types/ExternalSource';

export const getExternalSourceTypes = () => {
  return axios.get<string[]>(`/externalSources/types`);
};

export const getExternalSources = (resource: 'individuals' | 'businesses', profileId: string) => {
  return axios
    .get<ExternalSource[]>(`/${resource}/${profileId}/externalSources?page=1&perPage=999`)
    .then((res) => res.data);
};

export const getExternalSource = (id: string) => {
  return axios.get<ExternalSource>(`/externalSources/${id}`).then((res) => res.data);
};

export const deleteExternalSource = (id: string) => {
  return axios.delete(`/externalSources/${id}`);
};

export const getExternalSourceBalance = (id: string) => {
  return axios.get<Balance[]>(`/externalSources/${id}/balances`).then((res) => res.data);
};

export const getThirdPartyData = (id: string) => {
  return axios.get<ThirdPartyData>(`/externalSources/${id}/thirdPartyData`).then((res) => res.data);
};
