import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import lodashIsEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { H2 } from '~/components';
import NoteCard from './NoteCard';
import CreateNote from './CreateNote';
import { getNotes, selectors } from '~/ducks/notes';
import Scrollbars from 'react-custom-scrollbars';
import { NotesProfileType } from '~/api/notes';
import { useRightContentWidth } from '~/hooks/styles';

interface Props {
  referenceId: string;
  profileType: NotesProfileType;
}

const Notes = (props: Props) => {
  const { referenceId, profileType } = props;

  const width = useRightContentWidth();
  const classes = useStyles({ width });
  const dispatch = useDispatch();
  const { notes, loading } = useSelector(selectors.getNotes(referenceId));

  useEffect(() => {
    if (referenceId) dispatch(getNotes({ referenceId, profileType }));
  }, [dispatch, referenceId, profileType]);

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.Notes.${key}`);

  return (
    <div className={classes.root}>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        className={classes.bottomSpacing1}
      >
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t('notes')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          <CreateNote referenceId={referenceId} profileType={profileType} />
        </Grid>
      </Grid>
      <Scrollbars style={scrollbarsStyle} autoHide>
        <Grid
          container
          direction='column'
          spacing={2}
          className={cx(classes.fullWidth, classes.fixHiddenBorders)}
        >
          {notes && !lodashIsEmpty(notes) ? (
            notes.map((item) => {
              return (
                <Grid item key={item.id}>
                  <NoteCard profileType={profileType} {...item} />
                </Grid>
              );
            })
          ) : loading ? (
            <Grid item>
              <NoteCard profileType={profileType} loading />
              <NoteCard profileType={profileType} loading />
            </Grid>
          ) : (
            <Grid item>{t('empty')}</Grid>
          )}
        </Grid>
      </Scrollbars>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ width }: { width: number }) => width + 2,
    minWidth: ({ width }: { width: number }) => width + 2,
    height: 'calc(100% - 36px)',
    minHeight: 'calc(100vh - 260px)',
    '& #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #section-actions': {
        opacity: 1,
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  bottomSpacing1: {
    marginBottom: theme.spacing(1),
  },
  fixHiddenBorders: {
    padding: 1,
  },
}));

const scrollbarsStyle = { width: '100%', height: '100%', minHeight: 'calc(100vh - 260px)' };

export default Notes;
