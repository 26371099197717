import React from 'react';
import { ArrayInput, SimpleFormIterator, NullableBooleanInput, FileInput } from 'react-admin';

import get from 'lodash/get';

import ObjectInputFactory from './ObjectInput';
import buildValidator from './buildValidator';

import PrimitiveFormIterator from '~/components/ra/PrimitiveFormIterator';

import {
  DateInput,
  DateTimeInput,
  FeeInput,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
} from './inputs';

import { FileField } from './fields';

const buildInputs = (props) => {
  const { context, objectSchema, defaultData, sourcePrefix = '', exclude } = props;

  const label = (s) => {
    return `jsonSchema.${context}.${s}`;
  };
  function toSource(key) {
    if (sourcePrefix) {
      return `${sourcePrefix}.${key}`;
    } else {
      return key;
    }
  }

  function buildInput(objectSchema, propertyKey) {
    const source = toSource(propertyKey);
    const propertySchema = objectSchema.properties[propertyKey];

    const defaultValue = defaultData ? get(defaultData, source) : undefined;

    const validator = buildValidator(propertyKey, objectSchema, propertySchema, defaultValue);

    switch (propertySchema.inputType) {
      case 'textarea':
        return <TextInput multiline label={label(source)} source={source} validate={validator} />;
      default: // do nothing
    }
    switch (propertySchema.type) {
      case 'integer':
      case 'number':
        return (
          <NumberInput
            label={label(source)}
            source={source}
            validate={validator}
            defaultValue={defaultValue}
          />
        );
      case 'string':
        switch (propertySchema.format) {
          case 'date-time':
            return <DateTimeInput label={label(source)} source={source} validate={validator} />;
          case 'date':
            return <DateInput label={label(source)} source={source} validate={validator} />;
          default: // do nothing
        }
        if (propertySchema.enum) {
          return (
            <SelectInput
              label={`${label(source)}._`}
              source={source}
              validate={validator}
              choices={propertySchema.enum.map((enumValue) => ({
                id: enumValue,
                name: `${label(source)}.${enumValue}`,
              }))}
              defaultValue={defaultValue}
            />
          );
        } else {
          return (
            <TextInput
              label={label(source)}
              source={source}
              validate={validator}
              defaultValue={defaultValue}
            />
          );
        }
      case 'boolean':
        return <NullableBooleanInput label={label(source)} source={source} validate={validator} />;
      case 'array':
        if (propertySchema.items.type === 'file') {
          return (
            <FileInput label={label(source)} source={source} validate={validator} multiple={true}>
              <FileField title='title' />
            </FileInput>
          );
        } else if (propertySchema.items.type === 'string') {
          return (
            <ArrayInput label={label(source)} source={source} validate={validator} multiple={true}>
              <PrimitiveFormIterator>
                <TextInput label={label(source)} />
              </PrimitiveFormIterator>
            </ArrayInput>
          );
        } else {
          return (
            <ArrayInput label={`${label(source)}._`} source={source} validate={validator}>
              <SimpleFormIterator>
                {buildInputs({
                  context: `${context}.${source}`,
                  objectSchema: propertySchema.items,
                })}
              </SimpleFormIterator>
            </ArrayInput>
          );
        }
      case 'file':
        return (
          <FileInput label={label(source)} source={source} validate={validator}>
            <FileField title='title' />
          </FileInput>
        );
      case 'object':
        return (
          <ObjectInput
            context={context}
            defaultData={defaultData}
            objectSchema={propertySchema}
            source={source}
            required={objectSchema.required && objectSchema.required.includes(propertyKey)}
          />
        );
      case 'veengu.product':
        return (
          <ReferenceInput
            label={label(source)}
            source={source}
            reference='products'
            validate={validator}
            defaultValue={defaultValue}
          >
            <SelectInput optionText='code' />
          </ReferenceInput>
        );
      case 'veengu.fee':
        return (
          <FeeInput
            label={label(source)}
            source={source}
            validate={validator}
            types={propertySchema.enum}
            defaultValue={defaultValue}
          />
        );
      default: // do nothing
    }
  }

  return Object.keys(objectSchema.properties)
    .filter((propertyKey) => {
      const source = toSource(propertyKey);
      return !exclude || !exclude(source);
    })
    .sort((a, b) => {
      return (a + '').localeCompare(b);
    })
    .sort((a, b) => {
      const p1 = objectSchema.properties[a],
        p2 = objectSchema.properties[b];
      if ((p1.formIndex || 999) < (p2.formIndex || 999)) {
        return -1;
      } else if ((p1.formIndex || 999) > (p2.formIndex || 999)) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((propertyKey) => {
      const input = buildInput(objectSchema, propertyKey);
      return input;
    });
};

export default buildInputs;

const ObjectInput = ObjectInputFactory(buildInputs);
