import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import Polyglot from 'node-polyglot';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import { createBrowserHistory as createHistory } from 'history';

import i18n from './i18n';
import createAdminStore from './createAdminStore';

import { Layout, Login } from './layout';
import { Dashboard } from './home';
import theme from './theme';
import SnackbarNotifications from '~/utils/SnackbarNotifications';
import onError from './errorsHandler';

import accounts from './resources/accounts';
import individuals from './resources/individuals';
import businesses from './resources/businesses';
import solutions from './resources/solutions';
import configurations from './resources/configurations';
import auditLog from './resources/auditLog';
import identities from './resources/identities';
import apiIdentities from './resources/apiIdentities';
import localization from './resources/localization';
import locations from './resources/locations';
import terminals from './resources/terminals';
import employees from './resources/employees';
import externalProfileLinks from './resources/externalProfileLinks';
import paymentDetails from './resources/paymentDetails';
import pricings from '~/resources/pricings';
import partners from '~/resources/partners';
import pnlAccount from '~/resources/pnlAccount';
import bankAccount from './resources/bankAccount';
import currencies from './resources/currencies';
import FXMappingList from './resources/currencies/components/FXMappingList';
import headTransactions from './resources/headTransactions';
import deals from './resources/deals';
import actionReports from './resources/actionReports';
import events from './resources/events';
import batches from './resources/batches';
import paymentGroups from './resources/paymentServices/groups';
import paymentServices from './resources/paymentServices';
import roles from './resources/roles';
import profileTags from './resources/profileTags';

import Preloader from '~/components/Preloader';
import { YMInitializer } from 'react-yandex-metrika';
import { getTenantBranding } from './api/utils';
import { merge } from 'lodash';
// import ym from 'react-yandex-metrika';

let locale = 'en';
moment.locale(locale);

const polyglot = new Polyglot({ locale, phrases: (i18n as any)[locale] });

let translate = polyglot.t.bind(polyglot);

export const i18nProvider = {
  translate: (key: string, options: any) => translate(key, options),
  changeLocale: (newLocale: string) => {
    locale = newLocale;
    return new Promise(() => {
      // load new messages and update the translate function
    });
  },
  getLocale: () => locale,
};

export const history = createHistory();
export const { store, persistor } = createAdminStore({
  authProvider,
  dataProvider,
  history,
});

/*
history.listen((location) => {

  if (window.location.hostname === 'demo.veengu.cloud') {
    ym('hit', location.pathname);
  }
  // location is an object like window.location
  //console.log(action, location.pathname, location.state);
});
*/

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError,
    },
    queries: {
      onError,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [branding, setBranding] = useState<any | undefined>();
  useEffect(() => {
    getTenantBranding().then((data) => {
      setBranding(data);
    });
  }, []);
  useEffect(() => {
    if (branding) {
      const link = document.querySelector('link[rel="shortcut icon"]');
      if (link) {
        const base64String = branding.theme.favicon;
        link.setAttribute('href', base64String || '/veengu.ico');
      }
    }
  }, [branding]);

  if (!branding) {
    return null;
  }

  branding.theme = merge(theme, branding.theme || {});

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Preloader>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                <SnackbarNotifications />
                <Admin
                  authProvider={authProvider}
                  dataProvider={dataProvider}
                  i18nProvider={i18nProvider}
                  layout={Layout}
                  loginPage={Login(branding)}
                  dashboard={Dashboard}
                  theme={branding.theme}
                  history={history}
                  disableTelemetry
                >
                  <Resource name='accounts' {...accounts} />
                  <Resource name='externalSources' />
                  <Resource name='individuals' {...individuals} />
                  <Resource name='individuals/accounts' />
                  <Resource name='tenant/accounts' {...pnlAccount} />
                  <Resource name='bank/accounts' {...bankAccount} />
                  <Resource name='businesses' {...businesses} />
                  <Resource name='businesses/accounts' />
                  <Resource name='locations' {...locations} />
                  <Resource name='terminals' {...terminals} />
                  <Resource name='employees' {...employees} />
                  <Resource name='paymentDetails' {...paymentDetails} />
                  <Resource name='products' />
                  <Resource name='pricings' {...pricings} />
                  <Resource name='productTemplates' />
                  <Resource name='features' />
                  <Resource name='configurations/settings' {...configurations} />
                  <Resource name='configurations/pricing' {...configurations} />
                  <Resource name='solutions' {...solutions} />
                  <Resource name='partners' {...partners} />
                  <Resource name='auditLog' {...auditLog} />
                  <Resource name='roles' {...roles} />
                  <Resource name='fxrates' {...currencies} />
                  <Resource name='fxrates/mappings' list={FXMappingList} />
                  <Resource name='headTransactions' {...headTransactions} />
                  <Resource name='deals' {...deals} />
                  <Resource name='beneficiaries' />
                  <Resource name='team' {...identities} />
                  <Resource name='apiIdentities' {...apiIdentities} />
                  <Resource name='actionReports' {...actionReports} />
                  <Resource name='events' {...events} />
                  <Resource name='batches' {...batches} />
                  <Resource name='paymentServices/groups' {...paymentGroups} />
                  <Resource name='paymentServices' {...paymentServices} />
                  <Resource name='localization' {...localization} />
                  <Resource name='externalProfileLinks' {...externalProfileLinks} />
                  <Resource name='profileTags' {...profileTags} />
                </Admin>
              </MuiPickersUtilsProvider>
            </Preloader>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
      {window.location.hostname === 'demo.veengu.net' ? (
        <YMInitializer
          accounts={[65837992]}
          options={{
            webvisor: true,
            trackHash: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version='2'
        />
      ) : null}
    </>
  );
};

export default App;
