import React, { Fragment, useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import { MenuItem, Dialog, DialogTitle, DialogContent, Grid, SvgIcon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useHandbook, useNotify } from '~/hooks';
import { EditButton } from '~/components';
import { eventLogUpdate } from '~/utils';
import { beginKycCheck } from '~/api/kyc';
import { Individual } from '~/types';
import { ShieldCheckmarkIcon } from '~/img';

const label = (key: string): string => `resources.individuals.fields.${key}`;

interface Props {
  resource: string;
  record: Individual | undefined;
  solutionId?: string;
  permissions: string[];
}

const KYCLevelCheck = (props: Props) => {
  const { solutionId, resource, record, permissions } = props;
  const notify = useNotify();
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const currentKYC = record?.kycLevel;
  const [selected, setSelected] = useState<string>(currentKYC || '');

  const { data } = useHandbook(solutionId);
  const kycLevels = (data?.kycLevels || []).sort((a, b) => {
    return a.priority - b.priority;
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (currentKYC) {
      setSelected(currentKYC);
    }
    setOpen(false);
  };

  const handleCheckKyc = async () => {
    notify({ message: 'KYC check initiated', type: 'info' });
    if (!record || !selected) return null;
    try {
      await beginKycCheck('INDIVIDUAL', record.id, selected);
      eventLogUpdate(resource);
      handleClose();
    } catch (error) {
      notify({ message: error.message, type: 'error' });
    }
  };

  const classes = useStyles();

  return (
    <Fragment>
      <EditButton
        label={t('checkKYC')}
        icon={
          <SvgIcon>
            <ShieldCheckmarkIcon />
          </SvgIcon>
        }
        onClick={handleOpen}
        disabled={!permissions.includes('kyc.workflow.start')}
      />
      <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
        <DialogTitle className={classes.DialogTitle} aria-label='simple-dialog-title'>
          {t('selectKYCtoCheck')}
        </DialogTitle>
        <DialogContent>
          <Grid container justify='center'>
            {kycLevels?.map((item, index) => {
              return (
                <Grid item xs={12} key={item.id}>
                  <MenuItem
                    component='button'
                    selected={selected === item.id}
                    onClick={() => setSelected(item.id)}
                    key={index}
                    value={item.id}
                    className={classes.menuItem}
                    disabled={currentKYC === item.id}
                  >
                    {item.name}
                  </MenuItem>
                </Grid>
              );
            })}
          </Grid>
          <Grid container spacing={1} justify='center' className={classes.topSpacing}>
            <Grid item>
              <Button label='ra.action.cancel' onClick={handleClose} />
            </Grid>
            <Grid item>
              <Button
                label={t('checkKYC')}
                variant='contained'
                onClick={handleCheckKyc}
                disabled={selected === currentKYC || !selected}
              >
                <SvgIcon>
                  <ShieldCheckmarkIcon />
                </SvgIcon>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: '100%',
    justifyContent: 'center',
  },
  topSpacing: {
    marginTop: theme.spacing(2),
  },
  DialogTitle: {
    '& > h2': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default KYCLevelCheck;
