import React from 'react';
import {
  Query,
  Loading,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  usePermissions,
} from 'react-admin';
import { required } from 'react-admin';

import { Create } from '~/layout';
import { HiddenInput, NoPermissions, ReferenceInput } from '~/components/ra';
import ConfigurationForm from './ConfigurationForm';

const ConfigurationReferenceInput = ({ resource }) => {
  return (
    <FormDataConsumer>
      {({ formData }) => {
        return formData ? (
          <ReferenceInput
            source='parentId'
            reference={resource}
            filter={{
              solutionId: formData.solutionId,
            }}
            validate={required()}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
        ) : null;
      }}
    </FormDataConsumer>
  );
};

const ConfigurationCreate = (props) => {
  let solutionId = null;
  let resource = null;
  let type = null;
  if (props && props.location && props.location.state) {
    solutionId = props.location.state.parentId;
    resource = props.location.state.resource;
    type = props.location.state.type;
  }
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions.includes('solution.settings.update')) return <NoPermissions />;

  return (
    <Query
      type='getSolutionConfigurationSchema'
      resource='solutionConfigurationSchema'
      payload={{ id: solutionId, resource }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <p>Failed to load solution configuration schema</p>;
        }
        return (
          <Create {...props}>
            <SimpleForm
              redirect='show'
              initialValues={{ solutionId, type }}
              // redirect={redirect}
            >
              <HiddenInput source='solutionId' disabled />
              <HiddenInput source='type' disabled />
              <ConfigurationReferenceInput resource={resource} />
              <TextInput source='name' validate={required()} />
              <ConfigurationForm configurationSchema={data.configurationSchema} />
            </SimpleForm>
          </Create>
        );
      }}
    </Query>
  );
};

export default ConfigurationCreate;
