import React, { FC, useState } from 'react';
import { useTranslate, Button, SimpleForm, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { AsideBase } from '~/layout';
import { Skeleton } from '@material-ui/lab';
import { useGetProfileTagsByResource, useMutateProfileTags } from '~/hooks/profileTags';
import CreateIcon from '@material-ui/icons/Create';
import { Grid, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { BottomToolbar, ChipField, CreateButton, H2, TooltipIcon } from '~/components';
import { AutocompleteArrayTextInput } from './ra';
import { isEmpty } from 'lodash';
import { Business, Individual, Partner } from '~/types';
import palette from '~/theme/palette';

interface ProfileTagsProps {
  profileId: string;
  resource: 'businesses' | 'individuals' | 'partners';
  permissions: string[];
  record: Business | Individual | Partner;
  changelogMode?: boolean;
}

const ProfileTags: FC<ProfileTagsProps> = (props) => {
  const { profileId, resource, permissions, record, changelogMode } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`components.ProfileTags.${key}`);
  const classes = useStyles();

  let hasPermissionToAddTags = false;
  if (resource === 'businesses') {
    hasPermissionToAddTags = permissions.includes('business.tags.update');
  } else if (resource === 'individuals') {
    hasPermissionToAddTags = permissions.includes('individual.tags.update');
  } else if (resource === 'partners') {
    hasPermissionToAddTags = permissions.includes('partner.tags.update');
  }

  const tags: string[] | undefined = record?.tags;
  return (
    <div>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={1} className={classes.h2}>
          <>
            {t('profileTags')}
            <TooltipIcon title={t('profileTagsTooltip')} />
          </>
        </H2>
        <div id='section-actions'>
          {hasPermissionToAddTags && !changelogMode && (
            <ProfileTagsEdit resource={resource} profileId={profileId} record={record} />
          )}
        </div>
      </header>
      <Grid container direction='column' spacing={4}>
        <Grid item xs={12}>
          {isEmpty(tags) ? (
            t('noTagsYet')
          ) : (
            <AsideBase className={changelogMode ? classes.changelogMode : undefined}>
              <Grid container direction='column'>
                <Grid item xs={12}>
                  {tags?.map((tag, index) => (
                    <ChipField key={index} label={tag} />
                  ))}
                </Grid>
              </Grid>
            </AsideBase>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

interface ProfileTagsEditProps {
  resource: 'businesses' | 'individuals' | 'partners';
  profileId: string;
  record: Business | Individual | Partner;
}

export const ProfileTagsEdit: FC<ProfileTagsEditProps> = (props) => {
  const { profileId, resource, record } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const refresh = useRefresh();
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string) => translate(`components.ProfileTags.${key}`);

  const tags: string[] | undefined = record?.tags;

  const { data: allTags, isLoading } = useGetProfileTagsByResource(resource, open);
  const { mutate } = useMutateProfileTags();

  const handleSubmit = (values: any) => {
    mutate(
      { resource: resource, profileId: profileId, tags: values.tags },
      {
        onSuccess: () => {
          refresh();
          handleClose();
        },
      }
    );
  };

  const autocompleteOptions = allTags?.map((tag) => tag?.name);
  return (
    <>
      {isEmpty(tags) ? (
        <CreateButton variant='add' onClick={handleOpen} />
      ) : (
        <Button label={t('edit')} onClick={handleOpen}>
          <CreateIcon />
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>
          {isEmpty(tags) ? t('addProfileTags') : t('editProfileTags')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {isLoading ? (
            <Skeleton width='100%' height={24} />
          ) : (
            <SimpleForm
              className={classes.form}
              record={record}
              toolbar={
                <BottomToolbar
                  onSave={handleSubmit}
                  onClose={handleClose}
                  className={classes.toolbar}
                />
              }
            >
              <AutocompleteArrayTextInput
                label={t('profileTags')}
                source='tags'
                options={autocompleteOptions}
                className={classes.autocomplete}
                freeSolo={false}
              />
            </SimpleForm>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
  h2: {
    lineHeight: '30px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: 360,
    boxSizing: 'border-box',
  },
  toolbar: {
    backgroundColor: 'transparent',
    marginTop: 0,
    minHeight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  autocomplete: {
    width: '100%',
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  },
}));

export default ProfileTags;
