import React, { FC, useState } from 'react';
import { TextInput } from 'react-admin';
import { SelectInput, required, useTranslate } from 'react-admin';
import { Grid, Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';

import { CountryInput, CreateForm, ReferenceInput } from '~/components/ra';
import FullscreenDrawer from '~/components/FullscreenDrawer';
import { Section } from '~/layout';
import { history } from '~/App';
import { Alert, AlertTitle } from '@material-ui/lab';
import onError from '~/errorsHandler';
import validatePhoneNumber from '~/utils/validatePhoneNumber';

interface Props {
  open: boolean;
  onClose: () => void;
}

const IndividualCreateDrawer: FC<Props> = (props) => {
  const { open, onClose } = props;

  const [isAlreadyRegistered, setAlreadyRegistered] = useState(false);

  const resetAlreadyRegistered = () => {
    isAlreadyRegistered && setAlreadyRegistered(false);
  };

  const handleError = (error: any) => {
    const code: string | undefined = error?.body?.code;
    if (code === 'RESOURCE_ALREADY_EXISTS') {
      setAlreadyRegistered(true);
    } else {
      onError(error);
    }
  };

  const handleClose = () => {
    onClose();
    resetAlreadyRegistered();
  };

  const t = useTranslateIndividualCreate();
  const classes = useStyles();

  return (
    <FullscreenDrawer
      open={open}
      onClose={handleClose}
      heading={t('createNewProfile')}
      subtitle={t('enterThePrimaryDetails')}
    >
      <CreateForm
        resource='individuals'
        onSuccess={(res: any) => history.push(`/individuals/${res.data.id}/show/profile`)}
        onError={handleError}
        style={fullWidth}
        classes={classes}
      >
        <IndividualForm
          isAlreadyRegistered={isAlreadyRegistered}
          resetAlreadyRegistered={resetAlreadyRegistered}
        />
      </CreateForm>
    </FullscreenDrawer>
  );
};

const IndividualForm: FC<{ isAlreadyRegistered: boolean; resetAlreadyRegistered: () => void }> = (
  props
) => {
  const { isAlreadyRegistered, resetAlreadyRegistered } = props;
  const formValues = useFormState().values;

  const tc = useTranslateIndividualCreate();
  const t = useTranslateIndividualFields();

  const secondFormActive = formValues.solutionId && formValues.country;

  return (
    <>
      <Grid container>
        <Stepper nonLinear orientation='vertical' style={fullWidth}>
          <Step active expanded>
            <StepLabel>{tc('1row')}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ReferenceInput
                    source='solutionId'
                    reference='solutions'
                    filter={{
                      hasFeatures: ['veengu_individual_onboarding_dashboard'],
                    }}
                    validate={required()}
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <CountryInput
                    solutionId={formValues.solutionId}
                    source='country'
                    validate={required()}
                    label={t('country')}
                    disabled={!formValues.solutionId}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>
          <Step active={secondFormActive} expanded={secondFormActive}>
            <StepLabel>{tc('2row')}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput source='firstName' validate={required()} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextInput source='lastName' validate={required()} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    source='phoneNumber'
                    validate={[required(), validatePhoneNumber]}
                    options={{ error: isAlreadyRegistered }}
                    onChange={resetAlreadyRegistered}
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        </Stepper>
      </Grid>
      {isAlreadyRegistered && (
        <Section>
          <Alert severity='error'>
            <AlertTitle>{tc('alreadyRegistered')}</AlertTitle>
            {tc('youCantCreateProfile')}
          </Alert>
        </Section>
      )}
    </>
  );
};

const useTranslateIndividualCreate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.individuals.create.${key}`);
};
const useTranslateIndividualFields = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.individuals.fields.${key}`);
};

const fullWidth = { width: '100%' };

const useStyles = makeStyles((theme) => ({
  bottomToolbar: {
    marginLeft: theme.spacing(3),
  },
}));

export default IndividualCreateDrawer;
