import React, { Fragment, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { time } from '~/utils';

import { RibbonIcon, ReceiptIcon } from '~/img';
import { Drawer, TextField, H3 } from '~/components';
import { AuditLogType } from '~/types';
import { useApiLazy } from '~/hooks';
import { resource } from './AuditLogList';
import { getProfileDetails, ProfileDetail } from '~/api/solutions';
import { Tags } from './CustomCell';
import ProfileReference from '~/components/TransactionsHistory/ProfileReference';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  topSpacing: {
    marginTop: 1,
  },
  breakWord: {
    overflowWrap: 'break-word',
  },
}));

interface Props {
  showData: AuditLogType | null;
  onClose: () => void;
}

const AuditLogShow = (props: Props) => {
  const { showData, onClose } = props;

  const [
    getProfileDetailsRequest,
    { data: profileDetail, loading, error },
  ] = useApiLazy<ProfileDetail>(getProfileDetails, [showData]);

  useEffect(() => {
    if (showData?.profileId && getProfileDetailsRequest && loading === false) {
      getProfileDetailsRequest(showData.profileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showData, getProfileDetailsRequest]);

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.${resource}.${key}`);

  const classes = useStyles();

  return (
    <Drawer
      heading={time(showData?.timestamp).format('LL hh:mm:ss.ms')}
      open={!!showData}
      onClose={onClose}
    >
      {showData && (
        <Fragment>
          <div className={classes.bottomSpacing}>
            <TextField label={t('profileId')} icon={<RibbonIcon />}>
              {showData.profileId}
            </TextField>
          </div>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item xs>
              {profileDetail && (
                <ProfileReference
                  party={{
                    profileId: profileDetail.id,
                    profileType: profileDetail.type,
                  }}
                />
              )}
            </Grid>
            <Grid item xs>
              <TextField label={t('solutionId')} loading={loading} error={error}>
                {profileDetail?.solutionId}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                loading={loading}
                error={error}
                showLink={profileDetail && `/solutions/${profileDetail.solutionId}/show`}
                label={t('solution')}
              >
                {profileDetail?.solutionName}
              </TextField>
            </Grid>
          </Grid>
          <H3 icon={<ReceiptIcon />}>{t('actionLog')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item xs>
              <TextField label={t('message')} textWrap>
                {showData.message}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                label={t('actor')}
                showLink={
                  showData &&
                  showData.identityType?.toLowerCase() === 'dashboard' &&
                  `/team/${showData.identityId}/show`
                }
              >
                {showData.identityName}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField label={t('channel')}>{showData.identityType}</TextField>
            </Grid>
          </Grid>
          <H3 icon={<FingerprintIcon />}>{t('auditInfo')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item xs>
              <TextField label={t('ip')}>{showData.clientAddress}</TextField>
            </Grid>
            <Grid item xs>
              <TextField label={t('hash')} textWrap className={classes.breakWord}>
                {showData.hash}
              </TextField>
            </Grid>
            {showData.tags && !!showData.tags.length && (
              <Grid item xs>
                <TextField label={t('tags')}>
                  <Tags tags={showData.tags} className={classes.topSpacing} />
                </TextField>
              </Grid>
            )}
            {showData.reference && !!showData.reference.length && (
              <Grid item xs>
                <TextField label={t('reference')}>
                  {showData.reference.map((ref) => (
                    <div key={ref}>{ref}</div>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </Drawer>
  );
};

export default AuditLogShow;
