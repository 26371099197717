import axios from '~/utils/axios';
import { OperationType } from '~/types/OperationType';
import { Status } from '~/components/StatusField';
import { ActionReport } from '~/types';

export type HeadTransactionSearchFilterDto = {
  createdFrom?: string;
  createdTo?: string;
  senderAccountNumber?: string;
  recipientAccountNumber?: string;
  operationType?: OperationType;
  status?: Status;
  baseAmountFrom?: number;
  baseAmountTo?: number;
  baseAmountCurrency?: string;
  referenceId?: string;
  reportType?: string;
  reportStatus?: ActionReport['status'];
  next?: string;
  prev?: string;
  limit?: number;
  reportSystem?: string;
};

export type DealSearchFilterDto = {
  createdFrom?: string;
  createdTo?: string;
  // senderProfileId?: string; // ignore
  // senderAccountId?: string; // ignore
  senderAccountNumber?: string;
  // recipientProfileId?: string; // ignore
  // recipientAccountId?: string; // ignore
  recipientAccountNumber?: string;
  // status?: ActionReport['status'];
  beneficiaryAmountFrom?: number;
  beneficiaryAmountTo?: number;
  beneficiaryAmountCurrency?: string;
  next?: string;
  prev?: string;
  limit?: number;
};

export type BackgroundTaskStatus = 'INITIAL' | 'IN_PROGRESS' | 'DONE' | 'FAILED';

export interface CreateBackgroundTaskParams<A = Record<string, any>> {
  type:
    | 'HEAD_TRANSACTION_EXPORT'
    | 'DEAL_EXPORT'
    | 'TENANT_INVOICE'
    | 'INDIVIDUAL_EXPORT'
    | 'BUSINESS_EXPORT'
    | 'EVENT_EXPORT'
    | 'BALANCE_TRANSACTION_EXPORT'
    | 'BANK_ACCOUNT_TRANSFER_EXPORT'
    | 'INNBUCKS_INDIVIDUAL_IMPORT';
  arguments: A;
}

export const createBackgroundTask = (
  params: CreateBackgroundTaskParams<HeadTransactionSearchFilterDto | DealSearchFilterDto>
) => {
  return axios
    .post<{
      id: string;
      taskType: CreateBackgroundTaskParams['type'];
      arguments: HeadTransactionSearchFilterDto | DealSearchFilterDto;
      status: BackgroundTaskStatus;
      created: string;
      updated: string;
    }>(`/backgroundTasks`, params)
    .then((res) => res.data);
};

export const createBackgroundTaskImport = (params: any) => {
  const { type, formData, queryArguments } = params;
  const solutionId = formData?.solution;
  const form = new FormData();
  const fileBlob = new Blob([formData?.file?.rawFile], { type: 'application/octet-stream' });
  form.append('file', fileBlob, formData?.file?.rawFile.name);
  form.append(
    'arguments',
    JSON.stringify({ type: type, arguments: queryArguments ? queryArguments : {} })
  );
  return axios
    .post<{
      id: string;
      taskType: CreateBackgroundTaskParams['type'];
      arguments: {};
      status: BackgroundTaskStatus;
      created: string;
      updated: string;
    }>(`/backgroundTasks/withFile`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'V-Solution': solutionId,
      },
    })
    .then((res) => res.data);
};

export type BackgroundTaskType = {
  id: string;
  taskType: CreateBackgroundTaskParams['type'];
  arguments: HeadTransactionSearchFilterDto;
  status: BackgroundTaskStatus;
  statusMessage?: string;
  resultType?: 'file' | 'json';
  created: string;
  updated: string;
};

export const getBackgroundTask = (id: string) => {
  return axios.get<BackgroundTaskType>(`/backgroundTasks/${id}`).then((res) => res.data);
};

export type ImportBackgroundTaskResult = {
  errors: string;
  records: string;
  resultFile: string;
};

export const getBackgroundTaskResult = (params: any) => {
  const { id, resultType } = params;
  return axios
    .get<ImportBackgroundTaskResult | any>(`/backgroundTasks/${id}/result`, {
      responseType: resultType === 'json' ? 'json' : 'arraybuffer',
    })
    .then((res) => (resultType === 'json' ? res.data : res));
};
