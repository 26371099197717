import React, { FC, Fragment } from 'react';
import { SelectInput, TextInput, required, useTranslate } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

import {
  // KycLevelInput,
  // AutocompleteArrayTextInput,
  SelectArrayInput,
  ReferenceInput,
  AutocompleteArrayTextInput,
} from '~/components/ra';
import { SelectTransactionType } from '~/components/TransactionsHistory/Filters';
import { l } from '../CurrenciesList';

const FXMappingForm: FC<any> = (props) => {
  const providedSolutionId: string = props.providedSolutionId;

  const { record } = props;

  const form = useForm();
  useFormState();
  const { solutionId } = form.getState().values || {};
  const resetField = (source: string) => () => {
    form.change(source, undefined);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <ReferenceInput
        label={t('solutionId')}
        source='solutionId'
        reference='solutions'
        onChange={resetField('productId')}
        validate={required()}
      >
        <SelectInput optionText='name' disabled={providedSolutionId && true} />
      </ReferenceInput>
      {solutionId ? (
        <ReferenceInput
          label={t('productId')}
          source='productId'
          reference='products'
          filter={{ solutionId }}
        >
          <SelectInput optionText='name' validate={required()} />
        </ReferenceInput>
      ) : (
        <TextInput label={t('productId')} source='productId' validate={required()} disabled />
      )}
      <ReferenceInput
        label={t('ratesId')}
        source='ratesId'
        reference='fxrates'
        validate={required()}
      >
        <SelectInput optionText='name' />
      </ReferenceInput>
      <SelectArrayInput
        label={t('operationTypes')}
        source='operationTypes'
        choices={
          (SelectTransactionType.getChoices(translate) as any[]).filter((item) => !!item.id) || []
        }
      />
      <AutocompleteArrayTextInput
        source='tags'
        label={t('tags')}
        record={record}
        regularHelperText={t('tagsHelperText')}
      />
      <TextInput label={t('form.name')} source='name' />
    </Fragment>
  );
};

export default FXMappingForm;
