import React, { cloneElement, FC, Fragment, useState } from 'react';
import {
  useListController,
  Pagination,
  Datagrid,
  TextField,
  ListToolbar,
  useTranslate,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  usePermissions,
} from 'react-admin';
import { ShowController, ChipField, NoPermissions } from '~/components/ra';
import { SelectTransactionType } from '~/components/TransactionsHistory/Filters';
import styled from 'styled-components';
import FXMappingCreate from './FXMappingCreate';
import { Longdash } from '~/utils';
import { l } from '../CurrenciesList';
import FXMappingShow from './FXMappingShow';

const listProps = {
  resource: 'fxrates/mappings',
  basePath: '/fxrates/setup/fxPlans',
  path: '/fxrates/setup/fxPlans',
  hasCreate: false,
  hasEdit: false,
  hasList: true,
  hasShow: false,
  title: null,
  bulkActionButtons: false,
  query: {
    sort: '',
    order: '',
    page: 1,
    perPage: 25,
  },
};

const MyList = (props: any) => {
  const controllerProps = useListController(props);
  return (
    <div>
      <ListToolbar {...controllerProps} actions={props.actions} permanentFilter={props.filter} />
      {cloneElement(props.children, {
        ...controllerProps,
        hasBulkActions: props.bulkActionButtons !== false,
      })}
      <Pagination {...controllerProps} />
    </div>
  );
};

const FXMappingList: FC<any> = (props) => {
  const { solutionId } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const [showData, setShowData] = useState(null);
  const handleOpen = (data: any) => {
    setShowData(data);
  };
  const handleClose = () => setShowData(null);

  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions?.includes('fxrates.list')) return <NoPermissions />;

  return (
    <Fragment>
      <FXMappingShow permissions={permissions} record={showData} onClose={handleClose} />
      <MyList
        {...listProps}
        actions={<FXMappingCreate permissions={permissions} />}
        filter={solutionId && { solutionId }}
      >
        <Datagrid rowClick={(id: string, basePath: string, record: any) => handleOpen(record)}>
          {!solutionId && (
            <ReferenceField source='solutionId' reference='solutions' link={false} addLabel={false}>
              <TextField source='name' />
            </ReferenceField>
          )}
          <ReferenceField source='productId' reference='products' link={false} addLabel={false}>
            <TextField source='name' />
          </ReferenceField>
          <ArrayField source='operationTypes' label={t('operationTypes')}>
            <SingleFieldList linkType={false}>
              <ShowController>
                {({ showRecord, ...rest }) => {
                  if (!showRecord) return Longdash;
                  return (
                    <ChipField
                      record={{ name: translate(SelectTransactionType.l(showRecord)) }}
                      source='name'
                      {...rest}
                    />
                  );
                }}
              </ShowController>
            </SingleFieldList>
          </ArrayField>
          <ArrayField source='tags' label={t('tags')}>
            <SingleFieldList linkType={false}>
              <ShowController>
                {({ showRecord, ...rest }) => {
                  if (!showRecord) return Longdash;
                  return <ChipField record={{ name: showRecord }} source='name' {...rest} />;
                }}
              </ShowController>
            </SingleFieldList>
          </ArrayField>
          {/*
          <ShowController>
            {({ showRecord: mappingRecord, ...restMapping }) => {
              return (
                <ArrayField
                  source="kycLevels"
                  label={t('kycLevels')}
                  record={mappingRecord}
                  {...restMapping}
                >
                  <SingleFieldList linkType={false}>
                    <ShowController>
                      {({ showRecord }) => {
                        if (!showRecord) return Longdash;
                        return (
                          <KycLevelField
                            record={{ name: showRecord, solutionId: mappingRecord.solutionId }}
                            source="name"
                          />
                        );
                      }}
                    </ShowController>
                  </SingleFieldList>
                </ArrayField>
              );
            }}
          </ShowController>
          <ArrayField source="tags" label={t('tags')}>
            <SingleFieldList linkType={false}>
              <ShowController>
                {({ showRecord, ...rest }) => {
                  if (!showRecord) return Longdash;
                  return <ChipField record={{ name: showRecord }} source="name" {...rest} />;
                }}
              </ShowController>
            </SingleFieldList>
          </ArrayField>
              */}
          <ReferenceField source='ratesId' reference='fxrates' link={false} addLabel={false}>
            <TextField source='name' />
          </ReferenceField>
        </Datagrid>
      </MyList>
    </Fragment>
  );
};

export default styled(FXMappingList)`
  .filter-field {
    height: 64px;
    .MuiInputBase-root {
      height: 57px;
      width: 286px;
    }
  }
`;
