import React from 'react';
import { CheckboxGroupInput, useTranslate, required, BooleanInput, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { H3, TooltipIcon } from '~/components';
import { HiddenInput } from '~/components/ra';
import { useForm } from 'react-final-form';

interface ProfileTagFormProps {
  edit?: boolean;
}

const ProfileTagForm = (props: ProfileTagFormProps) => {
  const { edit } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.profileTags.fields.${key}`);
  const classes = useStyles();
  const form = useForm();
  const tagName = form?.getFieldState('name')?.value;

  return (
    <>
      <HiddenInput source='id' defaultValue={tagName} />
      <TextInput
        source='name'
        label={t('tagName')}
        fullWidth
        validate={required()}
        disabled={edit}
      />
      <TextInput source='description' label={t('description')} fullWidth />
      <H3 bottomSpacing={1}>
        <>
          {t('enabledForProfileType')}
          <TooltipIcon title={t('profileTypeTooltip')} />
        </>
      </H3>
      <CheckboxGroupInput
        label={null}
        source='profileTypes'
        choices={[
          { id: 'INDIVIDUAL', name: t('INDIVIDUAL') },
          { id: 'BUSINESS', name: t('BUSINESS') },
          { id: 'PARTNER', name: t('PARTNER') },
        ]}
        row={false}
        className={classes.checkbox}
        disabled={edit}
      />
      <BooleanInput
        source='shouldPromoteToTransactionTag'
        label={
          <>
            {t('populateToTransactionTags')}
            <TooltipIcon title={t('transactionTagsTooltip')} />
          </>
        }
        defaultValue={false}
        fullWidth
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  checkbox: {
    '& .MuiButtonBase-root': {
      height: 'unset',
    },
  },
}));

export default ProfileTagForm;
