import React from 'react';

import {
  Button,
  EditButton,
  ShowController,
  SimpleShowLayout,
  Query,
  Link,
  Loading,
  TopToolbar,
  usePermissions,
} from 'react-admin';

import { TextField, NoPermissions } from '~/components/ra';
import { ShowView } from '~/layout';

import JsonSchemaView from './jsonschema/View';

import cache from './cache';

const ConfigurationShowActions = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <Button
        color='primary'
        component={Link}
        to={{
          pathname: `/solutions/${data && data.solutionId}/show/${resource}`,
        }}
        label='Back to solution'
      />
      {
        // data.parentId && <EditButton basePath={basePath} record={data} />
        <EditButton basePath={basePath} record={data} />
      }
    </TopToolbar>
  );
};

/* eslint-disable */
const sanitizeControllerProps = ({ loaded, loading, defaultTitle, ...rest }) => rest;
/* eslint-enable */

const ConfigurationShow = (props) => {
  const { resource } = props;

  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (resource === 'configurations/settings') {
    if (!permissions.includes('solution.settings.view')) return <NoPermissions />;
  }
  if (resource === 'configurations/pricing') {
    if (!permissions.includes('solution.pricing.view')) return <NoPermissions />;
  }
  return (
    <ShowController {...props}>
      {(controllerProps) => {
        return (
          <ShowView
            {...props}
            {...controllerProps}
            actions={<ConfigurationShowActions {...props} />}
          >
            {controllerProps.record &&
              (cache.lastSchema && controllerProps.record.solutionId === cache.lastSolutionId ? (
                <SimpleShowLayout {...props} {...controllerProps}>
                  <TextField source='name' />
                  <JsonSchemaView
                    schema={cache.lastSchema}
                    edit={true}
                    source='data'
                    {...sanitizeControllerProps(controllerProps)}
                  />
                </SimpleShowLayout>
              ) : (
                <Query
                  type='getSolutionConfigurationSchema'
                  resource='solutionConfigurationSchema'
                  payload={{
                    id: controllerProps.record.solutionId,
                    resource: props.resource,
                  }}
                >
                  {({ data, loading, error }) => {
                    if (loading) {
                      return <Loading />;
                    }
                    if (error) {
                      return <p>Failed to load solution configuration schema</p>;
                    }
                    // cache.lastSchema = data.configurationSchema;
                    // cache.lastSolutionId = controllerProps.record.solutionId;
                    return (
                      <SimpleShowLayout {...props} {...controllerProps}>
                        <TextField
                          source='name'
                          label={`resources.${controllerProps.resource}.fields.name`}
                        />
                        <JsonSchemaView
                          schema={data.configurationSchema}
                          edit={true}
                          source='data'
                          {...sanitizeControllerProps(controllerProps)}
                        />
                      </SimpleShowLayout>
                    );
                  }}
                </Query>
              ))}
          </ShowView>
        );
      }}
    </ShowController>
  );
};

export default ConfigurationShow;
