import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Accordion, AccordionDetails, Grid } from '@material-ui/core';
import { AmountField, TextField, AccordionSummary, HilightChanges, H3 } from '~/components';
import { l } from '~/resources/pricings/PricingShow';
import { RegularFeeType } from '../ConfirmationDrawers/FeeConfirmationField';
import { compareTiers } from '../Forms/TierBased';
import { makeStyles } from '@material-ui/core/styles';
import { Tier } from '~/types/pricings';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

interface TierBasedViewProps {
  curr: RegularFeeType;
  prev: RegularFeeType;
}

export const TierBasedView = (props: TierBasedViewProps) => {
  const { curr, prev } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };
  return (
    <>
      <Grid item xs={12}>
        <TextField label={t('currency')}>
          <HilightChanges curr={curr?.currency} prev={prev?.currency}>
            {curr?.currency}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <H3 icon={<FormatLineSpacingIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {t('tiers')}
        </H3>
      </Grid>
      {curr?.tiers
        ?.sort((firstTier: Tier, secondTier: Tier) => {
          return compareTiers(firstTier, secondTier);
        })
        .map((item: Tier, index: number) => {
          const isExpanded = Boolean(expanded[`panel-${index}`]);
          return (
            <Grid key={index} item xs={12}>
              <Accordion
                expanded={isExpanded}
                onChange={handleChange(`panel-${index}`, isExpanded)}
                key={index}
              >
                <AccordionSummary
                  aria-controls={`${index}-content`}
                  id={`${index}-header`}
                  expanded={isExpanded}
                  heading={
                    <>
                      <div className={classes.tierHeader}>{t('tierStartsWith')}</div>
                      <HilightChanges curr={curr?.tiers} prev={prev?.tiers}>
                        <AmountField amount={item.boundary} />
                      </HilightChanges>
                    </>
                  }
                />
                <AccordionDetails
                  key='external-sources-details'
                  className={classes.AccordionDetails}
                >
                  <Grid container direction='column' spacing={2}>
                    <Grid item xs={12}>
                      <TextField label={t('feeAlgorithm')}>
                        <HilightChanges curr={curr?.tiers} prev={prev?.tiers}>
                          {t(item.type, 2)}
                        </HilightChanges>
                      </TextField>
                    </Grid>
                    {item.type === 'fixed' && (
                      <Grid item xs={12}>
                        <TextField label={t('amount')}>
                          <HilightChanges curr={curr?.tiers} prev={prev?.tiers}>
                            <AmountField amount={item?.amount} />
                          </HilightChanges>
                        </TextField>
                      </Grid>
                    )}
                    {item.type === 'percent' && (
                      <>
                        <Grid item xs={12}>
                          <TextField label={t('percent', 2)}>
                            <HilightChanges curr={curr?.tiers} prev={prev?.tiers}>
                              {item?.percent?.toString() + '%'}
                            </HilightChanges>
                          </TextField>
                        </Grid>
                        <Grid item>
                          <Grid container direction='row' spacing={2}>
                            <Grid item xs={6}>
                              <TextField label={t('minAmount')}>
                                <HilightChanges curr={curr?.tiers} prev={prev?.tiers}>
                                  <AmountField amount={item?.minAmount} />
                                </HilightChanges>
                              </TextField>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField label={t('maxAmount')}>
                                <HilightChanges curr={curr?.tiers} prev={prev?.tiers}>
                                  <AmountField amount={item?.maxAmount} />
                                </HilightChanges>
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    display: 'block',
  },
  tierHeader: {
    fontSize: 10,
    fontWeight: 'normal',
    marginBottom: 4,
  },
}));

export default TierBasedView;
