import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import RoleForm from './RoleForm';
import { CreateButton, Drawer } from '~/components';
import { CreateForm } from '~/components/ra';
import { l } from './RoleShow';

interface Props {
  permissions?: string[];
}

const IdentityCreate = (props: Props) => {
  const { permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton
        onClick={handleOpen}
        disabled={!permissions?.includes('dashboardRole.create')}
      />
      <Drawer heading={t('addRole')} open={open} onClose={handleClose}>
        <CreateForm resource='roles' closeParent={handleClose}>
          <RoleForm />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default IdentityCreate;
