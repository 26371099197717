import React, { FC } from 'react';
import Drawer from '../Drawer';
import { AddressView } from '../ra';
import { Address } from '../ra/AddressForm';

interface Props {
  address: Address | undefined;
  open: boolean;
  heading?: string;
  onClose: () => void;
  changelogMode?: boolean;
}

const AddressShow: FC<Props> = (props) => {
  const { address, heading = 'Address', open, onClose, changelogMode } = props;

  return (
    <Drawer heading={heading} open={open} onClose={onClose} changelogMode={changelogMode}>
      {address && <AddressView address={address} />}
    </Drawer>
  );
};

export default AddressShow;
