import React, { useState } from 'react';
import { useInput, useTranslate } from 'react-admin';

import lodashGet from 'lodash/get';

import AutocompleteArrayTextInputComponent from '~/components/AutocompleteArrayTextInput';

interface AutocompleteArrayTextInputProps {
  record?: any;
  source: string;
  label?: string;
  disableHelperText?: boolean;
  regularHelperText?: any;
  onChange?: any;
  initialValueProp?: string[];
  options?: string[];
  className?: any;
  freeSolo?: boolean;
  [x: string]: any;
}

const AutocompleteArrayTextInput = (props: AutocompleteArrayTextInputProps) => {
  const {
    record,
    source,
    label,
    disableHelperText,
    regularHelperText,
    onChange: providedOnChange,
    options,
    className,
    freeSolo = true,
    ...rest
  } = props;
  const translate = useTranslate();

  const {
    input: { onChange },
    meta: { touched, error },
    isRequired,
  } = useInput({
    source,
    ...rest,
  });

  const initial = props.initialValue || (record && lodashGet(record, source)) || [];
  const [value, setValue] = useState(initial);

  return (
    <AutocompleteArrayTextInputComponent
      label={label || source}
      value={value}
      onChange={(e, option, reason) => {
        onChange(option);
        setValue(option);
        providedOnChange && providedOnChange(e, option, reason);
      }}
      required={isRequired}
      errorMessage={touched && error && translate(error)}
      disableHelperText={disableHelperText}
      regularHelperText={regularHelperText}
      options={options}
      className={className}
      freeSolo={freeSolo}
    />
  );
};

export default AutocompleteArrayTextInput;
