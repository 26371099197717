import React, { FC, Fragment, useState } from 'react';
import { useTranslate, SelectInput, SaveButton, required, TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import ReviewDataIcon from '@material-ui/icons/Visibility';

import { Drawer, H2 } from '..';
import { resource } from '~/resources/actionReports/ActionReportsList';
import { SimpleForm } from '../ra';
import Toolbar from '../ra/Toolbar';
import { CheckActionReportRes } from '~/api/actionReports';
import { ActionReport } from '~/types';
import ActionReportDraftDrawer from './ActionReportDraftDrawer';
import { useCreateActionReportDraft } from '~/hooks/actionReports';

interface Props {
  open: boolean;
  onClose: () => void;
  initialValues: any;
  formConfig: CheckActionReportRes;
  objectId: string;
  objectType: ActionReport['objectType'];
}

type FormValues = {
  system: string;
  reportType: string;
  reason: string;
};

const ManualReportDrawer: FC<Props> = (props) => {
  const { open, onClose, initialValues, formConfig, objectId, objectType } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);
  const [actionReportDraft, setActionReportDraft] = useState<{
    report: ActionReport;
    reason: string;
  }>();
  const handleActionReportDraftClose = () => {
    setActionReportDraft(undefined);
    onClose();
  };

  const { mutateAsync, isLoading } = useCreateActionReportDraft();
  const onSave = async ({ reason, ...restFormValues }: FormValues) => {
    try {
      const reportDraft = await mutateAsync({
        ...restFormValues,
        objectId,
        objectType,
      });
      setActionReportDraft({ report: reportDraft, reason });
    } catch {}
  };

  return (
    <Fragment>
      <ActionReportDraftDrawer record={actionReportDraft} onClose={handleActionReportDraftClose} />
      <Drawer open={open} onClose={onClose} heading={t('manualReport')}>
        <H2 topSpacing={0}>{t('selectReportType')}</H2>
        <SimpleForm
          initialValues={initialValues}
          saving={isLoading}
          toolbar={
            <Toolbar>
              <SaveButton onSave={onSave} icon={<ReviewDataIcon />} label={t('reviewReportData')} />
            </Toolbar>
          }
        >
          <FormFields formConfig={formConfig} fullWidth />
        </SimpleForm>
      </Drawer>
    </Fragment>
  );
};

const FormFields: FC<{ formConfig: CheckActionReportRes; [x: string]: any }> = (props) => {
  const { formConfig, ...implicitProps } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.fields.${key}`);

  const getSystemChoices = () => {
    return formConfig.map(({ system }) => ({ id: system, name: system }));
  };
  const getReportTypeChoices = (system: string | undefined) => {
    return formConfig
      .find((item) => item.system === system)
      ?.reportTypes.map((rt) => ({ id: rt, name: rt }));
  };

  const form = useForm<Partial<FormValues>>();

  const [reportTypeChoices, setReportTypeChoices] = useState(
    getReportTypeChoices(form.getState().values.system)
  );

  const resetReportTypeIfSystemNotMatch = (e: any) => {
    const { value: system } = e.target || {};
    const newReportTypeChoices = getReportTypeChoices(system);
    setReportTypeChoices(newReportTypeChoices);
    const reportType = form.getState().values.reportType;
    if (system && reportType) {
      const isMatch = !!newReportTypeChoices?.find(({ id }) => id === reportType);
      if (!isMatch) {
        form.change('reportType', undefined);
      }
    }
  };

  return (
    <Fragment>
      <SelectInput
        source='system'
        label={t('system')}
        choices={getSystemChoices()}
        validate={required()}
        onChange={resetReportTypeIfSystemNotMatch}
        {...implicitProps}
      />
      <SelectInput
        source='reportType'
        label={t('reportType')}
        choices={reportTypeChoices}
        validate={required()}
        {...implicitProps}
      />
      <TextInput
        source='reason'
        label={t('reason')}
        multiline
        rows={5}
        rowsMax={20}
        validate={required()}
        {...implicitProps}
      />
    </Fragment>
  );
};

export default ManualReportDrawer;
