import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

import { useHandbook } from '~/hooks';

// import * as hb from '~/types/Handbook';
import SelectArrayInput from './SelectArrayInput';

export interface Props {
  solutionId?: string;
  multiple?: boolean;
  label?: string;
  [x: string]: any;
}

const KycLevelInput = ({ solutionId, multiple, label, ...rest }: Props) => {
  const { data } = useHandbook(solutionId);

  const translate = useTranslate();
  const tLabel = translate('utils.KycLevelInput.label');

  if (data && data.kycLevels?.length) {
    const kycLevels = (data?.kycLevels || []).sort((a, b) => {
      return a.priority - b.priority;
    });
    // let minLevel: hb.KycLevel | undefined = kycLevels.length > 0 ? kycLevels[0] : undefined;
    // let initialValue = minLevel ? minLevel?.id : undefined;
    if (multiple) {
      return (
        <SelectArrayInput choices={kycLevels} default source='' label={label || tLabel} {...rest} />
      );
    } else {
      return (
        <SelectInput
          choices={kycLevels}
          // initialValue={initialValue}
          default
          label={label || tLabel}
          {...rest}
        />
      );
    }
  } else {
    return (
      <SelectInput
        choices={[{ id: multiple ? [] : null, name: translate('utils.noHandbookValues') }]}
        {...rest}
        allowEmpty={rest.allowEmpty}
        label={label || tLabel}
      />
    );
  }
};

export default KycLevelInput;
