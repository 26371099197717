import React from 'react';
import { useTranslate } from 'react-admin';

import ProductForm from './ProductForm';
import { Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { productLabel as l } from './';
import { Product, Solution } from '~/types';

interface Props {
  productRecord: Product | null;
  solutionRecord: Solution;
  open: boolean;
  onClose: () => void;
  closeParent: () => void;
  permissions: string[];
}

const ProductEdit = (props: Props) => {
  const { productRecord, solutionRecord, open, onClose, closeParent, permissions } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const closeAll = () => {
    onClose();
    closeParent();
  };

  return (
    <Drawer heading={t('editProduct')} open={open} onClose={onClose}>
      {productRecord && (
        <EditForm
          resource='products'
          target='solutions'
          id={solutionRecord.id}
          resourceId={productRecord.id}
          record={productRecord}
          closeParent={closeAll}
          withDelete
          disabled={!permissions?.includes('solution.product.update')}
        >
          <ProductForm solutionRecord={solutionRecord} edit />
        </EditForm>
      )}
    </Drawer>
  );
};

export default ProductEdit;
