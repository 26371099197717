import React, { Fragment, useState, useCallback } from 'react';
import { ReferenceManyField, useTranslate, Filter } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { debounce, isEmpty, set as lodashSet } from 'lodash';

import {
  ShowController,
  ModalOrSelectInput,
  CurrencySelect,
  NoPermissions,
  ReferenceInput,
} from '~/components/ra';
import { TextInput, StatusField } from '~/components';
import PaymentDetailCard from './PaymentDetailCard';
import PaymentDetailsCreate from './PaymentDetailsCreate';
import { PaymentDetail, Business, Terminal } from '~/types';
import { cleanupFormValues } from '~/utils';
import RaPagination from '~/components/Pagination/RaPagination';
import usePath from '~/hooks/usePath';

type ShowControllerProps = {
  ids: string[];
  data: {
    [id: string]: PaymentDetail;
  };
  loaded: boolean;
  permissions: string[];
};

interface PaymentDetailsListProps {
  permissions: string[];
  [x: string]: any;
}

const PaymentDetailsList = (props: PaymentDetailsListProps) => {
  const { permissions } = props;
  const businessRecord = props.record as Business | undefined;

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.${key}`);

  const classes = useStyles();

  const [filters, setFilters] = useState<any>({});
  if (!businessRecord) return null;
  if (!permissions) return null;
  if (!permissions.includes('business.paymentDetails.list')) return <NoPermissions />;
  return (
    <Fragment>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <PaymentDetailsFilters setFilters={setFilters} businessRecord={businessRecord} />
        </Grid>
        <Grid item className={classes.bottomSpacing}>
          <PaymentDetailsCreate permissions={permissions} businessRecord={businessRecord} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Employees'
        reference='paymentDetails'
        target='businesses'
        pagination={
          <RaPagination
            className={classes.pagination}
            noBackgraund
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        filter={filters}
        {...props}
      >
        <ShowController>
          {(props: ShowControllerProps) => {
            const { ids, data, loaded } = props;
            if (loaded && isEmpty(ids)) return <div>{t(`noQr`)}</div>;
            return (
              <Grid container spacing={2}>
                {(ids || [{}, {}]).map((id, index) => {
                  const item = (data && data[id]) || {};
                  return (
                    <Grid item xs={6} key={index}>
                      <PaymentDetailCard
                        permissions={permissions}
                        value={item}
                        loading={!loaded}
                        parentProps={props}
                        businessRecord={businessRecord as Business}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
          }}
        </ShowController>
      </ReferenceManyField>
    </Fragment>
  );
};

const PaymentDetailsFilters = (props: any) => {
  const { setFilters, businessRecord } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.${key}`);
  const { changePath } = usePath();

  const debouncedSetFilters = useCallback(debounce(setFilters, 400), [setFilters]);
  const handleChange = (e: any) => {
    if (!e?.target) return null;
    const { name, value } = e.target;
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return cleanupFormValues({ ...lodashSet(prev, name, value) });
    });
  };
  const handleTerminalChange = (name: string, value: string) => {
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return cleanupFormValues({ ...lodashSet(prev, name, value) });
    });
  };
  const handleCurrencyChange = (e: any) => {
    if (!e?.target) return null;
    const { name = 'currency', value } = e.target;
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return cleanupFormValues({ ...lodashSet(prev, name, value || '') });
    });
  };

  const classes = useStyles();
  return (
    <Filter className={classes.filters} setFilters={() => null} hideFilter={() => null}>
      <TextInput
        name='name'
        label='Name'
        source='name'
        disableHelperText
        alwaysOn
        onChange={handleChange}
      />
      <ReferenceInput
        source='terminalId'
        reference='terminals'
        filter={{ businessId: businessRecord.id }}
        label={t('terminal')}
        alwaysOn
        name='terminalId'
      >
        <ModalOrSelectInput<Terminal> onChange={handleTerminalChange} optionText='label'>
          {(item) => {
            return (
              <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                <Grid item>
                  <StatusField status={item.status} variant='dot' />
                </Grid>
                <Grid item>{item.label}</Grid>
              </Grid>
            );
          }}
        </ModalOrSelectInput>
      </ReferenceInput>
      <TextInput
        source='amount'
        name='amount'
        type='number'
        label={t('fields.amount.value')}
        onChange={handleChange}
        style={{ width: 156 }}
        alwaysOn
      />
      <CurrencySelect
        source='currency'
        name='currency'
        label={t('fields.amount.currency')}
        onChange={handleCurrencyChange}
        solutionId={businessRecord.solutionId}
        style={{ width: 156 }}
        alwaysOn
      />
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(1) / 2,
  },
  filters: {
    alignItems: 'flex-start',
  },
}));

const perPage = 10;

export default PaymentDetailsList;
