import { ApiKey } from '~/types/terminals';
import axios from '~/utils/axios';

export const issueActivationCode = (terminalId: string, phoneNumber?: string) => {
  return axios.post(`/terminals/${terminalId}/activationCode`, {
    phone: phoneNumber ? phoneNumber : undefined,
  });
};

export const getActivationCode = (terminalId: string) => {
  return axios.get(`/terminals/${terminalId}/activationCode`);
};

export const deactivateTerminal = (terminalId: string) => {
  return axios.post(`/terminals/${terminalId}/deactivate`);
};

export const issueApiKey = (terminalId: string) => {
  return axios.post<ApiKey>(`/terminals/${terminalId}/issueApiKey`).then((res) => res.data);
};

export const getApiKeyInfo = (terminalId: string) => {
  return axios
    .get<ApiKey>(`/terminals/${terminalId}/apiKey`, {
      validateStatus: (status) => status === 200 || status === 404,
    })
    .then((res) => res.data);
};

export const revokeApiKey = (terminalId: string) => {
  return axios.post(`/terminals/${terminalId}/revokeApiKey`);
};
