import React, { FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import {
  AccordionDetails,
  AccordionSummary,
  CloseButton,
  Confirmation,
  TextField,
} from '~/components';
import { useApiIdentityTranslate } from '..';
import { useApiIdentityDevicesById, useDeleteDevice } from '~/hooks/apiIdentity';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Device } from '~/types/apiIdentity';
import { usePermissions } from 'react-admin';
import { hasTranslation, time } from '~/utils';

interface DevicesAndKeysShowProps {
  apiIdentityId: string;
}

const DevicesAndKeysShow: FC<DevicesAndKeysShowProps> = (props) => {
  const { apiIdentityId } = props;
  const t = useApiIdentityTranslate();
  const { permissions } = usePermissions();
  const hasDeviceDeletePermission = permissions?.includes('identity.update');

  const { data: devices, isLoading, refetch } = useApiIdentityDevicesById(apiIdentityId);

  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  const deleteDeviceMutation = useDeleteDevice();

  const handleDelete = (deviceId: string) => {
    deleteDeviceMutation.mutate(
      { deviceId: deviceId, apiIdentityId: apiIdentityId },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };
  if (isLoading) return <Skeleton />;
  return (
    <Grid item xs={12}>
      {!isEmpty(devices)
        ? devices?.map((device, index) => {
            const isExpanded = Boolean(expanded[`panel-${index}`]);
            const keys = Object.keys(device);
            return (
              <Accordion
                expanded={isExpanded}
                onChange={handleChange(`panel-${index}`, isExpanded)}
                key={device.id}
              >
                <AccordionSummary
                  aria-controls={`${index}-content`}
                  id={`${index}-header`}
                  expanded={isExpanded}
                  heading={device.deviceLabel}
                  actionNode={
                    hasDeviceDeletePermission && (
                      <Confirmation
                        onConfirm={() => {
                          handleDelete(device.id);
                        }}
                      >
                        <CloseButton
                          label={t('delete')}
                          // onClick={() => handleDelete(device.id)}
                          red
                        />
                      </Confirmation>
                    )
                  }
                />
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {keys.map((key, index) => {
                      if (key === 'id') return null;
                      return (
                        <Grid item xs={12} key={index}>
                          <TextField
                            label={hasTranslation(`components.ApiIdentity.${key}`) ? t(key) : key}
                          >
                            {key === 'enrolledAt'
                              ? time(device[key as keyof Device]).format('LL LT')
                              : device[key as keyof Device]}
                          </TextField>
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })
        : t('noDevices')}
    </Grid>
  );
};

export default DevicesAndKeysShow;
