import React, { useEffect, CSSProperties } from 'react';
import { useTranslate, Button } from 'react-admin';
import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  Paper,
  Grid,
  SvgIcon,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Skeleton } from '@material-ui/lab';
import { useDispatch } from 'react-redux';

import { H2, OutterTable } from '~/components';
import { ArrowForwardCircleIcon } from '~/img';

import { filterChange } from '~/ducks/virtualizedList';
import { getAuditLog, Response, AuditLogType, AuditLogFilters } from '~/api/auditLog';
import { useApi, useNotify } from '~/hooks';
import { resource } from '~/resources/auditLog/AuditLogList';
import CustomCell from '~/resources/auditLog/CustomCell';
import { useIsSmallLaptop } from '~/hooks/styles';
import { time } from '~/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '& #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #section-actions': {
        opacity: 1,
      },
    },
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  TableCell: {
    whiteSpace: 'nowrap',
  },
}));

interface TableCellProps {
  loading?: boolean;
  children?: any;
  style?: CSSProperties;
}

// TODO: use common table cell from ~/components
const TableCell = (props: TableCellProps) => {
  const { loading, children, style } = props;
  const classes = useStyles();
  return (
    <MuiTableCell className={classes.TableCell} style={style}>
      {loading ? <Skeleton width='100%' height='18px' /> : children}
    </MuiTableCell>
  );
};

interface TableBodyCellProps extends TableCellProps {
  id: string;
  rowData: AuditLogType;
}

const TableBodyCell = (props: TableBodyCellProps) => {
  const { loading, children, id, rowData, style } = props;
  return (
    <TableCell loading={loading}>
      <div style={style}>
        <CustomCell column={{ id } as any} rowData={rowData}>
          {children}
        </CustomCell>
      </div>
    </TableCell>
  );
};

interface Props extends AuditLogFilters {
  history: any;
  tableStyle?: any;
}

const ActionLogWidget = (props: Props) => {
  const { objectId, profileId, identityId, tags, history } = props;

  const [{ data: auditLogData, loading, error }] = useApi<Response>(
    () =>
      getAuditLog({
        objectId,
        profileId,
        identityId,
        tags,
        limit: 10,
        timestampFrom: time().subtract(1, 'month'),
        timestampTo: time(),
      }),
    [profileId, identityId, tags?.sort().join(',')]
  );

  const notify = useNotify();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.${resource}.${key}`);

  useEffect(() => {
    if (error) {
      notify({ message: error.message, type: 'error' });
    }
  }, [error, notify]);

  const dispatch = useDispatch();
  const handleViewAllEntriesClick = () => {
    if (history) {
      dispatch(filterChange({ resource, filters: { profileId, identityId, tags } }));
      history.push('/auditLog');
    }
  };

  const isSmallLaptop = useIsSmallLaptop();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify='space-between' alignItems='center' className={classes.bottomSpacing}>
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t('lastEntries')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          <Button label={t('viewAllEntries')} onClick={handleViewAllEntriesClick}>
            <SvgIcon>
              <ArrowForwardCircleIcon />
            </SvgIcon>
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <OutterTable>
          <TableHead>
            {!error && (
              <TableRow>
                <TableCell loading={loading}>{t('timestamp')}</TableCell>
                <TableCell loading={loading}>{t('action')}</TableCell>
                <TableCell loading={loading}>{t('actor')}</TableCell>
                <TableCell loading={loading}>{t('channel')}</TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {!loading
              ? auditLogData?.records.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableBodyCell id='timestamp' rowData={item} style={{ width: 180 }}>
                        {item.timestamp}
                      </TableBodyCell>
                      <TableBodyCell
                        id='action'
                        rowData={item}
                        style={{ maxWidth: isSmallLaptop ? 320 : 600 }}
                      >
                        {item.message}
                      </TableBodyCell>
                      <TableBodyCell id='actor' rowData={item}>
                        {item.identityName}
                      </TableBodyCell>
                      <TableBodyCell id='channel' rowData={item}>
                        {item.identityType}
                      </TableBodyCell>
                    </TableRow>
                  );
                })
              : new Array(7).fill(0).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell loading={loading} />
                    <TableCell loading={loading} />
                    <TableCell loading={loading} />
                    <TableCell loading={loading} />
                  </TableRow>
                ))}
            {error?.message && (
              <TableRow>
                <TableCell>{error.message}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </OutterTable>
      </TableContainer>
    </div>
  );
};

export default ActionLogWidget;
