import React, { useState } from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import ProfileTagForm from './ProfileTagForm';

const ProfileTagCreate = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.profileTags.fields.${key}`);
  const { permissions } = usePermissions();
  if (!permissions?.includes('profileTag.create')) return null;
  return (
    <>
      <CreateButton
        onClick={handleOpen}
        variant='add'
        label={t('addNewTag')}
        disabled={!permissions?.includes('profileTag.create')}
      />
      <Drawer heading={t('addNewTag')} open={open} onClose={handleClose}>
        <CreateForm resource='profileTags' closeParent={handleClose}>
          <ProfileTagForm />
        </CreateForm>
      </Drawer>
    </>
  );
};

export default ProfileTagCreate;
