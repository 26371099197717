import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { TextField, AmountField } from '~/components';
import { l } from '~/resources/pricings/PricingShow';
import { AcquiringFeeType } from '~/api/pricings';

const AcquiringFeeField = (props: any) => {
  const { record = {} } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  return (
    <Fragment>
      <Grid item xs={4}>
        <TextField label={t('feeType')}>{record.type && t(record.type)}</TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('statementTitle')}>{record.name}</TextField>
      </Grid>
      {(() => {
        switch (record.type as AcquiringFeeType | undefined) {
          case 'FIXED_INCREASE':
          case 'FIXED_DECREASE':
            return (
              <Grid item xs={4}>
                <TextField label={t('amount')}>
                  <AmountField amount={record.amount} />
                </TextField>
              </Grid>
            );
          case 'PERCENT_INCREASE':
          case 'PERCENT_DECREASE':
            return (
              <Fragment>
                <Grid item xs={4}>
                  <TextField label={t('percent', 2)}>
                    {record.percent?.toString() && `${record.percent}%`}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('minAmount')}>
                    <AmountField amount={record.minAmount} />
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('maxAmount')}>
                    <AmountField amount={record.maxAmount} />
                  </TextField>
                </Grid>
              </Fragment>
            );
          default:
            return null;
        }
      })()}
      {(record.index || record.index === 0) && (
        <Grid item xs={4}>
          <TextField label={t('index')}>{record.index}</TextField>
        </Grid>
      )}
    </Fragment>
  );
};

const AcquiringFeeView = (props: any) => {
  const { record } = props;
  return (
    <Grid item container spacing={2}>
      <AcquiringFeeField record={record} />
    </Grid>
  );
};

export default AcquiringFeeView;
