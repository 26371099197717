import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import PaymentDetailForm from './PaymentDetailForm';
import { EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { PaymentDetail } from '~/types';
import { Business } from '~/types';

interface Props {
  paymentDetailRecord: PaymentDetail;
  businessRecord: Business;
  closeParent: () => void;
  permissions: string[];
}

const PaymentDetailEdit = (props: Props) => {
  const { paymentDetailRecord, businessRecord, closeParent, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeParent();
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.${key}`);

  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions.includes('business.paymentDetails.update')}
      />
      <Drawer
        heading={`${t('editPaymentDetail')} "${paymentDetailRecord.name}"`}
        open={open}
        onClose={handleClose}
      >
        <EditForm resource='paymentDetails' record={paymentDetailRecord} recordCleanupMode='hard'>
          <PaymentDetailForm businessRecord={businessRecord} />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default PaymentDetailEdit;
