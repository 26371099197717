import React, { FC } from 'react';
import { JSONSchemaType } from 'ajv';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { time } from '~/utils';

import { CountryField, DocumentCard, StatusField, TextField } from '~/components';
import { Document } from '~/types/businesses';
import { Beneficiary } from '~/types/beneficiaries';
import { useTranslate } from 'react-admin';

interface BeneficiaryViewContentProps {
  solutionId: string;
  country: string;
  documentFamily: 'INDIVIDUAL' | 'BUSINESS';
  data: Beneficiary;
  schema: JSONSchemaType<Record<string, any>>;
  propertyColumns?: number;
  documentColumns?: number;
  withMandatoryFields?: boolean;
}

export const l = (key: string): string => `resources.beneficiaries.${key}`;

const BeneficiaryViewContent: FC<BeneficiaryViewContentProps> = ({
  solutionId,
  country,
  documentFamily,
  data,
  schema,
  propertyColumns = 3,
  documentColumns = 2,
  withMandatoryFields = true,
}) => {
  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const properties: any = schema.properties || {};

  const propertyItems: JSX.Element[] = [];

  if (withMandatoryFields) {
    propertyItems.push(
      <Grid item xs={(12 / propertyColumns) as any} key='country'>
        <TextField label={t('country')}>
          <CountryField>{data.country}</CountryField>
        </TextField>
      </Grid>
    );
    propertyItems.push(
      <Grid item xs={(12 / propertyColumns) as any} key='phoneNumber'>
        <TextField label={t('phoneNumber')}>
          <CountryField>{data.phoneNumber}</CountryField>
        </TextField>
      </Grid>
    );
    propertyItems.push(
      <Grid item xs={(12 / propertyColumns) as any} key='email'>
        <TextField label={t('email')}>
          <CountryField>{data.email}</CountryField>
        </TextField>
      </Grid>
    );
    propertyItems.push(
      <Grid item xs={(12 / propertyColumns) as any} key='createdAt'>
        <TextField label={t('createdAt')}>{time(data.createdAt).format('L')}</TextField>
      </Grid>
    );
    if (data.status) {
      propertyItems.push(
        <Grid item xs={(12 / propertyColumns) as any} key='status'>
          <TextField label={t('status')}>
            <StatusField status={data.status} />
          </TextField>
        </Grid>
      );
    }
  }

  Object.keys(properties)
    .filter(
      (propertyKey) =>
        propertyKey !== 'country' &&
        propertyKey !== 'phoneNumber' &&
        propertyKey !== 'email' &&
        propertyKey !== 'status' &&
        propertyKey !== 'createdAt'
    )
    .forEach((propertyKey) => {
      const property: any = properties[propertyKey];
      if (data[propertyKey] && property.type !== 'object' && property.format !== 'document') {
        let field: JSX.Element;
        switch (property.format) {
          case 'country':
            field = (
              <TextField label={property.title || t(`schema.${propertyKey}`)}>
                <CountryField>{data[propertyKey]}</CountryField>
              </TextField>
            );
            break;
          default:
            field = (
              <TextField label={property.title || t(`schema.${propertyKey}`)}>
                {data[propertyKey]}
              </TextField>
            );
            break;
        }
        propertyItems.push(
          <Grid item xs={(12 / propertyColumns) as any} key={propertyKey}>
            {field}
          </Grid>
        );
      }
    });

  const documentItems: JSX.Element[] = [];
  Object.keys(properties).forEach((propertyKey) => {
    const property: any = properties[propertyKey];
    if (data[propertyKey] && property.format === 'document') {
      documentItems.push(
        <Grid item xs={(12 / documentColumns) as any} key={propertyKey}>
          <DocumentCard
            solutionId={solutionId}
            country={country}
            documentFamily={documentFamily}
            index={0}
            document={data[propertyKey] as Document}
            fullWidth
            permissions={[]}
            editDocument={() => <></>}
          />
        </Grid>
      );
    }
  });

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        {propertyItems}
      </Grid>
      {documentItems.length > 0 && (
        <>
          <TextField className={`${classes.documentsHeading}`}>{t('documents')}</TextField>
          <Grid container item xs={12} spacing={2}>
            {documentItems}
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  documentsHeading: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
}));

export default BeneficiaryViewContent;
