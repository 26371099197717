import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { TextInput, SaveButton } from '~/components';
import { Note, NotesProfileType } from '~/api/notes';
import { createNote, updateNote, deleteNote, selectors } from '~/ducks/notes';
import DeleteButton from '../DeleteButton';

interface Props {
  initialValues?: Note;
  referenceId: string;
  profileType: NotesProfileType;
  handleClose?: () => void;
}

const NoteForm = (props: Props) => {
  const { initialValues, referenceId, profileType, handleClose } = props;
  const dispatch = useDispatch();
  const { loading } = useSelector(selectors.getNotes(referenceId));

  const [text, setText] = useState(initialValues?.text || '');
  const handleChange = (e: any) => {
    setText(e.target?.value || '');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (text) {
      if (initialValues) {
        dispatch(
          updateNote(
            {
              ...initialValues,
              text,
            },
            profileType
          )
        );
      } else {
        dispatch(
          createNote(
            {
              text,
              referenceId,
              createdAt: new Date().toISOString(),
              creatorName: JSON.parse(localStorage.identity).name,
            },
            referenceId,
            profileType
          )
        );
      }
      if (typeof handleClose === 'function') handleClose();
    }
  };

  const handleDelete = async (e: any) => {
    e.preventDefault();
    if (initialValues?.id) {
      dispatch(deleteNote({ id: initialValues.id, referenceId, profileType }));
      if (typeof handleClose === 'function') handleClose();
    }
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.Notes.${key}`);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextInput
        name='text'
        label={t('text')}
        value={text}
        onChange={handleChange}
        multiline
        rows={5}
        rowsMax={20}
      />
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <SaveButton loading={loading} disabled={!text || text === initialValues?.text} />
        </Grid>
        {initialValues && (
          <Grid item>
            <DeleteButton onClick={handleDelete} />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default NoteForm;
