import React, { Fragment, FC, ChangeEvent, useMemo } from 'react';
import { useTranslate, TextInput, required, SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { getSolutionProducts } from '~/api/product';

import { HiddenInput } from '~/components/ra';
import { useApi } from '~/hooks';
import { Product, ProductTemplate } from '~/types';
import { l } from './index';
import { useProductTemplates } from '~/hooks/useProductTemplates';
import { getProfileType } from '~/utils/getProfileType';

interface AccountFormProps {
  resource: 'businesses' | 'individuals' | 'partners';
  solutionId: string;
  profileId: string;
  switchToLimits?: boolean;
}

const AccountForm: FC<AccountFormProps> = (props) => {
  const { solutionId, resource, profileId, switchToLimits } = props;

  const { data: productsTemplate } = useProductTemplates();
  const productsTemplateMap: Record<string, ProductTemplate> | undefined = useMemo(
    () => productsTemplate?.reduce((prev, cur) => Object.assign(prev, { [cur.id]: cur }), {}),
    [productsTemplate]
  );
  const [{ data, loading }] = useApi<Product[]>(getSolutionProducts(solutionId));
  const products = data
    ?.filter((product) => {
      const isCnlAccount = productsTemplateMap?.[product.templateCode]?.category === 'CNL_ACCOUNT';
      return switchToLimits ? isCnlAccount : !isCnlAccount;
    })
    .map((item) => ({
      id: item.id,
      name: item.name,
    }));
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const form = useForm();
  const handleProductChange = (e: ChangeEvent<{ name: string; value: string }>) => {
    const { value } = e.target;
    const alias = products?.find((item) => item.id === value)?.name || '';
    alias && form.change('alias', alias);
  };

  return (
    <Fragment>
      <HiddenInput source='profileType' defaultValue={getProfileType(resource)} />
      <HiddenInput source='profileId' defaultValue={profileId} />
      <SelectInput
        onChange={handleProductChange}
        choices={products || []}
        source='productId'
        validate={required()}
        disabled={loading}
      />
      <TextInput source='alias' label={t('alias')} />
      <TextInput source='description' label={l('description')} multiline />
    </Fragment>
  );
};

export default AccountForm;
