import React from 'react';
import { useMutation, useTranslate, crudGetManyReference, crudGetList } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useNotify } from '~/hooks';
import { cleanupFormValues } from '~/utils';
import BottomToolbar, { BottomToolbarBaseProps } from './BottomToolbar';
import usePath from '~/hooks/usePath';
import SimpleForm from '../SimpleForm';
import { useListPaginationContext, useListContext } from 'ra-core';
import defaultOnError from '~/errorsHandler';

interface Props<Record> extends CreateAndEditFormPropsBase<Record> {
  type: 'create' | 'update';
}

function CreateAndEditForm<Record extends RecordMockup>(props: Props<Record>) {
  const {
    record,
    id,
    resourceId,
    children,
    resource,
    target,
    sort = { field: 'id', order: 'DESC' },
    type,
    closeParent,
    onDelete,
    onDeleteSuccess,
    disabled,
    withDelete = false,
    deleteButtonProps,
    saveButtonProps,
    onSubmit,
    onError = defaultOnError,
    onSuccess: customOnSuccess,
    classes: cssApi,
    subresource,
    HTTPMethod,
    externalState,
    withSaveConfirmation,
    withDeleteConfirmation,
    saveConfirmationSettings,
    deleteConfirmationSettings,
    updateAfterSubmit = true,
    destroyOnUnregister = false,
    parseRecordBeforeSubmit,
    initialValues,
    recordCleanupMode = 'soft',
    style,
  } = props;
  const notify = useNotify();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const t = (key: string): string => translate(`utils.snackbarNotifications.${key}`);

  const listFiltersContext = useListContext(props);
  const listPaginationContext = useListPaginationContext(props);
  const {
    params: { page, perPage },
  } = usePath();
  const resultPagination = {
    page: Number(listPaginationContext?.page || page || 1),
    perPage: Number(listPaginationContext?.perPage || perPage || 25),
  };

  const updateList = () => {
    if (typeof updateAfterSubmit === 'function') {
      updateAfterSubmit();
    } else {
      if (!updateAfterSubmit) return;
      // Refresh ReferenceManyField or List redux state
      // https://marmelab.com/blog/2018/08/27/react-admin-tutorials-custom-forms-related-records.html
      if ((type === 'create' || withDelete) && target && id) {
        dispatch(
          crudGetManyReference(
            resource,
            target,
            id,
            `${target}_${resource}@${target}_${id}`,
            resultPagination,
            sort,
            {}
          )
        );
      } else if ((type === 'create' || withDelete || HTTPMethod === 'POST') && resource) {
        dispatch(crudGetList(resource, resultPagination, sort, listFiltersContext.filterValues));
      }
    }
  };

  const [mutate, { loading }] = useMutation();
  const [deletionMutate, { deletionLoading }] = useMutation();
  const deleteQuery = () =>
    deletionMutate(
      {
        type: 'delete',
        resource: resource,
        payload: { id: resourceId || id || record?.id },
      },
      {
        onSuccess: () => {
          notify({ message: t('dataUpdatedSuccess'), type: 'success' });
          updateList();
          if (closeParent && typeof closeParent === 'function') {
            closeParent();
          }
          if (onDeleteSuccess && typeof onDeleteSuccess === 'function') {
            onDeleteSuccess();
          }
        },
        onFailure: onError,
      }
    );
  const updateOrCreateQuery = (
    newRecord: Record,
    onSuccess?: (response: any) => void,
    onFailure?: (error: any) => void
  ) =>
    mutate(
      {
        type: type,
        resource: resource,
        payload: { id: resourceId || id || record?.id, subresource, data: newRecord, HTTPMethod },
      },
      {
        onSuccess: (response: any) => {
          const message = type === 'update' ? 'dataUpdatedSuccess' : 'dataCreateSuccess';
          notify({ message: t(message), type: 'success' });
          updateList();
          if (closeParent && typeof closeParent === 'function') {
            closeParent();
          }
          onSuccess?.(response);
          customOnSuccess?.(response);
        },
        onFailure: (error: any) => {
          onError(error);
          onFailure?.(error);
        },
      }
    );

  const handleSubmit = (cleanupMode: 'hard' | 'soft') => (newRecord: Record) => {
    let resultRecord = newRecord;
    if (typeof parseRecordBeforeSubmit === 'function') {
      resultRecord = parseRecordBeforeSubmit(newRecord);
    }
    const submitQuery = (record: Record, onSuccess?: () => void, onFailure?: () => void) =>
      updateOrCreateQuery(cleanupFormValues(record, cleanupMode), onSuccess, onFailure);
    try {
      if (onSubmit) {
        // Custom submit function from props
        onSubmit(resultRecord, submitQuery);
      } else {
        submitQuery(resultRecord);
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleDelete = () => {
    try {
      if (onDelete) {
        const submitQuery = (record: Record, onSuccess?: () => void, onFailure?: () => void) =>
          updateOrCreateQuery(cleanupFormValues(record, 'soft'), onSuccess, onFailure);
        onDelete(deleteQuery, submitQuery);
      } else {
        deleteQuery();
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleUnarchive = () => {
    let newRecord: any = record;
    newRecord.deleted = false;
    const submitQuery = (record: Record, onSuccess?: () => void, onFailure?: () => void) =>
      updateOrCreateQuery(cleanupFormValues(record, 'soft'), onSuccess, onFailure);
    try {
      submitQuery(newRecord);
    } catch (error) {
      onError(error);
    }
  };
  return (
    <SimpleForm
      record={record}
      toolbar={
        <BottomToolbar
          onSave={handleSubmit(recordCleanupMode)}
          onDelete={handleDelete}
          onUnarchive={handleUnarchive}
          deletionLoading={deletionLoading}
          disabled={disabled}
          withDelete={withDelete}
          deleteButtonProps={deleteButtonProps}
          saveButtonProps={saveButtonProps}
          classes={cssApi}
          externalState={externalState}
          withSaveConfirmation={withSaveConfirmation}
          withDeleteConfirmation={withDeleteConfirmation}
          saveConfirmationSettings={saveConfirmationSettings}
          deleteConfirmationSettings={deleteConfirmationSettings}
        />
      }
      saving={loading}
      initialValues={initialValues}
      style={style}
      destroyOnUnregister={destroyOnUnregister}
    >
      {children}
    </SimpleForm>
  );
}

type RecordMockup = {
  id?: string | number;
  [x: string]: any;
};

export interface CreateAndEditFormPropsBase<Record> extends BottomToolbarBaseProps<Record> {
  record?: Record;
  children: any;
  resource: string;
  target?: string;
  id?: string;
  resourceId?: string;
  pagination?: {
    page: number;
    perPage: number;
  };
  sort?: {
    field: string;
    order: string;
  };
  closeParent?: () => void;
  onDelete?: (
    deleteQuery: () => void,
    submitQuery: (newRecord: Record, onSuccess?: () => void, onFailure?: () => void) => void
  ) => void;
  onDeleteSuccess?: () => void;
  onSubmit?: (
    newRecord: Record,
    submitQuery: (newRecord: Record, onSuccess?: () => void, onFailure?: () => void) => void
  ) => void;
  onError?: (error: any) => void;
  onSuccess?: (newRecord: Record) => void;
  fullWidth?: boolean;
  destroyOnUnregister?: boolean;
  subresource?: string;
  HTTPMethod?: 'POST' | 'PUT';
  updateAfterSubmit?: boolean | (() => void);
  parseRecordBeforeSubmit?: (record: any) => any;
  initialValues?: any;
  recordCleanupMode?: 'hard' | 'soft';
  style?: React.CSSProperties;
}

export default CreateAndEditForm;
