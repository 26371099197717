import React from 'react';
import {
  Datagrid,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  SelectInput,
  usePermissions,
} from 'react-admin';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { List } from '~/layout';
import { NoPermissions, LocalSort } from '~/components/ra';
import { Aside } from './components';
import { Longdash } from '~/utils';
import { useFeatures } from '~/hooks';
import SolutionCreate from './SolutionCreate';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  rowCell: {
    minWidth: 210,
  },
}));

const SolutionsFilter = (props: any) => {
  const { data } = useFeatures();
  const choices = data?.map((item) => ({ id: item.key, name: item.name })) || [];

  return (
    <Filter {...props}>
      {/* <TextInput label="Name" source="name" alwaysOn /> */}
      <SelectInput
        label='Features'
        source='hasFeatures'
        choices={choices}
        emptyText={Longdash}
        alwaysOn
      />
    </Filter>
  );
};

const SolutionList = (props: any) => {
  const { resource } = props;

  const classes = useStyles();
  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions?.includes('solution.list')) return <NoPermissions />;

  return (
    <div className={classes.root}>
      <List
        {...props}
        filters={<SolutionsFilter />}
        style={{ width: '100%' }}
        bulkActionButtons={false}
        sort={{ field: 'name', order: 'ASC' }}
        actions={<SolutionCreate resource={resource} permissions={permissions} />}
      >
        <LocalSort>
          <Datagrid
            rowClick='show'
            size='medium'
            selectedIds={null}
            classes={{ rowCell: classes.rowCell }}
          >
            <TextField source='name' />
            <ReferenceArrayField source='features' reference='features'>
              <SingleFieldList linkType={false}>
                <ChipField source='name' />
              </SingleFieldList>
            </ReferenceArrayField>
          </Datagrid>
        </LocalSort>
      </List>
      <Aside />
    </div>
  );
};

export default SolutionList;
