export default {
  name: 'Solution |||| Solutions',
  subtitle: 'Here you can manage solutions and define non-financial parameters.',
  fields: {
    features: 'Features',
    productTemplates: 'Product templates',
    featureName: 'Feature Name',
    templateAlias: 'Template Alias',
    editSolution: 'Edit Solution definition',
    solutionDefinition: 'Solution definition',
    name: 'Name',
    addSolution: 'Add Solution',
    noProductTemplate: 'There are no provided product templates yet',
    noFeatures: 'There are no provided features yet',
  },
  tabs: {
    summary: 'Summary',
    products: 'Products',
    pricingTables: 'Pricing plans',
    kycLevels: 'KYC levels',
    handbooks: 'Handbooks',
    fxPlans: 'FX plans',
    featuresConfig: 'Features config',
    notifications: 'Notifications',
  },
  Aside: {
    availableFeatures: 'Available features',
    availableProductTemplates: 'Available product templates',
  },
  pricings: {
    editPricing: 'Edit pricing plan',
    addPricing: 'Add pricing plan',
    showPricing: 'Pricing plan information',
    showPricingOld: 'Pricing plan information (Old)',
    name: 'Name',
    pricingPlan: 'Pricing plan',
    viewEdit: 'View/edit values',
    product: 'Product',
    kycLevels: 'KYC levels',
    kycLevel: 'KYC level',
    accountStatus: 'Account Status',
    tags: 'Tags',
    activeFrom: 'Active from',
    activeTill: 'Active till',
    any: 'Any',
    pricingTables: 'Pricing plans',
    solution: 'Solution',
    pricingPlans: 'Pricing plans definitions',
    validityFrom: 'Validity From',
    validityTo: 'Validity To',
    profileStatus: 'Profile status',
    profileKycLevel: 'Profile KYC level',
    paymentInstrument: 'Payment Instrument',
    profileSolution: 'Profile Solution',
    profileTags: 'Profile Tags',
    externalSourceType: 'External Source Type',
    Active: 'Active',
    Blocked: 'Blocked',
    Closed: 'Closed',
    v1: 'v1',
    showOldWarning:
      'You are viewing the old format pricing. Please create a new one as a replacement.',
    editOldWarning:
      'You are editing the old format pricing. Please create a new one as a replacement.',
  },
  products: {
    name: 'Product Name',
    template: 'Product Template',
    currency: 'Currency',
    accountNumbering: 'Account numbering',
    editProduct: 'Edit Product',
    addProduct: 'Add Product',
    showProduct: 'Product information',
    noData: 'No product templates selected at Summary tab',
    bankAccount: 'Bank account',
  },
  fxmappings: {
    operationTypes: 'Operation types',
    tags: 'Tags',
  },
  featuresConfig: {
    defaultProduct: 'Default product',
    defaultMetaProduct: 'Default product (meta)',
    tenantAccounts: 'Tenant accounts (deprecated)',
    noData: 'No feature configuration available',
    noFeatures: 'There are no provided features yet',
    beneficiarySchema: 'Beneficiary schema',
    beneficiarySchemaValidationError: 'Invalid JSON format',
    remittancePayoutBeneficiarySchema: 'Remittance Payout beneficiary schema',
    remittanceAdditionalPropertiesSchema: 'Remittance additional properties schema',
    remittanceAdditionalPropertiesSchemaValidationError: 'Invalid JSON format',
    profileSchema: 'Profile schema',
    profileSchemaValidationError: 'Invalid JSON format',
    employeeConfig: 'Employee configuration',
    employeeConfigValidationError: 'Invalid JSON format',
    serviceChargeId: 'Service charge ID',
    serviceChargeName: 'Service charge name',
    countriesAndCurrencies: 'Countries & Currencies',
    countriesAndCurrenciesValidationError: 'Invalid JSON format',
    remittancePayoutServices: 'Payout services',
    businessPortalBaseUrl: 'Web-portal base URL',
    invitationExpirationPeriod: 'Invitation Expiration Period (days)',
    maxNumberOfInvitationsToPhoneNumberPerMonth:
      'Max number of invitations sent to one number (monthly)',
    maxNumberOfInvitationsByActorPerDay: 'Max number of invitations sent by actor (daily)',
    termsAndConditionsLink: 'Terms And Conditions Link',
    websiteLink: 'Website Link',
    phoneNumberPrefixes: 'Phone number prefixes',
    solutionId: 'Solution ID',
    requireMappingToLocation: 'Terminals and their users always linked to location',
    inviteToSolution: 'Invite to Solution',
    requireRecipientFullNameValidation: 'Require recipient full name validation',
    codeGenerationAlgorithm: 'Code generation algorithm',
    UUID: 'UUID',
    shortCode: 'Short code',
    codeLength: 'Code length',
    codeLifetime: 'Code lifetime (minutes)',
    operationSubtypes: 'Operation subtypes',
    tagsValidation:
      'You can only use uppercase letters and _ symbol. Please remove %{invalidValues}.',
  },
  notifications: {
    transactionNotififcations: 'Transactional notifications',
    individualNotfications: 'Individual notifications',
    payerNotififcations: 'Payer notififcations',
    payeeNotififcations: 'Payee notififcations',
    MOBILE: 'Mobile',
    USSD: 'USSD',
    WEB: 'WEB',
    WhatsApp: 'WhatsApp',
    middleware: 'Middleware',
    OTHER: 'Other',
    PROFILE_DEFAULT: 'PROFILE_DEFAULT',
    NO_NOTIFICATION: 'NO_NOTIFICATION',
    SMS_ONLY: 'SMS_ONLY',
    PUSH_WITH_FALLBACK_TO_SMS: 'PUSH_WITH_FALLBACK_TO_SMS',
    individualKYCLevelUpdated: 'Individual KYC level updated',
    individualKYCLevelUpdateRejected: 'Individual KYC level update rejected',
    individualStatusUpdated: 'Individual status updated',
    beneficiaryStatusUpdated: 'Beneficiary status updated',
  },
};
