import React from 'react';
import { SelectInput } from 'react-admin';
import { TextInput, required, FormDataConsumer, useTranslate } from 'react-admin';

import { ReferenceInput } from '~/components/ra';
import { Section } from '~/layout';
import { Longdash } from '~/utils';

interface Props {
  edit?: boolean;
}

const l = (key: string): string => `resources.paymentServices/groups.${key}`;

const PaymentGroupForm = (props: Props) => {
  const { edit } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  return (
    <FormDataConsumer>
      {({ formData }: any) => {
        const validateParent = (value: string) => {
          if (formData && value === formData.id) {
            return { message: t('parentMustBeDifferent') };
          }
        };
        return (
          <Section>
            <TextInput source='id' label={t('id')} validate={required()} disabled={edit} />
            <TextInput source='name' label={t('name')} validate={required()} />
            <ReferenceInput
              label={t('parentId')}
              source='parentId'
              reference='paymentServices/groups'
              allowEmpty
              emptyText={Longdash}
              validate={[validateParent as any]}
            >
              <SelectInput optionText='name' />
            </ReferenceInput>
          </Section>
        );
      }}
    </FormDataConsumer>
  );
};

export default PaymentGroupForm;
