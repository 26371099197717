import React, { useState } from 'react';
import { useTranslate } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const label = (key: string): string => `resources.welcome.DemoAlert.${key}`;

const DemoAlert = () => {
  const [open, setOpen] = useState(localStorage.justLoggedIn);

  const handleClose = () => {
    setOpen(false);
    localStorage.justLoggedIn = '';
  };

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='demo-alert-dialog-title'
      aria-describedby='demo-alert-dialog-description'
    >
      <DialogTitle id='demo-alert-dialog-title'>{t('title')}</DialogTitle>
      <DialogContent style={{ maxWidth: 400 }}>
        <DialogContentText id='demo-alert-dialog-description'>{t('text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemoAlert;
