import React, { useState, Fragment, ChangeEvent } from 'react';
import { useTranslate, TextInput, required, SelectInput } from 'react-admin';
import { isEmpty } from 'lodash';

import { l } from './PnlAccountList';
import { ReferenceInput } from '~/components/ra';

import { useSolution } from '~/hooks';
import { useTenant, useTenantProducts } from '~/hooks/tenant';
import { useProductTemplates } from '~/hooks/useProductTemplates';
import { useAccountNumberingList } from '~/hooks/useAccountNumberingList';

const PnlAccountForm = (props: any) => {
  const { edit, record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { data: tenant } = useTenant();
  const { data: solution } = useSolution(tenant?.id);
  const { data: products } = useTenantProducts();
  const { data: productTemplates } = useProductTemplates();

  console.log(`productTemplates:`, productTemplates);

  /*
  const handleProductChange = (e: ChangeEvent<{ name: string; value: string }>) => {
    // const { value } = e.target;
    // const alias = products?.find((item) => item.id === value)?.name || '';
    // alias && form.change('alias', alias);
  };
   */

  const [newProduct, setNewProduct] = useState<boolean>(false);

  const handleBankAccountChange = (e: ChangeEvent<{ name: string; value: string }>) => {
    const { value } = e.target;
    console.log(`change, e.target:`, e);

    const product = products?.find((p) => p.bankAccountId === value);
    setNewProduct(!product);
  };

  const allowEditProduct =
    record &&
    products &&
    (record.profileId === record.productId || !products?.find((p) => p.id === record.productId));

  const { data: accountNumberingOptions } = useAccountNumberingList();

  const productTemplateOptions = (() => {
    const solutionProductTemplates: string[] = solution?.productTemplates || [];
    const options = productTemplates
      ?.filter((pt) => !!solutionProductTemplates.find((code) => pt.code === code))
      .map((pt) => ({ id: pt.code, name: pt.alias }));

    if (!isEmpty(options)) {
      return options;
    } else {
      return [{ id: undefined, name: t('noData') }];
    }
  })();

  return (
    <Fragment>
      <TextInput source='alias' label={t('alias')} validate={required()} />
      <TextInput source='description' label={t('description')} />
      {!edit && (
        <ReferenceInput
          onChange={handleBankAccountChange}
          source='bankAccountId'
          reference='bank/accounts'
          label={t('bankAccount')}
          validate={required()}
        >
          <SelectInput
            optionText={(item: any) => (
              <>
                <span style={{ width: '100%', display: 'table-cell' }}>{item.alias}</span>
                <span
                  style={{ opacity: 0.5, display: 'table-cell', paddingLeft: 10, paddingRight: 10 }}
                >
                  {item.currency}
                </span>
              </>
            )}
          />
        </ReferenceInput>
      )}
      {newProduct && (
        <SelectInput
          source='productTemplateCode'
          choices={productTemplateOptions}
          label={t('productTemplateCode')}
          validate={required()}
        />
      )}
      {newProduct && (
        <SelectInput
          source='accountNumbering'
          choices={accountNumberingOptions}
          label={t('accountNumbering')}
          validate={required()}
        />
      )}
      {allowEditProduct && (
        <SelectInput
          // onChange={handleProductChange}
          choices={products || []}
          source='productId'
          validate={required()}
          disabled={!products}
        />
      )}
    </Fragment>
  );
};

export default PnlAccountForm;
