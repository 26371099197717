import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslate, Link } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { buildName } from '../utils';

let cache = {};

const RoutedBreadcrumbs = ({ location, translate, className }) => {
  const classes = useStyles();
  let parts = location.pathname.split('/').filter((p) => p);
  switch (parts[0]) {
    case 'configurations':
    case 'tenant':
    case 'bank':
      parts = [`${parts[0]}/${parts[1]}`].concat(parts.slice(2));
      break;
    case 'paymentServices':
      if (parts[1] === 'groups') {
        parts = [`${parts[0]}/${parts[1]}`].concat(parts.slice(2));
      }
      break;
    case 'headTransactions':
      parts = parts.slice(0, 2);
      break;
    default:
      break;
  }
  let resourcesKey = parts[0];
  const resources = useSelector((state) => state.admin.resources);

  const breadcrumbs = [];

  if (resourcesKey in resources) {
    // check if this is 'resources'-based URL
    const resourceId = parts[1]; // this is current resource id (may be null in case of list)
    // check for cached parent in case id is provided
    if (resourceId) {
      const newBasePath = `/${resourcesKey}/${resourceId}`;
      if (cache.basePath !== newBasePath) {
        // if no actual cache found then check routing state
        if (location.state && location.state.parentType) {
          cache = {
            basePath: newBasePath,
            parent: {
              id: location.state.parentId,
              type: location.state.parentType,
              name: location.state.parentName,
            },
          };
        } else {
          cache = {};
        }
      }
    } else {
      cache = {};
    }

    // optional parent data
    const parent = cache.parent;

    // render parent breadcrumbs in case it is known
    if (parent) {
      // list of parent-type resources
      breadcrumbs.push({
        name: translate(`resources.${parent.type}.name`, 2),
        path: `/${parent.type}`,
      });
      // show parent
      breadcrumbs.push({
        name: parent.name,
        path: `/${parent.type}/${parent.id}/show`,
      });
      // go to parent show-view tab
      breadcrumbs.push({
        name: translate(`resources.${resourcesKey}.name`, 2),
        path: `/${parent.type}/${parent.id}/show/${resourcesKey}`,
      });
    } else {
      // okay, no parent; ref to list of record-type resources
      const hasList = resources[resourcesKey].props.hasList;
      breadcrumbs.push({
        name: translate(`resources.${resourcesKey}.name`, 2),
        path: hasList ? `/${resourcesKey}` : null,
      });
    }

    // current record breadcrumb (get name from react-admin data)
    let resourcesData = resources[resourcesKey].data;
    // FIX THIS:
    if (isEmpty(resourcesData) && resourcesKey === 'tenant/accounts') {
      resourcesData = resources.accounts.data;
    }

    if (resourceId in resourcesData) {
      const resourceName = buildName(resourcesKey, resourcesData[resourceId]);
      breadcrumbs.push({
        name: resourceName,
        path: `/${resourcesKey}/${resourceId}/show`,
      });

      // breadcrumb for active tab (if any opened)
      const p = location.pathname.search('/show/');
      if (p >= 0 && resourcesKey !== 'headTransactions' && resourcesKey !== 'batches') {
        const tabKey = location.pathname.substring(p + 6);
        const tabName = translate(`resources.${resourcesKey}.tabs.${tabKey}`);
        breadcrumbs.push({
          name: tabName,
          path: `/${resourcesKey}/${resourceId}/show/${tabKey}`,
        });
      }
    }
  } else {
    // no breadcrumbs
  }

  return (
    <StyledBreadcrumbs className={className}>
      {breadcrumbs.map((bc, i) => {
        if (!bc.path || i === breadcrumbs.length - 1) {
          return (
            <Typography key={i} variant='body2' className={classes.ellipsis}>
              {bc.name}
            </Typography>
          );
        } else {
          return (
            <Link key={bc.path} to={bc.path}>
              {bc.name}
            </Link>
          );
        }
      })}
    </StyledBreadcrumbs>
  );
};

const StyledBreadcrumbs = styled(Breadcrumbs)`
  .MuiBreadcrumbs-li {
    overflow: hidden;
  }
`;

const useStyles = makeStyles(() => ({
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default withTranslate(withRouter(RoutedBreadcrumbs));
