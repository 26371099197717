import React, { FC, useState } from 'react';
import { ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { StatusField, TextField } from '~/components';
import Drawer from '~/components/Drawer';
import { LockType } from '~/types/apiIdentity';
import { useApiIdentityTranslate } from '..';
import { RibbonIcon } from '~/img';
import { Section } from '~/layout';
import moment from 'moment-timezone';
import DeleteIdentityLock from './DeleteIdentityLock';
import { Longdash } from '~/utils';
import { ShowController } from '~/components/ra';
import useInterval from '~/hooks/useInterval';
import { padStart } from 'lodash';

interface Props {
  identityId?: string;
  record: LockType | undefined;
  onClose: () => void;
}

const IdentityLocksDrawer: FC<Props> = (props) => {
  const { identityId, record, onClose } = props;
  const t = useApiIdentityTranslate();

  return (
    <Drawer heading={t('viewIdentityLock')} open={!!record} onClose={onClose}>
      <Grid container spacing={3}>
        <Grid item>{record && <IdentityLockView record={record} />}</Grid>
        <Grid item container justify='flex-end' spacing={2}>
          {record && identityId && (
            <Grid item>
              <DeleteIdentityLock identityId={identityId} record={record} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

interface IdentityLockViewProps {
  record: LockType;
}

export const IdentityLockView: FC<IdentityLockViewProps> = (props) => {
  const { record } = props;
  const t = useApiIdentityTranslate();

  const [tillUnlock, setTillUnlock] = useState('');
  const updateCounter = () => {
    if (record.till && moment(record.till).isAfter(moment())) {
      const countdownSeconds = moment(record.till).diff(moment(), 'seconds');
      const d = Math.floor(countdownSeconds / 60 / 60 / 24);
      const h = Math.floor((countdownSeconds - d * 60 * 60 * 24) / 60 / 60);
      const m = Math.floor((countdownSeconds - d * 60 * 60 * 24 - h * 60 * 60) / 60);
      const s = Math.floor(countdownSeconds - d * 60 * 60 * 24 - h * 60 * 60 - m * 60);
      setTillUnlock(
        ([] as string[])
          .concat([d ? `${toTime(d)} days` : ''])
          .concat([d || h ? `${toTime(h)} hours` : ''])
          .concat([d || h || m ? `${toTime(m)} minutes` : ''])
          .concat([d || h || m || s ? `${toTime(s)} seconds` : ''])
          .filter(Boolean)
          .join(', ')
      );
    }
  };
  useInterval(updateCounter, record.till ? 333 : null);

  const classes = useStyles();

  return (
    <Section className={classes.section} noPaddingRight>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField
            className={classes.textFieldWithIcon}
            label={t('status')}
            icon={<RibbonIcon />}
          >
            <StatusField status='ACTIVE' />
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('startDatetime')}>
            {record.from ? moment(record.from).format('ll, LTS') : Longdash}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('endDatetime')}>
            {record.till ? moment(record.till).format('ll, LTS') : Longdash}
          </TextField>
        </Grid>
        {record.till && (
          <Grid item xs={12}>
            <TextField label={t('timeLeft')}>{tillUnlock || ''}</TextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField label={t('reason')} multiline>
            {record.reason}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {record.identity ? (
            <ReferenceField
              source='identity'
              record={record}
              reference='team'
              link={false}
              addLabel={false}
              basePath={'/team'}
            >
              <ShowController>
                {({ showRecord }: any) => {
                  return (
                    <TextField label={t('actor')} showLink={`/team/${record.identity}/show`}>
                      {showRecord?.name || showRecord?.username || '???'}
                    </TextField>
                  );
                }}
              </ShowController>
            </ReferenceField>
          ) : (
            <TextField label={t('actor')}>{t('system')}</TextField>
          )}
        </Grid>
      </Grid>
    </Section>
  );
};

const toTime = (num: number) => {
  return padStart(`${num}`, 2, '0');
};

const useStyles = makeStyles((theme) => ({
  section: {
    paddingLeft: theme.spacing(4),
  },
  textFieldWithIcon: {
    marginLeft: -theme.spacing(4),
  },
}));

export default IdentityLocksDrawer;
