import React, { Children, useState } from 'react';
import FileSaver from 'file-saver';
import axios from '~/utils/axios';
import { FileType } from '~/api/utils';
import { useLoadImage } from '~/hooks';

import { Business, Individual } from '~/types';
import { Beneficiary } from '~/types/beneficiaries';
import { Document } from '~/types/businesses';

import { IconButton, Backdrop } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';
import palette from '~/theme/palette';
import { Skeleton } from '@material-ui/lab';

import Grid from '@material-ui/core/Grid';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import ImageIcon from '@material-ui/icons/Image';
import onError from '~/errorsHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 0,
    '& .actionsWrapper': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      minWidth: 0,
      '&:hover': {
        background: palette.imgFiles.wrapperBackground,
        '& #deleteButton, #viewButton, #openPopupButton, #downloadButton': {
          transition: `opacity ${theme.transitions.easing.easeIn}`,
          transitionDuration: `${theme.transitions.duration.shortest}ms`,
          opacity: 1,
        },
      },
    },
  },
  actionButtons: { opacity: 0, padding: '7px', color: theme.palette.text.primary },
  name: {
    textAlign: 'center',
    marginBottom: '8px',
    width: '170px',
    overflow: 'hidden',
    minWidth: '0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface DeleteButtonProps {
  onDeleteClick: () => void;
}

const DeleteButton = (props: DeleteButtonProps) => {
  const { onDeleteClick } = props;
  const classes = useStyles();
  return (
    <IconButton
      id='deleteButton'
      className={classes.actionButtons}
      onClick={onDeleteClick}
      tabIndex={-1}
    >
      <DeleteIcon />
    </IconButton>
  );
};

interface ViewButtonProps {
  dataURL: string | undefined;
}

const ViewButton = (props: ViewButtonProps) => {
  const { dataURL } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!dataURL) return null;

  return (
    <>
      <IconButton
        id='viewButton'
        className={classes.actionButtons}
        tabIndex={-1}
        onClick={handleOpen}
      >
        <VisibilityIcon />
      </IconButton>
      <Backdrop style={{ zIndex: 9999, cursor: 'zoom-out' }} open={open} onClick={handleClose}>
        <img style={{ maxHeight: '90vh' }} src={dataURL} alt='full screen view' />
      </Backdrop>
    </>
  );
};

interface OpenPopupButtonProps {
  dataURL: string | undefined;
}

interface ImageSize {
  width: string | null;
  height: string | null;
}

const OpenPopupButton = (props: OpenPopupButtonProps) => {
  const { dataURL } = props;
  const classes = useStyles();
  if (!dataURL) return null;
  const img = new Image();
  const imageSize: ImageSize = {
    width: null,
    height: null,
  };
  img.src = dataURL;
  img.onload = function () {
    imageSize.width = img.width.toString();
    imageSize.height = img.height.toString();
  };
  const handleOpenPopup = () => {
    window.open(
      `${dataURL}`,
      `document-${dataURL}`,
      `width=${imageSize.width || '600'},height=${imageSize.height || '600'},scrollbars=no`
    );
  };
  return (
    <IconButton
      id='openPopupButton'
      className={classes.actionButtons}
      tabIndex={-1}
      onClick={handleOpenPopup}
    >
      <OpenInNewIcon />
    </IconButton>
  );
};

interface DownloadButtonProps {
  record?: Business | Individual | Beneficiary;
  file: FileType;
}

const DownloadButton = (props: DownloadButtonProps) => {
  const { record, file } = props;
  const classes = useStyles();
  if (!record || !file) return null;
  const getFullFileName = () => {
    // Keep name of the file and mime type in const.
    const fileId = file.id;
    const fileName = file.name || file?.rawFile?.name;
    const mimeType = file.mimeType || file?.rawFile?.type;
    // Create extension from mimeType.
    const mimeTypeExtension = `.${mimeType?.slice(mimeType.indexOf('/') + 1)}`;
    // Cut off extension if file has it in the name.
    const fileNameNoExt = fileName?.endsWith(mimeTypeExtension)
      ? fileName.slice(0, -mimeTypeExtension.length)
      : fileName;
    // Keep legalName based on record in const.
    const fullName =
      'legalName' in record
        ? (record as Business).legalName
        : `${(record as Individual | Beneficiary).firstName}_${
            (record as Individual | Beneficiary).lastName
          }`;
    // Form a list and find file type
    const documents = ((record as Business | Individual | Beneficiary).documents ?? [])
      .concat((record as Beneficiary).document ?? [])
      .concat([(record as Business).primaryDocument ?? {}]);
    // Find current document by id.
    const currentDocument = (documents as Document[]).find((document) =>
      document?.files?.find((file) => file.id === fileId)
    );
    // Create a name for download.
    const newFileName =
      `${fullName}_${currentDocument?.type}_${fileNameNoExt}`.toUpperCase().replace(/ /g, '_') +
      mimeTypeExtension;
    return newFileName;
  };
  const download = () => {
    const fullFileName = getFullFileName();
    if (file.id) {
      axios
        .get(`/files/${file.id}`, { responseType: 'blob' })
        .then((response) => {
          FileSaver.saveAs(response.data, fullFileName);
        })
        .catch(() => null);
    }
  };
  return (
    <IconButton
      id='downloadButton'
      className={classes.actionButtons}
      tabIndex={-1}
      onClick={download}
    >
      <GetAppIcon />
    </IconButton>
  );
};

interface AddDeleteButtonProps {
  children: JSX.Element;
  file: FileType;
  onDeleteClick?: () => void;
  name?: string;
  record?: Business | Individual | Beneficiary;
}

const AddActionButtons = (props: AddDeleteButtonProps) => {
  const { children, onDeleteClick, record, file } = props;
  const classes = useStyles();
  const { dataURL, loading, error } = useLoadImage(file);
  if (loading) return <Skeleton height='190px' variant='rect' />;
  if (!file) {
    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ width: '100%', height: '190px', backgroundColor: 'rgba(0, 0, 0, .1)' }}
      >
        <ImageIcon />
      </Grid>
    );
  }
  if (file?.mimeType?.startsWith('image') && error) {
    error && onError({ body: { message: error } });
    return (
      <Grid
        container
        justify='center'
        alignItems='center'
        style={{ width: '100%', height: '190px', backgroundColor: 'rgba(0, 0, 0, .1)' }}
      >
        <BrokenImageIcon />
      </Grid>
    );
  }
  return (
    <>
      <div className={classes.root}>
        <div className='actionsWrapper'>
          <ViewButton dataURL={dataURL} />
          <OpenPopupButton dataURL={dataURL} />
          {!file.rawFile && <DownloadButton record={record} file={file} />}
          {onDeleteClick && <DeleteButton onDeleteClick={onDeleteClick} />}
        </div>
        {Children.only(children)}
      </div>
      <div className={classes.name}>{file.name || file?.rawFile?.name}</div>
    </>
  );
};

export default AddActionButtons;
