import React, { FC } from 'react';
import { Alert } from '@material-ui/lab';

import { EditButton } from '..';
import Confirmation from '../Confirmation';
import { useResetAttemptsCounter } from '~/hooks/apiIdentity';
import { AuthenticationType } from '~/api/apiIdentity';
import { FlashIcon } from '~/img';
import { useApiIdentityTranslate } from '.';

interface Props {
  identityId: string | undefined;
  authenticationType: AuthenticationType | undefined;
  className?: string;
  disabled?: boolean;
  title?: string;
}

const ResetAttemptsCounter: FC<Props> = (props) => {
  const { className, identityId, authenticationType, disabled, title } = props;
  const t = useApiIdentityTranslate();

  const resetAttemptsCounterMutation = useResetAttemptsCounter();
  const handleReset = () => {
    if (identityId && authenticationType) {
      return resetAttemptsCounterMutation.mutateAsync({ identityId, authenticationType });
    }
  };

  return (
    <Confirmation
      onConfirm={handleReset}
      confirmButtonProps={{ loading: resetAttemptsCounterMutation.isLoading }}
      confirmationSettings={{
        variant: 'modal',
        modal: { content: <Alert severity='warning'>{t('resetAttemptsCounterWarning')}</Alert> },
      }}
    >
      <EditButton
        className={className}
        label={title ?? t('resetAttemptsCounter')}
        icon={<FlashIcon />}
        disabled={disabled}
        yellow
      />
    </Confirmation>
  );
};

export default ResetAttemptsCounter;
