import React, { FC } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import { Grid } from '@material-ui/core';

import { ChipField, Drawer, TextField } from '~/components';

import { l } from '../CurrenciesList';
import FXMappingEdit from './FXMappingEdit';
import { TextField as RaTextField } from '~/components/ra';
import { SelectTransactionType } from '~/components/TransactionsHistory/Filters';
import { Solution } from '~/types';
import { Longdash } from '~/utils';

interface FXMappingShowProps {
  solutionRecord?: Solution;
  record: any;
  onClose: () => void;
  permissions?: string[];
}

const FXMappingShow: FC<FXMappingShowProps> = (props) => {
  const { permissions, record, solutionRecord, onClose } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const referenceFiledProps = {
    record,
    link: false,
    addLabel: false,
    basePath: `/fxrates/mappings`,
  };

  return (
    <Drawer heading={t('fxPlan')} open={!!record} onClose={onClose}>
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <ReferenceField source='solutionId' reference='solutions' {...referenceFiledProps}>
              <RaTextField
                source='name'
                label={t('solutionId')}
                showLink={record && `/solutions/${record.solutionId}`}
              />
            </ReferenceField>
          </Grid>
          <Grid item xs={12}>
            <ReferenceField source='productId' reference='products' {...referenceFiledProps}>
              <RaTextField
                source='name'
                label={t('productId')}
                showLink={record && `/solutions/${record.solutionId}/show/products`}
              />
            </ReferenceField>
          </Grid>
          <Grid item xs={12}>
            <TextField label={t('operationTypes')}>
              {record?.operationTypes?.length > 0 ? (
                record.operationTypes.map((item: string) => (
                  <ChipField key={item} label={translate(SelectTransactionType.l(item))} />
                ))
              ) : (
                <span>{Longdash}</span>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField label={t('tags')}>
              {record?.tags?.map((item: string) => (
                <ChipField label={item} key={item} />
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <ReferenceField source='ratesId' reference='fxrates' {...referenceFiledProps}>
              <RaTextField source='name' label={t('ratesId')} />
            </ReferenceField>
          </Grid>
        </Grid>
        {record && (
          <Grid container item spacing={1} justify='flex-end'>
            <Grid item>
              <FXMappingEdit
                permissions={permissions}
                solutionRecord={solutionRecord}
                record={record}
                onClose={onClose}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default FXMappingShow;
