import React, { Fragment, useState, useCallback } from 'react';
import { useTranslate, Button } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { time } from '~/utils';
import msk from 'msk';

import { getActivationCode, issueActivationCode } from '~/api/terminal';
import { useApi, useDeepEffect } from '~/hooks';
import { Drawer, TextField, PhoneNumberInput, H3 } from '~/components';
import { FlashIcon, BarcodeIcon } from '~/img';
import { Terminal } from '~/types';
import onError from '~/errorsHandler';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
}));

interface Props {
  terminalId: string;
  phoneNumber?: string;
  parentReacord: Terminal;
  permissions: string[];
}

const ActivationCodeDrawer = (props: Props) => {
  const { terminalId, phoneNumber, parentReacord, permissions } = props;

  const [lastIssued, setLastIssued] = useState(new Date());

  const [{ loading, data }] = useApi(() => {
    return getActivationCode(terminalId);
  }, [terminalId, lastIssued]);

  const [phone, setPhone] = useState(phoneNumber || '');
  const handlePhone = (value: string) => setPhone(value);

  useDeepEffect(() => {
    if (data) {
      setPhone(data.phoneNumber || phoneNumber);
    }
  }, [data]);

  useDeepEffect(() => {
    if (phoneNumber) {
      setPhone(phoneNumber);
    }
  }, [phoneNumber]);

  const [issuing, setIssuing] = useState(false);
  const issueCode = useCallback(async () => {
    if (issuing) return;
    setIssuing(true);

    await issueActivationCode(terminalId, phone ? phone : undefined).catch((error) => {
      onError(error);
    });
    setIssuing(false);
    setLastIssued(new Date());
  }, [issuing, terminalId, phone]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.terminals.${key}`);

  const classes = useStyles();

  return (
    <Fragment>
      <Button
        label={translateLabel('issueActivationCode')}
        onClick={handleOpen}
        className={classes.warningColor}
        disabled={!permissions.includes('business.terminal.update')}
      >
        <SvgIcon>
          <FlashIcon />
        </SvgIcon>
      </Button>
      <Drawer
        heading={`${translateLabel('issueActivationCodeFor')} "${parentReacord.label}"`}
        open={open}
        onClose={handleClose}
      >
        {data?.value && (
          <H3 topSpacing={0} icon={<BarcodeIcon />}>
            {translateLabel('lastCode')}
          </H3>
        )}
        <Grid container spacing={2} direction='column' className={classes.container}>
          {data?.value && (
            <Grid item>
              <TextField
                label={`${translateLabel('activationCode')} ${
                  data?.status ? `(${data.status})` : ''
                }`}
                loading={loading}
              >
                {msk(data.value, 'XXXX-XXXX-XXXX-XXXX')}
              </TextField>
            </Grid>
          )}
          {data?.expires && (
            <Grid item>
              <TextField label={translateLabel('validTill')}>
                {time(data.expires).format('LL - LT')}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <PhoneNumberInput
              label={translateLabel('phoneSmsCode')}
              value={phone}
              onChange={handlePhone}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button label={translateLabel('issueNewCode')} variant='contained' onClick={issueCode}>
              <SvgIcon>
                <FlashIcon />
              </SvgIcon>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
};

export default ActivationCodeDrawer;
