export default {
  alias: 'Account alias',
  description: 'Description',
  glNumber: 'GL number',
  currency: 'Currency',
  bankAccount: 'Bank account',
  productTemplateCode: 'Product template',
  accountNumbering: 'Account numbering',
  type: 'Type',
  productId: 'Product',
  addPnl: "Add Tenant's Account",
  editPnl: "Edit Tenant's Account details",
  accountDetails: 'Account details',
  accountSummary: 'Account Summary',
  balance: 'Balance',
  amount: 'Amount',
  status: 'Status',
  transferAction: {
    transfer: 'Transfer',
    transferHeadline: 'Create settlement',
    transferSubtitle: "Settlement transfer for tenant's accounts",
    approve: 'Approve',
    selectTransferMode: 'Select transfer mode',
    inputDetails: 'Input transfer parameters',
    transferMode: {
      _: 'Transfer mode',
      SINGLE: 'Single transfer',
      PAIRED: 'Paired transfers',
    },
    details: {
      // noModeSelected: 'Select transfer mode '
    },
    amount: 'Amount',
    creditAccount: 'Credit account',
    debitAccount: 'Debit account',
    checkConsent: 'Check transfer details',
    account: 'Account',
    sender: 'Debit',
    recipient: 'Credit',
    senderAmounts: 'Debit amounts',
    recipientAmounts: 'Credit amounts',
    entry: {
      category: 'Category',
      transactionAmount: 'Transfer amount',
      totalAmount: 'Total amount',
      amount: 'Amount',
    },
    from: 'From',
    to: 'To',
    transferDone: 'Transfer done!',
  },
  accountNumber: 'Account number',
  balanceCode: {
    CURRENT_AVAILABLE: 'Available',
    CURRENT_UNAVAILABLE: 'Unavailable',
    CURRENT_POSTED: 'Posted',
  },
  tabs: {
    details: 'Details',
    bankDaySummary: 'Bank day summary',
    transactions: 'Transactions',
  },
  transactionsHistory: {
    bankingDay: 'Banking day',
    totalAmount: 'Total amount',
    type: 'Type',
    cpAccount: 'CP Account',
    dateAndTime: 'Date & time',
    trnId: 'Original Trn ID',
    status: 'Status',
    // Filters
    fromCreatedAt: 'From created at',
    toCreatedAt: 'To created at',
    accountId: 'Tenant account ID',
    search: 'Search',
  },
  bankDaySummary: {
    toDate: 'To date',
    fromDate: 'From date',
    bankingDay: 'Banking day',
    totalOperations: 'Total operations number',
    totalAmount: 'Total amount',
    warning: 'The bank day summary is available only for the last 90 days.',
  },
};
