import React, { FC, Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { time } from '~/utils';

import { Drawer, H3, TextField } from '~/components';
import { Section } from '~/layout';
import { Transfer } from '~/types';
import { SenderIcon, RecipientIcon, RibbonIcon } from '~/img';
import { TextField as RaTextField } from '~/components/ra';
import { buildProfileLink } from '~/utils';
import { amountToString } from '~/utils/amountFormat';

interface Props {
  transfer: Transfer | null;
  onClose: () => void;
}

const TransferView: FC<Props> = (props) => {
  const { transfer, onClose } = props;

  const translate = useTranslate();
  const t = (key: string): string =>
    translate(`resources.bank/accounts.transactionsHistory.${key}`);

  const classes = useStyles();

  return (
    <Drawer
      heading={transfer ? amountToString(transfer.amount) : ''}
      open={!!transfer}
      onClose={onClose}
    >
      {transfer && (
        <Fragment>
          <Section className={classes.section} noPaddingRight>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextField label={t('dateAndTime')} icon={<RibbonIcon />} noPaddingLeft>
                  {transfer.timestamp && time(transfer.timestamp).format('L')}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('originalTransactionId')}>{transfer.transactionId}</TextField>
              </Grid>
            </Grid>
          </Section>

          <H3 icon={<SenderIcon />}>{t('transferFrom')}</H3>
          <Section className={classes.section} noPaddingRight>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  showLink={buildProfileLink(
                    transfer.from.accountId,
                    transfer.from.profileId,
                    transfer.from.profileType
                  )}
                  label={`${t('from')} ${transfer.from.profileId}`}
                >
                  {transfer.from.accountNumber}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceField
                  source='bankAccountId'
                  reference='bank/accounts'
                  link={false}
                  addLabel={false}
                  record={transfer.from}
                  basePath='/bank/accounts'
                >
                  <RaTextField source='alias' label={t('cpBankAccount')} allowEmpty />
                </ReferenceField>
              </Grid>
            </Grid>
          </Section>

          <H3 icon={<RecipientIcon />}>{t('transferTo')}</H3>
          <Section className={classes.section} noPaddingRight>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  showLink={buildProfileLink(
                    transfer.to.accountId,
                    transfer.to.profileId,
                    transfer.to.profileType
                  )}
                  label={`${t('to')} ${transfer.to.profileId}`}
                >
                  {transfer.to.accountNumber}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceField
                  source='bankAccountId'
                  reference='bank/accounts'
                  link={false}
                  addLabel={false}
                  record={transfer.to}
                  basePath='/bank/accounts'
                >
                  <RaTextField source='alias' label={t('cpBankAccount')} allowEmpty />
                </ReferenceField>
              </Grid>
            </Grid>
          </Section>
        </Fragment>
      )}
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  section: {
    paddingLeft: theme.spacing(4),
  },
}));

export default TransferView;
