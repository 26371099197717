export default {
  ANY: 'Any',
  status: 'Status',
  CREATED: 'Created',
  SENDING: 'Sending',
  SENT: 'Sent',
  CANCELLING: 'Cancelling',
  CANCELLED: 'Cancelled',
  PAYING_OUT: 'Paying out',
  PAID_OUT: 'Paid out',
  FAILED: 'Failed',
  COMPLETED: 'Completed',
};
