import React, { FC, Fragment } from 'react';
import { Datagrid, TextField, Filter, TextInput, usePermissions } from 'react-admin';

import { List } from '~/layout';
import { NoPermissions, StatusField } from '~/components/ra';
import BankAccountCreate from './BankAccountCreate';

export const l = (key: string): string => `resources.bank/accounts.${key}`;

const PnlAccountList: FC<any> = (props) => {
  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions?.includes('bank.account.list')) return <NoPermissions />;

  return (
    <Fragment>
      <List
        bulkActionButtons={false}
        {...props}
        sort={{ field: 'name', order: 'ASC' }}
        actions={<BankAccountCreate permissions={permissions} />}
        filters={
          <Filter>
            <TextInput
              label='Account number'
              source='accountNumber'
              alwaysOn
              style={{ minWidth: 284 }}
            />
          </Filter>
        }
      >
        <Datagrid rowClick='show' size='medium' selectedIds={null}>
          <TextField source='alias' label={l('alias')} sortable={false} />
          <TextField source='number' label={l('accountNumber')} sortable={false} />
          <TextField source='currency' label={l('currency')} sortable={false} />
          <TextField source='description' label={l('description')} sortable={false} />
          <StatusField source='status' sortable={false} />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default PnlAccountList;
