import React from 'react';
import { useTranslate, Toolbar, SaveButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

interface BottomToolbarProps {
  onSave: (newRecord: any) => void;
  onClose: () => void;
  [x: string]: any;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: 'transparent',
    marginTop: 0,
    minHeight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const BottomToolbar = (props: BottomToolbarProps) => {
  const { onSave, onClose, ...rest } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Toolbar className={classes.toolbar} {...rest}>
      <SaveButton onSave={onSave} />
      <Button onClick={onClose} color='primary'>
        {translate('ra.action.cancel')}
      </Button>
    </Toolbar>
  );
};

export default BottomToolbar;
