import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { JSONSchema6 } from 'json-schema';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import { DatePicker } from '@material-ui/pickers';

import { isEqual } from 'lodash';

import AmountInput from '~/components/AmountInput';
import NumberInput from '~/components/NumberInput';
import { Amount, Moment } from '~/types';

interface Value {
  type: string | null;
  period: string | null;
  billingDateType: string | null;
  billingDate: number | null;
  name: string | null;
  amount: Amount | null;
  [key: string]: any;
}

interface Errors {
  name?: {
    error: boolean;
    message: string;
  };
  [key: string]: any;
}

export interface Props {
  schema: JSONSchema6;
  uiSchema: any;
  idSchema: any;
  formData: any;
  errorSchema: any;
  registry: any;
  formContext: any;
  onChange?: (e: any) => void;
  [key: string]: any;
}

const RegularFeeInput = ({ formData, idSchema, uiSchema, formContext, onChange }: Props) => {
  const i18nPrefix = uiSchema['ui:i18nPrefix'];
  const raTranslate = useTranslate();

  const translate = (key: string): string => {
    return raTranslate(`${i18nPrefix}.${key}`);
  };

  const validate = (state: Value): Errors => {
    const errors: Errors = {};
    if (!state.name) {
      errors.name = { error: true, message: raTranslate('ra.validation.required') };
    }
    if (!state.amount) {
      errors.amount = { error: true, message: raTranslate('ra.validation.required') };
    }
    if (state.billingDateType === 'fixed') {
      if (!state.billingDate) {
        errors.billingDate = { error: true, message: raTranslate('ra.validation.required') };
      }
    }
    return errors;
  };

  // FIX non-null amount object by default
  const initialState = formData;
  if (initialState) {
    if (Object.keys(initialState.amount || {}).length === 0) {
      initialState.amount = null;
    }
  }

  const [state, setState] = React.useState<Value>(initialState || { type: '' });
  const [errors, onChangeWithErrors] = React.useState<Errors>({});

  const validateAndSetState = (newState: Value): void => {
    setState(newState);
    onChangeWithErrors(validate(newState)); // force update
  };

  const defaultErrors = validate(state);
  if (!isEqual(errors, defaultErrors)) {
    onChangeWithErrors(defaultErrors);
  }

  {
    const hasError = !!Object.keys(defaultErrors).find((key) => defaultErrors[key] !== undefined);
    formContext?.onValidate?.(idSchema.$id, hasError);
  }

  useEffect(() => {
    const hasError = !!Object.keys(errors).find((key) => errors[key] !== undefined);
    formContext?.onValidate?.(idSchema.$id, hasError);

    onChange?.(state);
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  const dateFromDay = (year: number, day: number) => {
    var date = new Date(year, 0); // initialize a date in `year-01-01`
    return new Date(date.setDate(day)); // add the number of days
  };

  const renderBillingDate = () => {
    if (state.period === 'annually') {
      if (state.billingDateType === 'fixed') {
        return (
          <DatePicker
            label={translate('billingDate')}
            value={dateFromDay(2020, state.billingDate || 1)}
            onChange={(date) => {
              validateAndSetState({ ...state, billingDate: (date as Moment).dayOfYear() });
            }}
            error={errors.billingDate?.error}
            helperText={errors.billingDate?.message || '\u00A0'}
          />
        );
      } else {
        return null;
      }
    } else if (state.period === 'monthly') {
      if (state.billingDateType === 'fixed') {
        return (
          <>
            <NumberInput
              key='billingDate'
              label={translate('billingDate')}
              value={state.billingDate == null ? '' : `${state.billingDate}`}
              required
              error={errors.billingDate?.error}
              helperText={errors.billingDate?.message || '\u00A0'}
              onChange={(billingDate) => {
                validateAndSetState({ ...state, billingDate });
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={state.payOnTheFirstCalendarMonth}
                  onChange={(event) => {
                    validateAndSetState({
                      ...state,
                      payOnTheFirstCalendarMonth: event.target.checked,
                    });
                  }}
                />
              }
              label={translate('payOnTheFirstCalendarMonth')}
            />
          </>
        );
      } else {
        return (
          <FormControlLabel
            control={
              <Switch
                checked={state.payOnTheFirstCalendarMonth}
                onChange={(event) => {
                  validateAndSetState({
                    ...state,
                    payOnTheFirstCalendarMonth: event.target.checked,
                  });
                }}
              />
            }
            label={translate('payOnTheFirstCalendarMonth')}
          />
        );
      }
    }
  };

  const periodOptions = [
    { id: 'annually', disabled: false },
    { id: 'monthly', disabled: false },
    // { id: 'daily', disabled: false },
  ].map((item) => (
    <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
      {translate(`period.${item.id}`)}
    </MenuItem>
  ));

  const typeOptions = [
    { id: 'fixedFee', disabled: false },
    { id: 'tierBasedTurnover', disabled: true },
    { id: 'tierBasedBalance', disabled: true },
  ].map((item) => (
    <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
      {translate(`type.${item.id}`)}
    </MenuItem>
  ));

  const billingDateTypeOptions = [
    { id: 'fixed', disabled: false },
    { id: 'sliding', disabled: false },
  ].map((item) => (
    <MenuItem key={item.id} value={item.id} disabled={item.disabled}>
      {translate(`billingDateType.${item.id}`)}
    </MenuItem>
  ));

  const title = uiSchema['ui:title'] ? (
    <Typography variant='h5'>{uiSchema['ui:title']}</Typography>
  ) : null;

  return (
    <Box>
      {title}
      <Divider />
      <Box mt={2}>
        <TextField
          label={translate('name')}
          value={state.name}
          required
          error={errors.name?.error}
          helperText={errors.name?.message || '\u00A0'}
          onChange={(e: any) => {
            setState({ ...state, name: e.target.value });
          }}
          onBlur={() => {
            onChangeWithErrors(validate(state));
          }}
        />
      </Box>
      <Box>
        <FormControl required>
          <InputLabel>{translate('period._')}</InputLabel>
          <Select
            value={state.period}
            onChange={(event) => {
              validateAndSetState({
                ...state,
                period: event.target.value as string,
                billingDate: 1,
              });
            }}
          >
            {periodOptions}
          </Select>
          <FormHelperText>{'\u00A0'}</FormHelperText>
        </FormControl>
        {'\u00A0'}
        <FormControl required>
          <InputLabel>{translate('type._')}</InputLabel>
          <Select
            value={state.type}
            onChange={(event) => {
              validateAndSetState({ ...state, type: event.target.value as string });
            }}
          >
            {typeOptions}
          </Select>
          <FormHelperText>{'\u00A0'}</FormHelperText>
        </FormControl>
      </Box>
      <AmountInput
        key='amount'
        label={translate(`amount`)}
        currencyLabel={translate(`currency`)}
        amount={state.amount}
        required
        onChange={(amount: Amount | null) => {
          validateAndSetState({ ...state, amount: amount });
        }}
        error={errors.amount?.error}
        helperText={errors.amount?.message || '\u00A0'}
      />
      <Box>
        <FormControl required>
          <InputLabel>{translate('billingDateType._')}</InputLabel>
          <Select
            value={state.billingDateType}
            onChange={(event) => {
              validateAndSetState({ ...state, billingDateType: event.target.value as string });
            }}
            style={{ minWidth: 210 }}
          >
            {billingDateTypeOptions}
          </Select>
          <FormHelperText>{'\u00A0'}</FormHelperText>
        </FormControl>
      </Box>
      {renderBillingDate()}
    </Box>
  );
};

export default RegularFeeInput;
