import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';

import { useTheme } from '@material-ui/core/styles';

import TransactionView from '~/components/TransactionsHistory/TransactionView';
import { DateTimeField, ColoredAmountField, RowMenu, ColoredField } from './cellComponents';

import { ApiLocalizedTextField, ChipField, VirtualizedList } from '~/components';
import {
  selectors,
  getVirtualizedListBegin,
  getVirtualizedListSuccess,
  getVirtualizedListFail,
} from '~/ducks/virtualizedList';
import { Column, Individual, Business, Transaction, TransactionsFilters } from '~/types';
import { getTransactions } from '~/api/transactionsHistory';
import Filters from './Filters';
import { NoPermissions } from '../ra';
import { Props as FieldProps } from './cellComponents';
import OperationTypeField from '~/components/OperationTypeField';

export const resource = 'transactions';

const label = (key: string): string => `components.TransactionsHistory.${key}`;

export type Target = 'individuals' | 'businesses' | 'partners' | 'tenant' | 'bank/accounts';
interface Props {
  record?: Individual | Business;
  target: Target;
  permissions: string[];
}

const TransactionHistory = (props: Props) => {
  const { record, target, permissions } = props;
  const theme = useTheme();
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFilters<TransactionsFilters>(resource));
  const list = useSelector(selectors.getList(resource));

  const loadNextPage = async () => {
    if (isNextPageLoading || !record) return;

    const queryToken = Math.random();
    try {
      dispatch(getVirtualizedListBegin({ resource, queryToken }));
      const { data } = await getTransactions({
        target,
        resourceId: record.id,
        next: list.cursors.next,
        filters,
      });
      const responseData = {
        ...data,
        cursors: data.cursors,
        records: data.transactions as any[],
      };
      dispatch(getVirtualizedListSuccess({ resource, responseData, queryToken }));
    } catch (error) {
      const err = error as Error;
      dispatch(getVirtualizedListFail({ resource, error: err, queryToken }));
    }
  };

  const RowField = (Field: any) => {
    return (props: FieldProps) => {
      const style =
        props.rowData.status === 'REJECTED' ? { color: theme.palette.error.main } : undefined;
      return (
        <Field {...props} style={style}>
          {props.children}
        </Field>
      );
    };
  };

  const columns: Column[] = [
    {
      selectCellData: (rowData) => rowData.status !== 'PENDING' && rowData.postedAmount,
      cellDataWrapperComponent: RowField((props: FieldProps) => {
        const { rowData } = props;
        if (
          !rowData.headTransactionId &&
          !rowData.postedAmount &&
          (rowData.status === 'COMPLETED' || rowData.status === 'REJECTED')
        ) {
          return <ChipField label={translateLabel('external')} />;
        } else {
          return (
            <ColoredField {...props}>
              <ColoredAmountField {...props} />
            </ColoredField>
          );
        }
      }),
      id: 'postedAmount',
      header: translateLabel('postedAmount'),
      headerAlign: 'right',
      cellAlign: 'right',
      style: {
        flexBasis: 150,
        minWidth: 110,
      },
    },
    {
      selectCellData: (rowData) => rowData.baseAmount,
      cellDataWrapperComponent: RowField(ColoredAmountField),
      id: 'baseAmount',
      header: translateLabel('baseAmount'),
      headerAlign: 'right',
      cellAlign: 'right',
      style: {
        flexBasis: 150,
        minWidth: 110,
      },
    },
    {
      selectCellData: (rowData) => rowData.operationType,
      cellDataWrapperComponent: RowField((props: FieldProps) => (
        <ColoredField {...props}>
          {props?.rowData?.operationSubtype ? (
            <ApiLocalizedTextField
              keyPrefix='operationSubtype'
              secondKey={props?.rowData?.operationSubtype}
              label={translate(`operationType.${props?.rowData?.operationType}`)}
            />
          ) : (
            <OperationTypeField operationType={props.children} />
          )}
        </ColoredField>
      )),
      id: 'operationType',
      header: translateLabel('operationType'),
      style: {
        flexBasis: 170,
        minWidth: 110,
      },
    },
    {
      selectCellData: (rowData) => rowData.description,
      cellDataWrapperComponent: RowField(ColoredField),
      header: translateLabel('details'),
      style: {
        flexBasis: 450,
        display: 'inline',
        marginTop: 'auto',
        marginBottom: 'auto',
      },
    },
    {
      selectCellData: (rowData) => rowData.createdAt,
      cellDataWrapperComponent: RowField(DateTimeField),
      header: translateLabel('datetime'),
      width: 160,
    },
    {
      selectCellData: (rowData) => rowData,
      cellDataWrapperComponent: RowMenu,
      header: '',
      width: 50,
      style: {
        paddingLeft: 10,
      },
    },
  ];

  const [showData, setShowData] = useState<Transaction | null>(null);
  const handleShowOpen = (data: Transaction) => setShowData(data);
  const handleShowClose = () => setShowData(null);

  const { hasNextPage, isNextPageLoading, data } = list;
  if (!record) return null;
  if (!permissions) return null;
  if (target === 'individuals') {
    if (!permissions.includes('individual.transaction.list')) return <NoPermissions />;
  }
  if (target === 'businesses') {
    if (!permissions.includes('business.transaction.list')) return <NoPermissions />;
  }
  const viewPermission =
    target === 'businesses'
      ? permissions.includes('business.transaction.view')
      : permissions.includes('individual.transaction.view');
  return (
    <Fragment>
      <Filters record={record} />
      <VirtualizedList<Transaction>
        resource={resource}
        columns={columns}
        hasNextPage={hasNextPage}
        filters={filters}
        isNextPageLoading={isNextPageLoading}
        data={data}
        loadNextPage={loadNextPage}
        onRowClick={viewPermission ? handleShowOpen : undefined}
      />
      <TransactionView
        record={record}
        target={target}
        transaction={showData}
        onClose={handleShowClose}
      />
    </Fragment>
  );
};

export default TransactionHistory;
