import React, { Fragment, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import lodashGet from 'lodash/get';

import FeeIcon from '@material-ui/icons/AttachMoney';
import { CalculatorIcon, BarChartIcon } from '~/img';

import { Pricing, Product } from '~/types';
import { useConfiguration, useFeatures } from '~/hooks';
import { PricingSchema, PricingSchemaPropertie } from '~/api/pricings';
import { H3, AccordionSummary } from '~/components';
import { l } from '~/resources/pricings/PricingShow';
import PricingDataEdit from './PricingDataEdit';
import PricingDataShow from './PricingDataShow';
import { Section } from '~/layout';
import { getProductById } from '~/api/product';
import PricingDataFork from './PricingDataFork';
import { useQuery } from 'react-query';

export type ByFeature = {
  propertyKey: string;
} & PricingSchemaPropertie;

type ByFeatureObject = {
  [x: string]: ByFeature[];
};

interface Props {
  record: Pricing;
  pricingSchema: PricingSchema | undefined;
}

const PerFeature = (props: Props) => {
  const { record, pricingSchema } = props;

  const { data: productData } = useQuery<Product>(
    [`productById-${record?.productId || ''}`],
    () => getProductById(record?.productId!).then((res) => res.data),
    {
      enabled: !!record?.productId,
    }
  );
  const currency = productData?.currency || '';

  // Get full list of all existig features
  const { list: featuresList } = useFeatures();

  // format received data by feature
  const [byFeature, setByFeature] = useState<ByFeatureObject>();
  useEffect(() => {
    if (pricingSchema) {
      const properties = pricingSchema.properties;
      const result: ByFeatureObject = {};
      for (let key in properties) {
        const prop = properties[key];
        const featureKey = prop['v:feature'];
        const featureContent = result[featureKey] || [];
        result[featureKey] = [...featureContent, { propertyKey: key, ...prop }];
      }
      setByFeature(result);
    }
  }, [pricingSchema]);

  // Main state
  const [state, setState] = useState<any>(record.data || {});

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  const classes = useStyles();

  const { data: configuration } = useConfiguration(record.solutionId);

  return (
    <Grid container>
      {byFeature &&
        featuresList &&
        Object.keys(byFeature)
          .sort((k1, k2) => {
            return (featuresList[k1] || k1).localeCompare(featuresList[k2] || k2);
          })
          .map((featureKey) => {
            const featureContent = byFeature[featureKey].sort((a, b) => {
              if (typeof a['v:index'] !== 'undefined' && typeof b['v:index'] === 'undefined')
                return -1;
              if (typeof a['v:index'] === 'undefined' && typeof b['v:index'] !== 'undefined')
                return 1;
              if (typeof a['v:index'] !== 'undefined' && typeof b['v:index'] !== 'undefined') {
                if (a['v:index'] < b['v:index']) return -1;
                if (a['v:index'] > b['v:index']) return 1;
              }

              var x = a['v:name'].localeCompare(b['v:name']);
              if (x !== 0) return x;
              return 0;
            });
            const isExpanded = Boolean(expanded[featureKey]);
            return (
              <Grid key={featureKey} item xs={12}>
                <Accordion expanded={isExpanded} onChange={handleChange(featureKey, isExpanded)}>
                  <AccordionSummary
                    aria-controls={`${featureKey}-content`}
                    id={`${featureKey}-header`}
                    expanded={isExpanded}
                    heading={featuresList[featureKey]}
                  />
                  <AccordionDetails className={classes.AccordionDetails}>
                    {featureContent.map((item) => {
                      if (item.type === 'veengu_service_charges') {
                        const items = configuration?.veengu_service_charges?.items || [];
                        return items.map((serviceCharge: any) => {
                          const schema: ByFeature = {
                            propertyKey: `${item.propertyKey}.${serviceCharge.id}`,
                            type: 'veengu_fee_default',
                            'v:category': 'service',
                            'v:feature': item['v:feature'],
                            'v:name': serviceCharge.name,
                            'v:enum': [{ id: 'fixedDebitFee', name: 'Fixed Debit Fee' }],
                          };

                          const keys = Object.keys(record.data['serviceCharges'] || {})
                            .map((k) => `serviceCharges.${k}`)
                            .filter(
                              (k) => k.startsWith(schema.propertyKey) && k !== schema.propertyKey
                            );
                          keys.unshift(schema.propertyKey);

                          const pairs = keys
                            .map((k) => ({
                              key: k,
                              entity: lodashGet(record.data, k),
                            }))
                            .sort((a, b) => {
                              if (
                                typeof a?.entity?.index !== 'undefined' &&
                                typeof b?.entity?.index === 'undefined'
                              )
                                return 1;
                              if (
                                typeof a?.entity?.index === 'undefined' &&
                                typeof b?.entity?.index !== 'undefined'
                              )
                                return -1;
                              if (
                                typeof a?.entity?.index !== 'undefined' &&
                                typeof b?.entity?.index !== 'undefined'
                              ) {
                                if (a?.entity?.index < b?.entity?.index) return -1;
                                if (a?.entity?.index > b?.entity?.index) return 1;
                              }
                              return 0;
                            });

                          return (
                            <Fragment key={item.propertyKey}>
                              {pairs.map((pair) => {
                                const key = pair.key;
                                const entity = pair.entity;

                                return (
                                  <Section key={key} className={classes.section}>
                                    <Grid item container>
                                      <Grid item container xs={12} justify='space-between'>
                                        <Grid item>
                                          <ServiceChargeHeading
                                            item={serviceCharge}
                                            tags={entity?.tags || []}
                                          />
                                        </Grid>
                                        <Grid item id='section-actions'>
                                          <PricingDataEdit
                                            schema={{
                                              ...schema,
                                              propertyKey: key,
                                            }}
                                            pricingRecord={record}
                                            record={state}
                                            setMainState={setState}
                                            currency={currency}
                                          />
                                          {!entity?.tags && (
                                            <PricingDataFork
                                              schema={schema}
                                              pricingRecord={record}
                                              record={state}
                                              setMainState={setState}
                                              currency={currency}
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                      <Grid item container xs={12}>
                                        <PricingDataShow
                                          schema={schema}
                                          entity={entity}
                                          currency={currency}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Section>
                                );
                              })}
                            </Fragment>
                          );
                        });
                      } else {
                        const keys = Object.keys(record.data).filter(
                          (k) => k.startsWith(item.propertyKey) && k !== item.propertyKey
                        );
                        keys.unshift(item.propertyKey);
                        const pairs = keys
                          .map((k) => ({
                            key: k,
                            entity: lodashGet(record.data, k),
                          }))
                          .sort((a, b) => {
                            if (
                              typeof a?.entity?.index !== 'undefined' &&
                              typeof b?.entity?.index === 'undefined'
                            )
                              return 1;
                            if (
                              typeof a?.entity?.index === 'undefined' &&
                              typeof b?.entity?.index !== 'undefined'
                            )
                              return -1;
                            if (
                              typeof a?.entity?.index !== 'undefined' &&
                              typeof b?.entity?.index !== 'undefined'
                            ) {
                              if (a?.entity?.index < b?.entity?.index) return -1;
                              if (a?.entity?.index > b?.entity?.index) return 1;
                            }
                            return 0;
                          });

                        return (
                          <Fragment key={item.propertyKey}>
                            {pairs.map((pair) => {
                              const key = pair.key;
                              const entity = pair.entity;
                              // const entity = lodashGet(record.data, key); // ? (Object.keys(record.data[key]) == )
                              return (
                                <Section className={classes.section} key={key}>
                                  <Grid item container>
                                    <Grid item container xs={12} justify='space-between'>
                                      <Grid item style={{ maxWidth: '80%' }}>
                                        <CategoryHeading item={item} tags={entity?.tags || []} />
                                      </Grid>
                                      <Grid item id='section-actions'>
                                        <PricingDataEdit
                                          schema={{
                                            ...item,
                                            propertyKey: key,
                                          }}
                                          pricingRecord={record}
                                          record={state}
                                          setMainState={setState}
                                          currency={currency}
                                        />
                                        {(item['v:category'] === 'fee' ||
                                          item['v:category'] === 'tax' ||
                                          item['v:category'] === 'loyalty' ||
                                          item.type === 'veengu_limit_single_operation_amount') &&
                                          !entity?.tags && (
                                            <PricingDataFork
                                              schema={item}
                                              pricingRecord={record}
                                              record={state}
                                              setMainState={setState}
                                              currency={currency}
                                            />
                                          )}
                                      </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                      <PricingDataShow
                                        schema={item}
                                        entity={entity}
                                        currency={currency}
                                      />
                                    </Grid>
                                  </Grid>
                                </Section>
                              );
                            })}
                          </Fragment>
                        );
                      }
                    })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
    </Grid>
  );
};

type ServiceChargeHeadingProps = {
  item: any;
  tags: string[];
};

const ServiceChargeHeading = ({ item, tags }: ServiceChargeHeadingProps) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();
  return (
    <H3 topSpacing={0} icon={<FeeIcon />} noPaddingLeft>
      <span className={classes.secondaryHeading}>
        <span>{t('service')}</span> <span>"{item.name}"</span>
        {tags.map((t) => (
          <Chip key={t} label={t} size='small' style={{ margin: 1 }} />
        ))}
      </span>
    </H3>
  );
};

type CategoryHeadingProps = {
  item: PricingSchemaPropertie;
  tags: string[];
};

const CategoryHeading = ({ item, tags }: CategoryHeadingProps) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  const getIcon = () => {
    switch (item['v:category']) {
      case 'fee':
      case 'tax':
      case 'service':
        return <FeeIcon />;
      case 'interest':
        return <BarChartIcon />;
      case 'limit':
      case 'counter':
        return <CalculatorIcon />;
      default:
        return <></>;
    }
  };

  return (
    <H3 topSpacing={0} icon={getIcon()} noPaddingLeft>
      <span className={classes.secondaryHeading}>
        <span>{t(item['v:category'])}</span> <span>"{item['v:name']}"&nbsp;</span>
        {tags.map((t) => (
          <Chip key={t} label={t} size='small' style={{ margin: 1 }} />
        ))}
      </span>
    </H3>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    display: 'block',
  },
  heading: {
    fontSize: '16px',
  },
  secondaryHeading: {
    fontSize: '16px',
  },
  section: {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

export default PerFeature;
