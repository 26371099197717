import React, { useState, Fragment, useRef } from 'react';
import { useTranslate, TextInput, FormDataConsumer, SimpleForm, required } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-final-form';
import lodashGet from 'lodash/get';

import { EditButton, Drawer, CreateButton, AddDeleteButton, BottomToolbar } from '~/components';
import { PhoneNumberInput, EditForm } from '~/components/ra';
import { ChannelIdInput, ChannelSelect } from '~/resources/businesses/components';
import { makeStyles } from '@material-ui/core/styles';
import { Individual, Business } from '~/types';
import { Contact } from '~/types/businesses';
import { email } from 'react-admin';
import { Channel } from '~/types/profile';

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: 360,
    boxSizing: 'border-box',
  },
  toolbar: {
    backgroundColor: 'transparent',
    marginTop: 0,
    minHeight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const label = (key: string): string => `components.ra.fields.ProfileEditContacts.${key}`;

interface AddContactProps {
  onClick: () => void;
  onClose: () => void;
  open: boolean;
  formData: Business;
  getSource: any;
  formRef: any;
}

const AddContact = (props: AddContactProps) => {
  const { onClick, open, onClose, formData, getSource, formRef } = props;
  const lastContactIndex = formData.contacts?.length || 0;
  const form = useForm();
  const classes = useStyles();

  const handleSubmit = (newRecord: Business): void => {
    if (newRecord.contacts) {
      form.change('contacts', [...newRecord.contacts]);
      onClose();
      setTimeout(() => {
        const input = formRef.current?.getElementsByTagName('input')[
          `contacts[${lastContactIndex}].id`
        ];
        if (input) input.focus();
      }, 10);
    }
  };

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  return (
    <Fragment>
      <Grid container justify='flex-end'>
        <Grid item>
          <CreateButton variant='add' onClick={onClick} />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>{translateLabel('addContact')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <SimpleForm
            redirect={false}
            margin='none'
            className={classes.form}
            record={formData}
            toolbar={<BottomToolbar onSave={handleSubmit} onClose={onClose} />}
          >
            <FormDataConsumer>
              {({ formData }: FormData) => {
                return (
                  <Fragment>
                    <ChannelSelect source={getSource(lastContactIndex, 'channel')} />
                    <ChannelIdInput
                      source={getSource(lastContactIndex, 'id')}
                      selectInputSource={getSource(lastContactIndex, 'channel')}
                      record={formData}
                    />
                  </Fragment>
                );
              }}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const ContactInput = (props: {
  getContactsSource: (index: number, source?: string) => string;
  index: number;
  contact: Contact;
  formData: Business;
  autoFocus?: boolean;
}) => {
  const { getContactsSource, index, contact, formData, autoFocus } = props;
  const form = useForm();
  const translate = useTranslate();
  const contacts: Contact[] | undefined = lodashGet(formData, 'contacts');
  const addValidate = (inputType: Channel) => {
    switch (inputType) {
      case 'EMAIL':
        return email();
      default:
        return undefined;
    }
  };
  return (
    <AddDeleteButton
      onDeleteClick={() => {
        if (contacts) {
          form.change(
            'contacts',
            contacts.filter((item, i) => i !== index)
          );
        }
      }}
    >
      {contact.channel === 'PHONE' ? (
        <PhoneNumberInput
          source={getContactsSource(index)}
          label={translate(`components.ra.inputs.ChannelSelect.${contact.channel}`)}
          autoFocus={autoFocus}
          fullWidth
        />
      ) : (
        <TextInput
          source={getContactsSource(index)}
          label={translate(`components.ra.inputs.ChannelSelect.${contact.channel}`)}
          validate={[required(), addValidate(contact.channel)]}
          autoFocus={autoFocus}
          fullWidth
        />
      )}
    </AddDeleteButton>
  );
};

type FormData = {
  formData: Business;
  [x: string]: any;
};

interface Props {
  resource: 'businesses' | 'individuals';
  record: Business | Individual | undefined;
  actionsId?: string; // used to apply some css from BusinessShow
  permissions: string[];
}

export const EditContact = (props: Props) => {
  const { resource, record, actionsId = 'section-actions', permissions } = props;
  const formRef = useRef<any>(null);

  const [open, setOpen] = useState(false);
  const [isAddContactOpen, setAddContactOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAddContactOpen = () => setAddContactOpen(true);
  const handleAddContactClose = () => setAddContactOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const getContactsSource = (index: number, source: string = 'id'): string =>
    `contacts[${index}].${source}`;

  if (!record) return null;

  return (
    <Fragment>
      <div id={actionsId}>
        <EditButton
          label={translate(label('editContacts'))}
          onClick={handleOpen}
          disabled={!permissions?.includes('business.update')}
        />
      </div>
      <Drawer heading={translateLabel('editContacts')} open={open} onClose={handleClose}>
        <EditForm resource={resource} record={record} closeParent={handleClose}>
          <FormDataConsumer>
            {({ formData }: FormData) => {
              return (
                <div ref={formRef}>
                  {formData.contacts && formData.contacts.length > 0
                    ? formData.contacts?.map((contact, index) => {
                        return (
                          <ContactInput
                            key={index}
                            getContactsSource={getContactsSource}
                            index={index}
                            contact={contact}
                            formData={formData}
                            autoFocus={index === 0}
                          />
                        );
                      })
                    : translateLabel('noContacts')}
                  <AddContact
                    open={isAddContactOpen}
                    onClick={handleAddContactOpen}
                    onClose={handleAddContactClose}
                    formData={formData}
                    getSource={getContactsSource}
                    formRef={formRef}
                  />
                </div>
              );
            }}
          </FormDataConsumer>
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default EditContact;
