import React, { FC } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { Location } from '~/types';
import { getDaysOfWeek, useFirstWeekDay, useTranslateDay } from './WorkingHoursInput';
import { Longdash } from '~/utils';
import isEmpty from 'lodash/isEmpty';
import { Grid } from '@material-ui/core';

interface Props {
  schedule?: Location['schedule'];
  country?: string;
}

const WorkingHoursField: FC<Props> = (props) => {
  const { schedule, country } = props;
  const { data: firstWeekDay } = useFirstWeekDay(country);
  const t = useTranslateDay();

  return (
    <Timeline>
      {getDaysOfWeek(firstWeekDay)?.map((day, index, arr) => {
        const isLast = index === arr.length - 1;
        const workingHours = schedule?.[day]?.workingHours;
        return (
          <TimelineItem>
            <TimelineOppositeContent>{t(day)}</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color={isEmpty(workingHours) ? 'grey' : 'primary'} />
              {!isLast && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Grid container spacing={1} direction='column'>
                {workingHours?.map((item, index) => {
                  return (
                    <Grid key={index} item>
                      <div>
                        {item.from} {Longdash} {item.to}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default WorkingHoursField;
