import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { time } from '~/utils';

import { SelectInput, TextInput } from '~/components';
import { selectors, filterChange } from '~/ducks/virtualizedList';
import { resource } from './AuditLogList';
import { Moment } from '~/types';
import { Tag } from '~/api/auditLog';
import TagsInput from './TagsInput';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(1),
      flexGrow: 1,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  profileId: {
    maxWidth: 300,
  },
  dateTimePicker: {
    maxWidth: 230,
  },
  timeframe: {
    maxWidth: 200,
  },
  tags: {
    minWidth: 509,
    '& > div': {
      height: 46,
    },
    marginRight: 8,
  },
  reference: {
    maxWidth: 300,
  },
}));

const Filters = () => {
  const filters = useSelector(selectors.getFilters(resource));
  const [localValues, setLocalValues] = useState(filters);
  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);
  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;
    if (name === 'tags' && (value || []).length > 10) return;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };
  const handleDateTimeChange = (data: Moment, inputType: string) => {
    setLocalValues({
      ...localValues,
      timeframe: '',
      [inputType]: data,
    });
    dispatchFilterChange(inputType, data);
  };

  const handleTimeframeChange = (e: any) => {
    const { name, value } = e.target;
    const newState = {
      [name]: value,
      fromTimestamp: value && time().subtract(value, 'minutes'),
      toTimestamp: value && time(),
    };
    setLocalValues({
      ...localValues,
      ...newState,
    });
    dispatch(
      filterChange({
        resource,
        filters: newState,
      })
    );
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.auditLog.${key}`);

  return (
    <Fragment>
      {filters && (
        <div className={classes.root}>
          <div className={classes.container}>
            <TextInput
              name='profileId'
              onChange={debouncedHandleInput}
              value={localValues.profileId}
              label={t('profileId')}
              className={classes.profileId}
              disableHelperText
            />
            <SelectInput<number>
              label={t('timeframe')}
              name='timeframe'
              value={localValues.timeframe || ''}
              className={classes.timeframe}
              options={[
                { value: 5, text: 'Last 5 minutes' },
                { value: 30, text: 'Last 30 minutes' },
                { value: 6 * 60, text: 'Last 6 hours' },
                { value: 24 * 60, text: 'Last 24 hours' },
                { value: 3 * 24 * 60, text: 'Last 3 days' },
                { value: 7 * 24 * 60, text: 'Last 7 days' },
                { value: 2 * 7 * 24 * 60, text: 'Last 2 weeks' },
              ]}
              onChange={handleTimeframeChange}
              allowEmpty
            />
            <DateTimePicker
              className={classes.dateTimePicker}
              format='L - LT'
              margin='normal'
              label={t('fromTimestamp')}
              value={localValues.fromTimestamp || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'fromTimestamp')}
              maxDate={localValues.toTimestamp || time().format()}
            />
            <DateTimePicker
              className={classes.dateTimePicker}
              format='L - LT'
              margin='normal'
              label={t('toTimestamp')}
              value={localValues.toTimestamp || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'toTimestamp')}
              maxDate={time().format()}
              minDate={localValues.fromTimestamp}
            />
            <SelectInput<Tag | undefined>
              label={t('logLevel')}
              name='logLevel'
              value={localValues.logLevel}
              className={classes.timeframe}
              options={[
                { value: undefined, text: '–' },
                { value: 'l.error', text: 'Error' },
                { value: 'l.warn', text: 'Warning' },
                { value: 'l.info', text: 'Info' },
              ]}
              onChange={debouncedHandleInput}
            />
            <TextInput
              name='identityName'
              onChange={debouncedHandleInput}
              value={localValues.identityName}
              label={t('identityName')}
              className={classes.dateTimePicker}
              disableHelperText
            />
            <TextInput
              name='identityId'
              onChange={debouncedHandleInput}
              value={localValues.identityId}
              label={t('identityId')}
              className={classes.dateTimePicker}
              disableHelperText
            />
          </div>
          <TagsInput
            value={localValues.tags || []}
            className={classes.tags}
            onChange={debouncedHandleInput}
          />
          <TextInput
            name='reference'
            onChange={debouncedHandleInput}
            value={localValues.reference}
            label={t('reference')}
            className={classes.reference}
            disableHelperText
          />
        </div>
      )}
    </Fragment>
  );
};

export default Filters;
