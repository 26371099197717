import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { JSONSchema6 } from 'json-schema';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import { isEqual } from 'lodash';

import AmountInput from '~/components/AmountInput';
import NumberInput from '~/components/NumberInput';
import { Amount } from '~/types';

interface Value {
  type: string | unknown;
  name: string | unknown;
  [key: string]: any;
}

interface Errors {
  name?: {
    error: boolean;
    message: string;
  };
  [key: string]: any;
}

export interface Props {
  schema: JSONSchema6;
  uiSchema: any;
  idSchema: any;
  formData: any;
  errorSchema: any;
  registry: any;
  formContext: any;
  onChange?: (e: any) => void;
  [key: string]: any;
}

const FeeInput = ({ formData, idSchema, uiSchema, formContext, onChange }: Props) => {
  const i18nPrefix = uiSchema['ui:i18nPrefix'];
  const raTranslate = useTranslate();

  const translate = (key: string): string => {
    return raTranslate(`${i18nPrefix}.${key}`);
  };

  const validate = (state: Value): Errors => {
    const errors: Errors = {};
    if (!state.name) {
      errors.name = { error: true, message: raTranslate('ra.validation.required') };
    }
    switch (state.type) {
      case 'fixedDebitFee':
      case 'fixedDebitFeeDecrease':
      case 'fixedCreditFee':
        if (!state.amount) {
          errors.amount = { error: true, message: raTranslate('ra.validation.required') };
        }
        break;
      case 'percentDebitFee':
      case 'percentDebitFeeDecrease':
      case 'percentCreditFee':
        if (!state.percent) {
          errors.percent = { error: true, message: raTranslate('ra.validation.required') };
        }
        break;
      default:
        break;
    }
    return errors;
  };

  const actualTypes: string[] = uiSchema['ui:enum'] || [
    'fixedDebitFee',
    'fixedDebitFeeDecrease',
    'percentDebitFee',
    'percentDebitFeeDecrease',
    'fixedCreditFee',
    'percentCreditFee',
  ];

  // FIX non-null amount object by default
  const initialState = formData;
  if (initialState) {
    if (Object.keys(initialState.amount || {}).length === 0) {
      initialState.amount = null;
    }
    if (Object.keys(initialState.minAmount || {}).length === 0) {
      initialState.minAmount = null;
    }
    if (Object.keys(initialState.maxAmount || {}).length === 0) {
      initialState.maxAmount = null;
    }
  }

  const [state, setState] = React.useState<Value>(initialState || { type: actualTypes[0] });
  const [errors, onChangeWithErrors] = React.useState<Errors>({});

  const validateAndSetState = (newState: Value): void => {
    setState(newState);
    onChangeWithErrors(validate(newState)); // force update
  };

  const defaultErrors = validate(state);
  if (!isEqual(errors, defaultErrors)) {
    onChangeWithErrors(defaultErrors);
  }

  {
    const onValidate = formContext?.onValidate;
    const hasError = !!Object.keys(defaultErrors).find((key) => defaultErrors[key] !== undefined);
    onValidate && onValidate(idSchema.$id, hasError);
  }

  useEffect(() => {
    const onValidate = formContext?.onValidate;
    const hasError = !!Object.keys(errors).find((key) => errors[key] !== undefined);
    onValidate && onValidate(idSchema.$id, hasError);

    onChange && onChange(state);
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  let inputForType = <></>;
  switch (state.type) {
    case 'fixedDebitFee':
    case 'fixedDebitFeeDecrease':
    case 'fixedCreditFee':
      inputForType = (
        <>
          <AmountInput
            key='amount'
            label={translate(`amount`)}
            currencyLabel={translate(`currency`)}
            amount={state.amount}
            required
            onChange={(amount: Amount | null) => {
              validateAndSetState({ ...state, amount: amount });
            }}
            error={errors.amount?.error}
            helperText={errors.amount?.message || '\u00A0'}
          />
        </>
      );
      break;
    case 'percentDebitFee':
    case 'percentDebitFeeDecrease':
    case 'percentCreditFee':
      inputForType = (
        <>
          <NumberInput
            key='percent'
            label={translate('percent')}
            value={state.percent}
            required
            error={errors.percent?.error}
            helperText={errors.percent?.message || '\u00A0'}
            onChange={(percent) => {
              validateAndSetState({ ...state, percent });
            }}
          />
          <AmountInput
            key='minAmount'
            label={translate(`minAmount`)}
            currencyLabel={translate(`currency`)}
            amount={state.minAmount}
            onChange={(amount) => {
              validateAndSetState({ ...state, minAmount: amount });
            }}
          />
          <AmountInput
            key='maxAmount'
            label={translate(`maxAmount`)}
            currencyLabel={translate(`currency`)}
            amount={state.maxAmount}
            onChange={(amount) => {
              validateAndSetState({ ...state, maxAmount: amount });
            }}
          />
        </>
      );
      break;
    default:
      break;
  }

  const handleTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    validateAndSetState({ ...state, type: event.target.value });
  };

  const typeOptions = actualTypes.map((id) => (
    <MenuItem key={id} value={id}>
      {translate(`type.${id}`)}
    </MenuItem>
  ));

  const title = uiSchema['ui:title'] ? (
    <Typography variant='h5'>{uiSchema['ui:title']}</Typography>
  ) : null;

  return (
    <Box>
      {title}
      <Divider />
      <Box mt={2}>
        <FormControl required>
          <InputLabel>{translate('type._')}</InputLabel>
          <Select value={state.type} onChange={handleTypeChange}>
            {typeOptions}
          </Select>
          <FormHelperText>{'\u00A0'}</FormHelperText>
        </FormControl>
      </Box>
      <TextField
        label={translate('name')}
        value={state.name}
        required
        error={errors.name?.error}
        helperText={errors.name?.message || '\u00A0'}
        onChange={(e: any) => {
          setState({ ...state, name: e.target.value });
        }}
        onBlur={() => {
          onChangeWithErrors(validate(state));
        }}
      />
      {inputForType}
    </Box>
  );
};

export default FeeInput;
