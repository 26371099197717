import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import PartnerForm from './PartnerForm';

export const l = (key: string): string => `resources.partners.${key}`;

const PartnerCreate = (props: any) => {
  const { permissions, resource } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton
        variant='add'
        onClick={handleOpen}
        disabled={!permissions?.includes('partner.create')}
      />
      <Drawer heading={t('addPartner')} open={open} onClose={handleClose}>
        <CreateForm resource={resource} closeParent={handleClose}>
          <PartnerForm />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default PartnerCreate;
