import React, { useState } from 'react';
import {
  SelectInput,
  useTranslate,
  TextInput,
  required,
  usePermissions,
  SaveButton,
} from 'react-admin';
import { Grid, makeStyles } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';
import { isEmpty } from 'lodash';
import { AsideBase } from '~/layout';
import { Drawer, H2, TextField } from '~/components';
import { Button, HiddenInput, SimpleForm } from './ra';
import { RibbonIcon } from '~/img';
import { Processing } from '~/types/Event';
import { useRunManualAction, useTaskActions } from '~/hooks/events';
import { Skeleton } from '@material-ui/lab';
import Toolbar from './ra/Toolbar';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  h2: {
    lineHeight: '30px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mirroredIcon: {
    transform: 'scaleX(-1)',
  },
}));

interface Props {
  processing?: Processing;
  eventId: string;
  eventTopic: string;
  refresh: any;
}

const EventTasks = (props: Props) => {
  const { processing, eventId, eventTopic, refresh } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.EventTask.${key}`);
  const classes = useStyles();
  const { permissions } = usePermissions();
  const hasPermissionsList = permissions?.includes('event.actions.list');
  return (
    <Grid item>
      <div>
        <header className={classes.header}>
          <H2 topSpacing={0} bottomSpacing={1} className={classes.h2}>
            {t('eventTasks')}
          </H2>
          <div>
            {hasPermissionsList && (
              <ManualAction eventId={eventId} eventTopic={eventTopic} refresh={refresh} />
            )}
          </div>
        </header>
      </div>
      {processing?.state && !isEmpty(processing?.state)
        ? Object.entries(processing?.state).map(([label, status]) => {
            const reason = processing?.reasons[label];
            return <EventTaskCard key={label} type={label} state={status} reason={reason} />;
          })
        : t('empty')}
    </Grid>
  );
};

export default EventTasks;

const EventTaskCard = (props: any) => {
  const { type, state, reason } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.EventTask.${key}`);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AsideBase>
          <Grid container>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <TextField label={t('taskType')} icon={<RibbonIcon />} ellipsis>
                  {type}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField label={t('taskState')} ellipsis>
                  {state}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('reason')} icon={<></>} textWrap>
                  {reason}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </AsideBase>
      </Grid>
    </Grid>
  );
};

interface ManualActionProps {
  eventId: string;
  eventTopic: string;
  refresh: any;
}

const ManualAction = (props: ManualActionProps) => {
  const { eventId, eventTopic, refresh } = props;

  const { permissions } = usePermissions();
  const hasPermissionsCreate = permissions?.includes('event.actions.execute');

  const [open, setOpen] = useState<boolean>(false);
  const [taskType, setTaskType] = useState<string | undefined>(undefined);

  const { data: taskActions, isLoading: taskActionsLoading } = useTaskActions(eventId, open);
  const taskActionsChoices = taskActions?.map((item) => ({
    id: item.actionType,
    name: item.actionType,
  }));
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTaskType(undefined);
  };
  const handleActionSelect = (e: any) => {
    const actionType = e?.target?.value;
    const selectedTaskType = taskActions?.find((item) => item?.actionType === actionType)?.taskType;
    setTaskType(selectedTaskType);
  };
  const translate = useTranslate();
  const t = (key: string): string => translate(`components.EventTask.${key}`);
  const classes = useStyles();

  const runManualAction = useRunManualAction();
  const handleSave = async (formData: any) => {
    await runManualAction.mutateAsync(
      {
        formData: formData,
        eventId,
      },
      {
        onSuccess: () => {
          // Refreshes event therefore passed as prop.
          refresh();
          handleClose();
        },
      }
    );
  };
  if (!eventId) return null;
  return (
    <>
      <Button label={t('manualAction')} onClick={handleOpen}>
        <ReplyIcon classes={{ root: classes.mirroredIcon }} />
      </Button>
      <Drawer heading={t('runManualAction')} open={open} onClose={handleClose}>
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton
                onSave={handleSave}
                disabled={!hasPermissionsCreate || taskActionsLoading || isEmpty(taskActions)}
                label={t('run')}
                icon={<ReplyIcon classes={{ root: classes.mirroredIcon }} />}
              />
            </Toolbar>
          }
        >
          <>
            {!taskActionsLoading && isEmpty(taskActions) && (
              <>
                <Alert severity='info'>{t('noAvailableActions')}</Alert>
                <br />
              </>
            )}
            <HiddenInput source='eventTopic' defaultValue={eventTopic} />
            <HiddenInput source='taskType' defaultValue={taskType} />
            {taskActionsLoading ? (
              <>
                <Skeleton height={24} />
                <br />
              </>
            ) : (
              <SelectInput
                source='actionType'
                label={t('action')}
                validate={required()}
                choices={taskActionsChoices}
                onChange={handleActionSelect}
                disabled={isEmpty(taskActions)}
              />
            )}
            <TextInput
              source='reason'
              label={t('reason')}
              multiline
              rows={5}
              validate={required()}
              disabled={isEmpty(taskActions)}
            />
          </>
        </SimpleForm>
      </Drawer>
    </>
  );
};
