import React, { FC, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { CreateButton } from '~/components';
import FullscreenDrawer from '~/components/FullscreenDrawer';
import { CreateForm } from '~/components/ra';
import { history } from '~/App';
import onError from '~/errorsHandler';

import PaymentGroupForm from './PaymentGroupForm';

interface CreateDrawerProps {
  open: boolean;
  onClose: () => void;
}

const useCreateTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.paymentServices/groups.create.${key}`);
};

const CreateDrawer: FC<CreateDrawerProps> = (props) => {
  const { open, onClose } = props;

  const handleError = (error: any) => {
    onError(error);
  };

  const handleClose = () => {
    onClose();
  };

  const t = useCreateTranslate();
  const classes = useStyles();

  return (
    <FullscreenDrawer
      open={open}
      onClose={handleClose}
      heading={t('title')}
      subtitle={t('subtitle')}
    >
      <CreateForm
        resource='paymentServices/groups'
        onSuccess={(res: any) => history.push(`/paymentServices/groups/${res.data.id}/show`)}
        onError={handleError}
        style={fullWidth}
        classes={classes}
      >
        <PaymentGroupForm />
      </CreateForm>
    </FullscreenDrawer>
  );
};

const PaymentGroupCreateAction = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { permissions } = usePermissions();
  if (!permissions?.includes('paymentService.create')) return null;
  return (
    <>
      <CreateButton onClick={handleOpen} />
      <CreateDrawer open={open} onClose={handleClose} />
    </>
  );
};

const fullWidth = { width: '100%' };

const useStyles = makeStyles((theme) => ({
  bottomToolbar: {
    marginLeft: theme.spacing(3),
  },
}));

export default PaymentGroupCreateAction;
