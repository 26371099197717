import React, { Fragment, useState, useEffect } from 'react';
import { useTranslate, ReferenceField, ReferenceManyField, Datagrid } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import { hasTranslation, time } from '~/utils';
import { RibbonIcon } from '~/img';
import InfoIcon from '@material-ui/icons/Info';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PeopleIcon from '@material-ui/icons/People';
import { Drawer, TextField, PhoneField, H3, StatusField, Pagination } from '~/components';
import {
  TextField as CustomRaTextField,
  PhoneField as CustomRaPhoneField,
  ShowController,
  DeleteButton,
} from '~/components/ra';
import ActivationCodeDrawer from './components/ActivationCodeDrawer';
import SetLocationDrawer from './components/SetLocationDrawer';
import DeactivateTerminal from './components/DeactivateTerminal';
import { Business, Terminal, Location, Employee } from '~/types';
import TerminalEdit from './TerminalEdit';
import { Longdash } from '~/utils';
import LocationShow from '~/resources/locations/LocationShow';
import EmployeeShow from '~/resources/employees/EmployeeShow';
import Integration from './components/Integration';
import ApiIdentityLink from './components/ApiIdentityLink';
import CopyButton from '~/components/CopyButton';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  topSpacing: {
    paddingTop: theme.spacing(4),
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
  integrationAccordion: {
    marginTop: 16,
    marginLeft: -32,
  },
  uuid: {
    color: theme.palette.grey[500],
  },
  // For copy button
  button: {
    padding: 0,
    color: theme.palette.grey[500],
    marginLeft: 10,
  },
  icon: {
    fontSize: '1rem',
  },
}));

interface Props {
  showData: Terminal | null;
  onClose: () => void;
  businessRecord: Business;
  parentProps: any;
  permissions: string[];
}

const TerminalShow = (props: Props) => {
  const { showData, onClose, businessRecord, parentProps, permissions } = props;

  const [terminal, setTerminal] = useState(showData);
  const terminalFromRedux = useSelector((state: any) =>
    lodashGet(state, `admin.resources.terminals.data[${showData?.id}]`)
  );
  useEffect(() => {
    setTerminal(terminalFromRedux);
  }, [terminalFromRedux]);

  const status = terminal?.status;

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.terminals.${key}`);

  const classes = useStyles();

  return (
    <Drawer heading={terminal?.label || Longdash} open={!!terminal} onClose={onClose}>
      {terminal?.id && (
        <div className={cx(classes.bottomSpacing, classes.uuid)}>
          {terminal?.id}
          <CopyButton value={terminal?.id} classes={classes} />
        </div>
      )}
      {terminal && (
        <Fragment>
          <div className={classes.bottomSpacing}>
            <TextField label={translateLabel('status._')} icon={<RibbonIcon />}>
              <StatusField status={terminal.status} />
            </TextField>
          </div>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <TextField label={translateLabel('type')}>
                {terminal?.terminalType &&
                hasTranslation(`resources.businesses.terminals.${terminal?.terminalType}`)
                  ? translateLabel(terminal?.terminalType)
                  : terminal?.terminalType}
              </TextField>
            </Grid>
            <Grid item>
              <ReferenceField
                {...parentProps}
                record={terminal}
                source='accountId'
                reference='businesses/accounts'
                link={false}
              >
                <CustomRaTextField label={translateLabel('account')} source='number' />
              </ReferenceField>
            </Grid>
            <Grid item>
              {terminal.locationId ? (
                <ReferenceField
                  {...parentProps}
                  record={terminal}
                  source='locationId'
                  label={translateLabel('location')}
                  reference='locations'
                  link={false}
                >
                  <CustomRaTextField
                    label={translateLabel('location')}
                    source='name'
                    showComponent={(props) => {
                      const { open, handleClose } = props;
                      const locationRecord: Location = props.record;
                      return (
                        <LocationShow
                          permissions={permissions}
                          showData={open ? locationRecord : null}
                          businessRecord={businessRecord}
                          onClose={handleClose}
                        />
                      );
                    }}
                  />
                </ReferenceField>
              ) : (
                <TextField label={translateLabel('location')} />
              )}
            </Grid>

            <Grid item>
              <TextField label={translateLabel('linkedPhone')}>
                <PhoneField number={terminal.linkedPhone} />
              </TextField>
            </Grid>
          </Grid>

          {terminal?.device && (
            <Fragment>
              <H3 icon={<SmartphoneIcon />}>{translateLabel('device._')}</H3>
              <Grid container direction='column' spacing={2} className={classes.container}>
                <Grid item>
                  <TextField label={translateLabel('device.applicationId')}>
                    {terminal.device.applicationId}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField label={translateLabel('device.installationId')}>
                    {terminal.device.applicationIdForVendor}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField label={translateLabel('device.deviceLabel')}>
                    {terminal.device.deviceLabel}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField label={translateLabel('device.osName')}>
                    {`${terminal.device.osName} ${terminal.device.osVersion}`}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField label={translateLabel('device.enrolledAt')}>
                    {time(terminal.device.enrolledAt).format('LL LT')}
                  </TextField>
                </Grid>
              </Grid>
            </Fragment>
          )}

          <H3 icon={<InfoIcon />}>{translateLabel('additionalData')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <TextField label={translateLabel('additionalProperties.NFCTerminal')}>
                {terminal.additionalProperties?.NFCTerminal}
              </TextField>
            </Grid>

            <Grid item>
              <ApiIdentityLink id={terminal?.id} />
            </Grid>
          </Grid>
          {terminal?.terminalType === 'MPOS' && (
            <>
              <H3 icon={<PeopleIcon />}>{translateLabel('linkedEmployees')}</H3>
              <Grid container direction='column' spacing={2} className={classes.container}>
                <Grid item>
                  <ReferenceManyField
                    {...parentProps}
                    record={terminal}
                    label='Employees'
                    reference='employees'
                    target='terminals'
                    // @ts-ignore
                    pagination={<Pagination />}
                    perPage={15}
                  >
                    <ShowController>
                      {
                        /* eslint-disable-line */ ({ showRecord, ...rest }) => {
                          if (rest.ids?.length) {
                            return (
                              <Datagrid {...rest}>
                                <CustomRaTextField
                                  label={translateLabel('name')}
                                  addLabel={false}
                                  source='name'
                                  fn={(record: any) => {
                                    return `${record.firstName || ''} ${record.lastName || ''}`;
                                  }}
                                  showComponent={(props) => {
                                    const { open, handleClose } = props;
                                    const employeeRecord: Employee = props.record;
                                    return (
                                      <EmployeeShow
                                        permissions={permissions}
                                        showData={open ? employeeRecord : null}
                                        businessRecord={businessRecord}
                                        onClose={handleClose}
                                        parentProps={parentProps}
                                      />
                                    );
                                  }}
                                />
                                <CustomRaPhoneField
                                  label={translateLabel('phone')}
                                  source='phoneNumber'
                                />
                              </Datagrid>
                            );
                          } else
                            return <Typography>{translateLabel('noLinkedEmployees')}</Typography>;
                        }
                      }
                    </ShowController>
                  </ReferenceManyField>
                </Grid>
              </Grid>
            </>
          )}
          {terminal?.terminalType === 'ECOMMERCE' && terminal?.id && (
            <Grid container direction='column' spacing={2} className={classes.container}>
              <Grid item className={classes.integrationAccordion}>
                <Integration terminalId={terminal?.id} businessId={businessRecord?.id} />
              </Grid>
            </Grid>
          )}

          <Grid container item spacing={1} justify='flex-end' className={classes.topSpacing}>
            <Grid item>
              <TerminalEdit
                permissions={permissions}
                terminalRecord={terminal}
                businessRecord={businessRecord}
              />
            </Grid>
            <SetLocationDrawer
              permissions={permissions}
              terminalRecord={terminal}
              businessId={businessRecord.id}
              solutionId={businessRecord.solutionId}
            />
            {status === 'ACTIVE' && terminal?.terminalType === 'MPOS' ? (
              <Grid item>
                <DeactivateTerminal
                  permissions={permissions}
                  terminalId={terminal.id}
                  businessId={businessRecord.id}
                />
              </Grid>
            ) : status === 'NOT_ACTIVATED' && terminal?.terminalType === 'MPOS' ? (
              <Grid item>
                <ActivationCodeDrawer
                  permissions={permissions}
                  terminalId={terminal.id}
                  phoneNumber={terminal.linkedPhone}
                  parentReacord={terminal}
                />
              </Grid>
            ) : null}
            {status === 'NOT_ACTIVATED' && (
              <Grid item>
                <DeleteButton
                  resource='terminals'
                  target='businesses'
                  targetId={businessRecord.id}
                  record={terminal}
                  disabled={!permissions.includes('business.terminal.delete')}
                  label={translateLabel('close')}
                  onClose={onClose}
                />
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </Drawer>
  );
};

export default TerminalShow;
