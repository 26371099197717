import React, { useEffect, useState, Fragment } from 'react';
import { useTranslate, ReferenceField, TextField as DefaultRaTextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell, AmountField } from '~/components';

import { PricingSchema, PricingFeeType, AcquiringFeeType } from '~/api/pricings';
import { Pricing, Product } from '~/types';
import { ByFeature } from './PerFeature';
import { l } from '../PricingShow';
import { useFeatures } from '~/hooks';
import { getDateFromDayNumber, Longdash } from '~/utils';
import { billingDateFormat } from './PerFeature/Forms/RegularFeeForm';
import PricingShowDrawer from './PricingShowDrawer';
import { getProductById } from '~/api/product';
import { useQuery } from 'react-query';

export type ByFee = {
  data?: { [x: string]: any };
} & ByFeature;

interface Props {
  pricingSchema: PricingSchema | undefined;
  record: Pricing | undefined;
  loading: boolean;
  tax?: boolean;
}

const PerFees = (props: Props) => {
  const { pricingSchema, record, loading, tax } = props;

  const { data: productData } = useQuery<Product>(
    [`productById-${record?.productId || ''}`],
    () => getProductById(record?.productId!).then((res) => res.data),
    {
      enabled: !!record?.productId,
    }
  );
  const currency = productData?.currency || '';

  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  const classes = useStyles();

  const { list: featuresList, loading: featuresLoading } = useFeatures();

  // format received data by fee
  const [byFee, setByFee] = useState<ByFee[]>([]);
  useEffect(() => {
    if (pricingSchema && record) {
      const properties = pricingSchema.properties;
      const result: ByFee[] = [];
      for (let propertyKey in properties) {
        const prop = properties[propertyKey];
        if (tax ? prop['v:category'] !== 'tax' : prop['v:category'] !== 'fee') continue;

        result.push({
          ...prop,
          propertyKey,
          data: record.data[propertyKey],
        });

        Object.keys(record.data)
          .filter((dataKey) => dataKey.startsWith(propertyKey) && dataKey !== propertyKey)
          .forEach((dataKey) => {
            result.push({
              ...prop,
              propertyKey: dataKey,
              data: record.data[dataKey],
            });
          });
      }

      result.sort((a, b) => {
        if (typeof a['v:index'] !== 'undefined' && typeof b['v:index'] === 'undefined') return -1;
        if (typeof a['v:index'] === 'undefined' && typeof b['v:index'] !== 'undefined') return 1;
        if (typeof a['v:index'] !== 'undefined' && typeof b['v:index'] !== 'undefined') {
          if (a['v:index'] < b['v:index']) return -1;
          if (a['v:index'] > b['v:index']) return 1;
        }

        if (typeof a.data?.index !== 'undefined' && typeof b.data?.index === 'undefined') return 1;
        if (typeof a.data?.index === 'undefined' && typeof b.data?.index !== 'undefined') return -1;
        if (typeof a.data?.index !== 'undefined' && typeof b.data?.index !== 'undefined') {
          if (a.data.index < b.data.index) return -1;
          if (a.data.index > b.data.index) return 1;
        }
        var x = a['v:name'].localeCompare(b['v:name']);
        if (x !== 0) return x;
        return 0;
      });

      setByFee(result);
    }
  }, [pricingSchema, record, tax]);

  const getConfigurationText = (byFee: ByFee) => {
    if (!byFee || !byFee.data) return Longdash;
    switch (byFee.type) {
      case 'veengu_fee_membership': {
        const { period, type, billingDateType, billingDate, amount, index } = byFee.data || {};
        return (
          <span className={classes.configurationText}>
            {(period || type) && <span>{`${t(period)} ${t(type)};`}</span>}
            <span>
              <AmountField amount={amount} />;
            </span>
            <span>{t(billingDateType)};</span>
            <span>
              {billingDate
                ? period === 'annually'
                  ? getDateFromDayNumber(billingDate).format(billingDateFormat)
                  : billingDate + 'th'
                : null}
            </span>
            {index && (
              <span>
                `; ${t('index')}: ${index}`
              </span>
            )}
          </span>
        );
      }
      case 'veengu_fee_default':
      case 'veengu_tax_default': {
        const getParticipantText = (participant: any = {}) => {
          switch (participant.type as PricingFeeType) {
            case 'fixedDebitFee':
            case 'fixedDebitFeeDecrease':
            case 'fixedCreditFee': {
              const { amount, index } = participant;
              return (
                <span className={classes.configurationText}>
                  <span>{t(participant.type)};</span>
                  <span>
                    <AmountField amount={amount} />;
                  </span>
                  {index && (
                    <span>
                      {t('index')}: {index}
                    </span>
                  )}
                </span>
              );
            }
            case 'percentDebitFee':
            case 'percentDebitFeeDecrease':
            case 'percentCreditFee': {
              const { percent, minAmount, maxAmount, index } = participant;
              return (
                <span className={classes.configurationText}>
                  <span>{t(participant.type)};</span>
                  <span>{percent}%;</span>
                  <span className={classes.configurationText}>
                    <span>min:</span>
                    <AmountField amount={minAmount} />;
                  </span>
                  <span className={classes.configurationText}>
                    <span>max:</span>
                    <AmountField amount={maxAmount} />;
                  </span>
                  {index && (
                    <span>
                      `; ${t('index')}: ${index}`
                    </span>
                  )}
                </span>
              );
            }
            default:
              return null;
          }
        };
        return getParticipantText(byFee.data || {});
      }
      case 'veengu_fee_acquiring': {
        const getParticipantText = (participant: any = {}) => {
          switch (participant.type as AcquiringFeeType) {
            case 'FIXED_INCREASE':
            case 'FIXED_DECREASE':
              const { amount } = participant;
              return (
                <span className={classes.configurationText}>
                  <span>{t(participant.type)};</span>
                  <span>
                    <AmountField amount={amount} />;
                  </span>
                </span>
              );
            case 'PERCENT_INCREASE':
            case 'PERCENT_DECREASE':
              const { percent, minAmount, maxAmount } = participant;
              return (
                <span className={classes.configurationText}>
                  <span>{t(participant.type)};</span>
                  <span>{percent}%;</span>
                  <span className={classes.configurationText}>
                    <span>min:</span>
                    <AmountField amount={minAmount} />;
                  </span>
                  <span className={classes.configurationText}>
                    <span>max:</span>
                    <AmountField amount={maxAmount} />;
                  </span>
                </span>
              );
            default:
              return null;
          }
        };
        return getParticipantText(byFee.data || {});
      }
      default:
        return null;
    }
  };

  // Main state
  const [state, setState] = useState<any>(record?.data || {});

  const [showPropertyKey, setShowPropertyKey] = useState<string | null>(null);
  const handleShowOpen = (propertyKey: string) => setShowPropertyKey(propertyKey);
  const handleShowClose = () => setShowPropertyKey(null);

  return (
    <Fragment>
      {record && showPropertyKey && (
        <PricingShowDrawer
          pricingDataEditProps={{
            pricingRecord: record,
            record: state,
            schema: {} as any, // PricingShowDrawer add this prop by itself
            setMainState: setState,
            currency: currency,
          }}
          propertyKey={showPropertyKey}
          open={!!showPropertyKey}
          record={byFee}
          onClose={handleShowClose}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('feature')}</TableCell>
            <TableCell>{t('fields.name')}</TableCell>
            <TableCell>{t('tags')}</TableCell>
            <TableCell>{t('statementTitle')}</TableCell>
            <TableCell>{t('pnlAccount')}</TableCell>
            <TableCell>{t('configuration')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {byFee.map((item, index) => {
            return (
              <TableRow
                key={index}
                hover
                onClick={() => handleShowOpen(item.propertyKey)}
                className={classes.pointer}
              >
                <TableCell loading={featuresLoading}>{featuresList[item['v:feature']]}</TableCell>
                <TableCell>{item['v:name']}</TableCell>
                <TableCell>
                  {item.data?.tags &&
                    item.data.tags.map((tag: string) => (
                      <div key={tag}>
                        <Chip label={tag} size='small' style={{ margin: 1, height: 18 }} />
                      </div>
                    ))}
                </TableCell>
                <TableCell>{item.data?.name}</TableCell>
                <TableCell>
                  <ReferenceField
                    source='pnlAccountId'
                    record={item.data}
                    reference='tenant/accounts'
                    basePath='/tenant/accounts'
                    link={false}
                    addLabel={false}
                  >
                    <DefaultRaTextField source='alias' addLabel={false} />
                  </ReferenceField>
                </TableCell>
                <TableCell>{getConfigurationText(item)}</TableCell>
              </TableRow>
            );
          })}
          {loading &&
            [{}, {}, {}].map((_, index) => {
              return (
                <TableRow key={index}>
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  configurationText: {
    display: 'inline',
    '& > b': {
      fontWeight: 500,
    },
    '& > *': {
      marginRight: 4,
      display: 'inline-flex',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export default PerFees;
