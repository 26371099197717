import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '~/components/Drawer';
import Button, { ButtonProps } from '../ra/Button';

export type ConfirmationSettings<Record> = {
  variant: 'modal' | 'drawer';
  modal?: {
    heading?: any;
    content?: any;
  };
  drawer?: {
    heading?: any;
    content?: (prevRecord: Partial<Record>, newRecord: Record) => any;
  };
};

export interface ConfirmationProps<Record> {
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void | Promise<any>;
  confirmationSettings?: ConfirmationSettings<Record>;
  prevRecord?: Partial<Record>;
  newRecord?: Record;
  confirmButtonProps?: ButtonProps;
}

function Confirmation<Record>(props: ConfirmationProps<Record>) {
  const {
    open,
    handleClose,
    onConfirm,
    confirmationSettings,
    prevRecord,
    newRecord,
    confirmButtonProps = {},
  } = props;
  const { variant = 'modal', modal = {}, drawer = {} } = confirmationSettings || {};
  const translate = useTranslate();
  const t = (key: string): string => translate(`utils.confirmation.${key}`);
  const classes = useStyles();

  const handleConfirm = async () => {
    try {
      await onConfirm();
      handleClose();
    } catch (error) {
      //
    }
  };

  return (
    <Fragment>
      {variant === 'modal' && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='demo-alert-dialog-title'
          aria-describedby='demo-alert-dialog-description'
        >
          <DialogTitle id='demo-alert-dialog-title'>
            {modal.heading ? modal.heading : t('warning')}
          </DialogTitle>
          <DialogContent style={{ maxWidth: 400 }}>
            <DialogContentText id='demo-alert-dialog-description'>
              {modal.content ? modal.content : t('content')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button label='ra.action.cancel' onClick={handleClose} />
            <Button
              label={t('confirm')}
              onClick={handleConfirm}
              variant='contained'
              {...confirmButtonProps}
            >
              <CheckCircleIcon />
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {variant === 'drawer' && prevRecord && newRecord && (
        <Drawer
          heading={drawer.heading ? drawer.heading : t('warning')}
          open={open}
          onClose={handleClose}
        >
          <Fragment>
            {drawer.content ? drawer.content(prevRecord, newRecord) : null}
            <Grid container spacing={2} className={classes.buttonsContainer}>
              <Grid item>
                <Button
                  label={t('confirm')}
                  onClick={handleConfirm}
                  size='medium'
                  variant='contained'
                  {...confirmButtonProps}
                >
                  <CheckCircleIcon />
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        </Drawer>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default Confirmation;
