import React, { FC, useState } from 'react';
import { useTranslate, Button } from 'react-admin';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Grid, Accordion, SvgIcon } from '@material-ui/core';
import BusinessesIcon from '@material-ui/icons/Store';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, Skeleton } from '@material-ui/lab';
import { JSONSchemaType } from 'ajv';

import { time } from '~/utils';
import ajv from '~/utils/ajv';
import { useConfiguration, useDeepEffect, useNotify } from '~/hooks';
import { Business, Individual } from '~/types';
import { Beneficiary } from '~/types/beneficiaries';
import {
  AddressTable,
  CountryField,
  AccordionDetails,
  AccordionSummary,
  DeleteButton,
  H2,
  StatusField,
  TextField,
} from '~/components';
import { PersonIcon, ShieldCheckmarkIcon } from '~/img';
import BeneficiaryAccountList from './BeneficiaryAccountList';
import BeneficiaryViewContent from './BeneficiaryViewContent';
import {
  useBeneficiariesQuery,
  useBeneficiariesDelete,
  useBeneficiaryAccountsQuery,
} from '~/hooks/beneficiaries';

interface Props {
  record?: Business | Individual;
  resource?: 'businesses' | 'individuals';
  permissions: any;
}

export const l = (key: string): string => `resources.beneficiaries.${key}`;

interface BeneficiarySchema {
  schema?: JSONSchemaType<Record<string, any>>;
  error?: boolean;
}

interface ViewProps {
  record?: Business | Individual;
  resource?: string;
  item: Beneficiary;
  isExpanded: boolean;
  handleChange: (key: string, isExpanded: boolean) => any;
  isLoading: boolean;
  beneficiarySchema?: BeneficiarySchema;
}

const BeneficiaryView: FC<ViewProps> = ({
  record,
  resource,
  item,
  isExpanded,
  handleChange,
  isLoading,
  beneficiarySchema,
}) => {
  const key = item.id;
  // const isExpanded = Boolean(expanded[key]);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { type, Number, number } = item.document || {};
  const docNum = Number || number;

  const { data } = useBeneficiaryAccountsQuery(item.id);
  const hasPendingAccounts = data?.find((account) => account.status === 'PENDING') && true;
  const hadPrimaryAccount = data?.find((account) => account.primary) && true;

  const hasDeleteAction = !hasPendingAccounts && !hadPrimaryAccount;

  const [open, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const notify = useNotify();

  const { mutate: mutateDelete } = useBeneficiariesDelete({
    onSuccess: () => {
      notify({ message: t('deleteSuccess'), type: 'success' });
    },
    onError: () => {
      notify({ message: t('deleteError'), type: 'error' });
    },
  });

  const onDeleteClick = () => {
    closeDialog();
    mutateDelete({
      profileId: record!.id!,
      id: item.id,
    });
  };

  return (
    <Grid key={key} item xs={12}>
      <Accordion expanded={isExpanded} onChange={handleChange(key, isExpanded)}>
        <AccordionSummary
          aria-controls={`${key}-content`}
          id={`${key}-header`}
          expanded={isExpanded}
          heading={<Header data={item} />}
          content={
            <>
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <TextField label={t('country')} loading={isLoading}>
                    <CountryField>{item.country}</CountryField>
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('createdAt')} loading={isLoading}>
                    {time(item.createdAt).format('L')}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('status')} loading={isLoading}>
                    <StatusField status={item.deleted ? 'DELETED' : item.status} />
                  </TextField>
                </Grid>
              </Grid>
              <br />
              <Grid container item xs={12}>
                {type && (
                  <Grid item xs={4}>
                    <TextField label={t('primaryDocument')} loading={isLoading}>
                      {type && `${type} ${docNum ? `#${docNum}` : ''}`}
                    </TextField>
                  </Grid>
                )}
                {item.phoneNumber && (
                  <Grid item xs={4}>
                    <TextField label={t('phoneNumber')} loading={isLoading}>
                      {item.phoneNumber}
                    </TextField>
                  </Grid>
                )}
                {item.email && (
                  <Grid item xs={4}>
                    <TextField label={t('email')} loading={isLoading}>
                      {item.email}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </>
          }
        />
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={2}>
              {item.address?.postalCode && (
                <Grid item xs={12}>
                  <AddressTable address={item.address} />
                </Grid>
              )}
            </Grid>
            {beneficiarySchema?.schema ? (
              <BeneficiaryViewContent
                solutionId={record?.solutionId!}
                country={record?.country!}
                documentFamily={resource === 'individuals' ? 'INDIVIDUAL' : 'BUSINESS'}
                data={item}
                schema={beneficiarySchema?.schema}
                withMandatoryFields={false}
              />
            ) : beneficiarySchema?.error ? (
              <Alert severity='warning' style={{ marginBottom: '1em' }}>
                {t('badSchema')}
              </Alert>
            ) : (
              <Skeleton variant='text' width={200} height={24} />
            )}
            {/*
            <Grid container item xs={12} justify="flex-end" spacing={2}>
              <Grid item>
                <BeneficiariesEdit
                  beneficiaryRecord={item}
                  permissions={permissions}
                  refetch={refetch}
                />
              </Grid>
            </Grid>
            */}
            {hasDeleteAction && (
              <Grid item xs={12} alignItems='center'>
                <Box display='flex' justifyContent='right'>
                  <DeleteButton onClick={openDialog} />
                </Box>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
        <BeneficiaryAccountList beneficiary={item} />
        <Dialog open={open} onClose={closeDialog} aria-labelledby='form-dialog-title'>
          <DialogTitle>{t('delete')}</DialogTitle>
          <DialogContent>
            <Alert severity='warning'>{t('deleteWarning')}</Alert>
          </DialogContent>
          <DialogActions>
            <Button label='ra.action.cancel' onClick={closeDialog} />
            <Button label={t('delete')} variant='contained' onClick={onDeleteClick}>
              <SvgIcon>
                <ShieldCheckmarkIcon />
              </SvgIcon>
            </Button>
          </DialogActions>
        </Dialog>
      </Accordion>
    </Grid>
  );
};

const BeneficiariesList: FC<Props> = (props) => {
  const { record, resource } = props;

  const { data, isLoading } = useBeneficiariesQuery(record?.id as string, resource!);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [beneficiarySchema, setBeneficiarySchema] = useState<BeneficiarySchema>();
  const { data: configuration } = useConfiguration(record?.solutionId);
  useDeepEffect(() => {
    if (configuration) {
      const beneficiarySchemaRaw: string | undefined =
        resource === 'individuals'
          ? configuration?.veengu_individual_beneficiary_management?.beneficiarySchema
          : configuration?.veengu_business_beneficiary_management?.beneficiarySchema;
      if (beneficiarySchemaRaw) {
        try {
          const anySchema = JSON.parse(beneficiarySchemaRaw) as any;
          if (anySchema.type !== 'object') {
            throw new Error('Json schema type !== object');
          }
          const schema = anySchema as JSONSchemaType<Record<string, any>>;
          ajv.compile(schema);
          console.log(`using beneficiary schema: `, schema);
          setBeneficiarySchema({ schema });
        } catch (e) {
          console.error(`failed to parse beneficiary schema`, e);
          setBeneficiarySchema({ error: true });
        }
      } else {
        setBeneficiarySchema({ error: true });
      }
    }
  }, [configuration]);

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  return (
    <Grid container spacing={2}>
      <Grid container item justify='space-between'>
        <Grid>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t(`title`)}
          </H2>
        </Grid>
        <Grid item>
          {/*<BeneficiariesCreate permissions={permissions} refetch={refetch} record={record!} />*/}
        </Grid>
      </Grid>
      <Grid item container>
        {(data?.length || 0) === 0 ? (
          <div>{t('notFound')}</div>
        ) : (
          data?.map((item) => {
            return (
              <BeneficiaryView
                key={item.id}
                record={record}
                resource={resource}
                item={item}
                handleChange={handleChange}
                isLoading={isLoading}
                isExpanded={expanded[item.id]}
                beneficiarySchema={beneficiarySchema}
              />
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

interface HeaderProps {
  data: Beneficiary;
}

const Header: FC<HeaderProps> = (props) => {
  const { data } = props;
  const { name, type, document } = data;
  const legalType = document?.legalType;
  const getIcon = () => {
    switch (type) {
      case 'INDIVIDUAL':
        return (
          <SvgIcon>
            <PersonIcon />
          </SvgIcon>
        );
      case 'BUSINESS':
        return <BusinessesIcon />;
      default:
        return null;
    }
  };
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item className={classes.headerText}>{`${name} ${legalType || ''}`}</Grid>
      <Grid item className={classes.headerIcon}>
        {getIcon()}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  headerText: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    color: theme.palette.grey['500'],
    display: 'flex',
    alignItems: 'center',
  },
  topSpacing: {
    marginTop: theme.spacing(3),
  },
}));

export default BeneficiariesList;
