import * as React from 'react';
import { usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';

import { MaterialTable } from '~/components';

import * as hb from '~/types/Handbook';
import { validateId, alignCenter } from './utils';

import BusinessDocumentDetails from './BusinessDocumentDetails';

const defaultParameters: hb.DocumentParameter[] = [
  {
    name: 'number',
    mandatory: true,
    type: 'text',
    mask: '',
  },
  {
    name: 'dateOfExpiry',
    mandatory: false,
    type: 'date',
    mask: '',
  },
  {
    name: 'dateOfIssue',
    mandatory: false,
    type: 'date',
    mask: '',
  },
];

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 8,
  },
}));

export interface Props {
  legalType: hb.BusinessLegalType;
  setLegalType: hb.SetLegalType;
}

const BusinessDocumentTable = ({ legalType, setLegalType }: Props) => {
  const documents: hb.BusinessDocument[] = (legalType.documents || []).slice();

  const isValid = (
    newDoc: hb.BusinessDocument,
    oldDoc: hb.BusinessDocument | undefined = undefined
  ) => !validateId(documents, newDoc.type, (oldDoc || {}).type, true);

  const setDocument: hb.SetBusinessDocument = (document: hb.BusinessDocument) => {
    const index = documents.findIndex((d) => d.type === document.type);
    if (index >= 0) {
      documents[index] = document;
      return setLegalType({ ...legalType, documents });
    } else {
      return Promise.reject();
    }
  };

  const hasPrimary = legalType?.documents?.find((item) => item.primary);

  const classes = useStyles();

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Type',
          field: 'type',
          required: true,
          validate: validateId,
          cellStyle: {
            minWidth: 180,
          },
        },
        {
          type: 'string',
          title: 'Pages',
          field: 'pageNames',
          label: 'Page names',
          cellStyle: {
            minWidth: 300,
          },
          required: true,
          enum: {},
          render: (rowData: hb.BusinessDocument) => {
            return (rowData.pageNames || []).join(', ');
          },
        },
        {
          title: 'Primary',
          field: 'primary',
          type: 'boolean',
          width: 100,
          unique: true,
          ...alignCenter,
        },
        {
          title: 'Mandatory',
          field: 'mandatory',
          type: 'boolean',
          width: 100,
          ...alignCenter,
        },
      ]}
      components={{
        Container: (props: any) => (
          <Paper variant='outlined' style={{ borderRightWidth: 0 }}>
            {props.children}
          </Paper>
        ),
        Toolbar: (props: any) => (
          <div className={classes.toolbar}>
            <div>
              {!hasPrimary && legalType?.documents?.length ? (
                <Alert severity='error'>
                  One of this following documents has to be marked as primary
                </Alert>
              ) : null}
            </div>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      data={documents}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newDoc: hb.BusinessDocument) => {
            if (isValid(newDoc)) {
              const newDocWithDefaultParameters = { ...newDoc, parameters: defaultParameters };
              documents.push(newDocWithDefaultParameters);
              return setLegalType({ ...legalType, documents });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newDoc: hb.BusinessDocument, oldDoc: hb.BusinessDocument) => {
            if (isValid(newDoc, oldDoc)) {
              const index = documents.indexOf(oldDoc);
              documents[index] = newDoc;
              return setLegalType({ ...legalType, documents });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldDoc: hb.BusinessDocument) => {
            const index = documents.indexOf(oldDoc);
            documents.splice(index, 1);
            return setLegalType({ ...legalType, documents });
          },
        }
      }
      detailPanel={(rowData: hb.BusinessDocument) => {
        return <BusinessDocumentDetails document={rowData} setDocument={setDocument} />;
      }}
      onRowClick={(event: any, rowData: hb.Country, togglePanel: () => void) => togglePanel()}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        search: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default BusinessDocumentTable;
