import React, { Fragment, useState } from 'react';
import {
  FunctionField,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  useTranslate,
  ArrayField,
  ChipField,
  SingleFieldList,
  usePermissions,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useShowController } from 'ra-core';
import SolutionIcon from '@material-ui/icons/Star';
import Chip from '@material-ui/core/Chip';

import { Show } from '~/layout';
import {
  NoPermissions,
  ShowController,
  DateField,
  StatusField,
  LocalSort,
  KycLevelField,
} from '~/components/ra';
import { UpdateContext, H2, PlusField } from '~/components';
import HandbookField from './handbook/HandbookField';
import { Longdash } from '~/utils';
import { PricingShow, PricingCreate } from './components/Pricing';
import { productLabel, ProductCreate, ProductShow } from './components/Product';
import { FeaturesConfig, SummaryTab } from './components';
import { OpenIcon } from '~/img';
import { useHandbook } from '~/hooks';
import FXMappingShow from '../currencies/components/FXMappingShow';
import FXMappingCreate from '../currencies/components/FXMappingCreate';
import { SelectTransactionType } from '~/components/TransactionsHistory/Filters';
import { useProductTemplates } from '~/hooks/useProductTemplates';
import { useAccountNumberingList } from '~/hooks/useAccountNumberingList';
import { Link } from '~/components';
import { isEmpty } from 'lodash';
import NotificationsField from './components/Notifications/NotificationsField';
import RaPagination from '~/components/Pagination/RaPagination';

export const PricingDatagrid = (props: any) => {
  const { rowClick, showLinkToPricing, ...rest } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.pricings.${key}`);

  const classes = useStyles();

  return (
    <Datagrid rowClick={rowClick} {...rest} selectedIds={null}>
      <ShowController label={t('pricingPlan')}>
        {({ showRecord }) => {
          if (!showRecord) return Longdash;
          return (
            <div>
              {showRecord.name}
              {showRecord.format !== 'v2' && (
                <span className={classes.chipContainer}>
                  <Chip variant='outlined' size='small' label={t('v1')} className={classes.chip} />
                </span>
              )}
            </div>
          );
        }}
      </ShowController>
      {showLinkToPricing && (
        <ShowController>
          {({ showRecord }) => {
            if (!showRecord) return Longdash;
            return (
              <Link
                href={`/pricings/${showRecord.id}/show`}
                target='_blank'
                onClick={(e: any) => e.stopPropagation()}
                className={classes.link}
              >
                {t('viewEdit')}
                <SvgIcon className={classes.icon}>
                  <OpenIcon title='show' />
                </SvgIcon>
              </Link>
            );
          }}
        </ShowController>
      )}
      <ReferenceField
        source='solutionId'
        label={t('profileSolution')}
        reference='solutions'
        link={false}
      >
        <TextField source='name' allowEmpty emptyText={Longdash} />
      </ReferenceField>
      <ShowController label={t('paymentInstrument')}>
        {({ showRecord }) => {
          if (!showRecord) return Longdash;
          if (showRecord.productId) {
            return (
              <ReferenceField source='productId' reference='products' link={false}>
                <TextField source='name' allowEmpty emptyText={Longdash} />
              </ReferenceField>
            );
          } else if (showRecord.externalSourceType) {
            return <TextField source='externalSourceType' emptyText={Longdash} />;
          } else return Longdash;
        }}
      </ShowController>
      <DateField source='activeFrom' label={t('activeFrom')} emptyText={Longdash} format='LL' />
      <DateField source='activeTill' label={t('activeTill')} emptyText={Longdash} format='LL' />
      <StatusField
        source='accountStatus'
        label={t('profileStatus')}
        allowEmpty
        emptyText={Longdash}
      />
      <KycLevelField source='kycLevel' label={t('kycLevel')} sortable={false} />
      <ShowController label={t('profileTags')} sortable={false}>
        {({ showRecord }) => {
          if (isEmpty(showRecord?.profileTags)) return Longdash;
          return (
            <PlusField
              items={showRecord?.profileTags}
              mainComponent={(tag) => (
                <ChipField record={{ name: tag }} source='name' {...rest} key={tag} />
              )}
              tooltipsComponent={(tag) => (
                <ChipField record={{ name: tag }} source='name' {...rest} key={tag} />
              )}
            />
          );
        }}
      </ShowController>
    </Datagrid>
  );
};

interface PricingFieldProps {
  record?: any;
  permissions?: any;
  [x: string]: any;
}

const PricingField = (props: PricingFieldProps) => {
  const { record, permissions, ...rest } = props;

  const [showData, setShowData] = useState(null);
  const handleOpen = (data: any) => {
    permissions.includes('solution.pricing.view') && setShowData(data);
  };
  const handleClose = () => setShowData(null);

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.pricings.${key}`);
  return (
    <Fragment>
      <PricingShow
        record={showData}
        permissions={permissions}
        solutionRecord={record}
        onClose={handleClose}
        {...rest}
      />
      <Grid container item justify='space-between'>
        <Grid item>
          <H2 bottomSpacing={2} topSpacing={0}>
            {t('pricingPlans')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          <PricingCreate permissions={permissions} solutionRecord={record} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Configurations'
        reference='pricings'
        target='solutions'
        sort={{ field: 'name', order: 'ASC' }}
        pagination={
          <RaPagination
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        {...props}
      >
        <LocalSort>
          <PricingDatagrid
            rowClick={(_id: any, _basePath: any, record: any) => handleOpen(record)}
            showLinkToPricing
          />
        </LocalSort>
      </ReferenceManyField>
    </Fragment>
  );
};

interface ProductFieldProps {
  permissions: any;
  record?: any;
}

const ProductField = (props: ProductFieldProps) => {
  const { permissions, record: recordProp } = props;
  const classes = useStyles();

  const [showData, setShowData] = useState(null);
  const handleShowOpen = (data: any) => {
    permissions.includes('solution.product.view') && setShowData(data);
  };
  const handleShowClose = () => setShowData(null);

  const { data: productTemplates } = useProductTemplates();

  const { data: accountNumberingOptions } = useAccountNumberingList();

  const l = productLabel;
  if (!permissions) return null;
  if (!permissions.includes('solution.product.list')) return <NoPermissions />;
  return (
    <Fragment>
      <ProductShow
        productRecord={showData}
        solutionRecord={recordProp}
        onClose={handleShowClose}
        permissions={permissions}
      />
      <Grid container justify='flex-end' className={classes.spacings}>
        <Grid item>
          <ProductCreate permissions={permissions} solutionRecord={recordProp} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Configurations'
        reference='products'
        target='solutions'
        sort={{ field: 'name', order: 'ASC' }}
        pagination={
          <RaPagination
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        {...props}
      >
        <LocalSort>
          <Datagrid
            rowClick={(_id: any, _basePath: any, record: any) => handleShowOpen(record)}
            classes={{ row: classes.row }}
          >
            <TextField source='name' label={l('name')} />
            <FunctionField
              label={l('template')}
              render={(record: any) => {
                return (
                  productTemplates?.find((pt) => pt.code === record.templateCode)?.alias ||
                  record.templateCode
                );
              }}
            />
            <ReferenceField
              source='bankAccountId'
              reference='bank/accounts'
              basePath='/bank/accounts'
              link={false}
              addLabel={false}
            >
              <TextField source='alias' label={l('bankAccount')} />
            </ReferenceField>
            <TextField source='currency' label={l('currency')} />
            <FunctionField
              label={l('accountNumbering')}
              render={(record: any) => {
                return (
                  accountNumberingOptions?.find((an) => an.id === record.accountNumbering)?.name ||
                  record.accountNumbering
                );
              }}
            />
          </Datagrid>
        </LocalSort>
      </ReferenceManyField>
    </Fragment>
  );
};

interface FXMappingFieldProps {
  record?: any;
  permissions?: any;
  [x: string]: any;
}

const FXMappingField = (props: FXMappingFieldProps) => {
  const { record, permissions, ...rest } = props;
  const classes = useStyles();

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.fxmappings.${key}`);

  const [showData, setShowData] = useState(null);
  const handleOpen = (data: any) => {
    (permissions.includes('solution.fxmapping.view') || true) && setShowData(data);
  };
  const handleClose = () => setShowData(null);

  return (
    <Fragment>
      <FXMappingShow
        record={showData}
        permissions={permissions}
        solutionRecord={record}
        onClose={handleClose}
        {...rest}
      />
      <Grid container justify='flex-end' className={classes.spacings}>
        <Grid item>
          <FXMappingCreate permissions={permissions} solutionRecord={record} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Configurations'
        reference='fxrates/mappings'
        target='solutions'
        sort={{ field: 'id', order: 'ASC' }}
        pagination={
          <RaPagination
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        {...props}
      >
        <LocalSort>
          <Datagrid rowClick={(_id: any, _basePath: any, record: any) => handleOpen(record)}>
            <ReferenceField source='productId' reference='products' link={false} addLabel={false}>
              <TextField source='name' />
            </ReferenceField>
            <ArrayField source='operationTypes' label={t('operationTypes')} sortable={false}>
              <SingleFieldList linkType={false}>
                <ShowController>
                  {({ showRecord, ...rest }) => {
                    if (!showRecord) return Longdash;
                    return (
                      <ChipField
                        record={{ name: translate(SelectTransactionType.l(showRecord)) }}
                        source='name'
                        {...rest}
                      />
                    );
                  }}
                </ShowController>
              </SingleFieldList>
            </ArrayField>
            {/*<ShowController>
              {({ showRecord: mappingRecord, ...restMapping }) => {
                return (
                  <ArrayField
                    source="kycLevels"
                    record={mappingRecord}
                    sortable={false}
                    {...restMapping}
                  >
                    <SingleFieldList linkType={false}>
                      <ShowController>
                        {({ showRecord }) => {
                          if (!showRecord) return Longdash;
                          return (
                            <KycLevelField
                              record={{ name: showRecord, solutionId: mappingRecord.solutionId }}
                              source="name"
                            />
                          );
                        }}
                      </ShowController>
                    </SingleFieldList>
                  </ArrayField>
                );
              }}
            </ShowController>*/}
            <ArrayField source='tags' label={t('tags')} sortable={false}>
              <SingleFieldList linkType={false}>
                <ShowController>
                  {({ showRecord, ...rest }) => {
                    if (!showRecord) return Longdash;
                    return <ChipField record={{ name: showRecord }} source='name' {...rest} />;
                  }}
                </ShowController>
              </SingleFieldList>
            </ArrayField>
            <ReferenceField
              source='ratesId'
              reference='fxrates'
              link={false}
              addLabel={false}
              sortable={false}
            >
              <TextField source='name' />
            </ReferenceField>
          </Datagrid>
        </LocalSort>
      </ReferenceManyField>
    </Fragment>
  );
};

const SolutionShow = (props: any) => {
  const { permissions } = usePermissions();
  const showController = useShowController(props);
  const showRecord: any = showController?.record;

  useHandbook(showRecord?.id);

  if (!permissions) return null;
  if (!permissions?.includes('solution.view')) return <NoPermissions />;

  return (
    <Fragment>
      {showRecord && <UpdateContext data={{ solutionId: showRecord.id }} />}
      <Show
        icon={
          <Avatar>
            <SolutionIcon />
          </Avatar>
        }
        {...props}
      >
        <TabbedShowLayout style={{ marginBottom: 32 }}>
          <Tab label='resources.solutions.tabs.summary'>
            <SummaryTab permissions={permissions} resource={'solution'} record={showRecord} />
          </Tab>
          {permissions?.includes('solution.product.list') &&
            permissions?.includes('solution.product.view') && (
              <Tab label='resources.solutions.tabs.products' path='products'>
                <ProductField permissions={permissions} />
              </Tab>
            )}
          {permissions?.includes('solution.pricing.list') && (
            <Tab label='resources.solutions.tabs.pricingTables' path='pricingTables'>
              <PricingField permissions={permissions} />
            </Tab>
          )}
          {permissions?.includes('solution.settings.view') && (
            <Tab label='resources.solutions.tabs.featuresConfig' path='featuresConfig'>
              <FeaturesConfig />
            </Tab>
          )}
          {permissions?.includes('fxrates.mapping.list') && (
            <Tab label='resources.solutions.tabs.fxPlans' path='fxPlans'>
              <FXMappingField permissions={permissions} />
            </Tab>
          )}
          {permissions?.includes('solution.handbook.view') && (
            <Tab label='resources.solutions.tabs.handbooks' path='handbooks'>
              <HandbookField solutionId={props.id} />
            </Tab>
          )}
          {permissions?.includes('solution.settings.notification.view') && (
            <Tab label='resources.solutions.tabs.notifications' path='notifications'>
              <NotificationsField
                permissions={permissions}
                solutionId={props.id}
                features={showRecord?.features}
              />
            </Tab>
          )}
        </TabbedShowLayout>
      </Show>
    </Fragment>
  );
};

const perPage = 10;

const useStyles = makeStyles((theme) => ({
  spacings: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    position: 'relative',
    textDecoration: 'underline',
    textWrap: 'nowrap',
  },
  icon: {
    position: 'absolute',
    top: '-5px',
    right: '-19px',
    width: 14,
    height: 14,
  },
  edit: {
    margin: -12,
    marginRight: -4,
    padding: theme.spacing(1) / 2,
  },
  row: {
    '& #edit-button': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #edit-button': {
        opacity: 1,
      },
    },
  },
  chipContainer: {
    marginLeft: 5,
  },
  chip: {
    borderColor: theme.palette.warning.light,
    color: theme.palette.warning.light,
    borderRadius: 4,
    height: '18px',
    '& .MuiChip-label': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
}));

export default SolutionShow;
