import * as React from 'react';
import { usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { MaterialTable } from '~/components';

import * as hb from '~/types/Handbook';
import { validateId } from './utils';
import BusinessLegalTypeDetails from './BusinessLegalTypeDetails';

export interface Props {
  country: hb.Country;
  setCountry: hb.SetCountry;
}

const BusinessLegalTypeTable = ({ country, setCountry }: Props) => {
  const legalTypes: hb.BusinessLegalType[] = (country.businessLegalTypes || []).slice();

  const isValid = (
    newType: hb.BusinessLegalType,
    oldType: hb.BusinessLegalType | undefined = undefined
  ) => !validateId(legalTypes, newType.name, (oldType || {}).name, true);

  const setLegalType: hb.SetLegalType = (legalType: hb.BusinessLegalType) => {
    const index = legalTypes.findIndex((lt) => lt.name === legalType.name);
    if (index >= 0) {
      legalTypes[index] = legalType;
      return setCountry({ ...country, businessLegalTypes: legalTypes });
    } else {
      return Promise.reject();
    }
  };

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Name',
          field: 'name',
          required: true,
          validate: validateId,
          cellStyle: {
            minWidth: 350,
          },
        },
      ]}
      components={{
        Container: (props: any) => (
          <Paper variant='outlined' style={{ borderRightWidth: 0 }}>
            {props.children}
          </Paper>
        ),
      }}
      data={legalTypes}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newType: hb.BusinessLegalType) => {
            if (isValid(newType)) {
              legalTypes.push(newType);
              return setCountry({ ...country, businessLegalTypes: legalTypes });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newType: hb.BusinessLegalType, oldType: hb.BusinessLegalType) => {
            if (isValid(newType, oldType)) {
              const index = legalTypes.indexOf(oldType);
              legalTypes[index] = newType;
              return setCountry({ ...country, businessLegalTypes: legalTypes });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldType: hb.BusinessLegalType) => {
            const index = legalTypes.indexOf(oldType);
            legalTypes.splice(index, 1);
            return setCountry({ ...country, businessLegalTypes: legalTypes });
          },
        }
      }
      detailPanel={(rowData: hb.BusinessLegalType) => {
        return <BusinessLegalTypeDetails legalType={rowData} setLegalType={setLegalType} />;
      }}
      onRowClick={(event: any, rowData: hb.Country, togglePanel: () => void) => togglePanel()}
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        search: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default BusinessLegalTypeTable;
