import React, { useState, Fragment } from 'react';
import { useTranslate, TextInput, required } from 'react-admin';

import { Drawer, CreateButton } from '~/components';
import { CreateForm, HiddenInput } from '~/components/ra';

export const l = (key: string): string => `resources.solutions.fields.${key}`;

interface Props {
  resource: string;
  permissions: string[] | undefined;
}

const SolutionCreate = (props: Props) => {
  const { resource, permissions } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton
        variant='add'
        onClick={handleOpen}
        disabled={!permissions?.includes('solution.create')}
      />
      <Drawer heading={t('addSolution')} open={open} onClose={handleClose}>
        <CreateForm resource={resource} closeParent={handleClose}>
          <TextInput source='name' label={t('name')} validate={required()} fullWidth />
          <HiddenInput source='productTemplates' defaultValue={[]} />
          <HiddenInput source='features' defaultValue={[]} />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default SolutionCreate;
