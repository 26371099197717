import axios from '~/utils/axios';

export const exportCsv = (filter: any) => {
  return axios.get<any>(`/localization/exportCsv`, { params: filter, responseType: 'arraybuffer' });
};

export const importCsv = (file: File) => {
  const formData = new FormData();
  formData.append('data', file);

  return axios({
    method: 'post',
    url: '/localization/importCsv',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getLocalization = (keyPrefix: string) => {
  return axios
    .get<{ [key: string]: string }>(`/localization/static/${keyPrefix}/all`)
    .then((res) => res.data);
};
