import React, { useState, Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { Drawer, TextField, EditButton } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { Product, Solution } from '~/types';
import { ProductEdit } from './index';
import { useProductTemplates } from '~/hooks/useProductTemplates';
import { useAccountNumberingList } from '~/hooks/useAccountNumberingList';

export const l = (key: string): string => `resources.solutions.products.${key}`;

interface Props {
  productRecord: Product | null;
  solutionRecord: Solution;
  onClose: () => void;
  permissions: string[];
}

const ProductShow = (props: Props) => {
  const { productRecord, solutionRecord, onClose, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { data: productTemplates } = useProductTemplates();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: accountNumberingOptions } = useAccountNumberingList();

  return (
    <Fragment>
      <ProductEdit
        productRecord={productRecord}
        solutionRecord={solutionRecord}
        open={open}
        onClose={handleClose}
        closeParent={onClose}
        permissions={permissions}
      />
      <Drawer heading={t('showProduct')} open={!!productRecord} onClose={onClose}>
        {productRecord && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label={t('name')}>{productRecord.name}</TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('template')}>
                {productTemplates?.find((pt) => pt.code === productRecord.templateCode)?.alias ||
                  productRecord.templateCode}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <ReferenceField
                source='bankAccountId'
                record={productRecord}
                reference='bank/accounts'
                basePath='/bank/accounts'
                link={false}
                addLabel={false}
              >
                <RaTextField source='alias' label={t('bankAccount')} />
              </ReferenceField>
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('currency')}>{productRecord.currency}</TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField label={t('accountNumbering')}>
                {accountNumberingOptions?.find((an) => an.id === productRecord.accountNumbering)
                  ?.name || productRecord.accountNumbering}
              </TextField>
            </Grid>
            <Grid item container justify='flex-end'>
              <Grid item>
                <EditButton
                  onClick={handleOpen}
                  disabled={!permissions.includes('solution.product.delete')}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Drawer>
    </Fragment>
  );
};

export default ProductShow;
