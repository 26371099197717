import React from 'react';
import { Datagrid, TextField, ReferenceField, usePermissions } from 'react-admin';

import { List } from '~/layout';
import { NoPermissions, ShowController } from '~/components/ra';
import IdentityEdit from './IdentityCreate';

const IdentityList = (props: any) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions.includes('identity.list')) return <NoPermissions />;

  return (
    <List {...props} bulkActionButtons={false} actions={<IdentityEdit permissions={permissions} />}>
      <Datagrid rowClick='show' size='medium' selectedIds={null}>
        <TextField source='username' label='resources.team.fields.username' sortable={false} />
        <TextField source='name' label='resources.team.fields.name' sortable={false} />
        <TextField source='email' label='resources.team.fields.email' sortable={false} />
        <TextField
          source='phoneNumber'
          label='resources.team.fields.phoneNumber'
          sortable={false}
        />
        <ReferenceField
          source='roleId'
          reference='roles'
          link={false}
          label='resources.team.fields.roleId'
          sortable={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <TextField source='lastLogin' label='resources.team.fields.lastLogin' sortable={false} />
        <ShowController label='resources.team.fields.status'>
          {({ showRecord }) => {
            return showRecord.deleted ? (
              <TextField source='status' allowEmpty emptyText='Blocked' sortable={false} />
            ) : (
              <TextField source='status' allowEmpty emptyText='Active' sortable={false} />
            );
          }}
        </ShowController>
      </Datagrid>
    </List>
  );
};

export default IdentityList;
