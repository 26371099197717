import { Alert } from '@material-ui/lab';
import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { TextInput, SelectInput, required, email } from 'react-admin';

import { ReferenceInput } from '~/components/ra';
import { Identity } from '~/types';
import validatePhoneNumber from '~/utils/validatePhoneNumber';

import { l } from './IdentityShow';

interface Props {
  edit?: boolean;
  record?: Identity;
}

const IdentityForm = (props: Props) => {
  const { edit, record } = props;
  const t = useTranslate();
  return (
    <Fragment>
      <Alert severity='warning' style={{ marginBottom: '1.5em' }}>
        {t(l('twoFactorAuthWarning'))}
      </Alert>
      <TextInput source='username' label={l('username')} validate={required()} disabled={edit} />
      <TextInput source='name' label={l('name')} validate={required()} />
      <TextInput source='email' label={l('email')} validate={[required(), email()]} />
      <TextInput source='phoneNumber' label={l('phoneNumber')} validate={[validatePhoneNumber]} />
      {record?.roleId !== 'support' && (
        <ReferenceInput
          source='roleId'
          reference='roles'
          label={l('roleId')}
          validate={[required()]}
        >
          <SelectInput optionText='name' validate={[required()]} />
        </ReferenceInput>
      )}
    </Fragment>
  );
};

export default IdentityForm;
