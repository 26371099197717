import React, { FC, useState, ChangeEvent, CSSProperties } from 'react';
import { useTranslate, Button } from 'react-admin';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { CreateButton, CurrencyAutocomplete, H2, TableCell, SaveButton } from '~/components';
import { l } from '../CurrenciesList';
import { CurrencyType, getCurrencies, registerCurrency } from '~/api/currencies';
import { useApi } from '~/hooks';
import i18n from '~/i18n';
import { isEmpty } from 'lodash';
import { Longdash } from '~/utils';
import theme from '~/theme';
import { FlagIcon } from '~/img';
import onError from '~/errorsHandler';

type TableCurrencyType = { code: string; name: string; isMaster?: boolean };
const allCurrencies: Record<string, string> = (i18n as any)['en'].currencies;

interface AddOrEditCurrencyProps {
  className?: string;
  refetchCurrencyPairs: () => void;
  permissions?: string[];
}

const AddOrEditCurrency: FC<AddOrEditCurrencyProps> = (props) => {
  const { className, permissions, refetchCurrencyPairs } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [{ data: currencies, loading }, refetch] = useApi<CurrencyType[]>(getCurrencies);

  const [open, setOpen] = useState<boolean>(false);
  const handleAdd = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid container direction='column' spacing={1} className={className}>
      <Grid container item justify='space-between'>
        <Grid item xs>
          <H2 topSpacing={0} bottomSpacing={0}>
            {t('currencies')}
          </H2>
        </Grid>
        <Grid container item xs spacing={2} justify='flex-end'>
          {!open ? (
            <Grid item>
              {permissions?.includes('currency.create') && (
                <CreateButton variant='add' onClick={handleAdd} />
              )}
            </Grid>
          ) : (
            <Grid item>
              <Button label={'ra.action.cancel'} onClick={handleClose} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container item>
        {!open && <CurrenciesTable currencies={currencies} loading={loading} />}
        {currencies && open && (
          <SelectCurrenciesTable
            choosenCurrencies={currencies}
            onClose={handleClose}
            refetch={refetch}
            refetchCurrencyPairs={refetchCurrencyPairs}
          />
        )}
      </Grid>
    </Grid>
  );
};

interface SelectCurrenciesTableProps {
  choosenCurrencies: CurrencyType[];
  onClose: () => void;
  refetch: () => void;
  refetchCurrencyPairs: () => void;
}

const SelectCurrenciesTable: FC<SelectCurrenciesTableProps> = (props) => {
  const { choosenCurrencies, onClose, refetch, refetchCurrencyPairs } = props;
  const initialChoosenCurrencies = choosenCurrencies.map((item) => ({
    code: item.code,
    name: allCurrencies[item.code],
    isMaster: item.isMaster,
  }));
  const [currentChoosenCurrencies, setChoosen] = useState<TableCurrencyType[]>(
    initialChoosenCurrencies
  );
  const handleAdd = (value: TableCurrencyType) => {
    setChoosen((prev) => prev.concat([value]));
  };

  const initialChoosenCurrenciesDict: Record<string, string> = {};
  initialChoosenCurrencies.forEach((item) => {
    initialChoosenCurrenciesDict[item.code] = item.name;
  });
  const result = currentChoosenCurrencies.filter(
    (item) => !initialChoosenCurrenciesDict[item.code]
  );

  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    setSaving(true);
    try {
      await Promise.all(result.map((item) => registerCurrency({ code: item.code })));
      refetch();
      refetchCurrencyPairs();
      onClose();
    } catch (error) {
      onError(error);
    }
    setSaving(false);
  };

  return (
    <Grid container item spacing={2} direction='column'>
      <Grid item>
        <CurrenciesTable
          currencies={currentChoosenCurrencies}
          initialChoosenCurrenciesDict={initialChoosenCurrenciesDict}
        />
      </Grid>
      <Grid item container spacing={2} justify='space-between' alignItems='center'>
        <Grid item xs={10}>
          <CurrencyAutocompleteWithButton
            onAdd={handleAdd}
            choosenCurrencies={currentChoosenCurrencies}
            disabled={saving}
          />
        </Grid>
        <Grid item>
          <SaveButton
            onClick={handleSave}
            loading={saving}
            disabled={isEmpty(currentChoosenCurrencies) || saving}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

interface CurrencyAutocompleteWithButton {
  onAdd: (value: TableCurrencyType) => void;
  choosenCurrencies: TableCurrencyType[];
  disabled?: boolean;
}

const CurrencyAutocompleteWithButton: FC<CurrencyAutocompleteWithButton> = (props) => {
  const { onAdd, choosenCurrencies, disabled } = props;
  const [state, setState] = useState<TableCurrencyType | null>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState({ code: value, name: allCurrencies[value] });
  };
  const handleAdd = () => {
    state && state.code && onAdd(state);
    setState(null);
  };

  const choosenCurrenciesDict: Record<string, string> = {};
  choosenCurrencies.forEach((item) => {
    const { code, name } = item;
    choosenCurrenciesDict[code] = name;
  });

  return (
    <Grid item container spacing={1}>
      <Grid item xs={10}>
        <CurrencyAutocomplete
          required
          onChange={handleChange}
          value={state && state.code}
          filterOptions={(currenciesArr) =>
            currenciesArr.filter((code) => !choosenCurrenciesDict[code])
          }
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant='outlined'
          onClick={handleAdd}
          label='Add'
          disabled={!(state && state.code) || disabled}
          style={fullHeight}
        >
          <ArrowUpwardIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

interface CurrenciesTableProps {
  currencies: { code: string; [x: string]: any }[] | undefined;
  initialChoosenCurrenciesDict?: Record<string, string>;
  loading?: boolean;
}

const CurrenciesTable: FC<CurrenciesTableProps> = (props) => {
  const { currencies, initialChoosenCurrenciesDict, loading } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={tableCodeStyles}>{t('alphabeticCode')}</TableCell>
          <TableCell>{t('currencyName')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!isEmpty(currencies)
          ? currencies?.map((item) => {
              const { code } = item;
              return (
                <TableRow key={code}>
                  <TableCell
                    style={getNewCurrencyStyle(code, initialChoosenCurrenciesDict, tableCodeStyles)}
                  >
                    {code} {item.isMaster && <FlagIcon />}
                  </TableCell>
                  <TableCell
                    style={getNewCurrencyStyle(code, initialChoosenCurrenciesDict, tableCodeStyles)}
                  >
                    {allCurrencies[code]}
                  </TableCell>
                </TableRow>
              );
            })
          : !loading && (
              <TableRow>
                <TableCell style={tableCodeStyles}>{Longdash}</TableCell>
                <TableCell>{Longdash}</TableCell>
              </TableRow>
            )}
        {loading &&
          [{}, {}, {}].map((_, index) => (
            <TableRow key={index}>
              <TableCell loading={loading} />
              <TableCell loading={loading} />
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const fullHeight: CSSProperties = { height: '100%' };
const tableCodeStyles: CSSProperties = { width: 200 };
const getNewCurrencyStyle = (
  code: string,
  old: Record<string, string> | undefined,
  styles: CSSProperties = {}
): CSSProperties | undefined => {
  if (old && !old[code]) {
    return { ...styles, color: theme.palette.warning.main };
  }
  return styles;
};

export default styled(AddOrEditCurrency)`
  max-width: 800px;
`;
