import React, { FC, useState } from 'react';
import { useTranslate, usePermissions } from 'react-admin';

import { EditButton } from '~/components';
import EditDrawer from './EditDrawer';
import { Deal } from '~/types/Deal';
import { useGetTransaction } from '~/hooks/headTransactions';

export const useTranslateDealDetails = () => {
  const translate = useTranslate();
  return (key: string): string => translate(`modules.editDealDetails.${key}`);
};

interface EditDealDetailsProps {
  record?: Deal;
  [x: string]: any;
}

const EditDealDetails: FC<EditDealDetailsProps> = (props) => {
  const { record } = props;
  const { permissions } = usePermissions();
  const t = useTranslateDealDetails();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { data: headTransaction } = useGetTransaction(record?.senderHeadTransactionId);
  const hasCrossBorderTransferTag = headTransaction?.tags?.includes('CROSS_BORDER_TRANSFER');
  if (!permissions) return null;
  if (!permissions.includes('deal.update')) return null;
  if (
    !hasCrossBorderTransferTag ||
    (hasCrossBorderTransferTag &&
      record?.senderCountry === 'ZA' &&
      ['BILL_PAYMENT', 'REMITTANCE', 'PAYOUT'].includes(record?.operationType))
  ) {
    return (
      <>
        <EditDrawer open={open} onClose={handleClose} record={record} />
        <EditButton label={t('editWithReporting')} onClick={handleOpen} />
      </>
    );
  } else return null;
};

export default EditDealDetails;
