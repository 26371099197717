import React, { useEffect, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FileSaver from 'file-saver';

import { ExportButton } from '~/components';
import { Button } from '~/components/ra';
import onError from '~/errorsHandler';
import { exportCsv } from '~/api/localization';

const useExportTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.localization.export.${key}`);
};

interface ExportDialogProps {
  open: boolean;
  onClose: () => void;
  filterValues?: {
    [x: string]: any;
  };
}

const ExportDialog = (props: ExportDialogProps) => {
  const { open, onClose, filterValues } = props;

  const t = useExportTranslate();

  useEffect(() => {
    if (open) {
      exportCsv(filterValues)
        .then(({ data, headers }: any) => {
          const blob = new Blob([data], { type: headers['content-type'] });
          const fileName = headers['content-disposition']
            ? headers['content-disposition'].split('filename=')[1].split(';')[0]
            : 'localizations.csv';
          FileSaver.saveAs(blob, fileName);
        })
        .catch(onError);
    }
  }, [open, filterValues]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('dialog.title')}</DialogTitle>
      <DialogContent>{t('dialog.content')}</DialogContent>
      <DialogActions>
        <Button label='ra.action.close' onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

type LocalizationExportActionProps = {
  filterValues?: {
    [x: string]: any;
  };
};

const LocalizationExportAction = (props: LocalizationExportActionProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log(`filterValues: `, props.filterValues);

  const { permissions } = usePermissions();
  if (!permissions?.includes('localization.list')) return null;

  return (
    <>
      <ExportButton onClick={handleOpen} />
      <ExportDialog open={open} onClose={handleClose} filterValues={props.filterValues} />
    </>
  );
};

export default LocalizationExportAction;
