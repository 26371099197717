import React, { useCallback, useState, Fragment, useEffect } from 'react';
import { useTranslate, Button } from 'react-admin';

import { SwapHoriz } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Container,
  Drawer,
  Grid,
  SvgIcon,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from '@material-ui/core';

import { H2 } from '~/components';

import { ArrowForwardCircleIcon, CloseCircleIcon } from '~/img';
import { createTransaction as createSettlementTransaction } from '~/api/settlement';
import { createTransaction as createAccountTransferTransaction } from '~/api/accountTransfer';

import SettlementForm from './SettlementForm';
import { ConsentHolder } from './RunTransferActionTypes';
import RunTransferActionConsent from './RunTransferActionConsent';
import { Skeleton } from '@material-ui/lab';
import { useNotify } from '~/hooks';
import { OperationType } from '~/types/OperationType';
import AccountTransferForm from './AccountTransferForm';
import OperationTypeInput from '../OperationTypeInput';

export const l = (key: string): string => `components.RunTransferAction.${key}`;

type RunTransferActionProps = {
  profileId: string;
  resource: 'individuals' | 'businesses' | 'partners' | 'tenant/accounts';
  permissions: string[];
  onDone: () => void;
};

const RunTransferAction = ({
  profileId,
  resource,
  permissions,
  onDone,
}: RunTransferActionProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const onClick = () => {
    setOpen(true);
  };

  const classes = useStyles();

  const [consent, setConsent] = useState<ConsentHolder | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<OperationType>();

  useEffect(() => {
    setConsent(undefined);
    setLoading(false);
  }, [open]);

  const onConsent = useCallback(
    (consent: ConsentHolder | undefined) => {
      console.log(`consent:`, consent);
      setConsent(consent);
    },
    [setConsent]
  );

  const notify = useNotify();

  const onCreateTransactions = () => {
    console.log(`creating transactions`);
    if (consent?.consents) {
      setLoading(true);
      const promise = Promise.all(
        consent?.consents.map((c) => {
          switch (c.operationType) {
            case 'SETTLEMENT':
              return createSettlementTransaction({ consentId: c.id });
            case 'ACCOUNT_TRANSFER':
              return createAccountTransferTransaction({ consentId: c.id });
            default:
              return null;
          }
        })
      );
      promise
        .then(() => {
          setLoading(false);
          setOpen(false);
          notify({ message: t('transferDone'), type: 'success' });
          onDone();
        })
        .catch((error) => {
          notify({ message: error.message, type: 'error' });
          setLoading(false);
        });
    }
  };

  const consentActive = !!consent;

  const errorMessage = (error: string) => {
    switch (error) {
      case 'NOT_SUFFICIENT_FUNDS':
        return 'Not sufficient funds!';
      default:
        return 'Transaction is not possible.';
    }
  };

  const hasSettlementPermission = permissions?.includes('settlement.runTransfer');
  const hasAccountTransferPermission = permissions?.includes('accountTransfer.runTransfer');

  return (
    <Fragment>
      <Button
        label={t('transfer')}
        // className={cx({ [classes.warning]: warning, [classes.red]: red })}
        onClick={onClick}
      >
        <SwapHoriz />
      </Button>
      <Drawer anchor='bottom' open={open} onClose={handleClose}>
        <Container maxWidth='md' className={classes.container}>
          <Grid container spacing={7}>
            <Grid container item xs={12} alignItems='center' justify='space-between'>
              <Grid item xs>
                <H2 topSpacing={0} bottomSpacing={0} className={classes.title}>
                  {t('transferHeadline')}
                </H2>
                <Typography variant='subtitle1'>{t('transferSubtitle')}</Typography>
              </Grid>
              <Grid container item justify='flex-end' spacing={2} xs={4}>
                <Grid item>
                  <Button
                    label='ra.action.cancel'
                    onClick={handleClose}
                    className={classes.red}
                    size='large'
                  >
                    <SvgIcon>
                      <CloseCircleIcon />
                    </SvgIcon>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    label={t('approve')}
                    color='primary'
                    size='large'
                    variant='contained'
                    disabled={!consent?.consents || loading}
                    onClick={onCreateTransactions}
                  >
                    {loading ? (
                      <CircularProgress size={18} thickness={2} />
                    ) : (
                      <SvgIcon>
                        <ArrowForwardCircleIcon />
                      </SvgIcon>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stepper nonLinear orientation='vertical'>
                <Step active expanded>
                  <StepLabel>{t('selectOperationType')}</StepLabel>
                  <StepContent>
                    <OperationTypeInput
                      label={t('operationType')}
                      value={operationType!}
                      onChange={(e) => {
                        setOperationType(e.target.value);
                        setConsent(undefined);
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      }}
                      style={{
                        width: 240,
                      }}
                      optionsFilter={(o) => {
                        return (
                          (hasSettlementPermission && o.value === 'SETTLEMENT') ||
                          (hasAccountTransferPermission && o.value === 'ACCOUNT_TRANSFER')
                        );
                      }}
                      allowEmpty
                    />
                  </StepContent>
                </Step>
                <Step active={!!operationType} expanded={!!operationType}>
                  <StepLabel>{t('inputDetails')}</StepLabel>
                  <StepContent>
                    {operationType === 'ACCOUNT_TRANSFER' ? (
                      <AccountTransferForm
                        resource={resource}
                        profileId={profileId}
                        onConsent={onConsent}
                      />
                    ) : operationType === 'SETTLEMENT' ? (
                      <SettlementForm
                        resource={resource}
                        profileId={profileId}
                        onConsent={onConsent}
                      />
                    ) : null}
                  </StepContent>
                </Step>
                <Step active={consentActive} expanded={consentActive}>
                  <StepLabel>{t('checkConsent')}</StepLabel>
                  <StepContent>
                    {consent?.consents ? (
                      consent.consents.map((c) => {
                        return (
                          <RunTransferActionConsent
                            key={c.id}
                            consent={c}
                            accounts={consent.accounts || []}
                          />
                        );
                      })
                    ) : consent?.error ? (
                      <>{errorMessage(consent.error)}</>
                    ) : (
                      <>
                        <Skeleton style={{ width: 400, height: 20 }} />
                        <Skeleton style={{ width: 400, height: 20 }} />
                        <Skeleton style={{ width: 400, height: 20 }} />
                      </>
                    )}
                  </StepContent>
                </Step>
              </Stepper>
            </Grid>
          </Grid>
        </Container>
      </Drawer>
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    fontSize: '32px',
  },
  red: {
    color: theme.palette.error.main,
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  labelBottomSpacing: {
    paddingBottom: theme.spacing(1),
  },
}));

export default RunTransferAction;
