import React, { FC } from 'react';
import { useApiLocalization } from '~/hooks/useApiLocalization';
import { TextField } from '.';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';

interface ApiLocalizedTextFieldProps {
  label: string;
  keyPrefix: string;
  secondKey?: string;
}

const ApiLocalizedTextField: FC<ApiLocalizedTextFieldProps> = (props) => {
  const { keyPrefix, secondKey, label } = props;
  const enabled = !!keyPrefix && !!secondKey;
  const { data: translations, isLoading } = useApiLocalization(`${keyPrefix}`, enabled);
  const translationValue = translations && translations[`${keyPrefix}.${secondKey}`];
  return (
    <TextField label={label}>
      {isLoading ? <Skeleton width={200} /> : isEmpty(translations) ? secondKey : translationValue}
    </TextField>
  );
};

export default ApiLocalizedTextField;
