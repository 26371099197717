import React, { FC, useState, Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import { useApi } from '~/hooks';
import { H3, AccordionSummary, AccordionDetails, ChipField, TextField, H2 } from '~/components';
import { NoPermissions, TextField as RaTextField } from '~/components/ra';
import { Section } from '~/layout';
import { getCurrenciesPairs, CurrencyPairsType } from '~/api/currencies';
import { getCurrenciesRates, CurrenciesRates } from '~/api/fxrates';
import styled from 'styled-components';
import { detachKeys } from '~/utils';
import { l } from '../CurrenciesList';
import { ArchiveIcon } from '~/img';
import TableEdit from './TableEdit';
import TableCreate from './TableCreate';
import { MANUAL_SOURCE_SYSTEM } from './RatesSourceSelect';

const FXConfigurationList: FC<any> = (props) => {
  const { className, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const [{ data, loading }, refetch] = useApi<CurrenciesRates[]>(getCurrenciesRates);
  const [{ data: pairs }] = useApi<CurrencyPairsType[]>(getCurrenciesPairs);
  const [pairsDict] = detachKeys<CurrencyPairsType>(pairs);

  // ExpansionPanel
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  if (!permissions) return null;
  if (!permissions?.includes('fxrates.list')) return <NoPermissions />;

  return (
    <Fragment>
      <Grid container spacing={1} direction='column'>
        <Grid item container justify='space-between'>
          <Grid item>
            <H2 topSpacing={0} bottomSpacing={0}>
              {t('fxRateTablesConfiguration')}
            </H2>
          </Grid>
          {permissions?.includes('fxrates.create') && (
            <Grid item>
              <TableCreate refetch={refetch} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container className={className}>
        {loading &&
          [{}, {}, {}].map((_, key) => {
            return (
              <Grid key={key} item xs={12}>
                <Accordion>
                  <AccordionSummary loading />
                </Accordion>
              </Grid>
            );
          })}
        {data &&
          data.map((item) => {
            const key = item.id as string;
            const isExpanded = Boolean(expanded[key]);
            return (
              <Grid key={key} item xs={12}>
                <Accordion expanded={isExpanded} onChange={handleChange(key, isExpanded)}>
                  <AccordionSummary
                    aria-controls={`${key}-content`}
                    id={`${key}-header`}
                    expanded={isExpanded}
                    heading={
                      <>
                        <span>{item.name}</span>{' '}
                        {item.useForFxProcessing && (
                          <span style={{ opacity: 0.5 }}>{t('forFxProcessing')}</span>
                        )}
                      </>
                    }
                    actionNode={
                      <TableEdit record={item} refetch={refetch} permissions={permissions} />
                    }
                  />
                  <AccordionDetails>
                    {permissions?.includes('fxrates.view') && (
                      <Section>
                        {(item.sourceSystem === MANUAL_SOURCE_SYSTEM || !item.sourceSystem) && (
                          <>
                            <H3 noPaddingLeft topSpacing={0} icon={<AttachMoneyIcon />}>
                              {t('currencyConversionPairs')}
                            </H3>
                            <Grid container spacing={1}>
                              {pairs
                                ?.filter((item) => item.isMaster)
                                .map((item) => {
                                  const { baseCurrency, quoteCurrency } = item;
                                  return (
                                    <Grid item>
                                      <ChipField
                                        label={`${baseCurrency}/${quoteCurrency}`}
                                        primary
                                      />
                                    </Grid>
                                  );
                                })}
                              {item.currencyPairs.map((pairId) => {
                                const { baseCurrency, quoteCurrency, isMaster } =
                                  pairsDict[pairId] || {};
                                return (
                                  <Grid item>
                                    <ChipField
                                      label={`${baseCurrency}/${quoteCurrency}`}
                                      primary={isMaster}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </>
                        )}
                        <H3 noPaddingLeft bottomSpacing={2} icon={<ArchiveIcon />}>
                          {t('buyAndSellRates')}
                        </H3>
                        {item.sourceSystem === MANUAL_SOURCE_SYSTEM || !item.sourceSystem ? (
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField label={t('buyRateSource')}>
                                {item.buyRateSource && t(item.buyRateSource)}
                              </TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField label={t('sellRateSource')}>
                                {item.sellRateSource && t(item.sellRateSource)}
                              </TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <ReferenceField
                                source='profitAccountId'
                                record={item}
                                reference='tenant/accounts'
                                basePath='/tenant/accounts'
                                link={false}
                                addLabel={false}
                              >
                                <RaTextField source='alias' label={t('profitAccountId')} />
                              </ReferenceField>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField label={t('buyMargin')}>
                                {item.buyRateSource === 'CALCULATION' && item.buyMargin}
                              </TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField label={t('sellMargin')}>
                                {item.sellRateSource === 'CALCULATION' && item.sellMargin}
                              </TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField label={t('key')}>{item.key}</TextField>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField label={t('buyRateSource')}>{item.sourceSystem}</TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField label={t('sellRateSource')}>{item.sourceSystem}</TextField>
                            </Grid>
                            <Grid item xs={4}>
                              <ReferenceField
                                source='profitAccountId'
                                record={item}
                                reference='tenant/accounts'
                                basePath='/tenant/accounts'
                                link={false}
                                addLabel={false}
                              >
                                <RaTextField source='alias' label={t('profitAccountId')} />
                              </ReferenceField>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField label={t('key')}>{item.key}</TextField>
                            </Grid>
                          </Grid>
                        )}
                      </Section>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
      </Grid>
    </Fragment>
  );
};

export default styled(FXConfigurationList)`
  margin-top: 16px;
`;
