import { useMutation, useQuery } from 'react-query';
import { getTaskActions, runManualAction } from '~/api/events';

export const useTaskActions = (eventId: string, isEnabled: boolean) => {
  return useQuery(['actions', eventId], () => getTaskActions(eventId), {
    enabled: !!eventId && isEnabled,
  });
};

export const useRunManualAction = () => {
  return useMutation(runManualAction);
};
