import React, { Fragment, useState } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Skeleton } from '@material-ui/lab';

import { TextField, AmountField } from '~/components';
import PaymentDetailShow from './PaymentDetailShow';
import { TextField as RaCustomTextField } from '~/components/ra';

import { PaymentDetail, Business } from '~/types';

const useStyles = makeStyles((theme) => ({
  // DocumentCard
  paper: {
    display: 'flex',
    flexWrap: 'nowrap',
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    maxWidth: 'calc(100% - 105px)',
  },
  fullWidth: {
    flexGrow: 1,
  },
  ellipsis: {
    display: 'inline',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  valueType: {
    display: 'flex',
    '& svg': {
      marginLeft: 3,
      marginRight: 3,
    },
  },
  photoContainer: {
    marginRight: theme.spacing(2),
    width: 88,
    height: 88,
  },
  outdated: {
    color: theme.palette.error.main,
  },
  bottomFields: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    '& > div': {
      width: '100%',
      maxWidth: 154,
    },
  },
}));

interface Props {
  value?: PaymentDetail;
  fullWidth?: boolean;
  loading?: boolean;
  parentProps: any;
  businessRecord: Business;
  permissions: string[];
}

const PaymentDetailCard = (props: Props) => {
  const { value, fullWidth, loading, parentProps, businessRecord, permissions } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.DocumentCard.${key}`);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    permissions.includes('business.paymentDetails.view') && setOpen(true);
  };

  return (
    <Fragment>
      <div className={cx(classes.paper, { [classes.fullWidth]: fullWidth })} onClick={handleOpen}>
        {!loading ? (
          <img className={classes.photoContainer} src={require('~/img/qr.svg')} alt='qr' />
        ) : (
          <Skeleton variant='rect' width={88} height={88} className={classes.photoContainer} />
        )}
        <div className={classes.fieldsContainer}>
          <TextField
            label={t('name')}
            className={classes.title}
            title={value?.name}
            loading={loading}
          >
            {value?.name}
          </TextField>
          <div className={classes.bottomFields}>
            <TextField label={t('amount')} loading={loading}>
              <AmountField amount={value?.amount} />
            </TextField>
            <TextField label={t('terminal')} loading={loading}>
              <ReferenceField
                {...parentProps}
                record={value}
                source='terminalId'
                reference='terminals'
                link={false}
              >
                <RaCustomTextField addLabel={false} source='label' />
              </ReferenceField>
            </TextField>
          </div>
        </div>
      </div>
      {value && (
        <PaymentDetailShow
          permissions={permissions}
          showData={value}
          open={open}
          onClose={handleClose}
          businessRecord={businessRecord}
          parentProps={parentProps}
        />
      )}
    </Fragment>
  );
};

export default PaymentDetailCard;
