import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, CreateButton } from '~/components';
import { CreateForm } from '~/components/ra';
import PnlAccountForm from './PnlAccountForm';
import { l } from './PnlAccountList';

const PnlAccountCreate = (props: any) => {
  const { permissions, resource } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <CreateButton
        variant='add'
        onClick={handleOpen}
        disabled={!permissions?.includes('tenant.account.create')}
      />
      <Drawer heading={t('addPnl')} open={open} onClose={handleClose}>
        <CreateForm resource={resource} closeParent={handleClose}>
          <PnlAccountForm />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default PnlAccountCreate;
