import React, { useEffect, useState, Fragment } from 'react';
import { useTranslate, ReferenceField, TextField as DefaultRaTextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '~/components';

import { PricingSchema } from '~/api/pricings';
import { Pricing, Product } from '~/types';
import { ByFeature } from './PerFeature';
import { l } from '../PricingShow';
import { useApi, useFeatures } from '~/hooks';
import { Longdash } from '~/utils';
import PricingShowDrawer from './PricingShowDrawer';
import { getProductById } from '~/api/product';

export type ByLoyalty = {
  data?: { [x: string]: any };
} & ByFeature;

interface Props {
  pricingSchema: PricingSchema | undefined;
  record: Pricing | undefined;
  loading: boolean;
}

const PerLoyalty = (props: Props) => {
  const { pricingSchema, record, loading } = props;

  const [{ data: productData }] = useApi<Product>(() => getProductById(record?.productId || ''), [
    record?.productId,
  ]);
  const currency = productData?.currency || '';

  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  const classes = useStyles();

  const { list: featuresList, loading: featuresLoading } = useFeatures();

  // format received data by loyalty
  const [byLoyalty, setByLoyalty] = useState<ByLoyalty[]>([]);
  useEffect(() => {
    if (pricingSchema && record) {
      const properties = pricingSchema.properties;
      const result: ByLoyalty[] = [];
      for (let key in properties) {
        const prop = properties[key];
        if (prop['v:category'] !== 'loyalty') continue;
        result.push({
          ...prop,
          propertyKey: key,
          data: record.data[key],
        });
      }
      setByLoyalty(result);
    }
  }, [pricingSchema, record]);

  const getConfigurationText = (byLoyalty: ByLoyalty) => {
    if (!byLoyalty || !byLoyalty.data) return Longdash;
    switch (byLoyalty.type) {
      case 'veengu_fee_loyalty_default': {
        const { discountType, discountAmount, discountPercent } = byLoyalty.data || {};
        let text: any = '';
        switch (discountType) {
          case 'FIXED':
            let amountText: string = Longdash;
            if (
              !discountAmount ||
              (discountAmount.value !== 0 && !discountAmount.value) ||
              !discountAmount.currency
            ) {
              amountText = Longdash;
            } else {
              amountText = discountAmount.value.toLocaleString('en-US', {
                style: 'currency',
                currency: discountAmount.currency,
                maximumFractionDigits: 6,
              });
            }
            text = (
              <>
                {`${t('loyalty_.discountType._')}: ${t(
                  `loyalty_.discountType.${discountType}`
                )}, ${amountText}`}
              </>
            );
            break;
          case 'PERCENT':
            text = `${t('loyalty_.discountType._')}: ${t(
              `loyalty_.discountType.${discountType}`
            )}, ${discountPercent}%`;
            break;
          default:
            text = `${t('loyalty_.discountType._')}: ${t(`loyalty_.discountType.${discountType}`)}`;
            break;
        }
        return (
          <span className={classes.configurationText}>{discountType && <span>{text}</span>}</span>
        );
      }
      default:
        return null;
    }
  };

  // Main state
  const [state, setState] = useState<any>(record?.data || {});

  const [showPropertyKey, setShowPropertyKey] = useState<string | null>(null);
  const handleShowOpen = (propertyKey: string) => setShowPropertyKey(propertyKey);
  const handleShowClose = () => setShowPropertyKey(null);

  return (
    <Fragment>
      {record && showPropertyKey && (
        <PricingShowDrawer
          pricingDataEditProps={{
            pricingRecord: record,
            record: state,
            schema: {} as any, // PricingShowDrawer add this prop by itself
            setMainState: setState,
            currency: currency,
          }}
          propertyKey={showPropertyKey}
          open={!!showPropertyKey}
          record={byLoyalty}
          onClose={handleShowClose}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('fields.name')}</TableCell>
            <TableCell>{t('feature')}</TableCell>
            <TableCell>{t('loyalty_.discountSourceAccount')}</TableCell>
            <TableCell>{t('configuration')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {byLoyalty.map((item, index) => {
            return (
              <TableRow
                key={index}
                hover
                onClick={() => handleShowOpen(item.propertyKey)}
                className={classes.pointer}
              >
                <TableCell>{item['v:name']}</TableCell>
                <TableCell loading={featuresLoading}>{featuresList[item['v:feature']]}</TableCell>
                <TableCell>
                  <ReferenceField
                    source='discountSourceAccountId'
                    record={item.data}
                    reference='tenant/accounts'
                    basePath='/tenant/accounts'
                    link={false}
                    addLabel={false}
                  >
                    <DefaultRaTextField source='alias' addLabel={false} />
                  </ReferenceField>
                </TableCell>
                <TableCell>{getConfigurationText(item)}</TableCell>
              </TableRow>
            );
          })}
          {loading &&
            [{}, {}, {}].map((_, index) => {
              return (
                <TableRow key={index}>
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  configurationText: {
    display: 'inline-flex',
    '& > b': {
      fontWeight: 500,
    },
    '& > *': {
      marginRight: 4,
      display: 'inline-flex',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export default PerLoyalty;
