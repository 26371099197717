import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import { CreateForm } from '~/components/ra';
import { CreateButton, Drawer } from '~/components';
import LocationForm from './LocationForm';
import { Business } from '~/types';

interface Props {
  businessRecord: Business;
  permissions: string[];
}

const LocationCreate = (props: Props) => {
  const { businessRecord, permissions } = props;
  const { id, solutionId } = businessRecord;
  const createRecord = {
    businessId: id,
    solutionId,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.locations.${key}`);

  return (
    <Fragment>
      <CreateButton
        variant='add'
        onClick={handleOpen}
        disabled={!permissions.includes('business.location.create')}
      />
      <Drawer heading={translateLabel('addLocation')} open={open} onClose={handleClose}>
        <CreateForm
          resource='locations'
          target='businesses'
          id={id}
          record={createRecord}
          closeParent={handleClose}
        >
          <LocationForm businessRecord={businessRecord} />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default LocationCreate;
