import { useMutation, useQuery } from 'react-query';
import { getAllProfileTags, getProfileTagsByResource, mutateProfileTags } from '~/api/profileTags';

export const useMutateProfileTags = () => {
  return useMutation(mutateProfileTags);
};

export const useGetAllProfileTags = () => {
  return useQuery(['profileTags'], () => getAllProfileTags());
};

export const useGetProfileTagsByResource = (
  resource: 'businesses' | 'individuals' | 'partners',
  isEnabled?: boolean
) => {
  return useQuery(['profileTags', resource], () => getProfileTagsByResource(resource), {
    enabled: isEnabled,
  });
};
