import React from 'react';
import { useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { H2 } from '~/components';
import ExternalProfileLinkCard from './ExternalProfileLinkCard';

import { useRightContentWidth } from '~/hooks/styles';
import { ProfileType } from '~/types/profile';
import isEmpty from 'lodash/isEmpty';
import { AsideBase } from '~/layout';
import { useExternalProfileLinkQuery } from '~/hooks/externalProfileLinks';
import palette from '~/theme/palette';

interface Props {
  profileId: string;
  profileType: ProfileType;
  permissions?: any;
}

const ExternalProfileLinks = (props: Props) => {
  const { profileId, permissions } = props;

  const width = useRightContentWidth();
  const classes = useStyles({ width });

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.ExternalProfileLinks.${key}`);

  const { data, isLoading } = useExternalProfileLinkQuery(profileId);

  return (
    <div>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={1} className={classes.h2}>
          {t('_')}
        </H2>
        <div id='section-actions'></div>
      </header>
      {!isLoading && isEmpty(data) ? (
        <div>{t('empty')}</div>
      ) : !isLoading && data ? (
        data.map((link) => (
          <ExternalProfileLinkCard link={link} key={link.id} permissions={permissions} />
        ))
      ) : (
        <AsideBase>
          <Grid container direction='column' spacing={2}>
            {[1, 2, 3].map((i) => (
              <Grid item key={i}>
                <Skeleton />
              </Grid>
            ))}
          </Grid>
        </AsideBase>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ width }: { width: number }) => width + 2,
    minWidth: ({ width }: { width: number }) => width + 2,
    height: 'calc(100% - 36px)',
    minHeight: 'calc(100vh - 260px)',
    '& #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& #section-actions': {
        opacity: 1,
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  bottomSpacing1: {
    marginBottom: theme.spacing(1),
  },
  fixHiddenBorders: {
    padding: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  h2: {
    lineHeight: '30px',
  },
  changelogMode: {
    backgroundColor: palette.changelog.yellow,
  },
}));

export default ExternalProfileLinks;
