import React from 'react';
import { withTranslate } from 'react-admin';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import ColoredAmountField from './ColoredAmountField';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    // justifyContent: 'flex-end',
  },
  subtitle: {
    opacity: 0.4,
  },
}));

type Props = {
  data?: any[];
  loading?: boolean;
  error?: any;
  className?: any;
  translate?: any;
  history?: any;
  [x: string]: any;
};

const PnlAccountsTable = ({ className, translate, data, history, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title={translate('home.pnlAccountsTable.title')} />
      <CardContent>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>{translate('home.pnlAccountsTable.alias')}</TableCell>
              <TableCell>{translate('home.pnlAccountsTable.balance')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((pnlAccount) => (
              <TableRow key={pnlAccount.id}>
                <TableCell component='th' scope='row'>
                  {pnlAccount.alias}
                </TableCell>
                <TableCell align='right'>
                  <ColoredAmountField amount={pnlAccount.balances[0].amount} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color='primary'
          size='small'
          variant='text'
          onClick={() => {
            history.push('/tenant/accounts');
          }}
          style={{ marginLeft: 'auto' }}
        >
          Go to P&amp;L accounts <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default withTranslate(PnlAccountsTable);
