import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';

import { DeleteButton, Confirmation } from '~/components';
import { l } from './RoleShow';
import { useDeleteRole } from '~/hooks/roles';

interface Props {
  roleId: string;
}

const RoleDelete: FC<Props> = (props) => {
  const { roleId } = props;
  const translate = useTranslate();

  const t = (key: string): string => translate(l(key));

  const deleteRoleMutation = useDeleteRole();
  const handleReset = () => {
    if (roleId) {
      return deleteRoleMutation.mutateAsync(roleId);
    }
  };

  return (
    <Confirmation
      onConfirm={handleReset}
      confirmButtonProps={{ loading: deleteRoleMutation.isLoading }}
      confirmationSettings={{
        variant: 'modal',
        modal: { content: <Alert severity='warning'>{t('deleteRoleWarning')}</Alert> },
      }}
    >
      <DeleteButton />
    </Confirmation>
  );
};

export default RoleDelete;
