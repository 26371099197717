import React from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { TextField, AmountField, BooleanField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { l } from '~/resources/pricings/PricingShow';
import { Longdash, getDateFromDayNumber } from '~/utils';
import { billingDateFormat } from '../Forms/RegularFeeForm';

const MembershipFeeView = (props: { record: any }) => {
  const { record } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={4}>
        <TextField label={t('type')}>{record.type && t(record.type)}</TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('statementTitle')}>{record.name}</TextField>
      </Grid>
      <Grid item xs={4}>
        {record.pnlAccountId ? (
          <ReferenceField
            source='pnlAccountId'
            record={record}
            reference='tenant/accounts'
            basePath='/tenant/accounts'
            link={false}
            addLabel={false}
          >
            <RaTextField source='alias' label={t('pnlAccount')} ellipsis />
          </ReferenceField>
        ) : (
          <RaTextField label={t('pnlAccount')} allowEmpty />
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('amount')}>
          <AmountField amount={record.amount} />
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('period')}>{record.period && t(record.period)}</TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('billingDate')}>
          {record.billingDate
            ? record.period === 'annually'
              ? getDateFromDayNumber(record.billingDate).format(billingDateFormat)
              : record.billingDate
            : Longdash}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <BooleanField
          label={t('payOnTheFirstCalendarMonth')}
          value={record.payOnTheFirstCalendarMonth}
        />
      </Grid>
      {(record.index || record.index === 0) && (
        <Grid item xs={4}>
          <TextField label={t('index')}>{record.index}</TextField>
        </Grid>
      )}
    </Grid>
  );
};

export default MembershipFeeView;
