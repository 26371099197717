/* eslint-disable no-use-before-define */
import React from 'react';
import { useLocale, useTranslate } from 'react-admin';

import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import i18n from '~/i18n';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export interface Props {
  value?: string | null;
  label?: string;
  onChange?: (e: any) => void;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  options?: string[];
  [key: string]: any;
  className?: string;
  name?: string;
}

const CountryAutocomplete = (props: Props) => {
  const classes = useStyles();
  const locale: keyof any = useLocale();

  const allCountries = (i18n as any)[locale].countries;
  let countries: any = {};
  if (props.options) {
    props.options.forEach((o) => {
      countries[o] = allCountries[o];
    });
  } else {
    countries = allCountries; // (i18n as any)[locale].countries;
  }

  const translate = useTranslate();

  return (
    <Autocomplete
      id='country-select-demo'
      options={Object.keys(countries)}
      classes={{
        option: classes.option,
      }}
      className={props.className}
      disabled={props.disabled}
      autoHighlight
      getOptionLabel={(option) => {
        return countries[option];
      }}
      renderOption={(option) => {
        return (
          <React.Fragment>
            <span>{countryToFlag(option)}</span>
            {countries[option]} ({option})
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label || 'Choose a country'}
          variant='filled'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          fullWidth
          required={props.required}
          helperText={props.helperText}
          error={props.error}
          style={props.style}
        />
      )}
      filterOptions={createFilterOptions({
        stringify: (option) => `${countries[option]},${option}`,
      })}
      value={props.value}
      onChange={(e: any, option: any) => {
        props.onChange &&
          props.onChange({
            target: {
              name: props.name,
              value: option,
            },
          });
      }}
      noOptionsText={translate('utils.noHandbookValues')}
    />
  );
};

export default CountryAutocomplete;

interface CountryType {
  code: string;
  label: string;
  phone: string;
}
