import React, { FC, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import { Longdash, time } from '~/utils';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import {
  H2,
  AccordionSummary,
  AccordionDetails,
  TextField,
  DeleteButton,
  AmountField,
  StatusField,
} from '~/components';
import { ExternalSource } from '~/types';
import {
  deleteExternalSource as apiDeleteExternalSource,
  getThirdPartyData,
} from '~/api/externalSources';
import { useExternalSourceBalance, useGetAllExternalSources } from '~/hooks/externalSources';
import { Skeleton } from '@material-ui/lab';
import Confirmation from '~/components/Confirmation';
import collectProperties from '~/utils/collectProperties';
import onError from '~/errorsHandler';
import EditSourceName from '~/components/EditSourceName';
import { useQueries, QueryObserverResult } from 'react-query';
import H3 from '~/components/H3';
import { ThirdPartyData } from '~/types/ExternalSource';

export const l = (key: string): string => `components.ra.ExternalSources.${key}`;

interface ExternalSourcesProps {
  solutionId: string | undefined;
  profileId: string;
  resource: 'individuals' | 'businesses';
}

const ExternalSources: FC<ExternalSourcesProps> = (props) => {
  const { resource, profileId } = props;
  const classes = useStyles();
  const { data, refetch } = useGetAllExternalSources({ resource, profileId });

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { permissions = [] } = usePermissions();

  const [accordionsState, setAccordionsState] = useState<{
    [x: string]: { isExpanded: boolean; hasThirdPartyData: boolean };
  }>({});

  const handleChange = (key: string, isExpanded: boolean, hasThirdPartyData: boolean) => () => {
    setAccordionsState((prevProps) => ({
      ...prevProps,
      [key]: { isExpanded: !isExpanded, hasThirdPartyData },
    }));
  };

  const deleteExternalSource = async (externalSource: ExternalSource) => {
    try {
      await apiDeleteExternalSource(externalSource.id!);
      refetch();
    } catch (error) {
      onError(error);
    }
  };

  // Third party details
  const uuidsToFetchThirdPartyData = Object.keys(accordionsState).filter(
    (uuid) => accordionsState[uuid]?.isExpanded && accordionsState[uuid]?.hasThirdPartyData
  );

  const thirdPartyDataQueries = useQueries(
    uuidsToFetchThirdPartyData.map((uuid) => ({
      queryKey: ['details', uuid],
      queryFn: () => getThirdPartyData(uuid),
      enabled: accordionsState[uuid]?.isExpanded && accordionsState[uuid]?.hasThirdPartyData,
    }))
  ) as QueryObserverResult<ThirdPartyData, unknown>[];

  if (
    !permissions.includes('externalSource.list') ||
    !permissions.includes('externalSource.view')
  ) {
    return null;
  }

  const hasDeletePermission = permissions.includes('externalSource.delete');

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <H2 topSpacing={0} bottomSpacing={0}>
          {t('externalSources')}
        </H2>
        <div id='section-actions'>
          {/*solutionId && (
            <CardCreate
              resource={resource}
              solutionId={solutionId}
              profileId={profileId}
              refetch={refetch}
            />
          )*/}
        </div>
      </header>
      {(data?.length || 0) === 0 ? (
        <div>{t('empty')}</div>
      ) : (
        data?.map((item) => {
          const { id, sourceType, status, name, createdAt, hasBalance, hasThirdPartyData } = item;

          const key = id!;
          const isExpanded = Boolean(accordionsState[key]?.isExpanded);

          const items: any[] = extractItems(item);
          /*
          if (card) {
            items.push(
              <Grid key="cardPaymentSystem" xs={4} item>
                <TextField label={t('card.paymentSystem')}>{card.paymentSystem}</TextField>
              </Grid>
            );
            items.push(
              <Grid key="cardExpirationDate" xs={4} item>
                <TextField label={t('card.expirationDate')}>{card.expirationDate}</TextField>
              </Grid>
            );
          }
           */

          // Third party details
          const thirdPartyQuery = thirdPartyDataQueries[uuidsToFetchThirdPartyData.indexOf(key)];
          const thirdPartyQueryIsLoading = thirdPartyQuery?.isLoading;
          const thirdPartyQueryData = thirdPartyQuery?.data;

          return (
            <Grid key={item.id} item xs={12} className={classes.externalSource}>
              <Accordion
                expanded={isExpanded}
                onChange={handleChange(key, isExpanded, hasThirdPartyData)}
              >
                <AccordionSummary
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={name}
                  actionNode={
                    <Grid container spacing={1}>
                      <Grid item>
                        <EditSourceName
                          record={item}
                          resource={resource}
                          source='externalSources'
                          refetch={refetch}
                        />
                      </Grid>
                      <Grid item className={classes.balanceWrapper}>
                        {hasBalance ? (
                          <ExternalSourceBalanceField id={id} />
                        ) : (
                          <Typography variant='subtitle2' style={{ opacity: 0.5 }}>
                            {t('balanceNotAvailable')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  }
                  content={
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <TextField label={t('sourceType')}>{sourceType}</TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('linkedOn')}>{time(createdAt).format('L')}</TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('status')}>
                          <StatusField status={status}></StatusField>
                        </TextField>
                      </Grid>
                    </Grid>
                  }
                />
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {items.length > 0 && (
                      <Grid container item xs={12}>
                        <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('parameter')}</TableCell>
                              <TableCell>{t('data')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {items.map((item) => {
                              if (item.key === 'hasThirdPartyData') return null;
                              return (
                                <TableRow>
                                  <TableCell title={item.key}>{item.key}</TableCell>
                                  <TableCell>{item.value || Longdash}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                    )}
                    {hasThirdPartyData && (
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          <H3>{t('externalDetails')}</H3>
                        </Grid>
                        <Grid item xs={12}>
                          {thirdPartyQueryIsLoading ? (
                            <Skeleton width='100%' height={38} />
                          ) : thirdPartyQueryData ? (
                            <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t('parameter')}</TableCell>
                                  <TableCell>{t('data')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.entries(thirdPartyQueryData)?.map(([key, value]) => (
                                  <TableRow key={key}>
                                    <TableCell title={key}>{key}</TableCell>
                                    <TableCell>
                                      {value
                                        ? key === 'created'
                                          ? time(value).format('L LTS')
                                          : value
                                        : Longdash}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ) : (
                            t('emptyDetails')
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <Grid container item xs={12} justify='flex-end'>
                      {hasDeletePermission && (
                        <Grid container item xs={12} spacing={2} justify='flex-end'>
                          <Grid item>
                            <Confirmation
                              onConfirm={() => {
                                deleteExternalSource(item);
                              }}
                            >
                              <DeleteButton />
                            </Confirmation>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })
      )}
    </div>
  );
};

export const extractItems = (externalSource: ExternalSource): { key: string; value: string }[] => {
  const items: any[] = [];
  const scan = (object: any, prefix: string | undefined = undefined) => {
    Object.keys(object).forEach((key: string) => {
      const value = object[key];
      if (!Array.isArray(value)) {
        switch (typeof value) {
          case 'object':
            scan(value, prefix ? `${prefix}.${key}` : key);
            break;
          default:
            items.push({
              key: prefix ? `${prefix}.${key}` : key,
              value,
            });
        }
      }
    });
  };
  scan(externalSource);

  return collectProperties(externalSource, [
    'id',
    'profileId',
    'solutionId',
    'deleted',
    'createdAt',
    'sourceType',
    'hasBalance',
    'name',
    'status',
  ]);
};

const ExternalSourceBalanceField: FC<{ id?: string }> = (props) => {
  const { id } = props;
  const { isLoading, data } = useExternalSourceBalance(id);
  const amount = data?.find(({ code }) => code === 'CURRENT_AVAILABLE')?.amount;

  const classes = useStyles();

  return isLoading ? (
    <Skeleton width='100px' height='19px' />
  ) : amount ? (
    <AmountField amount={amount} className={classes.balance} />
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topSpacing2: {
    marginTop: theme.spacing(3),
  },
  expansionContent: {
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  shrinkContent: {
    height: 87,
  },
  balanceWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  balance: {
    fontSize: 16,
  },
  externalSource: {
    '& .externalSource-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover .externalSource-actions': {
      opacity: 1,
    },
  },
  table: {
    width: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td': {
          width: '50%',
          maxWidth: 500,
          overflowWrap: 'break-word',
        },
      },
    },
  },
  autoWidth: {},
}));

export default ExternalSources;
