import React from 'react';
import { FormDataConsumer, Query, Loading } from 'react-admin';

import { buildInputs } from '../../jsonschema';

const ConfigurationForm = ({ configurationSchema, editMode, ...props }) => {
  return (
    <FormDataConsumer {...props}>
      {({ formData }) => {
        if (formData) {
          if (formData.parentId) {
            return (
              <Query
                type='getOne'
                resource={`configurations/${formData.type}`}
                payload={{ id: formData.parentId }}
              >
                {({ data, loading, error }) => {
                  if (loading) {
                    return <Loading />;
                  }
                  if (error) {
                    return <p>Failed to load parent configuration</p>;
                  }
                  return buildInputs({
                    context: 'configuration',
                    defaultData: data,
                    objectSchema: configurationSchema,
                    sourcePrefix: 'data',
                  });
                }}
              </Query>
            );
          } else if (editMode) {
            return buildInputs({
              context: 'configuration',
              objectSchema: configurationSchema,
              sourcePrefix: 'data',
            });
          } else {
            return <p>Choose parent configuration first.</p>;
          }
        } else {
          return null;
        }
      }}
    </FormDataConsumer>
  );
};

export default ConfigurationForm;
