import React, { FC, useState } from 'react';
import { NumberInput } from 'react-admin';
import { required, SaveButton } from 'react-admin';
import { TextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-final-form';

import BlockButton from '~/components/BlockButton';
import Drawer from '~/components/Drawer';
import { SimpleForm } from '~/components/ra';
import Toolbar from '~/components/ra/Toolbar';
import { useCreateBlock } from '~/hooks/apiIdentity';
import { useApiIdentityTranslate } from '..';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import useInterval from '~/hooks/useInterval';

interface Props {
  identityId: string;
}

const AddIdentityLock: FC<Props> = (props) => {
  const { identityId } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const createLockMutation = useCreateBlock();
  const handleSave = async (formData: any) => {
    const tillDate = getTillDate(formData);
    const lockIdentityRange = tillDate
      ? { from: moment().toISOString(), till: tillDate.toISOString() }
      : {};
    await createLockMutation.mutateAsync({
      identityId,
      params: { reason: formData.reason, ...lockIdentityRange },
    });
    handleClose();
  };
  const t = useApiIdentityTranslate();

  return (
    <>
      <BlockButton label={t('addLock')} red onClick={handleOpen} />
      <Drawer heading={t('addIdentityLock')} open={open} onClose={handleClose}>
        <SimpleForm
          toolbar={
            <Toolbar>
              <SaveButton onSave={handleSave} />
            </Toolbar>
          }
          saving={createLockMutation.isLoading}
        >
          <LockForm />
        </SimpleForm>
      </Drawer>
    </>
  );
};

const LockForm = () => {
  const t = useApiIdentityTranslate();
  const form = useForm();

  const [lockTillDate, setLockTillDate] = useState<string>();
  const handleLockTillDate = () => {
    setLockTillDate(getTillDate(form.getState().values)?.format('ll, LT'));
  };

  useInterval(handleLockTillDate, lockTillDate ? 1000 : null);

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <NumberInput
            label={t('days')}
            source='days'
            min={restrictions.days.min}
            max={restrictions.days.max}
            parse={parseDateTimeNumber(restrictions.days)}
            onChange={handleLockTillDate}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            label={t('hours')}
            source='hours'
            min={restrictions.hours.min}
            max={restrictions.hours.max}
            parse={parseDateTimeNumber(restrictions.hours)}
            onChange={handleLockTillDate}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberInput
            label={t('minutes')}
            source='minutes'
            min={restrictions.minutes.min}
            max={restrictions.minutes.max}
            parse={parseDateTimeNumber(restrictions.minutes)}
            onChange={handleLockTillDate}
          />
        </Grid>
      </Grid>
      <Alert severity='info' className={classes.bottomSpacing2}>
        Lock will be activated{' '}
        {lockTillDate ? (
          <>
            for period from confirmation till approximately{' '}
            <span className={classes.boldText}>{lockTillDate}</span>
          </>
        ) : (
          'forever'
        )}
      </Alert>
      <TextInput source='reason' multiline rows={5} rowsMax={20} validate={required()} fullWidth />
    </>
  );
};

const getTillDate = (formState: any = {}) => {
  const { days = 0, hours = 0, minutes = 0 } = formState;
  if (!parseInt(days) && !parseInt(hours) && !parseInt(minutes)) return;
  return moment().add(days, 'days').add(hours, 'hours').add(minutes, 'minutes');
};

const parseDateTimeNumber = ({ min, max }: { min: number; max: number }) => (value: string) => {
  const num = Math.abs(parseInt(value));
  if (num > max) return max;
  if (num >= min) return num;
  return min;
};

const restrictions = {
  days: {
    min: 0,
    max: 999,
  },
  hours: {
    min: 0,
    max: 23,
  },
  minutes: {
    min: 0,
    max: 59,
  },
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(3),
  },
  boldText: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));

export default AddIdentityLock;
