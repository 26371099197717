import React, { Fragment } from 'react';
import {
  useTranslate,
  NumberInput,
  SelectInput,
  FormDataConsumer,
  required,
  minValue,
  maxValue,
} from 'react-admin';
import { DateInput } from '~/components/ra';
import { AmountInput, ReferenceInput } from '~/components/ra';
import lodashGet from 'lodash/get';

import { ByFeature } from '..';
import { l } from '~/resources/pricings/PricingShow';
import { FeeLoyaltyDiscountType } from '~/api/pricings';
import { useTenantAccounts } from '~/hooks/tenant';

const oneDay = 24 * 60 * 60 * 1000;

interface Props {
  getSource: (key: string) => string;
  schema: ByFeature;
  currency: string;
}

interface BaseFormProps extends Props {
  formData: any;
}

const BaseForm = (props: BaseFormProps) => {
  const { getSource, formData } = props;
  const scopedFormData = lodashGet(formData, getSource('')) || {};

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`loyalty_.${key}`), 1);

  const { data: tenantAccounts } = useTenantAccounts();
  const discountSourceAccountCurrency = tenantAccounts?.find(
    (account) => account.id === scopedFormData.discountSourceAccountId
  )?.currency;
  const discountCurrencies = [
    { id: discountSourceAccountCurrency ?? '', name: discountSourceAccountCurrency ?? '' },
  ];

  return (
    <Fragment>
      <ReferenceInput
        source={getSource('loyaltySourceAccountId')}
        reference='tenant/accounts'
        label={t('loyaltySourceAccount')}
        validate={required()}
      >
        <SelectInput optionText='alias' />
      </ReferenceInput>
      <ReferenceInput
        source={getSource('discountSourceAccountId')}
        reference='tenant/accounts'
        label={t('discountSourceAccount')}
        validate={required()}
        filter={{ currency: discountSourceAccountCurrency }}
      >
        <SelectInput optionText='alias' />
      </ReferenceInput>
      <SelectInput
        source={getSource('discountType')}
        choices={[
          { id: 'FULL', name: t('discountType.FULL') },
          { id: 'PERCENT', name: t('discountType.PERCENT') },
          { id: 'FIXED', name: t('discountType.FIXED') },
        ]}
        label={t('discountType._')}
        validate={required()}
      />
      {(function () {
        switch (scopedFormData.discountType as FeeLoyaltyDiscountType | undefined) {
          case 'FULL':
            return null;
          case 'PERCENT':
            return (
              <NumberInput
                source={getSource('discountPercent')}
                label={t('discountPercent')}
                validate={[required(), minValue(0), maxValue(100)]}
              />
            );
          case 'FIXED':
            return (
              <AmountInput
                source={getSource('discountAmount')}
                amountLabel={t('discountAmount')}
                currencies={discountCurrencies}
                defaultValue={0}
                required
                fullWidth
              />
            );
          default:
            return null;
        }
      })()}
      <DateInput
        source={getSource('expiration')}
        label={t('expiration')}
        options={{ fullWidth: true, format: 'LL', clearable: true }}
        validate={(date: string) => {
          return date &&
            new Date(new Date(date).getTime() + oneDay).getTime() <= new Date().getTime()
            ? 'Expiration date should be today or in future'
            : '';
        }}
      />
    </Fragment>
  );
};

const FeeLoyaltyForm = (props: Props) => {
  const { getSource, schema, currency } = props;

  return (
    <FormDataConsumer>
      {({ formData }: any) => {
        return (
          <Fragment>
            <BaseForm
              getSource={getSource}
              currency={currency}
              schema={schema}
              formData={formData}
            />
          </Fragment>
        );
      }}
    </FormDataConsumer>
  );
};

export default FeeLoyaltyForm;
