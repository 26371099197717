import React, { Fragment } from 'react';
import { useTranslate, TextInput, required } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { StatusField } from '~/components';
import { HiddenInput, AmountInput, ModalOrSelectInput, ReferenceInput } from '~/components/ra';
import { Business, Terminal } from '~/types';

interface Props {
  businessRecord: Business;
  record?: any;
}

const PaymentDetailForm = (props: Props) => {
  const { businessRecord } = props;
  const { solutionId, id: businessId } = businessRecord;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.${key}`);

  return (
    <Fragment>
      <HiddenInput source='solutionId' defaultValue={solutionId} disabled />
      <HiddenInput source='businessId' defaultValue={businessId} disabled />
      <TextInput source='name' label={t('name')} validate={required()} fullWidth />
      <ReferenceInput
        source='terminalId'
        reference='terminals'
        filter={{ businessId: businessId }}
        label={t('terminal')}
        fullWidth
      >
        <ModalOrSelectInput<Terminal> optionText='label' validate={required()} fullWidth>
          {(item) => {
            return (
              <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                <Grid item>
                  <StatusField status={item.status} variant='dot' />
                </Grid>
                <Grid item>{item.label}</Grid>
              </Grid>
            );
          }}
        </ModalOrSelectInput>
      </ReferenceInput>
      <TextInput source='comment' label={t('comment')} fullWidth />
      <AmountInput positiveOnly nonNullable />
    </Fragment>
  );
};

export default PaymentDetailForm;
