/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

import { useLocale, useTranslate } from 'react-admin';

import i18n from '~/i18n';
import { CurrencySymbols } from '~/utils';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function currencyToSymbol(isoCode: string) {
  return (CurrencySymbols as { [key: string]: string })[isoCode] || undefined;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
  },
});

export interface Props {
  value?: string | null;
  label?: string;
  onChange?: (e: any) => void;
  required?: boolean;
  helperText?: string;
  error?: boolean;
  options?: string[];
  filterOptions?: (currencies: string[]) => string[];
  [key: string]: any;
}

const CurrencyAutocomplete = (props: Props) => {
  const {
    value,
    label,
    onChange,
    required,
    helperText,
    error,
    options,
    filterOptions,
    disabled,
    style,
    ...rest
  } = props;
  const classes = useStyles();
  const locale: keyof any = useLocale();

  const allCurrencies = (i18n as any)[locale].currencies;
  let currencies: any = {};
  if (options) {
    options.forEach((o) => {
      currencies[o] = allCurrencies[o];
    });
  } else {
    currencies = allCurrencies; // (i18n as any)[locale].countries;
  }

  const translate = useTranslate();

  return (
    <Autocomplete
      {...rest}
      options={
        typeof filterOptions === 'function'
          ? filterOptions(Object.keys(currencies))
          : Object.keys(currencies)
      }
      classes={{
        option: classes.option,
      }}
      disabled={disabled}
      autoHighlight
      getOptionLabel={(option) => {
        return option;
      }}
      renderOption={(option) => {
        return (
          <React.Fragment>
            <span style={{ minWidth: '50px' }}>{option}</span>
            <span style={{ opacity: 0.5 }}>{currencies[option]}</span>
            {'\u00A0'}
            <span>{currencyToSymbol(option)}</span>
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Choose a currency'}
          variant='filled'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          fullWidth
          required={required}
          helperText={helperText}
          error={error}
          style={style}
        />
      )}
      filterOptions={createFilterOptions({
        stringify: (option) => `${currencies[option]},${option}`,
      })}
      value={value}
      onChange={(e: any, option: any) => {
        onChange &&
          onChange({
            target: {
              name: props.name,
              value: option,
            },
          });
      }}
      noOptionsText={translate('utils.noHandbookValues')}
    />
  );
};

export default CurrencyAutocomplete;
