import React from 'react';
import { TextField } from 'react-admin';
import { get } from 'lodash';

const PrimitiveArrayField = ({ record, source, ...rest }) => (
  <React.Fragment>
    {(get(record, source) || []).map((value) => (
      <div key={value}>
        <TextField record={{ value }} source='value' {...rest} />
      </div>
    ))}
  </React.Fragment>
);
PrimitiveArrayField.defaultProps = { addLabel: true };

export default PrimitiveArrayField;
