import * as React from 'react';
import { StatusField, TextField } from '~/components';
import { Grid } from '@material-ui/core';

import ColoredField from './ColoredField';
import { CellProps } from './CellProps';
import { groupBy, isEmpty } from 'lodash';

const ListReportingStatusField = (props: CellProps<any>) => {
  const { rowData } = props;
  const reports = !isEmpty(rowData.details?.report)
    ? Object.entries(rowData.details.report!).map(([key, data]) => ({ key, ...data }))
    : undefined;
  const bySystem = groupBy(reports, 'system');
  const bySystemTuple = Object.entries(bySystem);

  return (
    <ColoredField {...props}>
      <Grid container wrap='nowrap' spacing={3}>
        {bySystemTuple.length
          ? bySystemTuple.map((reportTuple) => {
              const system = reportTuple && reportTuple[0];
              if (system === 'undefined') return null;
              const reportDataBySystem =
                reportTuple &&
                reportTuple[1].sort((a, b) => getMs(b.timestamp) - getMs(a.timestamp));
              const reportData =
                !isEmpty(reportDataBySystem) &&
                (reportDataBySystem[0] as typeof reportDataBySystem[0]);
              if (!reportData) return null;
              return (
                <Grid item key={reportData.key}>
                  <TextField compact label={reportData.key}>
                    <StatusField status={reportData?.status} />
                  </TextField>
                </Grid>
              );
            })
          : null}
      </Grid>
    </ColoredField>
  );
};

const getMs = (timestamp: string): number => {
  return new Date(timestamp).getTime();
};

export default ListReportingStatusField;
