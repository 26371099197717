import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import Grid from '@material-ui/core/Grid';

import { TextField, AmountField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { l } from '~/resources/pricings/PricingShow';
import { PricingFeeType } from '~/api/pricings';

const FeeField = (props: any) => {
  const { record = {}, tax } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  return (
    <Fragment>
      <Grid item xs={4}>
        <TextField label={tax ? t('taxType') : t('feeType')}>
          {record.type && t(record.type)}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField label={t('statementTitle')}>{record.name}</TextField>
      </Grid>
      <Grid item xs={4}>
        {record.pnlAccountId ? (
          <ReferenceField
            source='pnlAccountId'
            record={record}
            reference='tenant/accounts'
            basePath='/tenant/accounts'
            link={false}
            addLabel={false}
          >
            <RaTextField source='alias' label={t('pnlAccount')} ellipsis />
          </ReferenceField>
        ) : (
          <RaTextField label={t('pnlAccount')} allowEmpty />
        )}
      </Grid>
      {(() => {
        switch (record.type as PricingFeeType | undefined) {
          case 'fixedDebitFee':
          case 'fixedDebitFeeDecrease':
          case 'fixedCreditFee':
            return (
              <Grid item xs={4}>
                <TextField label={t('amount')}>
                  <AmountField amount={record.amount} />
                </TextField>
              </Grid>
            );
          case 'percentDebitFee':
          case 'percentDebitFeeDecrease':
          case 'percentCreditFee':
            return (
              <Fragment>
                <Grid item xs={4}>
                  <TextField label={t('percent', 2)}>
                    {record.percent?.toString() && `${record.percent}%`}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('minAmount')}>
                    <AmountField amount={record.minAmount} />
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('maxAmount')}>
                    <AmountField amount={record.maxAmount} />
                  </TextField>
                </Grid>
              </Fragment>
            );
          default:
            return null;
        }
      })()}
      {(record.index || record.index === 0) && (
        <Grid item xs={4}>
          <TextField label={t('index')}>{record.index}</TextField>
        </Grid>
      )}
    </Fragment>
  );
};

const DefaultFeeView = (props: any) => {
  const { record, tax } = props;
  return (
    <Grid item container spacing={2}>
      <FeeField record={record} tax={tax} />
    </Grid>
  );
};

export default DefaultFeeView;
