import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, closeNotification, Notification } from '~/ducks/snackbarNotifications';

type NotificationHook = [Notification | undefined, any];

const SnackbarNotifications = () => {
  const [isOpen, setOpen] = useState(false);
  const [notificationData, setNotificationData]: NotificationHook = useState();

  const dispatch = useDispatch();
  const notificationToShow = useSelector(selectors.getNotificationToShow());

  useEffect(() => {
    if (notificationToShow) {
      setOpen(true);
      setNotificationData({ ...notificationToShow });
    } else {
      setOpen(false);
    }
  }, [notificationToShow]);

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      if (notificationData?.disableClickawayClose) return;
      dispatch(closeNotification());
      return;
    }

    dispatch(closeNotification());
  };

  if (notificationData) {
    const { message, severity, autoHideDuration } = notificationData;
    return (
      <Snackbar open={isOpen} autoHideDuration={autoHideDuration} onClose={handleClose}>
        <MuiAlert elevation={6} variant='filled' severity={severity}>
          {message}
        </MuiAlert>
      </Snackbar>
    );
  } else return null;
};

export default SnackbarNotifications;
