import React, { useState, Fragment } from 'react';
import { useTranslate, TextInput, required } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';

export interface SolutionEditProps {
  resource?: string;
  record?: any;
  permissions?: string[];
}

const SolutionEdit = (props: SolutionEditProps) => {
  const { resource, record, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.fields.${key}`);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes('solution.update')} />
      <Drawer heading={t('editSolution')} open={open} onClose={handleClose}>
        {resource && record && (
          <EditForm resource={resource} record={record}>
            <TextInput source='name' label={t('name')} validate={required()} fullWidth />
          </EditForm>
        )}
      </Drawer>
    </Fragment>
  );
};

export default SolutionEdit;
