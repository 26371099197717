import React from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';

import { TextField as TextFieldComponent } from '~/components';
import { ShowComponent } from '~/components/TextField';
import PassProps from './PassProps';
import { Longdash } from '~/utils';
import i18n from '~/i18n/en';

type Children = JSX.Element | JSX.Element[] | string;

interface Props {
  [x: string]: any;
  record?: any;
  source?: string;
  label?: string;
  icon?: JSX.Element;
  children?: Children;
  title?: string;
  multiline?: boolean;
  textWrap?: boolean;
  showComponent?: (props: ShowComponent) => JSX.Element;
  addLabel?: boolean;
  emptyText?: string;
  allowEmpty?: boolean;
  showLink?: string | ((record: any) => string);
  fn?: (record: any) => string | undefined;
  style?: any;
  ellipsis?: boolean;
}

const TextField = (props: Props) => {
  const {
    record,
    source,
    label,
    icon,
    children,
    title,
    multiline,
    textWrap,
    showComponent,
    addLabel,
    allowEmpty,
    emptyText = Longdash,
    showLink,
    fn,
    style,
    ellipsis,
    ...rest
  } = props;

  const data = fn ? fn(record) : lodashGet(record, source || '');
  let result = null;

  if (data || typeof data === 'number') {
    result = data.toString();
  } else if (children) {
    result = (
      <PassProps record={record} {...rest}>
        {children as Children}
      </PassProps>
    );
  }

  if (allowEmpty && !data) {
    result = emptyText;
  }

  const translate = useTranslate();

  var actualShowLink: string | undefined = '';
  if (typeof showLink === 'function') {
    actualShowLink = showLink(record);
  } else {
    actualShowLink = showLink;
  }

  return (
    <TextFieldComponent
      label={lodashGet(i18n, label || '') ? translate(label) : label || source}
      icon={icon}
      title={title}
      multiline={multiline}
      textWrap={textWrap}
      record={record}
      showComponent={showComponent}
      addLabel={addLabel}
      showLink={actualShowLink}
      style={style}
      ellipsis={ellipsis}
    >
      {result}
    </TextFieldComponent>
  );
};

export default TextField;
