import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { time } from '~/utils';
import cx from 'classnames';

import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import { TextField, Drawer } from '~/components';
import { PersonCircleIcon } from '~/img';
import NoteForm from './NoteForm';
import { Note, NotesProfileType } from '~/api/notes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '100%',
  },
  editable: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  leftSpacing: {
    marginLeft: theme.spacing(4),
  },
  text: {
    '& *': {
      fontWeight: 400,
    },
  },
}));

interface Props {
  referenceId?: string;
  updatedAt?: string;
  createdAt?: string;
  createdBy?: string;
  updatedName?: string;
  creatorName?: string;
  text?: string;
  loading?: boolean;
  profileType: NotesProfileType;
  className?: string;
}

const NoteCard = (props: Props) => {
  const { loading, profileType, className, ...note } = props;
  const { referenceId, updatedAt, createdAt, updatedName, creatorName, text, createdBy } = note;
  const isEditable = referenceId && JSON.parse(localStorage.identity).id === createdBy;

  const [open, setOpen] = useState(false);
  const handleOpen = () => referenceId && setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(`components.Notes.${key}`);

  const classes = useStyles();

  return (
    <Fragment>
      <Paper
        className={cx(classes.root, { [classes.editable]: isEditable }, className)}
        onClick={handleOpen}
      >
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <TextField
              label={time(updatedAt || createdAt).format('LL LT')}
              icon={<PersonCircleIcon />}
              loading={loading}
            >
              {updatedName || creatorName}
            </TextField>
          </Grid>
          <Grid item className={cx(classes.leftSpacing)}>
            {loading ? (
              <Skeleton variant='rect' width='100%' height={65} />
            ) : (
              <TextField
                addLabel={false}
                className={classes.text}
                multiline={(text?.length || 0) > 140}
                textWrap
              >
                {text}
              </TextField>
            )}
          </Grid>
        </Grid>
      </Paper>
      {isEditable && (
        <Drawer heading={t('editNote')} open={open} onClose={handleClose}>
          <NoteForm
            referenceId={referenceId as string}
            profileType={profileType}
            initialValues={note as Note}
            handleClose={handleClose}
          />
        </Drawer>
      )}
    </Fragment>
  );
};

export default NoteCard;
