import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { EventFilters, EventType, TaskAction } from '~/types/Event';
import { Entity } from '~/types/Entity';

export type Response = { cursors: { next?: string }; events: EventType[] };

export const getEvents = (params: EventFilters): Promise<{ data: Response }> => {
  return axios.get<Response>(urlWithParams('/events', params));
};

export const getIndividualEvents = (
  actorId: string,
  params: EventFilters
): Promise<{ data: Response }> => {
  return axios.get<Response>(urlWithParams(`/individuals/${actorId}/events`, params));
};

export const getBusinessesEvents = (
  actorId: string,
  params: EventFilters
): Promise<{ data: Response }> => {
  return axios.get<Response>(urlWithParams(`/businesses/${actorId}/events`, params));
};

export const getPartnersEvents = (
  actorId: string,
  params: EventFilters
): Promise<{ data: Response }> => {
  return axios.get<Response>(urlWithParams(`/partners/${actorId}/events`, params));
};

export const getExternalProfileLinksEvents = (
  actorId: string,
  params: EventFilters
): Promise<{ data: Response }> => {
  return axios.get<Response>(urlWithParams(`/externalProfileLinks/${actorId}/events`, params));
};

export type EntitiesIdRequest = { [entityType: string]: string[] };
export type EntityRequest = { [id: string]: Entity };
export const getEntities = (params: EntitiesIdRequest): Promise<{ data: EntityRequest }> => {
  return axios.post<EntityRequest>('/objects/collect', params);
};

export const getTaskTypes = (): Promise<{ data: string[] }> => {
  return axios.get<string[]>('/events/tasks/types');
};

export const getTaskActions = (eventId: string) => {
  return axios.get<TaskAction[]>(`/events/${eventId}/actions`).then((res) => res.data);
};

export const runManualAction = (params: any) => {
  const { formData, eventId } = params;
  return axios.post(`/events/${eventId}/actions`, formData);
};
