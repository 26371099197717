export default {
  status: 'Status',
  operationType: 'Operation type',
  createdAt: 'Transaction date',
  headTransaction: 'Head transaction',
  headTransactionId: 'Head transaction ID',
  id: 'ID',
  description: 'Description',
  merchantCategoryCode: 'Merchant category code',
  sender: 'Sender',
  recipient: 'Recipient',
  account: 'Account',
  customer: 'Customer',
  sentAmount: 'Instructed amount',
  amount: 'Transfer amount',
  baseAmount: 'Base amount',
  totalAmount: 'Total amount',
  postedAmount: 'Posted amount',
  instructedAmount: 'Instructed amount',
  beneficiaryAmount: 'Beneficiary amount',
  convertedAmount: 'Converted amount',
  recievedAmount: 'Posted amount',
  exchangeRate: 'Exchange rate',
  feesAndTaxes: 'Fees and taxes',
  additionalData: 'Additional data',
  consentId: 'Consent ID',
  deal: 'Deal',
  comment: 'Comment',
  authentication: 'Authentication',
  device: 'Device data',
  type: 'Type',
  amountTable: 'Amount',
  amounts: 'Amounts',
  FEE: 'Fee',
  TAX: 'Tax',
  ACQUIRING_FEE: 'Acquiring fee',
  TRANSACTION: 'Transaction',
  serviceProvider: 'Service Provider',
  period: 'Period',
  partner: 'Partner',
  locationAddress: 'Location address',
  location: 'Location',
  paymentToken: 'Payment token',
  terminal: 'Terminal',
  accountNumber: 'Account number',
  tenant: 'Tenant',
  individual: 'Individual',
  business: 'Business',
  employee: 'Employee',
  //
  instruction: 'Instruction',
  transactionResult: 'Transaction result',
  party: 'Party',
  partyAccount: 'Party account',
  counterparty: 'Counter-party',
  counterpartyAccount: 'Counter-party account',
  postingDate: 'Posting date',
  senderName: 'Sender name',
  recipientPhoneNumber: 'Recipient phone number',
  recipientName: 'Recipient name',
  referenceNumber: 'Reference Number',
  // payer
  payerAccountNumber: 'Payer account number',
  payerName: 'Payer name',
  payerBank: 'Bank',
  // service
  service: 'Payment Service',
  paymentType: 'Payment Type',
  region: 'Region',
  supplierId: 'Supplier ID',
  partnerId: 'Partner ID',
  serviceName: 'Service name',
  consumerIdentifier: 'Consumer identifier',
  response: 'Response',
  externalSource: {
    _: 'External source',
    name: 'Name',
    type: 'Type',
  },
  operationSubtype: 'Operation subtype',
};
