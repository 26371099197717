import axios from '~/utils/axios';
import { urlWithParams } from '~/utils';
import { Individual } from '~/types';
import { IndividualFilters } from '~/types/individual';

type GetIndividualsResponse = {
  cursors: {
    next?: string;
  };
  individuals?: Individual[];
};

interface GetIndividualsProps {
  next?: string | null;
  filters?: IndividualFilters;
}

export const getIndividuals = (props: GetIndividualsProps) => {
  const { filters, next } = props;
  const url = `/individuals`;

  return axios.get<GetIndividualsResponse>(
    urlWithParams(url, { byCursor: true, next, limit: 50, ...filters })
  );
};

export const getIndividualById = (individualId: string) => {
  return axios.get<Individual>(`/individuals/${individualId}`).then((res) => res.data);
};

export const updatePhoneNumber = (individualId: string, phoneNumber: string, note: string) => {
  return axios.post(`/individuals/${individualId}/updatePhoneNumber`, {
    phoneNumber,
    note,
  });
};

export const checkPhoneNumberUpdate = (individualId: string, phoneNumber: string) => {
  return axios.post(`/individuals/${individualId}/checkPhoneNumberUpdate`, {
    phoneNumber,
  });
};

export const blockIndividual = (params: { id: string; note: string }) => {
  const { id, note } = params;
  return axios
    .post(`/individuals/${id}/block`, {
      note,
    })
    .then((res) => res.data);
};

export const unblockIndividual = (params: { id: string; note: string }) => {
  const { id, note } = params;
  return axios
    .post(`/individuals/${id}/unblock`, {
      note,
    })
    .then((res) => res.data);
};
