import React, { useEffect, useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell, AmountField } from '~/components';

import { PricingSchema } from '~/api/pricings';
import { Pricing, Product } from '~/types';
import { ByFeature } from './PerFeature';
import { l } from '../PricingShow';
import { useApi, useFeatures } from '~/hooks';
import { Longdash } from '~/utils';
import PricingShowDrawer from './PricingShowDrawer';
import { getProductById } from '~/api/product';

export type ByLimit = {
  data?: { [x: string]: any };
} & ByFeature;

interface Props {
  pricingSchema: PricingSchema | undefined;
  record: Pricing | undefined;
  loading: boolean;
}

const PerLimits = (props: Props) => {
  const { pricingSchema, record, loading } = props;

  const [{ data: productData }] = useApi<Product>(() => getProductById(record?.productId || ''), [
    record?.productId,
  ]);
  const currency = productData?.currency || '';

  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  const classes = useStyles();

  const { list: featuresList, loading: featuresLoading } = useFeatures();

  // format received data by Limit
  const [byLimit, setByLimit] = useState<ByLimit[]>([]);

  useEffect(() => {
    if (pricingSchema && record) {
      const properties = pricingSchema.properties;
      const result: ByLimit[] = [];
      for (let key in properties) {
        const prop = properties[key];
        if (prop['v:category'] !== 'limit') continue;
        result.push({
          ...prop,
          propertyKey: key,
          data: record.data[key],
        });
      }
      const sorted = result.sort((a, b) => (a['v:index'] || 0) - (b['v:index'] || 0));
      setByLimit(sorted);
    }
  }, [pricingSchema, record]);

  const getConfigurationText = (byLimit: ByLimit) => {
    if (!byLimit) return Longdash;
    switch (byLimit.type) {
      case 'veengu_limit_default_amount':
      case 'veengu_limit_single_operation_amount': {
        const value =
          byLimit.data?.amount?.value === undefined || byLimit.data?.amount?.value === null
            ? byLimit['v:default']
            : byLimit.data?.amount?.value;
        return value !== undefined || value !== null ? (
          <span className={classes.configurationText}>
            <AmountField amount={{ currency, value }} />
          </span>
        ) : (
          Longdash
        );
      }
      case 'veengu_limit_default_count': {
        const value =
          byLimit.data?.count === undefined || byLimit.data?.count === null
            ? byLimit['v:default']
            : byLimit.data?.count;
        return `${value || ''}`;
      }
      default:
        return null;
    }
  };

  // Main state
  const [state, setState] = useState<any>(record?.data || {});

  const [showPropertyKey, setShowPropertyKey] = useState<string | null>(null);
  const handleShowOpen = (propertyKey: string) => setShowPropertyKey(propertyKey);
  const handleShowClose = () => setShowPropertyKey(null);

  return (
    <Fragment>
      {record && showPropertyKey && (
        <PricingShowDrawer
          pricingDataEditProps={{
            pricingRecord: record,
            record: state,
            schema: {} as any, // PricingShowDrawer add this prop by itself
            setMainState: setState,
            currency: currency,
          }}
          propertyKey={showPropertyKey}
          open={!!showPropertyKey}
          record={byLimit}
          onClose={handleShowClose}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('fields.name')}</TableCell>
            <TableCell>{t('feature')}</TableCell>
            <TableCell>{t('configuration')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {byLimit.map((item, index) => {
            return (
              <TableRow
                key={index}
                hover
                onClick={() => handleShowOpen(item.propertyKey)}
                className={classes.pointer}
              >
                <TableCell>{item['v:name']}</TableCell>
                <TableCell loading={featuresLoading}>{featuresList[item['v:feature']]}</TableCell>
                <TableCell>{getConfigurationText(item)}</TableCell>
              </TableRow>
            );
          })}
          {loading &&
            [{}, {}, {}].map((_, index) => {
              return (
                <TableRow key={index}>
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                  <TableCell loading={loading} />
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  configurationText: {
    display: 'inline-flex',
    '& > b': {
      fontWeight: 500,
    },
    '& > *': {
      marginRight: 4,
      display: 'inline-flex',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export default PerLimits;
