// based on https://www.npmjs.com/package/react-admin-date-inputs2

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addField, FieldTitle } from 'ra-core';
import { withTranslate } from 'react-admin';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const styles = {
  root: {
    marginBottom: 7,
  },
};

const makePicker = (PickerComponent) => {
  class _makePicker extends Component {
    onChange(date) {
      this.props.input.onChange(date);
      this.props.input.onBlur();
    }

    render() {
      const {
        input,
        options,
        label,
        source,
        resource,
        isRequired,
        className,
        meta,
        providerOptions,
        disabled,
        helperText,
        classes,
      } = this.props;

      const { touched, error } = meta;

      return (
        <MuiPickersUtilsProvider {...providerOptions}>
          <PickerComponent
            inputVariant='filled'
            format='L'
            {...options}
            label={
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
            }
            margin='dense'
            error={!!(touched && error)}
            helperText={
              helperText === undefined
                ? (touched && error && this.props.translate(error)) || ' '
                : helperText
            }
            className={cx(className, classes.root)}
            value={input.value ? input.value : null}
            onChange={(date) => this.onChange(date)}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
        // <div className="picker">
        // </div>
      );
    }
  }
  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
    disabled: PropTypes.bool,
  };

  _makePicker.defaultProps = {
    input: {},
    isRequired: 'false',
    meta: { touched: false, error: false },
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
    providerOptions: {
      utils: MomentUtils,
      locale: undefined,
    },
  };
  return withStyles(styles)(_makePicker);
};

export const DateInput = addField(withTranslate(makePicker(DatePicker)));
DateInput.defaultProps = {
  parse: (x) => {
    return x ? x.format('YYYY-MM-DD') : null;
  },
};

export const TimeInput = addField(makePicker(TimePicker));

export const DateTimeInput = addField(makePicker(DateTimePicker));
DateTimeInput.defaultProps = {
  parse: (x) => {
    return x ? x.toISOString() : x;
  },
};
