export default {
  name: 'Deal |||| Deals',
  subtitle: 'All deals',
  list: {
    title: 'Deals',
    sender: 'Sender',
    recipient: 'Recipient',
    created: 'Date and time',
    remitter: 'Remitter',
    remitterAmount: 'Remitter amount',
    beneficiary: 'Beneficiary',
    beneficiaryAmount: 'Beneficiary amount',
    operationType: 'Operation type',
    status: 'Status',
    filters: {
      from: 'Created from',
      to: 'Created to',
      senderAccountNumber: 'Sender (account number)',
      recipientAccountNumber: 'Recipient (account number)',
      currency: 'Beneficiary currency',
      amountFrom: 'Beneficiary amount from',
      amountTo: 'Beneficiary amount to',
    },
  },
  tabs: {
    details: 'Details',
    remitter: 'Remitter',
    beneficiary: 'Beneficiary',
  },
  recordTitle: 'Transfer',
  show: {
    details: {
      details: 'Details',
      routingDetails: 'Routing details',
      operationType: 'Operation type',
      senderCountry: 'Sender country',
      sentFrom: 'Sent from',
      recipientCountry: 'Recipient country',
      sentTo: 'Sent to',
      createdAt: 'Created at',
      dealAmount: 'Deal amount',
      remitterAmount: 'Remitter amount',
      beneficiaryAmount: 'Beneficiary amount',
      status: 'Status',
      parties: 'Parties',
      senderActor: 'Sender',
      recipientActor: 'Recipient',
      account: 'Account',
      sentAt: 'Sent at',
      noRecipient: 'Recipient party is not known yet.',
      remitter: 'Remitter',
      name: 'Name',
      phone: 'Phone',
      beneficiary: 'Beneficiary',
      profileKycStatus: 'KYC status',
      remitterStatus: 'KYC status',
      beneficiaryStatus: 'KYC status',
      individual: 'Individual',
      business: 'Business',
      partner: 'Partner',
      tenant: 'Tenant',
      financialSummary: 'Financial summary',
      senderAccount: 'Veengu sender account',
      recipientAccount: 'Veengu recipient account',
      showCode: 'Deal Code Request',
      showCodeAttention1: `Attention! Sensitive data.`,
      showCodeAttention2: `Please do not share this code with anyone!`,
      hideCode: 'Hide deal code',
      dealCode: 'Deal code',
      reason: 'Reason',
      receivedAt: 'Received at',
      parameter: 'Parameter',
      payer: 'Payer (remitter)',
      additionalProperties: {
        _: 'Additional data',
        empty: 'No additional properties defined.',
        badSchema:
          "Additional properties schema is not provided or invalid. Check 'Deal' feature configuration of remitter's solution.",
      },
      purposeOfTransaction: 'Purpose of transaction',
      sourceOfFunds: 'Source of funds',
      comment: 'Comment',
      reportingCategory: 'Reporting category',
      transaction: {
        created: 'Date and time',
        postedAt: 'Posted at',
        baseAmount: 'Base amount',
        senderAccount: 'Sender account',
        recipientAccount: 'Recipient account',
        operationType: 'Operation type',
        status: 'Status',
      },
      accountNumber: 'Account number',
      financial: {
        code: 'Financial institution code',
        country: 'Financial institution country',
        name: 'Financial institution name',
        address: 'Financial institution address',
      },
      noData: 'No data.',
    },
    beneficiary: {
      sender: 'Sender',
      remitter: 'Remitter',
      beneficiary: 'Beneficiary',
      editPersonalInfo: 'Edit personal info',
      editDocument: 'Edit document',
      editContactsAndAddress: 'Edit contacts and addresses',
      recipient: 'Recipient',
      profileInformation: 'Profile Information',
      firstName: 'First name',
      lastName: 'Last name',
      birthDate: 'Birth date',
      contactsAndAddress: 'Contacts and address',
      address: 'Address',
      contacts: 'Contacts',
      phone: 'Phone',
      type: 'Type',
      documents: 'Documents',
      additionalData: 'Additional data',
      noData: 'No data',
      countryOfResidence: 'Country of residence',
      originatorData: 'Payer data',
      recipientData: 'Payee data',
      individualData: 'Individual data',
      typeNotDefined: 'Type is not defined',
      INDIVIDUAL: 'Individual',
      BUSINESS: 'Business',
      PARTNER: 'Partner',
      gender: 'Gender',
      MALE: 'Male',
      FEMALE: 'Female',
      X: 'X',
      mainAddress: 'Main address for beneficiary %{firstName} %{lastName}',
    },
  },
};
