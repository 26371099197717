import React from 'react';
import { useCreateController } from 'react-admin';
import { CreateContextProvider } from 'react-admin';

import CreateAndEditForm, { CreateAndEditFormPropsBase } from '~/components/ra/CreateAndEditForm';

function CreateForm<Record>(props: CreateAndEditFormPropsBase<Record>) {
  const { children, ...rest } = props;

  const createControllerProps = useCreateController({
    basePath: '',
    ...props,
  });

  return (
    <CreateContextProvider value={createControllerProps}>
      <CreateAndEditForm {...rest} type='create'>
        {children}
      </CreateAndEditForm>
    </CreateContextProvider>
  );
}

export default CreateForm;
