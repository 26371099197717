import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslate, ReferenceField, Button } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Alert, Skeleton } from '@material-ui/lab';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { RibbonIcon } from '~/img';
import { Drawer, TextField, H3, AmountField } from '~/components';
import { DeleteButton, TextField as CustomRaTextField } from '~/components/ra';
import TerminalShow from '~/resources/terminals/TerminalShow';
import axios from '~/utils/axios';
import PaymentDetailEdit from './PaymentDetailsEdit';
import FileSaver from 'file-saver';
import { PaymentDetail, Business, Terminal } from '~/types';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  topSpacing: {
    marginTop: theme.spacing(2),
  },
  image: {
    width: '100%',
    boxShadow: theme.shadows[3],
  },
}));

interface Props {
  showData: PaymentDetail | null;
  onClose: () => void;
  businessRecord: Business;
  open: boolean;
  parentProps: any;
  permissions: string[];
}

const PaymentDetailShow = (props: Props) => {
  const { showData, onClose, businessRecord, open, parentProps, permissions } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.${key}`);

  const [QRdata, setQRdata] = useState<any>(null);

  useEffect(() => {
    if (showData && open) {
      axios
        .get(`/paymentDetails/${showData.id}/qrcode`, { responseType: 'blob' })
        .then((response) => {
          setQRdata(response.data);
        })
        .catch((err) => console.log(err));
    }
  }, [open, showData]);

  const downloadQR = () => {
    if (QRdata) {
      FileSaver.saveAs(QRdata, `${showData?.name || 'qr'}.png`);
    }
  };

  const classes = useStyles();

  return (
    <Drawer heading={showData?.name || ''} open={open} onClose={onClose}>
      {showData && (
        <Fragment>
          <div className={classes.bottomSpacing}>
            <TextField label='id' icon={<RibbonIcon />}>
              {showData.id}
            </TextField>
          </div>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <TextField label={t('amount')}>
                <AmountField amount={showData.amount} />
              </TextField>
            </Grid>
            <Grid item>
              <ReferenceField
                {...parentProps}
                record={showData}
                source='terminalId'
                label={t('terminal')}
                reference='terminals'
                link={false}
              >
                <CustomRaTextField
                  label={t('terminal')}
                  source='label'
                  showComponent={(props) => {
                    const { open, handleClose } = props;
                    const terminalRecord: Terminal = props.record;
                    return (
                      <TerminalShow
                        permissions={permissions}
                        showData={open ? terminalRecord : null}
                        businessRecord={businessRecord}
                        onClose={handleClose}
                        parentProps={parentProps}
                      />
                    );
                  }}
                />
              </ReferenceField>
            </Grid>
            <Grid item>
              <TextField label={t('comment')}>{showData?.comment}</TextField>
            </Grid>
          </Grid>
          <H3 icon={<PhotoLibraryIcon />}>{t('qrCode')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item xs={10}>
              <ReferenceField
                record={showData}
                source='terminalId'
                reference='terminals'
                link={false}
              >
                <TerminalProvider>
                  {(record: Terminal) =>
                    record.status !== 'ACTIVE' ? (
                      <Alert severity='warning'>{t('inactiveTerminalAlert')}</Alert>
                    ) : QRdata ? (
                      <img src={URL.createObjectURL(QRdata)} alt='qr' className={classes.image} />
                    ) : (
                      <Skeleton variant='rect' width={266} height={266} />
                    )
                  }
                </TerminalProvider>
              </ReferenceField>
            </Grid>
          </Grid>

          <Grid container item spacing={2} justify='flex-end' className={classes.topSpacing}>
            <Grid item>
              <DeleteButton
                resource='paymentDetails'
                target='businesses'
                targetId={businessRecord.id}
                record={showData}
                disabled={!permissions.includes('business.paymentDetails.delete')}
                onClose={onClose}
              />
            </Grid>
            <Grid item>
              <ReferenceField
                record={showData}
                source='terminalId'
                reference='terminals'
                link={false}
              >
                <TerminalProvider>
                  {(record: Terminal) => (
                    <Button
                      label={t('downloadQr')}
                      onClick={downloadQR}
                      disabled={!QRdata || record?.status !== 'ACTIVE'}
                    >
                      <SaveAltIcon />
                    </Button>
                  )}
                </TerminalProvider>
              </ReferenceField>
            </Grid>
            <Grid item>
              <PaymentDetailEdit
                permissions={permissions}
                paymentDetailRecord={showData}
                businessRecord={businessRecord}
                closeParent={onClose}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Drawer>
  );
};

const TerminalProvider = ({ record, children }: PropsWithChildren<any>) => {
  return children(record);
};

export default PaymentDetailShow;
