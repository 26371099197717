import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { TextField, StatusField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { PersonCircleIcon as IndividualsIcon } from '~/img';
import { AsideBase } from '~/layout';
import { useApiIdentitiesT } from '../utils';
import { useIndividualById } from '~/hooks/individuals';
import { buildName } from '~/utils';
import { KycLevelField } from '~/components/ra';
import GoToProfile from './GoToProfile';

interface Props {
  profileId?: string;
  loading?: boolean;
}

const LinkedIndividualAside: FC<Props> = (props) => {
  const { profileId, loading } = props;

  const individualQuery = useIndividualById(profileId);
  const classes = useStyles();
  const t = useApiIdentitiesT();

  const resultLoading = loading || individualQuery.isLoading;

  return (
    <AsideBase>
      <Grid container spacing={1} className={classes.layout}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={t('INDIVIDUAL')}
              icon={
                <SvgIcon>
                  <IndividualsIcon />
                </SvgIcon>
              }
              loading={resultLoading}
              ellipsis
              noPaddingLeft
            >
              {buildName('individuals', individualQuery.data)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('status')} loading={resultLoading} ellipsi>
              <StatusField status={individualQuery.data?.status} />
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('kycLevel')} loading={resultLoading} ellipsis>
              {individualQuery.data?.kycLevel && (
                <KycLevelField
                  record={individualQuery.data as any}
                  source='kycLevel'
                  type='text'
                  addLabel={false}
                />
              )}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <ReferenceField
              record={individualQuery.data}
              source='solutionId'
              reference='solutions'
              link={false}
              addLabel={false}
              basePath='/'
            >
              <RaTextField source='name' label={t('solution')} loading={resultLoading} ellipsis />
            </ReferenceField>
          </Grid>
        </Grid>
        <Grid container item justify='flex-end'>
          {profileId && (
            <Grid item>
              <GoToProfile profileType='INDIVIDUAL' profileId={profileId} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </AsideBase>
  );
};

const useStyles = makeStyles((theme) => ({
  layout: {
    marginLeft: theme.spacing(3),
  },
  active: {
    color: theme.palette.success.main,
    fontWeight: 500,
  },
}));

export default LinkedIndividualAside;
