import * as React from 'react';
import { useState } from 'react';
import { usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { MaterialTable } from '~/components';

import * as hb from '~/types/Handbook';
import { validateId } from './utils';

import Localizations from './Localizations';
import LocalizationAutocomplete from './LocalizationAutocomplete';

const LocalizationTable = ({
  handbook,
  setState,
}: {
  handbook: Partial<hb.Handbook>;
  setState: hb.SetState;
}) => {
  const allLocalizations = Localizations;
  const localizations: hb.GenericRecord[] = (handbook.localizations || []).slice();

  const isValid = (
    newRecord: hb.GenericRecord,
    oldRecord: hb.GenericRecord | undefined = undefined
  ) => !validateId(localizations, newRecord.id, (oldRecord || {}).id, true);

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Localization',
          field: 'id',
          required: true,
          validate: validateId,
          render: (rowData: hb.GenericRecord) => (
            <>
              {allLocalizations[rowData.id]} ({rowData.id})
            </>
          ),
          width: 400,
        },
      ]}
      components={{
        Container: (props: any) => <Paper variant='outlined'>{props.children}</Paper>,
        EditField: (props: any) => {
          const [initialState] = useState(props.value);

          const errorMessage =
            (props.columnDef.validate
              ? props.columnDef.validate(localizations, props.value, initialState)
              : null) || '';
          return (
            <LocalizationAutocomplete
              value={props.value || ''}
              label={props.columnDef.title}
              onChange={(event) => {
                props.onChange(event.target.value);
              }}
              required={props.columnDef.required}
              helperText={errorMessage || '\u00A0'}
              error={errorMessage.length > 0}
            />
          );
        },
      }}
      data={localizations}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newRecord: hb.GenericRecord) => {
            if (isValid(newRecord)) {
              newRecord.name = allLocalizations[newRecord.id];
              localizations.push(newRecord);
              return setState({ localizations });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newRecord: hb.GenericRecord, oldRecord: hb.GenericRecord) => {
            if (isValid(newRecord, oldRecord)) {
              newRecord.name = allLocalizations[newRecord.id];
              const index = localizations.indexOf(oldRecord);
              localizations[index] = newRecord;
              return setState({ localizations });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldRecord: hb.GenericRecord) => {
            const index = localizations.indexOf(oldRecord);
            localizations.splice(index, 1);
            return setState({ localizations });
          },
        }
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default LocalizationTable;
