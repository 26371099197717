import React, { forwardRef, useState } from 'react';
import MaterialTable from 'material-table';

import { Chip, Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { SelectInput } from '~/components';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  TextField: {
    marginTop: 13,
    marginBottom: -4,
    '& input': {
      height: 21,
    },
  },
  MaterialTable: {
    '& .MuiTableCell-root': {
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const makeEditField = (currentTableData) => {
  const EditField = (props) => {
    const [initialState] = useState(props.value);
    const [value, setValue] = useState(props.value);

    const classes = useStyles();

    const [isCheckedOnce] = useState(
      (() => {
        // make checked field editable
        if (props.value) return false;
        const checkedFields = currentTableData.filter((item) => {
          return item[props.columnDef.field];
        });
        return Boolean(checkedFields.length);
      })()
    );

    const type = props.columnDef.type || 'text';

    const errorMessage =
      (props.columnDef.validate
        ? props.columnDef.validate(currentTableData, props.value, initialState)
        : null) || '';

    if (type === 'boolean') {
      return (
        <Checkbox
          checked={props.value || false}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          disabled={props.columnDef.unique === true ? isCheckedOnce : false}
        />
      );
    } else if (type === 'select') {
      const { options, title, label, defaultValue, initialEditValue } = props.columnDef;
      console.log(`props: `, props);
      return (
        <SelectInput
          options={options}
          label={label || title}
          value={props.value}
          defaultValue={defaultValue || initialEditValue}
          required={props.columnDef.required}
          onChange={(e) => {
            // setValue(e.target.value);
            props.onChange(e.target.value);
            if (e.key === 'Enter') {
              e.stopPropagation();
            }
          }}
          disabled={
            typeof props.columnDef.disabled === 'function' && props.columnDef.disabled(props)
          }
        />
      );
    } else {
      const { title, required } = props.columnDef;
      if (props.columnDef.enum) {
        return (
          <Autocomplete
            multiple
            options={props.columnDef.enum.options || []}
            freeSolo
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({ index })} size='small' label={option} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                margin='dense'
                required={required}
                helperText={errorMessage || '\u00A0'}
                error={errorMessage.length > 0}
                className={classes.TextField}
                fullWidth
                disabled={
                  typeof props.columnDef.disabled === 'function' && props.columnDef.disabled(props)
                }
              />
            )}
            value={value || []}
            onChange={(e, option) => {
              setValue(option);
              props.onChange && props.onChange(option);
              if (e.key === 'Enter') {
                e.stopPropagation();
              }
            }}
          />
        );
      } else {
        return (
          <TextField
            type={type === 'numeric' ? 'number' : 'text'}
            margin='dense'
            value={props.value || ''}
            label={title}
            onChange={(event) => {
              let value = event.target.value;
              if (value && type === 'numeric') {
                value = parseFloat(event.target.value);
              }
              props.onChange(value);
            }}
            required={required}
            helperText={errorMessage || '\u00A0'}
            className={classes.TextField}
            error={errorMessage.length > 0}
            disabled={
              typeof props.columnDef.disabled === 'function' && props.columnDef.disabled(props)
            }
          />
        );
      }
    }
  };
  return EditField;
};

const MaterialTableWrapper = (props) => {
  const currentTableData = props.data || [];

  const classes = useStyles();

  return (
    <div className={classes.MaterialTable}>
      <MaterialTable
        icons={tableIcons}
        {...props}
        components={{
          EditField: makeEditField(currentTableData),
          ...(props.components || {}),
        }}
        options={{
          ...(props.options || {}),
        }}
      />
    </div>
  );
};

export default MaterialTableWrapper;
