import React from 'react';
import {
  useTranslate,
  NumberInput,
  TextInput,
  SelectInput,
  FormDataConsumer,
  required,
} from 'react-admin';
import { AmountInput, ReferenceInput } from '~/components/ra';
import lodashGet from 'lodash/get';
import { useForm } from 'react-final-form';

import { l } from '~/resources/pricings/PricingShow';
import { ByFeature } from '..';
import { PricingFeeType } from '~/api/pricings';
import TierBased from './TierBased';

interface Props {
  getSource: (key: string) => string;
  schema: ByFeature;
  currency: string;
  format?: string;
  tax?: boolean;
}

interface BaseFormProps extends Props {
  formData: any;
}

const BaseForm = (props: BaseFormProps) => {
  const { getSource, schema, formData, currency, format, tax } = props;
  const scopedFormData = lodashGet(formData, getSource('')) || {};

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  const form = useForm();

  const currencyChange = (e: any) => {
    const name = e.target.name;
    const minAmountName = getSource('minAmount.currency');
    const maxAmountName = getSource('maxAmount.currency');
    const currency = lodashGet(form.getState().values, name);
    if (currency && name?.endsWith('.currency')) {
      if (name !== minAmountName) {
        form.change(minAmountName, currency);
      }
      if (name !== maxAmountName) {
        form.change(maxAmountName, currency);
      }
    }
  };
  return (
    <>
      <SelectInput
        source={getSource('type')}
        choices={schema['v:enum']}
        label={tax ? t('taxType') : t('feeType')}
        allowEmpty
        emptyText='—'
      />
      {scopedFormData.type && (
        <>
          {format === 'v2' ? (
            <ReferenceInput
              source={getSource('pnlAccountId')}
              reference='tenant/accounts'
              label={t('pnlAccount')}
              validate={required()}
            >
              <SelectInput optionText='alias' />
            </ReferenceInput>
          ) : (
            <ReferenceInput
              source={getSource('pnlAccountId')}
              reference='tenant/accounts'
              label={t('pnlAccount')}
              validate={required()}
              filter={{ currency }}
            >
              <SelectInput optionText='alias' />
            </ReferenceInput>
          )}
          <TextInput source={getSource('name')} label={t('statementTitle')} validate={required()} />
        </>
      )}
      {(function () {
        switch (scopedFormData.type as PricingFeeType | undefined) {
          case 'fixedDebitFee':
          case 'fixedDebitFeeDecrease':
          case 'fixedCreditFee':
            return (
              <AmountInput
                source={getSource('amount')}
                permanentSelectedCurrency={currency}
                required
                fullWidth
                positiveOnly
              />
            );
          case 'percentDebitFee':
          case 'percentDebitFeeDecrease':
          case 'percentCreditFee':
            return (
              <>
                <NumberInput
                  source={getSource('percent')}
                  label={t('percent')}
                  validate={required()}
                />
                <AmountInput
                  source={getSource('minAmount')}
                  amountLabel={t('minAmount')}
                  permanentSelectedCurrency={currency}
                  onChange={currencyChange}
                  fullWidth
                  positiveOnly
                />
                <AmountInput
                  source={getSource('maxAmount')}
                  amountLabel={t('maxAmount')}
                  permanentSelectedCurrency={currency}
                  onChange={currencyChange}
                  fullWidth
                  positiveOnly
                />
              </>
            );
          case 'tieredDebitFee':
          case 'tieredCreditFee':
            return (
              <TierBased
                formData={formData}
                getSource={getSource}
                schema={schema}
                permanentSelectedCurrency={currency}
              />
            );
          default:
            return null;
        }
      })()}
    </>
  );
};

const FeeForm = (props: Props) => {
  const { getSource, schema, currency, format, tax } = props;

  return (
    <FormDataConsumer>
      {({ formData }: any) => {
        return (
          <>
            <BaseForm
              getSource={getSource}
              currency={currency}
              schema={schema}
              formData={formData}
              format={format}
              tax={tax}
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default FeeForm;
