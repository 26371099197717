import React, { Fragment, useState } from 'react';
import { useTranslate, usePermissions } from 'react-admin';

import PricingForm from './PricingForm';
import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import { Pricing, Solution } from '~/types';

export interface PricingEditProps {
  record: Pricing;
  solutionRecord: Solution;
  onClose: () => void;
}

const PricingEdit = (props: PricingEditProps) => {
  const { record, solutionRecord, onClose } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.solutions.pricings.${key}`);

  const { permissions } = usePermissions();

  const closeAll = () => {
    handleClose();
    onClose();
  };

  return (
    <Fragment>
      <EditButton onClick={handleOpen} />
      <Drawer heading={t('editPricing')} open={open} onClose={onClose}>
        <EditForm
          resource='pricings'
          resourceId={record?.id}
          target='solutions'
          id={solutionRecord.id}
          withDelete
          record={record}
          closeParent={closeAll}
          disabled={!permissions?.includes('solution.pricing.update')}
          deleteButtonProps={{
            variant: 'delete',
            disabled: !permissions?.includes('solution.pricing.delete'),
          }}
          withSaveConfirmation
          saveConfirmationSettings={{
            variant: 'drawer',
          }}
          withDeleteConfirmation
        >
          <PricingForm edit pricingRecord={record} />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default PricingEdit;
