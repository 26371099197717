import React, { useState, ChangeEvent } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import { Confirmation, EditButton, H2, TextInput } from '~/components';
import Button from '@material-ui/core/Button';
import UndoIcon from '@material-ui/icons/Undo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import { HeadTransaction } from '~/types/HeadTransaction';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';

import { getTransaction } from '~/api/headTransactions';
import { createConsent as reversalCreateConsent } from '~/api/transactionReversal';
import { createTransaction as reversalCreateTransaction } from '~/api/transactionReversal';
import DetailsTab from '../DetailsTab';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface Props {
  transaction: HeadTransaction;
  history?: any;
}

const ShowSuccess = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);
  return <Alert severity='success'>{t('revertSuccess')} </Alert>;
};

const ShowFailure = (props: any) => {
  const error = props.error;
  return <Alert severity='error'>{error}</Alert>;
};

interface ShowDangerProps {
  hasPartner: boolean;
  revertTenantFees: boolean;
}

const ShowDanger = (props: ShowDangerProps) => {
  const { hasPartner, revertTenantFees } = props;
  const translate = useTranslate();
  const t = (key: string, args?: any) =>
    translate(`resources.headTransactions.show.details.${key}`, args);
  if (hasPartner) {
    return (
      <>
        <Alert severity='error'>{t('hasPartner')}</Alert>
        <br />
        <Alert severity='warning'>
          {revertTenantFees ? t('revertDangerWithFees') : t('revertDanger')}
        </Alert>
      </>
    );
  }
  return (
    <Alert severity='warning'>
      {revertTenantFees ? t('revertDangerWithFees') : t('revertDanger')}
    </Alert>
  );
};

const checkForPartner = (transaction: HeadTransaction): boolean => {
  const recipient = transaction.accounts.find((account) => account.id === transaction.recipient);
  const sender = transaction.accounts.find((account) => account.id === transaction.sender);
  const isRecipientPartner = recipient?.profileType === 'PARTNER';
  const isSenderPartner = sender?.profileType === 'PARTNER';
  return isRecipientPartner || isSenderPartner;
};

const ReversalAction = (props: Props) => {
  const { transaction, history } = props;
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.headTransactions.show.details.${key}`);

  const [reversalConsent, setReversalConsent] = useState<HeadTransaction>();
  const [reversalOpen, setReversalOpen] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [reversalTransaction, setReversalTransaction] = useState<HeadTransaction>();
  const [reasonValue, setReasonValue] = useState<string>('');
  const [revertTenantFees, setRevertTenantFees] = useState<boolean>(false);

  const handleReasonChange = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonValue(event.target.value);
  };

  const hasPartner = checkForPartner(transaction);

  const reversalCreateConsentFetch = (transactionId: string, isRevertTenantFees: boolean) => {
    return reversalCreateConsent({
      transactionId: transactionId,
      revertTenantFees: isRevertTenantFees,
    })
      .then(({ data: consent }) => {
        console.log('consent created:', consent);
        setReversalConsent(consent);
        setInProgress(false);
      })
      .catch((e: any) => {
        let errorText = e?.body?.message || 'Unknown error occurred';
        setError(errorText);
        setInProgress(false);
      });
  };

  const onRevertTransaction = () => {
    console.log(`trying to revert transaction ${transaction.id}`);
    setError(undefined);
    setInProgress(true);
    setReversalOpen(true);
    reversalCreateConsentFetch(transaction.id, revertTenantFees);
  };

  const checkForTransaction = (pendingTransaction: HeadTransaction) => {
    getTransaction({
      id: pendingTransaction.id,
    })
      .then(() => {
        setReversalTransaction(pendingTransaction);
        setInProgress(false);
      })
      .catch(() => {
        setTimeout(() => {
          checkForTransaction(pendingTransaction);
        }, 2000);
      });
  };

  const onConfirmReversal = () => {
    if (reversalConsent) {
      console.log(`confirm reversal consent ${reversalConsent.id}`);
      setInProgress(true);
      reversalCreateTransaction({ consentId: reversalConsent.id, reason: reasonValue })
        .then(({ data: transaction }) => {
          console.log('transaction created:', transaction);
          setTimeout(() => {
            checkForTransaction(transaction);
          }, 2000);
          setRevertTenantFees(false);
        })
        .catch((e) => {
          const errorText = e?.response?.data?.message || 'Unknown error occurred';
          setError(errorText);
          setInProgress(false);
          setRevertTenantFees(false);
        });
      setReasonValue('');
    }
  };

  const isReversalAllowed =
    (transaction.status === 'COMPLETED' || transaction.status === 'APPROVED') &&
    transaction.operationType !== 'REVERSAL' &&
    transaction.operationType !== 'FORCED_APPROVAL' &&
    !transaction.details.reversalTransaction;

  const handleRevertTenantFees = (event: any) => {
    setRevertTenantFees(event.target.checked);
    setError(undefined);
    setInProgress(true);
    reversalCreateConsentFetch(transaction.id, event.target.checked);
  };

  return (
    <>
      {isReversalAllowed && (
        <EditButton
          onClick={onRevertTransaction}
          label={t('revert')}
          icon={<UndoIcon />}
          disabled={!permissions?.includes('headTransaction.revert')}
          red
        />
      )}
      <Dialog
        open={reversalOpen}
        onClose={() => {
          setReversalOpen(false);
        }}
        aria-labelledby='reversal-dialog-title'
        aria-describedby='reversal-dialog-description'
        fullWidth
        maxWidth={inProgress || reversalTransaction || error ? 'xs' : 'lg'}
      >
        <DialogTitle id='reversal-dialog-title'>
          {inProgress || reversalTransaction || error ? (
            t('revertDialogTitle')
          ) : (
            <ShowDanger hasPartner={hasPartner} revertTenantFees={revertTenantFees} />
          )}
        </DialogTitle>
        <DialogContent>
          {inProgress ? (
            <Box mt={1} mb={3}>
              <LinearProgress />
            </Box>
          ) : error ? (
            <ShowFailure error={error} />
          ) : reversalTransaction ? (
            <ShowSuccess />
          ) : reversalConsent ? (
            <>
              <Box mt={0} mb={3}>
                <H2 topSpacing={0} bottomSpacing={2}>
                  {t('reversalParameters')}
                </H2>
                <FormControlLabel
                  style={{ marginLeft: '14px' }}
                  control={<Switch onChange={handleRevertTenantFees} checked={revertTenantFees} />}
                  label={t('revertTenantFees')}
                />
              </Box>
              <DetailsTab record={reversalConsent} />
            </>
          ) : null}
        </DialogContent>

        {!inProgress && (
          <DialogActions>
            <div>
              {error ? (
                <Button
                  onClick={() => {
                    setReversalOpen(false);
                  }}
                >
                  {t(`closeDialog`)}
                </Button>
              ) : reversalTransaction ? (
                <>
                  <Button
                    onClick={() => {
                      setReversalOpen(false);
                    }}
                  >
                    {t(`closeDialog`)}
                  </Button>
                  <Button
                    onClick={() => {
                      history && history.push(`/headTransactions/${reversalTransaction.id}/show`);
                    }}
                    color='primary'
                  >
                    {t(`goToTransaction`)}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      setReversalOpen(false);
                      setRevertTenantFees(false);
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Confirmation
                    onConfirm={onConfirmReversal}
                    confirmButtonProps={{ disabled: reasonValue.length === 0 }}
                    confirmationSettings={{
                      variant: 'modal',
                      modal: {
                        content: (
                          <>
                            <ShowDanger
                              hasPartner={hasPartner}
                              revertTenantFees={revertTenantFees}
                            />
                            <br />
                            <TextInput
                              value={reasonValue}
                              onChange={handleReasonChange}
                              multiline
                              rows={5}
                              rowsMax={20}
                              required
                              label={t('reason')}
                            />
                          </>
                        ),
                      },
                    }}
                  >
                    <Button color='primary'>{t('confirm')}</Button>
                  </Confirmation>
                </>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ReversalAction;
