export default {
  name: 'Business |||| Businesses',
  create: {
    createNewProfile: 'Create new business profile',
    enterThePrimaryDetails: 'Enter the primary details',
    '1row': 'Select solution and country',
    '2row': 'Input primary identification information',
    alreadyRegistered: 'Company with this primary document number is already registered.',
    youCantCreateProfile:
      'You cannot create new business profile with a primary document number that is already in use.',
  },
  fields: {
    viewProfileHistory: 'View profile history',
    viewHistory: 'View history',
    edit: {
      profileInfo: 'Edit profile information',
    },
    accounts: {
      _: 'Accounts',
    },
    additionalDocuments: 'Additional documents',
    additionalProperties: {
      _: 'Additional properties',
      badSchema: 'Additional properties schema is invalid. Check feature configuration!',
      edit: 'Edit additional properties',
      empty: 'No additional properties defined',
    },
    editAddress: 'Edit address',
    address: {
      _: 'Address',
      country: 'Country',
      addressLine: 'Address line',
      city: 'City',
      postalCode: 'Postal code',
      region: 'Region',
    },
    summary: 'Summary',
    country: 'Country',
    description: 'Description',
    profileInfo: 'Profile Information',
    documents: {
      _: 'Documents',
      add: 'Add document',
      edit: 'Edit document',
      files: 'Files',
      type: 'Type',
      empty: 'No documents provided',
    },
    details: 'Details',
    email: 'Email',
    branding: {
      _: 'Branding',
      color: 'Color',
      brandingColor: 'Branding color',
      empty: 'No branding data provided',
    },
    authorizedRepresentative: 'Authorized representative',
    employee: {
      authority: {
        owner: 'Owner',
        executive: 'Executive',
      },
      contact: 'Contact',
      email: 'Email',
      firstName: 'First name',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      position: 'Position',
      primaryDocument: {
        type: 'Document type',
        number: 'Document number',
      },
    },
    primaryDocument: {
      _: 'Primary document',
      type: 'Primary document type',
      number: 'Primary document number',
      dateOfIssue: 'Date of issue',
      dateOfExpiry: 'Date of expiry',
      dateOfBirth: 'Date of birth',
    },
    industry: 'Industry',
    kycLevel: 'KYC level',
    kycStatus: 'KYC status',
    legalName: 'Legal name',
    legalType: 'Legal type',
    merchantCategoryCode: 'Merchant Category Code (MCC)',
    name: 'Name',
    solutionId: 'Solution',
    status: {
      _: 'Status',
      active: 'Active',
    },
    tradeName: 'Trade name',
    tradeNameLocalized: 'Localized Trade name',
    editContacts: 'Edit contacts',
    contacts: {
      _: 'Contacts',
      channel: 'Channel',
      id: 'id',
      empty: 'There are no provided contacts',
      addContact: 'Add contact',
    },
    locations: 'Locations',
    terminals: 'Terminals',
    employees: 'Employees',
    deleteDialog: {
      _: 'Are you sure to delete this document?',
      no: 'No',
      yes: 'Yes',
    },
    tags: 'Tags',
    selectKYCtoCheck: 'Select KYB Level to check',
    checkKYC: 'Check KYB',
    noContacts: 'No contacts added',
    noAddress: 'There is no provided address',
    primaryDocumentNumber: 'Primary document number',
    accountNumber: 'Account number',
    setupFilter: 'To search for business set up the filter above',
    noResultsFound: 'No results found',
    searchInProgress: 'Search is in progress...',
  },
  tabs: {
    summary: 'Summary',
    accounts: 'Accounts',
    profile: 'Profile',
    locations: 'Locations',
    terminals: 'Terminals',
    transactions: 'Transactions',
    employees: 'Employees',
    paymentDetails: 'Payment Details',
    actionLog: 'Action log',
    beneficiaries: 'Beneficiaries',
    feedback: 'Feedback',
    systemLog: 'System Log',
    checks: 'Checks',
    eventLog: 'Events',
  },
  terminals: {
    account: 'Account',
    name: 'Name',
    label: 'Label',
    linkedPhone: 'Linked phone',
    phone: 'Phone',
    location: 'Location',
    status: {
      _: 'Status',
      ACTIVE: 'Active',
      NOT_ACTIVATED: 'Not actived',
      BLOCKED: 'Blocked',
      CLOSED: 'Closed',
    },
    locationId: 'Location',
    additionalProperties: {
      NFCTerminal: 'NFC Terminal',
    },
    device: {
      _: 'Device',
      applicationId: 'Application ID',
      applicationIdForVendor: 'Application for vendor',
      installationId: 'Installation ID',
      deviceLabel: 'Device Label',
      enrolledAt: 'Enrolled At',
      osName: 'OS',
      osVersion: 'OS version',
    },
    additionalData: 'Additional data',
    activationCode: 'Activation code',
    notIssued: '(not issued)',
    validTill: 'Valid till',
    issueActivationCode: 'Issue activation code',
    issueActivationCodeFor: 'Issue activation code for',
    issueNewCode: 'Issue new code',
    phoneSmsCode: 'Phone number to send activation code',
    lastCode: 'Last code',
    setLocation: 'Set location',
    setLocationFor: 'Set location for',
    editTerminal: 'Edit Terminal',
    addTerminal: 'Add Terminal',
    clear: 'Clear',
    close: 'Close',
    makeInactive: 'Make inactive',
    linkedEmployees: 'Linked employees',
    noLinkedEmployees: 'No linked employees',
    type: 'Type',
    MPOS: 'mPOS',
    ECOMMERCE: 'e-Commerce',
    integration: 'Integration',
    apiKey: 'API Key',
    tokenPrefix: 'Token prefix',
    dateOfCreation: 'Date of creation',
    issueApiKey: 'Issue API Key',
    revokeApiKey: 'Revoke API Key',
    yourApiKeyWarning: 'Your API code. It will disappear after your leave this page!',
    issueWarningContent: 'You are about to issue a new API key.',
    issueWarningContent1:
      'You can only see the API key once. If you lose it, you must revoke it and create a new one.',
    issueWarningContent2:
      'Anyone with your API key can perform actions on behalf of your account. Keep it safe.',
    issueWarningContent3: 'Please confirm to proceed.',
    warning: 'Warning!',
    revokeWarningContent:
      'You are about to revoke an API key. This means the key will be permanently disabled and any applications (like your e-commerce platform) that are using this key will immediately lose access. Please make sure you really want to do this, as it cannot be undone.',
    reissueWarningContent:
      'You are about to create a new API key. This will automatically disable the current key, which means any applications (like your e-commerce platform) using the old key will immediately lose access.',
    reissueWarningContent1: 'Here are some important things to note:',
    reissueWarningContent2:
      'You can only view the new API key once. If you lose it, you’ll need to revoke it and create a new one.',
    reissueWarningContent3: 'Update all applications that use the API key with the new key value.',
    reissueWarningContent4:
      'Keep the new API key safe. Anyone with this key can access your account and make changes.',
    reissueWarningContent5: 'Please ensure you want to proceed, as this action cannot be undone.',
    copyToClipboard: 'Copy to clipboard',
  },
  locations: {
    name: 'Name',
    status: {
      _: 'Status',
      ACTIVE: 'Active',
      BLOCKED: 'Blocked',
      CLOSED: 'Closed',
    },
    merchantCategoryCode: 'Merchant category code',
    MCC: 'MCC',
    groups: 'Groups',
    groupsHelperText: 'To add a group, enter its name and press Enter',
    geolocation: {
      _: 'Geolocation',
      latitude: 'Latitude',
      longitude: 'Longitude',
    },
    address: {
      _: 'Address',
      country: 'Country',
      region: 'Region',
      city: 'City',
      cityLocalized: 'City localized',
      postalCode: 'Postal code',
      addressLine: 'Address line',
      suburb: 'Suburb',
      building: 'Building',
    },
    additionalProperties: {
      _: 'Additional properties',
      headOffice: 'Head office',
    },
    createdAt: 'Created at',
    deleted: 'Deleted',
    editLocation: 'Edit Location',
    addLocation: 'Add Location',
    any: 'Any',
    icon: 'Icon',
    photos: 'Photos',
    schedule: 'Schedule',
    workingHours: 'Working hours',
    addTimeRange: 'Add time range',
    to: 'To',
    from: 'From',
  },
  employees: {
    accessCode: 'Access code',

    authority: {
      _: 'Authority',
      owner: 'Owner',
      executive: 'Executive',
      position: 'Position',
      employeeRole: {
        _: 'Role',
        BUSINESS_MANAGER: 'Manager',
        BUSINESS_CASHIER: 'Cashier',
        BUSINESS_ADMINISTRATOR: 'Administrator',
      },
    },
    document: {
      _: 'Document',
      type: 'Type',
      number: 'Number',
    },
    documentType: 'Document Type',
    documentNumber: 'Document Number',
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    any: 'Any',
    name: 'Name',
    phoneNumber: 'Phone number',
    terminalId: 'Terminal',
    reportsTo: 'Reports to',
    editEmployee: 'Edit details of',
    addEmployee: 'Add Employee',
    linkedTerminals: 'Linked terminals',
    getAccessCode: 'Get access code',
    getAccessCodeFor: 'Get access code for',
    phoneSmsCode: 'Phone number to send access code',
    accessCodeMessage:
      'Please communicate code to the user. You will not be able to view it again.',
    metrics: {
      _: 'Metrics',
    },
    cashierAgentAt: 'Cashier/agent at',
    managesLocations: 'Manages locations',
    managesLocation: 'Manages location',
    andministratorOrManager:
      "You can't select both Administrator and Manager roles. Please choose only one.",
  },
};
