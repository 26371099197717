import React, { Fragment, useState, useCallback, FC } from 'react';
import {
  ReferenceManyField,
  useTranslate,
  Filter,
  TextField,
  ReferenceField,
  Datagrid,
  SelectInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { debounce, set as lodashSet } from 'lodash';

import { NoPermissions, StatusField, ReferenceInput } from '~/components/ra';
import { TextInput, PhoneNumberInput } from '~/components';
import TerminalShow from './TerminalShow';
import TerminalCreate from './TerminalCreate';
import { hasTranslation, Longdash } from '~/utils';
import RaPagination from '~/components/Pagination/RaPagination';
import usePath from '~/hooks/usePath';
import { FunctionField } from 'react-admin';
import { Terminal } from '~/types';

const label = (key: string): string => `resources.businesses.terminals.${key}`;

const TerminalsList: FC<any> = (props) => {
  const { record, permissions, ...rest } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const [showData, setShowData] = useState(null);
  const handleOpen = (data: any) => {
    setShowData(data);
  };
  const handleClose = () => setShowData(null);

  const [filters, setFilters] = useState<any>({});
  const classes = useStyles();

  if (!record) return null;
  if (!permissions) return null;
  if (!permissions.includes('business.terminal.list')) return <NoPermissions />;

  return (
    <Fragment>
      <TerminalShow
        showData={showData}
        onClose={handleClose}
        businessRecord={record}
        permissions={permissions}
        parentProps={rest}
      />
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <Filters
            setFilters={setFilters}
            filters={filters}
            record={record}
            permissions={permissions}
          />
        </Grid>
        <Grid item className={classes.bottomSpacing}>
          <TerminalCreate permissions={permissions} businessRecord={record} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Terminals'
        reference='terminals'
        target='businesses'
        pagination={
          <RaPagination
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        filter={filters}
        basePath=''
      >
        <Datagrid rowClick={(_id: string, _basePath: string, record: any) => handleOpen(record)}>
          <TextField label={t('label')} source='label' sortable={false} />
          <FunctionField
            label={t('type')}
            render={(record: Terminal) =>
              record?.terminalType && hasTranslation(label(record?.terminalType))
                ? t(record?.terminalType)
                : record?.terminalType
            }
          />
          <ReferenceField
            label={t('account')}
            source='accountId'
            reference='accounts'
            link={false}
            basePath='/businesses'
            sortable={false}
          >
            <TextField source='number' />
          </ReferenceField>
          <ReferenceField
            label={t('location')}
            source='locationId'
            reference='locations'
            link={false}
            basePath='/businesses'
            sortable={false}
          >
            <TextField source='name' />
          </ReferenceField>
          <TextField label={t('linkedPhone')} source='linkedPhone' sortable={false} />
          <TextField label={t('device._')} source='device.deviceLabel' sortable={false} />
          <StatusField label={t('status._')} sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </Fragment>
  );
};

const Filters = (props: any) => {
  const { setFilters, record, permissions } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.terminals.${key}`);
  const { changePath } = usePath();

  const debouncedSetFilters = useCallback(debounce(setFilters, 400), [setFilters]);
  const handleChange = (e: any) => {
    if (!e?.target) return null;
    const { name, value } = e.target;
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return { ...lodashSet(prev, name, value) };
    });
  };
  const handlePhoneChange = (value: string) => {
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return { ...lodashSet(prev, 'linkedPhone', value) };
    });
  };

  const hasAccessAccounts = permissions.includes('business.account.list');

  const classes = useStyles();
  return (
    <Filter
      className={classes.filters}
      record={record}
      setFilters={() => null}
      hideFilter={() => null}
    >
      <TextInput
        name='label'
        source='label'
        label={t('label')}
        alwaysOn
        onChange={handleChange}
        style={minWidth}
      />
      <SelectInput
        name='status'
        source='status'
        label={t('status._')}
        choices={[
          { id: 'ACTIVE', name: t('status.ACTIVE') },
          { id: 'NOT_ACTIVATED', name: t('status.NOT_ACTIVATED') },
          { id: 'CLOSED', name: t('status.CLOSED') },
        ]}
        alwaysOn
        emptyText={Longdash}
        onChange={handleChange}
      />
      <SelectInput
        name='terminalType'
        source='terminalType'
        label={t('type')}
        choices={[
          { id: 'MPOS', name: t('MPOS') },
          { id: 'ECOMMERCE', name: t('ECOMMERCE') },
        ]}
        alwaysOn
        emptyText={Longdash}
        onChange={handleChange}
      />
      {hasAccessAccounts && record && (
        <ReferenceInput
          source='accountId'
          reference='accounts'
          filter={{ businessId: record.id }}
          allowEmpty
          emptyText={Longdash}
          onChange={handleChange}
          alwaysOn
        >
          <SelectInput optionText='number' fullWidth />
        </ReferenceInput>
      )}
      {record && (
        <ReferenceInput
          source='locationId'
          reference='locations'
          filter={{ businessId: record.id }}
          allowEmpty
          emptyText={Longdash}
          onChange={handleChange}
          alwaysOn
        >
          <SelectInput optionText='name' fullWidth />
        </ReferenceInput>
      )}

      <PhoneNumberInput
        name='linkedPhone'
        source='linkedPhone'
        label={t('linkedPhone')}
        alwaysOn
        // disableHelperText
        onChange={handlePhoneChange}
        style={minWidth}
      />
    </Filter>
  );
};

const perPage = 10;

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  filters: {
    alignItems: 'flex-start',
    // marginBottom: theme.spacing(2),
  },
}));

const minWidth = { width: 140 };

export default TerminalsList;
