import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import { H2, H3, TextField, AmountField } from '~/components';
import { ShowController } from '~/components/ra';
import { Section } from '~/layout';
import { Partner } from '~/types';
import LimitsAndCounters from '~/components/ra/Accounts/LimitsAndCounters';

import { l } from '../PartnerCreate';
import { Account, PartnerAccount } from '~/types/account';
import { useGetAllAccounts } from '~/hooks/accounts';

const accountsLabel = (key: string): string => `components.ra.AccountList.${key}`;

interface Props {
  record?: Partner;
  resource: string;
  loading?: boolean;
  basePath: string;
  permissions?: string[];
}

const Accounts = (props: Props) => {
  const { record, resource, basePath } = props;

  const { data, isLoading: loading } = useGetAllAccounts({
    resource: 'partners',
    profileId: record?.id,
  });

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));
  const at = (key: string): string => translate(accountsLabel(key));

  const classes = useStyles();

  return (
    <Fragment>
      {((data || [{}]) as any).map((account: Account | object, index: number) => {
        const { balances, id: accountId } = account as PartnerAccount;
        return (
          <Fragment key={index}>
            <H2 loading={loading} topSpacing={0} bottomSpacing={2}>
              <ReferenceField
                source='productId'
                reference='products'
                link={false}
                addLabel={false}
                record={account}
                basePath={basePath}
              >
                <ShowController>
                  {({ showRecord }) => {
                    return <div>{showRecord?.name || 'Unnamed account'}</div>;
                  }}
                </ShowController>
              </ReferenceField>
            </H2>
            <Section>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField label={t('accountNumber')} loading={loading} copyable>
                    {record?.accountNumber}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField label={t('accountAlias')} loading={loading}>
                    {record?.accountAlias}
                  </TextField>
                </Grid>
              </Grid>
            </Section>
            <Section>
              <Grid container item xs={12}>
                <Grid item container xs className={classes.table}>
                  <H3 icon={<AttachMoneyIcon />} noPaddingLeft loading={loading}>
                    {at('balances.accountSummary')}
                  </H3>
                  {loading ? (
                    <Skeleton variant='rect' width={420} height={148} />
                  ) : (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{at('balances.balance')}</TableCell>
                          <TableCell>{at('balances.amount')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {balances?.map((balance, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                {at(
                                  `balances.code.${
                                    resource === 'individuals' ? resource + '_' : ''
                                  }${balance.code}`
                                )}
                              </TableCell>
                              <TableCell className={classes.amountCell}>
                                <AmountField amount={balance.amount} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              </Grid>
              {accountId && <LimitsAndCounters id={accountId} />}
            </Section>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
  linkedAccount: {
    display: 'flex',
    '& > svg': {
      marginRight: theme.spacing(1),
      marginTop: 1,
    },
  },
}));

export default Accounts;
