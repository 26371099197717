import React, { FC } from 'react';
import { Tab, usePermissions } from 'react-admin';

import { PaperTabbedShowLayout, RecordTitle, Show } from '~/layout';
import DetailsTab from './components/DetailsTab';
import Aside from './components/Aside';
import { EventType } from '~/types/Event';
import { useTranslateEventsShow } from '~/resources/events/index';
import { NoPermissions } from '~/components/ra';
import { time } from '~/utils';
import { useRefresh } from 'react-admin';

const EventShow: FC<any> = (implicitProps) => {
  const { permissions } = usePermissions();
  const t = useTranslateEventsShow();
  const refresh = useRefresh();
  if (!permissions) return null;
  if (!permissions.includes('event.list')) return <NoPermissions />;

  return (
    <Show
      {...implicitProps}
      title={
        <RecordTitle
          headline={(record: EventType) => {
            return `${record.description}`;
          }}
          subtitle={(
            record: EventType,
            resource: string,
            translate: (key: string, opt: any) => string
          ) => {
            const parts = [];
            if (record.id) {
              parts.push(`${translate(`resources.${resource}.name`, 1)} #${record.id}`);
            }
            const created = record.timestamp;
            if (created) {
              const m = time(created);
              parts.push(`created at ${m.format('l')} ${m.format('LTS')}`);
            }
            return parts.join(', ');
          }}
        />
      }
      actions={null}
    >
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.eventData')}>
          <DetailsTab {...implicitProps} />
        </Tab>
      </PaperTabbedShowLayout>
      <Aside refresh={refresh} />
    </Show>
  );
};

export default EventShow;
