import axios from '~/utils/axios';
import { ApiIdentity, ApiKey, Device } from '~/types/apiIdentity';

export type ApiIdentityFilter = {
  name?: string;
  profileId?: string;
  username?: string;
  emailAddress?: string;
  phoneNumber?: string;
};

export const getApiIdentities = (filter: ApiIdentityFilter = {}) => {
  return axios
    .get<ApiIdentity[]>('apiIdentities', { params: { page: 1, perPage: 999, ...filter } })
    .then((res) => res.data);
};

export const getApiIdentityById = (id: string) => {
  return axios.get<ApiIdentity[]>(`apiIdentities/${id}`).then((res) => res.data);
};

export const clearSecretWord = (identityId: string) => {
  return axios.post(`apiIdentities/${identityId}/clearSecretWord`).then((res) => res.data);
};

export type AuthenticationType = NonNullable<keyof Required<ApiIdentity>['data']['authFailures']>;

export const resetAttemptsCounter = (props: {
  identityId: string;
  authenticationType: AuthenticationType;
}) => {
  const { identityId, authenticationType } = props;
  return axios
    .delete(`apiIdentities/${identityId}/authFailures/${authenticationType}`)
    .then((res) => res.data);
};

export const createBlock = (props: {
  identityId: string;
  params: { reason: string; from?: string; to?: string };
}) => {
  const { identityId, params } = props;
  return axios.post(`apiIdentities/${identityId}/blocks`, { ...params }).then((res) => res.data);
};

export const clearBlock = (props: { identityId: string; blockId: string }) => {
  const { identityId, blockId } = props;
  return axios.delete(`apiIdentities/${identityId}/blocks/${blockId}`).then((res) => res.data);
};

export const getApiKeyByIdentityId = (id: string) => {
  return axios.get<ApiKey>(`apiIdentities/${id}/apiKey`).then((res) => res.data);
};

export const getApiIdentityDevicesById = (id: string) => {
  return axios.get<Device[]>(`apiIdentities/${id}/devices`).then((res) => res.data);
};

export const deleteDeviceById = async (params: { deviceId: string; apiIdentityId: string }) => {
  const { deviceId, apiIdentityId } = params;
  return axios.delete(`apiIdentities/${apiIdentityId}/devices/${deviceId}`).then((res) => res.data);
};
