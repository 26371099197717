export default {
  name: 'Action report |||| Action reports',
  subtitle: 'All reporting actions',
  fields: {
    empty: 'No reports found',
    TRANSACTION: 'Transaction',
    HEAD_TRANSACTION: 'Head transaction',
    created: 'Created',
    updated: 'Last update',
    reportableObject: 'Reportable object',
    system: 'System',
    reportType: 'Report type',
    objectType: 'Object type',
    objectId: 'Object ID',
    status: 'Status',
    createdFrom: 'Created from',
    createdTo: 'Created to',
    hasWarnings: 'Warnings',
    hasNoWarnings: 'Has no warnings',
    hasSomeWarnings: 'Has warnings',
    reportData: 'Report data',
    details: 'Details',
    actionsReportingLog: 'Actions reporting log',
    data: 'Data',
    log: 'Log',
    field: 'Field',
    dateAndTime: 'Date and time',
    action: 'Action',
    actor: 'Actor',
    send: 'Send',
    reject: 'Reject',
    objectTitle: 'Object title',
    manualReport: 'Manual Report Origination',
    selectReportType:
      'Please select the report type. System does not check sequence of reporting actions.',
    reviewReportData: 'Review Report Data',
    pleaseReviewReportData: 'Please review the report data',
    saveNow: 'Save now',
    approve: 'Approve',
    actionReportSubmitted: 'Action report submitted',
    apply: 'Apply',
    reason: 'Reason',
    referenceId: 'Reference ID',
  },
  revokeAction: {
    revoke: 'Revoke report',
    revokeShort: 'Revoke',
    warning: {
      title: 'Warning',
      message:
        "This action is irreversable! Once report in 'Initial' state is revoked, it's state is changed to 'Revoked', and the report will not be submitted to the third-party system.",
    },
    reason: 'Revoke reason',
    revokedSuccess: 'Report has been revoked',
  },
};
