import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { SettingsIcon } from '~/img';
import { TextField, H3, AmountField, HilightChanges } from '~/components';
import { TextField as RaTextFiled } from '~/components/ra';
import { Section } from '~/layout';
import { l } from '~/resources/pricings/PricingShow';
import { PricingFeeType } from '~/api/pricings';
import TierBasedView from '../Views/TierBasedView';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
  },
}));

interface Props {
  record: any;
  prevRecord: any;
  tax?: boolean;
}

export type RegularFeeType = {
  [x: string]: any;
};

interface FeeFieldProps {
  record?: RegularFeeType;
  prevRecord?: RegularFeeType;
  tax?: boolean;
}

const FeeField = (props: FeeFieldProps) => {
  const { record = {}, prevRecord = {}, tax } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField label={tax ? t('taxType') : t('feeType')}>
          <HilightChanges curr={record.type} prev={prevRecord.type}>
            {record.type && t(record.type)}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('pnlAccount')} ellipsis>
          <HilightChanges curr={record?.pnlAccountId} prev={prevRecord?.pnlAccountId}>
            {record.pnlAccountId && (
              <ReferenceField
                record={record}
                source='pnlAccountId'
                reference='tenant/accounts'
                link={false}
                addLabel={false}
                basePath='/tenant/accounts'
              >
                <RaTextFiled source='alias' addLabel={false} ellipsis />
              </ReferenceField>
            )}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('statementTitle')}>
          <HilightChanges curr={record.name} prev={prevRecord.name}>
            {record.name}
          </HilightChanges>
        </TextField>
      </Grid>
      {(function () {
        switch (record.type as PricingFeeType | undefined) {
          case 'fixedDebitFee':
          case 'fixedDebitFeeDecrease':
          case 'fixedCreditFee':
            return (
              <Grid item xs={12}>
                <TextField label={t('amount')}>
                  <HilightChanges curr={record.amount} prev={prevRecord.amount}>
                    <AmountField amount={record.amount} />
                  </HilightChanges>
                </TextField>
              </Grid>
            );
          case 'percentDebitFee':
          case 'percentDebitFeeDecrease':
          case 'percentCreditFee':
            return (
              <Fragment>
                <Grid item xs={12}>
                  <TextField label={t('percent', 2)}>
                    <HilightChanges curr={record.percent} prev={prevRecord.percent}>
                      {record.percent?.toString() && `${record.percent}%`}
                    </HilightChanges>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('minAmount')}>
                    <HilightChanges curr={record.minAmount} prev={prevRecord.minAmount}>
                      <AmountField amount={record.minAmount} />
                    </HilightChanges>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('maxAmount')}>
                    <HilightChanges curr={record.maxAmount} prev={prevRecord.maxAmount}>
                      <AmountField amount={record.maxAmount} />
                    </HilightChanges>
                  </TextField>
                </Grid>
              </Fragment>
            );
          case 'tieredDebitFee':
          case 'tieredCreditFee':
            return <TierBasedView curr={record} prev={prevRecord} />;
          default:
            return null;
        }
      })()}
    </Fragment>
  );
};

interface FeeContentProps {
  record?: RegularFeeType;
  prevRecord?: RegularFeeType;
  tax?: boolean;
}

export const FeeContent = (props: FeeContentProps) => {
  const { record = {}, prevRecord = {}, tax } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  return (
    <Fragment>
      <Grid item xs={12}>
        <H3 icon={<SettingsIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {tax ? t('taxConfiguration') : t('feeConfiguration')}
        </H3>
      </Grid>
      <FeeField record={record} prevRecord={prevRecord} tax={tax} />
    </Fragment>
  );
};

const FeeConfirmationField = (props: Props) => {
  const { record = {}, prevRecord = {} } = props;
  const classes = useStyles();

  return (
    <Section className={classes.root}>
      <Grid item container spacing={2}>
        <FeeContent record={record} prevRecord={prevRecord} />
      </Grid>
    </Section>
  );
};

export default FeeConfirmationField;
