import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Grid } from '@material-ui/core';

import LocationForm from './LocationForm';
import { EditButton, Drawer } from '~/components';
import { AvatarField, EditForm } from '~/components/ra';
import { Location } from '~/types/locations';
import { Business } from '~/types';

interface Props {
  locationRecord: Location;
  businessRecord: Business;
  closeParent: () => void;
  permissions: string[];
}

const LocationEdit = (props: Props) => {
  const { locationRecord, businessRecord, closeParent, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    closeParent();
  };

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.locations.${key}`);

  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions.includes('business.location.update')}
      />
      <Drawer
        heading={
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <AvatarField source='branding.icon' record={locationRecord} />
            </Grid>
            <Grid item>{`${translateLabel('editLocation')} "${locationRecord.name}"`}</Grid>
          </Grid>
        }
        open={open}
        onClose={handleClose}
      >
        <EditForm resource='locations' record={locationRecord} closeParent={handleClose}>
          <LocationForm locationRecord={locationRecord} businessRecord={businessRecord} />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default LocationEdit;
