import React, { FC, Fragment } from 'react';
import { useTranslate, Tab, usePermissions } from 'react-admin';

import { Title } from '~/components';
import { CurrenciesPageIcon, l } from './CurrenciesList';
import { PaperTabbedShowLayout } from '~/layout';
import AddOrEditCurrency from './components/AddOrEditCurrency';
import CurrencyPairs from './components/CurrencyPairs';
import { useApi } from '~/hooks';
import { CurrencyPairsType, getCurrenciesPairs } from '~/api/currencies';
import FXConfigurationList from './components/FXConfigurationList';
import FXMappingList from './components/FXMappingList';

const CurrencySetup: FC<any> = (props) => {
  const { permissions } = usePermissions();

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));
  const useApiReturn = useApi<CurrencyPairsType[]>(getCurrenciesPairs);

  return (
    <Fragment>
      <Title
        resource={props.resource}
        headline={t('currencyConversionSetup')}
        icon={<CurrenciesPageIcon />}
        subtitle=' '
        style={{ marginBottom: '1em' } as object}
      />
      <PaperTabbedShowLayout>
        <Tab label={l('tabs.currencies')}>
          <AddOrEditCurrency permissions={permissions} refetchCurrencyPairs={useApiReturn[1]} />
          {permissions?.includes('currency.pair.list') && (
            <CurrencyPairs permissions={permissions} useApiReturn={useApiReturn} />
          )}
        </Tab>
        {permissions?.includes('fxrates.list') && (
          <Tab label={l('tabs.fxRateTables')} path='fxRateTables'>
            <FXConfigurationList permissions={permissions} />
          </Tab>
        )}
        {permissions?.includes('fxrates.mapping.list') && (
          <Tab label={l('tabs.fxPlans')} path='fxPlans'>
            <div>
              <FXMappingList permissions={permissions} />
            </div>
          </Tab>
        )}
      </PaperTabbedShowLayout>
    </Fragment>
  );
};

export default CurrencySetup;
