import { useQuery } from 'react-query';
import { fetchChangelogById, fetchChangelogs } from '~/api/changelog';

export const useGetChangelogsData = (resource: string, profileId: string, isEnabled: boolean) => {
  return useQuery(['changelogsData', profileId], () => fetchChangelogs(resource, profileId!), {
    enabled: !!profileId && isEnabled,
    staleTime: Infinity,
  });
};

export const useGetChangelogData = (
  resource: string,
  profileId: string,
  isEnabled: boolean,
  changelogId: number | undefined
) => {
  return useQuery(
    ['changelogData', profileId, changelogId],
    () => fetchChangelogById(resource, profileId!, changelogId),
    {
      keepPreviousData: true,
      enabled: !!profileId && isEnabled,
      staleTime: Infinity,
    }
  );
};
