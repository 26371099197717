import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from '@material-ui/core';

import { useHandbook } from '~/hooks';
import { CurrencySymbols } from '~/utils';
import { Amount } from '~/types';

const useStyles = makeStyles({
  numberInput: {
    marginRight: '5px',
    minWidth: '152px',
  },
  selectInput: {
    minWidth: 100,
  },
});

export interface Props {
  amount?: Amount | null;
  defaultValue?: number;
  label?: string;
  currencyLabel?: string;
  solutionId?: string;
  onChange?: (amount: Amount | null) => void;
  required?: boolean;
  permanentSelectedCurrency?: string;
  currencies?: string[];
  currencyDisabled?: boolean;
  [k: string]: any;
}

interface State {
  value: string;
  currency: string | undefined;
}

const AmountInput = ({
  amount,
  defaultValue,
  label,
  currencyLabel = 'Currency',
  permanentSelectedCurrency,
  currencies: providedCurrencies,
  currencyDisabled,
  solutionId,
  onChange,
  ...rest
}: Props) => {
  const classes = useStyles();

  const currencySymbols: { [key: string]: string } = CurrencySymbols;

  const translate = useTranslate();

  const { data } = useHandbook(solutionId);
  const [state, setState] = useState<State>({
    value: (amount?.value || defaultValue || '') + '',
    currency: undefined,
  });

  let currencies: { id: string | undefined; name: string }[] = [];
  if (data?.currencies && !permanentSelectedCurrency) {
    currencies = (data.currencies || []).map((c) => {
      return { id: c.id, name: currencySymbols[c.id] || c.id };
    });
    if (providedCurrencies) {
      currencies = currencies.filter((c) => providedCurrencies.find((pc) => pc === c.id));
    }

    if (!state.currency && currencies.length > 0) {
      setState({
        ...state,
        currency: currencies[0]?.id,
      });
    }
  } else if (permanentSelectedCurrency) {
    currencies = [
      {
        id: permanentSelectedCurrency,
        name: currencySymbols[permanentSelectedCurrency] || permanentSelectedCurrency,
      },
    ];
  } else {
    currencies = [{ id: undefined, name: translate('utils.noHandbookValues') }];
  }

  const currencyOptions = currencies.map((c) => (
    <MenuItem key={c.id || 'undefined'} value={c.id}>
      {c.name}
    </MenuItem>
  ));

  const currentCurrency = permanentSelectedCurrency || state.currency || currencies[0]?.id;

  const handleBlur = () => {
    let actualAmount: Amount | null = null;
    if (state.value) {
      let value = parseFloat(state.value + '');
      actualAmount = {
        value: isNaN(value) ? 0 : value,
        currency: permanentSelectedCurrency || state.currency || '',
      };
    } else if (typeof defaultValue !== 'undefined') {
      actualAmount = { ...state, value: defaultValue } as Amount;
      setState({ ...state, value: defaultValue + '' });
    }
    onChange && onChange(actualAmount);
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        <TextField
          label={label && translate(label)}
          type='number'
          value={state.value + ''}
          onChange={(e: any) => {
            setState({ ...state, value: e.target.value });
          }}
          onBlur={handleBlur}
          onClick={handleBlur} // hacky way to trigger blur on step-button click
          className={classes.numberInput}
          {...rest}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl>
          <InputLabel>{currencyLabel && translate(currencyLabel)}</InputLabel>
          <Select
            className={classes.selectInput}
            value={currentCurrency || ''}
            onChange={(e: any) => {
              setState({ ...state, currency: e.target.value });
            }}
            defaultValue={permanentSelectedCurrency}
            disabled={currencyDisabled || !!permanentSelectedCurrency}
            onBlur={handleBlur}
          >
            {currencyOptions}
          </Select>
          <FormHelperText>{'\u00A0'}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AmountInput;
