import React, { useState, Fragment } from 'react';
import { useTranslate, Button, useRefresh } from 'react-admin';
import { SvgIcon, DialogContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { EditButton } from '~/components';
import { Business, Individual } from '~/types';
import { resetKycProgress } from '~/api/kyc';
import { useNotify } from '~/hooks';
import { ShieldCheckmarkIcon } from '~/img';
import { eventLogUpdate } from '~/utils';

const label = (key: string): string => `resources.individuals.fields.${key}`;

interface Props {
  record: Business | Individual | undefined;
  resource: string;
}

const KycResetProgress = (props: Props) => {
  const { record, resource } = props;

  const [open, setOpen] = useState(false);
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const notify = useNotify();

  const forceRefresh = useRefresh();

  const handleReset = async () => {
    notify({ message: t('kycResetInitiated'), type: 'info' });
    closeDialog();

    if (!record) return null;
    try {
      await resetKycProgress('INDIVIDUAL', record.id);
      forceRefresh();
      eventLogUpdate(resource);
    } catch (error) {
      notify({ message: error.message, type: 'error' });
    }
  };

  return (
    <Fragment>
      <EditButton
        label={t('kycResetProgress')}
        icon={
          <SvgIcon>
            <ShieldCheckmarkIcon />
          </SvgIcon>
        }
        onClick={openDialog}
      />
      <Dialog open={open} onClose={closeDialog} aria-labelledby='form-dialog-title'>
        <DialogTitle>{t('kycResetProgress')}</DialogTitle>
        <DialogContent>
          <Alert severity='warning'>{t('kycResetProgressWarning')}</Alert>
        </DialogContent>
        <DialogActions>
          <Button label='ra.action.cancel' onClick={closeDialog} />
          <Button label={t('kycResetProgress')} variant='contained' onClick={handleReset}>
            <SvgIcon>
              <ShieldCheckmarkIcon />
            </SvgIcon>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default KycResetProgress;
