import React, { FC } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';

import { Transfer } from '~/types';
import { TextField as RaTextField } from '~/components/ra';
import { buildProfileLink, Longdash } from '~/utils';

const makeLabel = (key: string): string => `components.TransactionView.${key}`;

interface Props {
  party: Transfer['from'];
  ownerId?: string;
  additionalName?: string;
  label?: string;
}

const ProfileReference: FC<Props> = (props) => {
  const { party, ownerId, additionalName, label } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(makeLabel(key));

  const makeProps = (reference: string, source?: keyof Transfer['from']) => ({
    reference,
    basePath: `/businesses`,
    source: source || 'profileId',
    record: party,
    link: false,
    addLabel: false,
  });

  switch (party.profileType) {
    case 'INDIVIDUAL':
      return (
        <ReferenceField {...makeProps('individuals')}>
          <RaTextField
            fn={(r) =>
              `${r.firstName || Longdash} ${r.lastName} ${
                additionalName ? ` / '${additionalName}'` : ''
              }`
            }
            label={label || t('individual')}
            showLink={buildProfileLink(
              party.accountId,
              party.profileId,
              party.profileType,
              ownerId
            )}
            textWrap
          />
        </ReferenceField>
      );
    case 'BUSINESS':
      return (
        <ReferenceField {...makeProps('businesses')}>
          <RaTextField
            source='legalName'
            label={label || t('business')}
            allowEmpty
            showLink={buildProfileLink(
              party.accountId,
              party.profileId,
              party.profileType,
              ownerId
            )}
            textWrap
          />
        </ReferenceField>
      );
    case 'PARTNER':
      return (
        <ReferenceField {...makeProps('partners')}>
          <RaTextField
            source='name'
            label={label || t('partner')}
            allowEmpty
            showLink={buildProfileLink(
              party.accountId,
              party.profileId,
              party.profileType,
              ownerId
            )}
            textWrap
          />
        </ReferenceField>
      );
    case 'TENANT':
      return (
        <ReferenceField {...makeProps('tenant/accounts', 'accountId')}>
          <RaTextField
            source='alias'
            label={label || t('tenant')}
            allowEmpty
            showLink={buildProfileLink(
              party.accountId,
              party.profileId,
              party.profileType,
              ownerId
            )}
            textWrap
          />
        </ReferenceField>
      );
    default:
      return null;
  }
};

export default ProfileReference;
