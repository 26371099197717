import React, { Fragment } from 'react';
import { useTranslate, TextInput, required } from 'react-admin';
import { CurrencySelect } from '~/components/ra';

import { l } from './BankAccountList';

const BankAccountForm = (props: any) => {
  const { edit } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <TextInput source='alias' label={t('alias')} validate={required()} />
      {!edit && <CurrencySelect source='currency' label={t('currency')} validate={required()} />}
      <TextInput source='description' label={t('description')} />
    </Fragment>
  );
};

export default BankAccountForm;
