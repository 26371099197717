export default {
  name: 'Profile tags',
  profileTagsTooltip:
    'Profile tags serve for profiling purposes, aiding in the classification of profiles and facilitating pricing table selection. Optionally, they can be populated into the transaction context, enabling "forked" fee application based on the transaction tag mechanism.',
  fields: {
    tagName: 'Tag name',
    description: 'Description',
    profileTypes: 'Profile types',
    enabledForProfileType: 'Enabled for profile type',
    populateToTransactionTags: 'Populate to transaction tags',
    status: 'Status',
    withArchived: 'With archived',
    addNewTag: 'Add new tag',
    editTag: 'Edit tag',
    INDIVIDUAL: 'Individual',
    BUSINESS: 'Business',
    PARTNER: 'Partner',
    allTags: 'All tags',
    nonTransactionalOnly: 'Non-transactional only',
    transactionalOnly: 'Transactional only',
    transactionTagsTooltip:
      'If you want the profile tag to populate in the transaction context, please ensure that the appropriate checkbox is enabled in this tag configuration. If the function is enabled, the profile tag will be populated in the notation <ROLE>:<PROFILE_TYPE>=<TAG_VALUE>',
    profileTypeTooltip:
      'The profile tag will only be enabled for the respective type of profiles if the corresponding checkbox, labeled "Enabled for ... profiles," is selected.',
    noResultsFound: 'No results found',
    searchInProgress: 'Search is in progress...',
  },
};
