import React from 'react';
import { Tab, useTranslate, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Show, PaperTabbedShowLayout } from '~/layout';
import { l } from './PartnerCreate';
import { Profile, TransactionsHistory, Configuration } from './components';
import { Notes, AuditLogWidget, ProfileTags } from '~/components';
import { Partner } from '~/types';
import { Accounts, NoPermissions } from '~/components/ra';
import Aside from './components/Aside';
import { useSolution } from '~/hooks';
import ProfileLimits from '~/components/ProfileLimits';
import { useIsLargeScreen, useRightContentSpacing } from '~/hooks/styles';
import EventLogWidget from '~/components/Events/EventLogWidget';

const PartnerShow = (props: any) => {
  const { history, resource, record, basePath } = props;
  const currentPathname = history?.location.pathname;
  const eventLogPathname = `/${resource}/${record?.id || ''}/show/eventLog`;
  const systemLogPathname = `/${resource}/${record?.id || ''}/show/systemLog`;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions?.includes('partner.view')) return <NoPermissions />;

  return (
    <PaperTabbedShowLayout
      {...props}
      bottomContent={
        currentPathname === eventLogPathname && permissions?.includes?.('partner.event.list') ? (
          <EventLogWidget objectId={record.id} objectType='partners' history={history} />
        ) : currentPathname === systemLogPathname ? (
          <AuditLogWidget history={history} profileId={record.id} />
        ) : null
      }
    >
      {permissions?.includes('partner.account.view') && (
        <Tab label={t('tabs.accounts')}>
          <Accounts
            profileId={record?.id || ''}
            solutionId={record?.solutionId}
            permissions={permissions}
            resource={resource}
            basePath={basePath}
          />
        </Tab>
      )}
      {permissions?.includes('partner.view') && (
        <Tab label={t('tabs.profile')} path='profile'>
          <Profile permissions={permissions} />
        </Tab>
      )}
      {permissions?.includes('partner.transaction.list') && (
        <Tab label={t('tabs.transactions')} path='transactions'>
          <TransactionsHistory permissions={permissions} record={record} />
        </Tab>
      )}
      {permissions?.includes('partner.event.list') && (
        <Tab label={t('tabs.eventLog')} path='eventLog' />
      )}
      {permissions?.includes('partner.configuration.view') && (
        <Tab label={t('tabs.configuration')} path='configuration'>
          <Configuration permissions={permissions} />
        </Tab>
      )}
      {permissions?.includes('auditLog.list') && (
        <Tab label={t('tabs.systemLog')} path='systemLog' />
      )}
    </PaperTabbedShowLayout>
  );
};

const RightPanel = (props: any) => {
  const { history, resource, permissions } = props;
  const partnerRecord: Partner | undefined = props.record;
  const isLargeScreen = useIsLargeScreen();

  const spacing = useRightContentSpacing();
  const classes = useStyles({ spacing });

  const currentPathname = history?.location.pathname;
  const accountsPathname = `/${resource}/${partnerRecord?.id || ''}/show`;
  const profilePathname = `/${resource}/${partnerRecord?.id || ''}/show/profile`;
  const transactionsPathname = `/${resource}/${partnerRecord?.id || ''}/show/transactions`;

  const { data: solution } = useSolution(partnerRecord?.solutionId);

  const hasTagsViewPermission = permissions?.includes('partner.tags.view');
  if (!partnerRecord) return null;
  return (
    <Grid container item xs direction='column' spacing={spacing}>
      <Grid item className={classes.rightContent}>
        <Aside record={partnerRecord} />
      </Grid>
      {(currentPathname === accountsPathname || currentPathname === profilePathname) &&
        hasTagsViewPermission && (
          <Grid item className={classes.rightContent}>
            <ProfileTags
              profileId={partnerRecord.id}
              resource='partners'
              permissions={permissions}
              record={partnerRecord}
            />
          </Grid>
        )}
      {currentPathname === accountsPathname && (
        <Grid item className={classes.rightContent}>
          <ProfileLimits
            profileId={partnerRecord?.id}
            resource='partners'
            solutionId={solution?.id}
            permissions={permissions}
          />
        </Grid>
      )}
      {(currentPathname !== transactionsPathname || isLargeScreen) && (
        <Grid item className={classes.rightContent}>
          <Notes referenceId={partnerRecord?.id || ''} profileType='PARTNER' />
        </Grid>
      )}
    </Grid>
  );
};

const ShowController = (props: any) => {
  const { history } = props;

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <Show {...props}>
      <PartnerShow history={history} />
      <RightPanel history={history} permissions={permissions} />
    </Show>
  );
};

type StylesPrpos = {
  spacing: number;
};

const useStyles = makeStyles<Theme, StylesPrpos>((theme) => ({
  rightContent: {
    marginLeft: ({ spacing }) => theme.spacing(spacing),
  },
}));

export default ShowController;
