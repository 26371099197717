import React, { Fragment, useState } from 'react';
import { TextInput, useTranslate, required } from 'react-admin';
import { Drawer, BlockButton, H2 } from '~/components';
import { EditForm as CloseForm } from '~/components/ra';
import { useDispatch } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';

import { l } from '../PartnerCreate';
import { Partner } from '~/types';
import { createNote } from '~/ducks/notes';
import { history } from '~/App';

interface Props {
  resource?: string;
  record?: Partner;
  permissions?: string[];
}

const PartnerClose = (props: Props) => {
  const { permissions, resource, record } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const handleSubmit = (
    newRecord: any,
    submitQuery: (newRecord: Partner, onSuccess?: () => void, onFailure?: () => void) => void
  ): void => {
    const reason: string = newRecord.reason;
    if (reason && record) {
      dispatch(
        createNote(
          {
            text: `${t('closePrefixText')}: ${reason}`,
            referenceId: record.id,
            createdAt: new Date().toISOString(),
            creatorName: JSON.parse(localStorage.identity).name,
          },
          record.id,
          'PARTNER'
        )
      );
      submitQuery(record, () => history.push('/' + resource));
    }
  };

  return (
    <Fragment>
      <BlockButton
        label='close'
        onClick={handleOpen}
        red
        disabled={!permissions?.includes('partner.delete')}
      />
      <Drawer heading={t('closePartner')} open={open} onClose={handleClose}>
        <H2 topSpacing={0}>{t('closeWarn')}</H2>
        {record && resource && (
          <CloseForm
            record={{} as any}
            resource={resource}
            id={record.id}
            subresource='close'
            onSubmit={handleSubmit}
            closeParent={handleClose}
            HTTPMethod='POST'
            saveButtonProps={{
              color: 'red',
              label: 'Close',
              icon: <LockIcon />,
            }}
          >
            <TextInput source='reason' multiline rows={8} validate={required()} fullWidth />
          </CloseForm>
        )}
      </Drawer>
    </Fragment>
  );
};

export default PartnerClose;
