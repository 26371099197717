import React, { Fragment } from 'react';
import { withTranslate } from 'react-admin';
import { FormControlLabel, Collapse } from '@material-ui/core';

import CollapseToggle from './CollapseToggle';

export default (buildFields) => {
  class ObjectField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: true,
      };

      this.setCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
      };
    }
    render() {
      const { context, objectSchema, source, translate, ...props } = this.props;
      const { collapsed } = this.state;
      return (
        <Fragment>
          <FormControlLabel
            checked={!collapsed}
            control={<CollapseToggle onChange={this.setCollapsed} />}
            label={translate(`jsonSchema.${context}.${source}._`)}
          />
          <Collapse in={!collapsed}>
            <div style={{ marginLeft: 16 }}>
              {buildFields({
                context,
                objectSchema,
                sourcePrefix: source,
                ...props,
              }).map((field) => {
                return <div className='ra-field'>{field}</div>;
              })}
            </div>
          </Collapse>
        </Fragment>
      );
    }
  }
  return withTranslate(ObjectField);
};
