import React, { FC } from 'react';
import { Tab, useTranslate, usePermissions } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import SvgIcon from '@material-ui/core/SvgIcon';

import { Show, PaperTabbedShowLayout } from '~/layout';
import { l } from './PnlAccountList';
import { ArchiveIcon as PnLIcon } from '~/img';
import Details from './components/Details';
import TransactionsHistory from './components/TransactionsHistory';
import { NoPermissions } from '~/components/ra';
import BankDaySummary from './components/BankDaySummary';

const PnlAccountShow: FC<any> = (props) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  if (!permissions) return null;
  if (!permissions?.includes('tenant.account.view')) return <NoPermissions />;

  return (
    <Show
      icon={
        <Avatar>
          <SvgIcon>
            <PnLIcon />
          </SvgIcon>
        </Avatar>
      }
      {...props}
      resource='accounts'
      basePath='/accounts'
    >
      <PaperTabbedShowLayout>
        <Tab label={t('tabs.details')}>
          <Details permissions={permissions} />
        </Tab>
        {permissions?.includes('tenant.account.daySummary.list') && (
          <Tab label={t('tabs.bankDaySummary')} path='bankDaySummary'>
            <BankDaySummary permissions={permissions} />
          </Tab>
        )}
        {permissions?.includes('tenant.account.transaction.list') && (
          <Tab label={t('tabs.transactions')} path='transactions'>
            <TransactionsHistory permissions={permissions} />
          </Tab>
        )}
      </PaperTabbedShowLayout>
    </Show>
  );
};

export default PnlAccountShow;
