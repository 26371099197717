export const getProfileType = (resource: 'businesses' | 'individuals' | 'partners') => {
  switch (resource) {
    case 'businesses':
      return 'BUSINESS';
    case 'individuals':
      return 'INDIVIDUAL';
    case 'partners':
      return 'PARTNER';
    default:
      return null;
  }
};
