import React, { Fragment } from 'react';
import { useTranslate, TextInput, required, BooleanInput } from 'react-admin';

import { H3 } from '~/components';
import {
  MerchantCategoryCodeInput,
  HiddenInput,
  AddressForm,
  ImageInput,
  AutocompleteArrayTextInput,
} from '~/components/ra';
import { Location } from '~/types/locations';
import { Business } from '~/types';
import WorkingHoursInput from './components/WorkingHoursInput';

interface Props {
  locationRecord?: Location;
  businessRecord: Business;
  record?: any;
}

const LocationForm = (props: Props) => {
  const { businessRecord, record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.locations.${key}`);

  return (
    <Fragment>
      <HiddenInput source='solutionId' disabled />
      <HiddenInput source='businessId' disabled />
      <TextInput source='name' label={t('name')} validate={required()} fullWidth />
      <MerchantCategoryCodeInput solutionId={businessRecord.solutionId} fullWidth />
      <AutocompleteArrayTextInput
        source='groups'
        label={t('groups')}
        record={record}
        regularHelperText={t('groupsHelperText')}
      />
      <H3>{t('address._')}</H3>
      <AddressForm solutionId={businessRecord.solutionId} defaultCountry={businessRecord.country} />
      <H3>{t('icon')}</H3>
      <ImageInput source='branding.icon' anonymous />
      <H3>{t('photos')}</H3>
      <ImageInput source='branding.photos' multiple maxCount={5} anonymous />
      <H3>{t('workingHours')}</H3>
      <WorkingHoursInput source='schedule' country={businessRecord.country} />
      <H3>{t('additionalProperties._')}</H3>
      <BooleanInput
        source='additionalProperties.headOffice'
        label={t('additionalProperties.headOffice')}
        fullWidth
      />
    </Fragment>
  );
};

export default LocationForm;
