import React from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import { Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import ProfileTagForm from './ProfileTagForm';
import {} from 'react-admin';

interface Props {
  record: any;
  onClose: () => void;
}

const ProfileTagEdit = (props: Props) => {
  const { record, onClose } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.profileTags.fields.${key}`);
  const { permissions } = usePermissions();
  if (!record) return null;
  return (
    <Drawer heading={t('editTag')} open={!!record} onClose={onClose}>
      <EditForm
        resource='profileTags'
        closeParent={onClose}
        record={record}
        withDelete
        disabled={!permissions?.includes('profileTag.update') || record?.deleted}
        deleteButtonProps={{
          variant: record?.deleted ? 'unarchive' : 'archive',
          disabled: !permissions?.includes('profileTag.delete'),
        }}
      >
        <ProfileTagForm edit />
      </EditForm>
    </Drawer>
  );
};

export default ProfileTagEdit;
