import React from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';

import { Address } from '~/components/ra/AddressForm';
import { useAddressConfig } from '~/hooks';
import { Skeleton } from '@material-ui/lab';

interface Props {
  [x: string]: any;
}

const AddressField = (props: Props) => {
  const { record, source } = props;
  const address: Address = lodashGet(record, source);
  const translate = useTranslate();
  const conf = useAddressConfig(address && address.country);

  const getCountryName = (countryCode: string): string => {
    try {
      const name = translate(`countries.${countryCode}`);
      if (name.startsWith('countries.')) throw new Error(`Invalid country code: ${countryCode}`);
      return name;
    } catch (error) {
      console.error(error.message);
      return countryCode;
    }
  };

  const result: string[] = [];

  if (address && translate && conf.data) {
    conf.data.forEach((item, index, arr) => {
      const prev = arr[index - 1];
      const curr = address[item.source];
      if (!curr) return;

      if (
        prev &&
        ((prev.source === 'building' && item.source === 'addressLine') ||
          (prev.source === 'addressLine' && item.source === 'building'))
      ) {
        const prevValue = result[result.length - 1];
        result[result.length - 1] = `${prevValue} ${curr}`;
        return;
      }

      if (item.source === 'country') {
        result.push(getCountryName(curr));
        return;
      }
      result.push(curr);
    });
  }

  return <div>{conf.isLoading ? <Skeleton width='100%' height='18px' /> : result.join(', ')}</div>;
};

AddressField.defaultProps = {
  addLabel: true,
  label: 'components.ra.fields.AddressField',
  source: 'address',
};

export default AddressField;
