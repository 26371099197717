import React from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AmountField, H2, H3, StatusField, TextField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { Section } from '~/layout';
import { l } from '../PnlAccountList';
import { Balance } from '~/types/account';
import PnlAccountEdit from '../PnlAccountEdit';

const Details = (props: any) => {
  const { permissions, record } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  const classes = useStyles();

  if (!record) return null;
  return (
    <Section noPaddings>
      <Grid container justify='space-between'>
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('accountDetails')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          <PnlAccountEdit permissions={permissions} record={record} />
        </Grid>
      </Grid>
      <Section>
        <Grid container spacing={2}>
          <Grid xs spacing={2} item container direction='column'>
            <Grid item>
              <TextField label={t('alias')}>{record.alias}</TextField>
            </Grid>
            <Grid item>
              <TextField label={t('currency')}>{record.balances[0].amount.currency}</TextField>
            </Grid>
            <Grid item>
              <TextField label={t('status')}>
                <StatusField status='ACTIVE' />
              </TextField>
            </Grid>
          </Grid>
          <Grid xs spacing={2} item container direction='column'>
            <Grid item>
              <TextField label={t('glNumber')}>{record.data?.glNumber}</TextField>
            </Grid>
            <Grid item>
              <ReferenceField
                source='bankAccountId'
                record={record}
                reference='bank/accounts'
                basePath='/bank/accounts'
                link={false}
                addLabel={false}
              >
                <RaTextField
                  source='alias'
                  label={t('bankAccount')}
                  // showLink={(record) => `/bank/accounts/${record.id}/show`}
                />
              </ReferenceField>
            </Grid>
            <Grid item>
              <TextField label={t('description')} multiline>
                {record.description}
              </TextField>
            </Grid>
          </Grid>
          <Grid xs spacing={2} item container direction='column'>
            <Grid item>
              <TextField label={t('accountNumber')} copyable>
                {record.number}
              </TextField>
            </Grid>
            <Grid item>
              <ReferenceField
                source='productId'
                record={record}
                reference='products'
                link={false}
                addLabel={false}
              >
                <RaTextField
                  source='name'
                  label={t('productId')}
                  // showLink={(record) => `/solutions/${record.solutionId}/show/products`}
                />
              </ReferenceField>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Section>
        <H3 icon={<AttachMoneyIcon />} noPaddingLeft>
          {t('accountSummary')}
        </H3>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('balance')}</TableCell>
              <TableCell>{t('amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.balances?.map((balance: Balance, index: number) => {
              const { amount, code } = balance;
              return (
                <TableRow key={index}>
                  <TableCell>{t(`balanceCode.${code}`)}</TableCell>
                  <TableCell className={classes.amountCell}>
                    <AmountField amount={amount} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Section>
    </Section>
  );
};

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
}));

export default Details;
