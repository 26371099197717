import React, { Fragment } from 'react';
import { ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { H2, JsonField, StatusField, TextField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { Section } from '~/layout';
import { useExternalProfileLinkTranslate } from '..';
import { ExternalProfileLink } from '~/types/ExternalProfileLink';
import { buildProfileLink, Longdash, time } from '~/utils';
import EditAction from '~/components/ExternalProfileLinks/EditAction';
import DeleteAction from '~/components/ExternalProfileLinks/DeleteAction';

type Props = {
  record?: ExternalProfileLink;
  [x: string]: any;
};

const Details = (props: Props) => {
  const { record, permissions } = props;
  const t = useExternalProfileLinkTranslate();

  const classes = useStyles();

  if (!record) return null;

  const profileReference = record.profileType === 'INDIVIDUAL' ? 'individuals' : 'businesses';

  const profileTextFn = record
    ? record.profileType === 'INDIVIDUAL'
      ? (p: any) => `${p.firstName || Longdash} ${p.lastName}`
      : (p: any) => p.legalName
    : undefined;

  const hasUpdatePermission = permissions?.includes('externalProfileLink.update');
  const hasDeletePermission = permissions?.includes('externalProfileLink.delete');

  return (
    <Section noPaddings>
      <Grid container justify='space-between'>
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('commonDetails')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          {hasUpdatePermission && <EditAction link={record} />}
          {hasDeletePermission && <DeleteAction link={record} />}
        </Grid>
      </Grid>
      <Section>
        <Grid spacing={2} container className={classes.bottomSpacing}>
          <Grid item xs={4}>
            <TextField label={t('fields.system')}>{record.externalSourceSystem}</TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('fields.updated')}>
              {time(record.updated).format('ll, LTS')}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('fields.status')}>
              <StatusField status={record.status} />
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('fields.profileType')}>
              {t(`profileTypeInput.${record.profileType}`)}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <ReferenceField
              reference={profileReference}
              basePath={`/${profileReference}`}
              source='profileId'
              record={record}
              link={false}
              addLabel={false}
            >
              <RaTextField
                fn={profileTextFn}
                label={t('fields.profileTitle')}
                showLink={buildProfileLink(undefined, record.profileId, record.profileType)}
                textWrap
              />
            </ReferenceField>
          </Grid>
          <Grid item xs={4}>
            <TextField label={t('fields.referenceId')}>
              {(record.referenceId || []).map((id) => (
                <Fragment key={id}>
                  {id}
                  <br />
                </Fragment>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Section>
      <Grid container justify='space-between'>
        <Grid item xs={12}>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('identificationDetails')}
          </H2>
        </Grid>
        <Section>
          <JsonField json={record.identificationDetails} reactJsonOptions={{ collapsed: true }} />
        </Section>
      </Grid>
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
}));

export default Details;
