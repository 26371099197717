import React, { FC } from 'react';
import { SimpleForm as RaSimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  record?: any;
  initialValues?: any;
  toolbar: any;
  saving?: boolean;
  [x: string]: any;
}

const SimpleForm: FC<Props> = (props) => {
  const { record, saving, toolbar, children, initialValues, ...rest } = props;
  const classes = useStyles();

  return (
    <RaSimpleForm
      redirect={false}
      className={classes.form}
      record={record}
      toolbar={toolbar}
      margin='none'
      saving={saving}
      submitOnEnter={false}
      initialValues={initialValues}
      //fullWidth={fullWidth} // no effect in some reason
      {...rest}
    >
      {children}
    </RaSimpleForm>
  );
};

const useStyles = makeStyles(() => ({
  form: {
    '& > div': {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
}));

export default SimpleForm;
