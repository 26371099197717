import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import palette from '~/theme/palette';

interface TooltipIconProps {
  title: string;
}

const TooltipIcon = (props: TooltipIconProps) => {
  const { title } = props;
  const classes = useStyles();
  return (
    <Tooltip className={classes.tooltip} title={title}>
      <HelpIcon />
    </Tooltip>
  );
};
const useStyles = makeStyles(() => ({
  tooltip: {
    width: 16,
    height: 16,
    color: palette.tooltip.grey,
    cursor: 'pointer',
    marginLeft: 4,
    alignSelf: 'center',
  },
}));

export default TooltipIcon;
