import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { colors } from '@material-ui/core';
import cx from 'classnames';

import { useTheme } from '@material-ui/core/styles';

import { Notification } from 'react-admin';
import { useTranslate, useLogin, useNotify } from 'ra-core';
import { TextInput, Link } from '~/components';
import CardLayout from './CardLayout';

import OtpInput from 'react-otp-input';
import { beginSmsOtpChallenge } from '~/api/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 16,
  },
  h1: {
    fontSize: '30px',
    lineHeight: '30px',
  },
  subtitle: {
    color: colors.blueGrey[800],
    fontSize: '16px',
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  bottomSpacing1: {
    marginBottom: theme.spacing(1),
  },
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
  bottomSpacing3: {
    marginBottom: theme.spacing(3),
  },
  bottomSpacing4: {
    marginBottom: theme.spacing(4),
  },
  note: {
    color: colors.blueGrey[800],
    fontSize: '16px',
  },
}));

const label = (key: string): string => `layout.login.${key}`;

const LoginPath = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return <span>Dashboard</span>;
  } else {
    const path = hostname.split('.').map((item, index) => {
      if (index === 0) return <b>{item}</b>;
      return `.${item}`;
    });
    return <span>{path}</span>;
  }
};

const getDemoAccessHref = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return 'https://veengu.net/access';
  } else {
    const path = hostname
      .split('.')
      .map((item, index) => {
        if (index === 0) return '';
        if (index === 1) return item;
        return `.${item}`;
      })
      .join('');
    return `https://${path}/access`;
  }
};

const PrivacyComponent = ({ branding }: any) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  return (
    <Link
      href={branding?.tenant?.privacyPolicyUrl || 'http://veengu.com/privacy-policy/'}
      target='_blank'
    >
      {t('privacy')}
    </Link>
  );
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextInput
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

interface MfaChallenge {
  mfa: string[];
  maskedPhoneNumber?: string;
  challengeToken?: string;
}

const Login = ({ branding }: any) => {
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();

  const [mfa, setMfa] = useState<MfaChallenge>();
  const [otp, setOtp] = useState<string>();

  const issueCode = async () => {
    setLoading(true);
    setOtp('');

    try {
      const beginChallengeQuery = await beginSmsOtpChallenge();
      const beginChallengeResponse = beginChallengeQuery.data;

      setMfa({
        mfa: mfa?.mfa || [],
        maskedPhoneNumber: beginChallengeResponse.maskedPhoneNumber as string,
        challengeToken: beginChallengeResponse.challengeToken as string,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmit = (auth: FormValues) => {
    setLoading(true);
    login(
      mfa ? { smsOtp: otp, challengeToken: mfa.challengeToken } : auth,
      location.state ? location.state.nextPathname : '/'
    ).catch((error: Error) => {
      setLoading(false);
      if (error.message === 'mfa') {
        const anyError = error as any;
        setMfa({
          mfa: anyError.mfa,
        });
        issueCode();
      } else {
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        );
        if (mfa) {
          issueCode();
        }
      }
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!mfa) {
      if (!values.username) {
        errors.username = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      }
    }
    return errors;
  };

  const { hostname } = window.location;
  const showDemoAccess = hostname === 'localhost' || hostname.startsWith('demo.');

  const renderPasswordForm = (handleSubmit: any) => {
    return (
      <form onSubmit={handleSubmit} noValidate className={classes.root}>
        <Typography variant='h1' className={cx(classes.bottomSpacing2, classes.h1)}>
          {branding?.tenant?.name || 'Veengu'} Dashboard
        </Typography>
        <Typography variant='body2' className={cx(classes.bottomSpacing4, classes.subtitle)}>
          {t('singInTo')} <LoginPath />
        </Typography>
        <Field
          autoFocus
          name='username'
          // @ts-ignore
          component={renderInput}
          label={translate('ra.auth.username')}
          disabled={loading}
          className={classes.bottomSpacing1}
        />
        <Field
          name='password'
          // @ts-ignore
          component={renderInput}
          label={translate('ra.auth.password')}
          type='password'
          disabled={loading}
          className={classes.bottomSpacing1}
        />
        <Button
          variant='contained'
          type='submit'
          color='primary'
          disabled={loading}
          className={classes.bottomSpacing4}
          fullWidth
        >
          {loading ? <CircularProgress size={25} thickness={2} /> : translate('ra.auth.sign_in')}
        </Button>
        {showDemoAccess && <Link href={getDemoAccessHref()}>{t('getDemoAccess')}</Link>}
      </form>
    );
  };

  const renderMfaForm = (handleSubmit: any) => {
    return (
      <form onSubmit={handleSubmit} noValidate className={classes.root}>
        <Typography variant='h1' className={cx(classes.bottomSpacing2, classes.h1)}>
          {branding?.tenant?.name || 'Veengu'} Dashboard
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant='body2' className={cx(classes.bottomSpacing4, classes.subtitle)}>
              {t('enterCode')}
            </Typography>
            <Typography
              variant='body2'
              className={cx(classes.bottomSpacing4, classes.note)}
              style={{ maxWidth: 300 }}
            >
              {t('weHaveSentCode')} <b>{mfa?.maskedPhoneNumber}</b>
            </Typography>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={5}
              inputStyle={{
                fontSize: 24,
                margin: 3,
                padding: 2,
                color: theme.palette.text.primary,
              }}
              containerStyle={{
                marginBottom: '2em',
              }}
              isDisabled={loading}
            />
            <Button
              variant='contained'
              type='submit'
              color='primary'
              disabled={loading || otp?.length !== 5}
              className={classes.bottomSpacing4}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={25} thickness={2} />
              ) : (
                translate('ra.auth.sign_in')
              )}
            </Button>
            <span>
              {t('dontReceiveTheOtp')}{' '}
              <Link
                onClick={() => {
                  if (!loading) {
                    issueCode();
                  }
                }}
              >
                {t('resendOtp')}
              </Link>
            </span>
          </>
        )}
      </form>
    );
  };

  return (
    <Form
      key={mfa ? 'mfa' : 'password'}
      onSubmit={handleFormSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <CardLayout privacyComponent={<PrivacyComponent branding={branding} />}>
          {mfa ? renderMfaForm(handleSubmit) : renderPasswordForm(handleSubmit)}
          <Notification />
        </CardLayout>
      )}
    ></Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithThemeBuilder = (branding: any) => (props: any) => (
  <ThemeProvider theme={createMuiTheme(branding.theme)}>
    <Login {...props} branding={branding} />
  </ThemeProvider>
);

export default LoginWithThemeBuilder;
