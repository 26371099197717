import React, { Fragment } from 'react';
import { useTranslate, ReferenceField } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { SettingsIcon } from '~/img';
import { TextField, H3, AmountField, BooleanField, HilightChanges } from '~/components';
import { TextField as RaTextFiled } from '~/components/ra';

import { Section } from '~/layout';
import { l } from '~/resources/pricings/PricingShow';
import { billingDateFormat } from '../Forms/RegularFeeForm';
import { Longdash, getDateFromDayNumber } from '~/utils';

type RegularFeeType = {
  [x: string]: any;
};

interface RegularFeeContentProps {
  record?: RegularFeeType;
  prevRecord?: RegularFeeType;
}

export const RegularFeeContent = (props: RegularFeeContentProps) => {
  const { record = {}, prevRecord = {} } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);

  return (
    <Fragment>
      <Grid item xs={12}>
        <H3 icon={<SettingsIcon />} noPaddingLeft bottomSpacing={0} topSpacing={0}>
          {t('feeConfiguration')}
        </H3>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('statementTitle')}>
          <HilightChanges curr={record?.name} prev={prevRecord?.name}>
            {record?.name}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('pnlAccount')} ellipsis>
          <HilightChanges curr={record?.pnlAccountId} prev={prevRecord?.pnlAccountId}>
            {record.pnlAccountId && (
              <ReferenceField
                record={record}
                source='pnlAccountId'
                reference='tenant/accounts'
                link={false}
                addLabel={false}
                basePath='/tenant/accounts'
              >
                <RaTextFiled source='alias' addLabel={false} ellipsis />
              </ReferenceField>
            )}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('period')}>
          <HilightChanges curr={record?.period} prev={prevRecord?.period}>
            {record?.period && t(record?.period)}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('type')}>
          <HilightChanges curr={record?.type} prev={prevRecord?.type}>
            {record?.type && t(record?.type)}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('amount')}>
          <HilightChanges curr={record?.amount} prev={prevRecord?.amount}>
            <AmountField amount={record?.amount} />
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('billingDateType')}>
          <HilightChanges curr={record?.billingDateType} prev={prevRecord?.billingDateType}>
            {record?.billingDateType && t(record?.billingDateType)}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField label={t('billingDate')}>
          <HilightChanges curr={record?.billingDate} prev={prevRecord?.billingDate}>
            {record?.billingDate
              ? record?.period === 'annually'
                ? getDateFromDayNumber(record?.billingDate).format(billingDateFormat)
                : record?.billingDate
              : Longdash}
          </HilightChanges>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <HilightChanges
          curr={record?.payOnTheFirstCalendarMonth}
          prev={prevRecord?.payOnTheFirstCalendarMonth}
        >
          <BooleanField
            label={t('payOnTheFirstCalendarMonth')}
            value={!!record?.payOnTheFirstCalendarMonth}
          />
        </HilightChanges>
      </Grid>
    </Fragment>
  );
};

interface Props {
  record?: RegularFeeType;
  prevRecord?: RegularFeeType;
}

const RegularFeeConfirmationField = (props: Props) => {
  const { record, prevRecord } = props;

  return (
    <Section>
      <Grid item container spacing={2}>
        <RegularFeeContent record={record} prevRecord={prevRecord} />
      </Grid>
    </Section>
  );
};

export default RegularFeeConfirmationField;
