import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useListPaginationContext } from 'ra-core';

import usePath from '~/hooks/usePath';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TablePagination from '@material-ui/core/TablePagination';
import { PaginationActions } from 'ra-ui-materialui';
import { history } from '~/App';
import { useDebouncedCallback } from 'use-debounce/lib';
import cx from 'classnames';
import useMutableCallback from '~/hooks/useMutableCallback';

interface Props {
  defaultPerPage?: number;
  rowsPerPageOptions?: number[];
  noBackgraund?: boolean;
  className?: string;
  [x: string]: any;
}

const RaPagination: FC<Props> = (props) => {
  const { defaultPerPage = 25, rowsPerPageOptions, noBackgraund, className } = props;
  const { total, setPage, setPerPage } = useListPaginationContext(props);
  const translate = useTranslate();
  const {
    changePath,
    params: { page, perPage },
  } = usePath();

  const setDefaultPagination = useDebouncedCallback(() => {
    changePath({ page: 1, perPage: defaultPerPage });
  }, 0);

  useEffect(() => {
    if (page) {
      setPage(page);
    } else {
      setDefaultPagination();
    }

    if (perPage) {
      setPerPage(perPage);
    } else {
      setDefaultPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, setDefaultPagination]);

  useEffect(() => {
    return () => {
      history.replace(history.location.pathname);
    };
  }, []);

  const totalPages = useMemo(() => {
    return Math.ceil(total / (perPage || 1)) || 1;
  }, [perPage, total]);

  const isEmptyPage = useMemo(() => {
    if (!page || !perPage) return false;
    return page > totalPages;
  }, [page, perPage, totalPages]);
  const goToValidPage = useMutableCallback(() => {
    changePath({ page: totalPages });
  });
  useEffect(() => {
    if (isEmptyPage) {
      goToValidPage();
    }
  }, [isEmptyPage, goToValidPage]);

  const handlePageChange = useCallback(
    (event: any, p: number) => {
      event && event.stopPropagation();
      if (p < 0 || p > totalPages - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: p + 1,
          })
        );
      }
      changePath({ page: p + 1 });
    },
    [totalPages, translate, changePath]
  );

  const handlePerPageChange = useCallback(
    (event) => {
      const newPerPage = event.target.value;
      changePath({ perPage: newPerPage });
    },
    [changePath]
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  );

  const classes = useStyles();

  return (
    <div className={cx(classes.root, className, { [classes.noBackgraund]: noBackgraund })}>
      <TablePagination
        count={total}
        rowsPerPage={perPage || defaultPerPage}
        page={(page || 1) - 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        ActionsComponent={PaginationActions}
        component='span'
        labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPageOptions={rowsPerPageOptions}
        classes={{
          toolbar: cx(classes.toolbar, className, { [classes.noBackgraund]: noBackgraund }),
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  toolbar: {
    borderTop: 'unset',
  },
  noBackgraund: {
    background: 'unset',
    borderTop: 'unset',
  },
}));

export default RaPagination;
