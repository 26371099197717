import React, { Fragment, useState, useCallback, FC } from 'react';
import {
  ReferenceManyField,
  useTranslate,
  TextField,
  SingleFieldList,
  Datagrid,
  ArrayField,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { debounce, set as lodashSet } from 'lodash';

import {
  ShowController,
  NoPermissions,
  ChipField,
  TextField as RaTextField,
  BooleanField,
} from '~/components/ra';
import { SelectInput, TextInput } from '~/components';
import { Employee } from '~/types';
import EmployeeShow from './EmployeeShow';
import EmployeeCreate from './EmployeeCreate';
import { Longdash } from '~/utils';
import RaPagination from '~/components/Pagination/RaPagination';
import usePath from '~/hooks/usePath';
import useEmployeeRoles, { useEmployeeRolesTranslations } from '~/hooks/useEmployeeRoles';

const EmployeesList: FC<any> = (props) => {
  const { record, permissions, ...rest } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.employees.${key}`);

  const [showData, setShowData] = useState(null);
  const handleOpen = (data: any) => {
    setShowData(data);
  };
  const handleClose = () => setShowData(null);

  const [filters, setFilters] = useState<any>({});
  const classes = useStyles();

  const { data: tEmployees } = useEmployeeRolesTranslations();

  if (!record) return null;
  if (!permissions) return null;
  if (!permissions.includes('business.employee.list')) return <NoPermissions />;

  return (
    <Fragment>
      <EmployeeShow
        showData={showData}
        onClose={handleClose}
        businessRecord={record}
        permissions={permissions}
        parentProps={rest}
      />
      <Grid container justify='space-between' alignItems='center'>
        <Grid item>
          <Filters {...props} filters={filters} setFilters={setFilters} />
        </Grid>
        <Grid item className={classes.bottomSpacing}>
          <EmployeeCreate permissions={permissions} businessRecord={record} />
        </Grid>
      </Grid>
      <ReferenceManyField
        label='Employees'
        reference='employees'
        target='businesses'
        pagination={
          <RaPagination
            defaultPerPage={perPage}
            rowsPerPageOptions={[perPage, perPage * 2, perPage * 5, perPage * 10]}
          />
        }
        perPage={perPage}
        filter={filters}
        {...props}
      >
        <Datagrid rowClick={(id: string, basePath: string, record: any) => handleOpen(record)}>
          <RaTextField
            label={t('name')}
            addLabel={false}
            fn={(record: Employee) => `${record?.firstName || ''} ${record?.lastName || ''}`}
          />
          <ArrayField source='roles' label={t('authority.employeeRole._')}>
            <SingleFieldList linkType={false}>
              <ShowController>
                {({ showRecord, ...rest }) => {
                  if (!showRecord || !tEmployees) return '';
                  return (
                    <ChipField record={{ name: tEmployees[showRecord] }} source='name' {...rest} />
                  );
                }}
              </ShowController>
            </SingleFieldList>
          </ArrayField>
          <TextField label={t('authority.position')} source='authority.position' />
          <BooleanField
            label={t('authority.owner')}
            addLabel={false}
            source='authority.owner'
            nullOnFalse
          />
          <BooleanField
            label={t('authority.executive')}
            addLabel={false}
            source='authority.executive'
            nullOnFalse
          />
        </Datagrid>
      </ReferenceManyField>
    </Fragment>
  );
};

const Filters = (props: any) => {
  const { filters, setFilters, record } = props;
  const { solutionId } = record;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.employees.${key}`);
  const tRole = (key: string): string => t(`authority.employeeRole.${key}`);
  const { changePath } = usePath();
  const { data: employeeRoles } = useEmployeeRoles(solutionId);

  const debouncedSetFilters = useCallback(debounce(setFilters, 400), [setFilters]);
  const handleChange = (e: any) => {
    if (!e?.target) return null;
    const { name, value } = e.target;
    changePath({ page: 1 });
    debouncedSetFilters((prev: any) => {
      return { ...lodashSet(prev, name, value) };
    });
  };

  const classes = useStyles();
  return (
    <form className={classes.filters}>
      <TextInput
        name='name'
        source='name'
        label={t('name')}
        disableHelperText
        onChange={handleChange}
        className={classes.formControl}
      />
      <SelectInput
        allowEmpty
        name='role'
        value={filters.role === undefined ? '' : filters.role}
        label={tRole('_')}
        options={employeeRoles?.map(({ role, name }) => ({ value: role, text: name })) ?? []}
        emptyText={t('any')}
        onChange={handleChange}
        className={classes.formControl}
      />
      <SelectInput
        allowEmpty
        name='owner'
        value={filters.owner === undefined ? '' : filters.owner}
        label={t('authority.owner')}
        options={[
          { value: true, text: 'Yes' },
          { value: false, text: 'No' },
        ]}
        emptyText={Longdash}
        onChange={handleChange}
        className={classes.formControl}
      />
      <SelectInput
        allowEmpty
        name='executive'
        value={filters.executive === undefined ? '' : filters.executive}
        label={t('authority.executive')}
        options={[
          { value: true, text: 'Yes' },
          { value: false, text: 'No' },
        ]}
        emptyText={Longdash}
        onChange={handleChange}
        className={classes.formControl}
      />
    </form>
  );
};

const perPage = 10;

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  filters: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 150,
    maxWidth: 270,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export default EmployeesList;
