import React, { useState, FC } from 'react';
import { useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import { notification } from '~/ducks/snackbarNotifications';
import { changePassword } from '~/api/user';
import { TextInput } from '~/components';
import PasswordStrengthChecklist, {
  passwordValidators,
} from '~/components/PasswordStrengthChecklist';
import onError from '~/errorsHandler';

const useStyles = makeStyles({
  root: {
    width: 360,
  },
  applyButton: {
    minWidth: 125,
  },
});

const noDisplay = {
  display: 'none',
};

interface Props {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

const initialValidState = {
  isValid: true,
  errorMessage: '',
};

const initialPasswordsState = {
  oldPassword: '',
  password: '',
  passwordAgain: '',
};

const ChangePasswordModal: FC<Props> = ({ isOpen, setOpen }) => {
  const [validOldPassword, setValidOldPassword] = useState(initialValidState);
  const [validPassword, setValidPassword] = useState(initialValidState);
  const [validPasswordAgain, setValidPasswordAgain] = useState(initialValidState);

  const [loading, setLoading] = useState(false);

  const [passwords, setPasswords] = useState(initialPasswordsState);

  const dispatch = useDispatch();
  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(`layout.ChangePasswordModal.${key}`);

  const handleClose = () => {
    setValidOldPassword(initialValidState);
    setValidPassword(initialValidState);
    setValidPasswordAgain(initialValidState);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const { oldPassword, password, passwordAgain } = passwords;
    if (!oldPassword || !password || !passwordAgain) {
      if (!oldPassword) {
        setValidOldPassword({
          isValid: false,
          errorMessage: translateLabel('required'),
        });
      }
      if (!password) {
        setValidPassword({
          isValid: false,
          errorMessage: translateLabel('required'),
        });
      }
      if (!passwordAgain) {
        setValidPasswordAgain({
          isValid: false,
          errorMessage: translateLabel('required'),
        });
      }
      return;
    }
    if (password !== passwordAgain) {
      setValidPasswordAgain({
        isValid: false,
        errorMessage: translateLabel('notMatch'),
      });
      return;
    }
    if (!passwordValidators.enothLength(password)) {
      setValidPassword({
        isValid: false,
        errorMessage: translateLabel('length'),
      });
      return;
    }
    if (!passwordValidators.containsLetters(password)) {
      setValidPassword({
        isValid: false,
        errorMessage: translateLabel('mustContainLetters'),
      });
      return;
    }
    if (!passwordValidators.containsDigits(password)) {
      setValidPassword({
        isValid: false,
        errorMessage: translateLabel('mustContainDigits'),
      });
      return;
    }

    try {
      setLoading(true);
      await changePassword(oldPassword, password);
      setPasswords(initialPasswordsState);
      setOpen(false);
      dispatch(
        notification({
          message: translateLabel('success'),
          severity: 'success',
        })
      );
    } catch (error) {
      const message: string = error?.body?.message || '';
      if (message.toLowerCase().includes('password does not match')) {
        setValidOldPassword({
          isValid: false,
          errorMessage: translateLabel('notMatch'),
        });
      } else {
        onError(error);
      }
    }
    setLoading(false);
  };

  const handleInput = ({ target }: any) => {
    setValidOldPassword(initialValidState);
    setValidPassword(initialValidState);
    setValidPasswordAgain(initialValidState);
    if (target) {
      const { id, value } = target;
      setPasswords({
        ...passwords,
        [id]: value
          .split('')
          .filter((char: string) => char !== ' ')
          .join(''),
      });
    }
  };

  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <div className={classes.root}>
        <DialogTitle id='form-dialog-title'>{translateLabel('_')}</DialogTitle>
        <DialogContent>
          <input type='password' tabIndex={-1} style={noDisplay} />
          <TextInput
            value={passwords.oldPassword}
            onChange={handleInput}
            autoFocus
            margin='dense'
            id='oldPassword'
            label={translateLabel('oldPassword')}
            type='password'
            fullWidth
            error={!validOldPassword.isValid}
            helperText={validOldPassword.errorMessage}
            required
          />
          <TextInput
            value={passwords.password}
            onChange={handleInput}
            margin='dense'
            id='password'
            label={translateLabel('password')}
            type='password'
            fullWidth
            error={!validPassword.isValid}
            helperText={validPassword.errorMessage}
            required
          />
          <TextInput
            value={passwords.passwordAgain}
            onChange={handleInput}
            margin='dense'
            id='passwordAgain'
            label={translateLabel('passwordAgain')}
            type='password'
            fullWidth
            error={!validPasswordAgain.isValid}
            helperText={validPasswordAgain.errorMessage}
            required
          />
          <PasswordStrengthChecklist password={passwords.password} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {translate('ra.action.cancel')}
          </Button>
          <Button className={classes.applyButton} onClick={handleSubmit} color='primary'>
            {loading ? (
              <CircularProgress size={24} thickness={2} />
            ) : (
              translateLabel('applyChanges')
            )}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ChangePasswordModal;
