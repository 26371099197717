import React, { FC } from 'react';
import { Button } from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui';

import { ImportIcon } from '~/img';

const ImportButton: FC<ButtonProps> = (props) => {
  return (
    <Button label='components.ImportButton' {...props}>
      <ImportIcon />
    </Button>
  );
};

export default ImportButton;
