import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { SECTION_ACTIONS_ID } from './Section';

interface Props {
  actions?: JSX.Element;
  topSpacing?: number;
  bottomSpacing?: number;
}

const Heading: FC<Props> = (props) => {
  const { children, actions, topSpacing = 0, bottomSpacing = 0 } = props;
  const classes = useStyles({ topSpacing, bottomSpacing });
  return (
    <Grid container justify='space-between' alignItems='center' className={classes.root}>
      <Grid item>{children}</Grid>
      <Grid item id={SECTION_ACTIONS_ID}>
        {actions}
      </Grid>
    </Grid>
  );
};

interface StylesProps {
  topSpacing: number;
  bottomSpacing: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    marginTop: ({ topSpacing }) => theme.spacing(topSpacing),
    marginBottom: ({ bottomSpacing }) => theme.spacing(bottomSpacing),
  },
}));

export default Heading;
