import React from 'react';

import { ByFeature } from '.';

import AcquiringFeeView from './Views/AcquiringFeeView';
import MembershipFeeView from './Views/MembershipFeeView';
import DefaultFeeView from './Views/DefaultFeeView';
import FeeLoyaltyView from './Views/FeeLoyaltyView';
import DefaultAmountLimitView from './Views/DefaultAmountLimitView';
import DefaultCountLimitView from './Views/DefaultCountLimitView';

interface Props {
  schema: ByFeature;
  entity: any;
  currency: string;
}

const PricingDataShow = (props: Props) => {
  const { schema, entity, currency } = props;

  /*
  const keys = Object.keys(record.data).filter(
    (k) => k.startsWith(schema.propertyKey) && k !== schema.propertyKey
  );
  keys.unshift(schema.propertyKey);

  console.log(keys);
   */

  return (() => {
    // const data = lodashGet(record.data, key); // ? (Object.keys(record.data[key]) == )
    switch (schema.type) {
      case 'veengu_fee_membership':
        return <MembershipFeeView record={entity || {}} />;
      case 'veengu_fee_default':
        return <DefaultFeeView record={entity || {}} />;
      case 'veengu_tax_default':
        return <DefaultFeeView record={entity || {}} tax />;
      case 'veengu_fee_acquiring':
        return <AcquiringFeeView record={entity || {}} />;
      case 'veengu_fee_loyalty_default':
        return <FeeLoyaltyView record={entity || {}} />;
      case 'veengu_limit_default_amount':
      case 'veengu_limit_single_operation_amount': {
        const defaultValue = schema['v:default'];
        return (
          <DefaultAmountLimitView
            record={entity || (defaultValue ? { amount: { currency, value: defaultValue } } : {})}
          />
        );
      }
      case 'veengu_limit_default_count': {
        const defaultValue = schema['v:default'];
        return (
          <DefaultCountLimitView record={entity || (defaultValue ? { count: defaultValue } : {})} />
        );
      }
      default:
        return null;
    }
  })();
};

export default PricingDataShow;
