import React, { Fragment, useState, useCallback, useEffect } from 'react';

import { usePermissions, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';

import { DateTimePicker } from '@material-ui/pickers';
import { TextField, Switch, FormControlLabel, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import {
  CountryAutocomplete,
  SelectInput,
  SolutionSelect,
  KycLevelSelect,
  AddFilterAction,
  ProfileTagsFilter,
} from '~/components';
import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { Moment } from '~/types';
import { IndividualFilters } from '~/types/individual';
import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import IndividualCreateAction from '../../IndividualCreateAction';
import CustomModule from '~/modules';
import cx from 'classnames';

//--------------------------------------------
const resource = 'individuals';

interface Props {
  cleanFilters?: boolean;
}

const Filters = (props: Props) => {
  const { cleanFilters = true } = props;
  const filters = useSelector(selectors.getFilters<IndividualFilters>(resource));
  const [localValues, setLocalValues] = useState<IndividualFilters>(filters);

  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const t = (key: string): string => translate(`resources.individuals.fields.${key}`);

  const { permissions } = usePermissions();
  const hasExtendedSearchPermission = permissions?.includes('individual.search.extended');

  const dispatchFilterChange = (name: string, value: string | Moment) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 500), []);

  const handleDateTimeChange = (data: Moment, inputType: string) => {
    debouncedDispatchFilterChange(inputType, data);
  };

  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const filtersAreEmpty = isEmpty(filters);
  useEffect(() => {
    if (cleanFilters || filtersAreEmpty) {
      dispatch(cleanupFilters({ resource }));
      dispatch(
        filterChange({
          resource,
          filters: {
            withDeleted: false,
          },
        })
      );
    }
  }, [dispatch, cleanFilters, filtersAreEmpty]);

  const handleSelectInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    dispatchFilterChange(name, value);
  };

  const [addedFilters, setAddedFilters] = useState<string[]>(
    Object.keys(filters).filter((k) => {
      return !!(filters as any)[k];
    })
  );

  const hideFilter = (filter: string) => {
    let inputName = filter;
    if (filter === 'createdAtFrom') {
      inputName = 'fromCreatedAt';
    } else if (filter === 'createdAtTo') {
      inputName = 'toCreatedAt';
    }
    debouncedHandleInput({
      target: { name: inputName, value: null },
    });
    setAddedFilters(addedFilters.filter((f) => f !== filter));
  };

  const allFilters = hasExtendedSearchPermission
    ? [
        'accountNumber',
        'primaryDocumentNumber',
        'solution',
        'country',
        'kycLevel',
        'kycStatus',
        'createdAtFrom',
        'createdAtTo',
        'status',
        'affiliationType',
        'affiliatedObjectId',
        'tags',
      ]
    : ['accountNumber', 'primaryDocumentNumber'];

  const renderAddedFilter = (key: string) => {
    if (hasExtendedSearchPermission) {
      switch (key) {
        case 'accountNumber':
          return (
            <TextField
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('accountNumber')}
              onChange={debouncedHandleInput}
              value={localValues.accountNumber ?? ''}
              name='accountNumber'
            />
          );
        case 'primaryDocumentNumber':
          return (
            <TextField
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('documentNumber')}
              onChange={debouncedHandleInput}
              value={localValues.primaryDocumentNumber ?? ''}
              name='primaryDocumentNumber'
            />
          );
        case 'solution':
          return (
            <SolutionSelect
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('solutionId')}
              onChange={handleSelectInput}
              value={localValues.solution ?? ''}
              name='solution'
              withFilter={(solution) => solution.features.includes('veengu_individual_profile')}
              allowEmpty
            />
          );
        case 'country':
          return (
            <CountryAutocomplete
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('country')}
              value={localValues.country}
              name='country'
              onChange={handleSelectInput}
              fullWidth
            />
          );
        case 'kycLevel':
          return (
            <KycLevelSelect
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('kycLevel')}
              onChange={debouncedHandleInput}
              value={localValues.kycLevel ?? ''}
              name='kycLevel'
              withFeature='veengu_individual_profile'
              allowEmpty
            />
          );
        case 'kycStatus':
          return (
            <SelectInput
              className={cx(classes.formControl, classes.formControlAdd)}
              value={localValues.kycStatus}
              label={t('kycStatus')}
              name='kycStatus'
              onChange={handleSelectInput}
              allowEmpty={false}
              options={[
                { value: '', text: t('any') },
                { value: 'ACTIVE', text: t('active') },
                { value: 'PENDING', text: t('pending') },
              ]}
              defaultValue=''
            />
          );
        case 'createdAtFrom':
          return (
            <DateTimePicker
              className={cx(classes.formControl, classes.datePickers, classes.formControlAdd)}
              format='L - LT'
              margin='normal'
              label={t('createdAtFrom')}
              value={localValues.fromCreatedAt || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'fromCreatedAt')}
              clearable
            />
          );
        case 'createdAtTo':
          return (
            <DateTimePicker
              className={cx(classes.formControl, classes.datePickers, classes.formControlAdd)}
              format='L - LT'
              margin='normal'
              label={t('createdAtTo')}
              value={localValues.toCreatedAt || null}
              onChange={(date) => handleDateTimeChange(date as Moment, 'toCreatedAt')}
              clearable
            />
          );
        case 'status':
          return (
            <SelectInput
              className={cx(classes.formControl, classes.formControlAdd)}
              value={localValues.status}
              label={t('status')}
              name='status'
              onChange={handleSelectInput}
              allowEmpty={false}
              options={[
                { value: '', text: t('any') },
                { value: 'ACTIVE', text: t('active') },
                { value: 'BLOCKED', text: t('blocked') },
                { value: 'CLOSED', text: t('closed') },
              ]}
              defaultValue=''
            />
          );
        case 'affiliationType':
          return (
            <SelectInput
              className={cx(classes.formControl, classes.formControlAdd)}
              value={localValues.affiliationType}
              label={t('affiliationType')}
              name='affiliationType'
              onChange={handleSelectInput}
              allowEmpty={false}
              options={[
                { value: '', text: t('any') },
                { value: 'INVITED_BY', text: t('INVITED_BY') },
                { value: 'CLONED_FROM', text: t('CLONED_FROM') },
              ]}
              defaultValue=''
            />
          );
        case 'affiliatedObjectId':
          return (
            <TextField
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('affiliatedObjectId')}
              onChange={debouncedHandleInput}
              value={localValues.affiliatedObjectId ?? ''}
              name='affiliatedObjectId'
            />
          );
        case 'tags':
          return (
            <ProfileTagsFilter
              value={localValues.tags}
              className={cx(
                classes.formControl,
                classes.formControlAdd,
                classes.formControlAddLong
              )}
              onChange={(_e: any, value: string[], reason: string) => {
                switch (reason) {
                  case 'create-option':
                  case 'select-option':
                  case 'remove-option':
                    debouncedHandleInput({ target: { name: 'tags', value } });
                    break;
                  case 'clear':
                    debouncedHandleInput({ target: { name: 'tags', value: [] } });
                    break;
                  default:
                    break;
                }
              }}
              resource='individuals'
            />
          );
        default:
          return null;
      }
    } else {
      switch (key) {
        case 'accountNumber':
          return (
            <TextField
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('accountNumber')}
              onChange={debouncedHandleInput}
              value={localValues.accountNumber ?? ''}
              name='accountNumber'
            />
          );
        case 'primaryDocumentNumber':
          return (
            <TextField
              className={cx(classes.formControl, classes.formControlAdd)}
              label={t('documentNumber')}
              onChange={debouncedHandleInput}
              value={localValues.primaryDocumentNumber ?? ''}
              name='primaryDocumentNumber'
            />
          );
        default:
          return null;
      }
    }
  };

  const translateFilter = (key: string) => {
    switch (key) {
      case 'primaryDocumentNumber':
        return t('documentNumber');
      case 'solution':
        return t('solutionId');
      default:
        return t(key);
    }
  };

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div className={classes.leftSide}>
            {hasExtendedSearchPermission ? (
              <div className={classes.inputsGroup}>
                <TextField
                  className={cx(classes.formControl, classes.formControlLong)}
                  label={t('name')}
                  onChange={debouncedHandleInput}
                  value={localValues.name ?? ''}
                  name='name'
                />
                <TextField
                  className={cx(classes.formControl, classes.formControlLong)}
                  label={t('phoneNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.phoneNumber ?? ''}
                  name='phoneNumber'
                  placeholder='+'
                />
                <FormControlLabel
                  className={cx(classes.formControl, classes.formControlLong)}
                  control={
                    <Switch
                      onChange={(e) => {
                        debouncedHandleInput({
                          target: {
                            value: e.target.checked,
                            name: 'withDeleted',
                          },
                        });
                      }}
                      value={localValues.withDeleted ?? ''}
                    />
                  }
                  label={t('withDeleted')}
                />
              </div>
            ) : (
              <div className={classes.inputsGroup}>
                <TextField
                  className={cx(classes.formControl, classes.formControlLong)}
                  label={t('name')}
                  onChange={debouncedHandleInput}
                  value={localValues.name ?? ''}
                  name='name'
                />
                <TextField
                  className={cx(classes.formControl, classes.formControlLong)}
                  label={t('phoneNumber')}
                  onChange={debouncedHandleInput}
                  value={localValues.phoneNumber ?? ''}
                  name='phoneNumber'
                  placeholder='+'
                />
              </div>
            )}
            <div className={classes.inputsGroup}>
              {addedFilters.map((filter) => {
                const component = renderAddedFilter(filter);
                if (component) {
                  return (
                    <div className={classes.flexContainer} key={filter}>
                      {component}
                      <IconButton
                        aria-label='delete'
                        className={classes.deleteIcon}
                        onClick={() => hideFilter(filter)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className={classes.rightSide}>
            <AddFilterAction
              filters={allFilters
                .filter((f) => !addedFilters.includes(f))
                .sort()
                .map((filterKey) => ({
                  key: filterKey,
                  label: translateFilter(filterKey),
                }))}
              onFilterSelected={(filter: string) => {
                setAddedFilters(addedFilters.concat([filter]));
              }}
            />
            <IndividualCreateAction />
            <BackgroundTaskExport
              type='INDIVIDUAL_EXPORT'
              queryArguments={handleFilters(localValues)}
            />
            <CustomModule name='IndividualImport' />
          </div>
        </div>
      )}
    </Fragment>
  );
};

const handleFilters = (filters: IndividualFilters) => {
  if (!filters) return {};
  return filters;
};

const useStyles = makeStyles((theme) => {
  const formControlWidth = 200;
  const deleteIconSize = theme.spacing(1) * 4;
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      paddingBottom: theme.spacing(1),
    },
    flexContainer: {
      display: 'flex',
    },
    leftSide: {
      maxWidth: 'calc(100% - 330px)',
    },
    rightSide: { minWidth: 330, textAlign: 'right' },
    deleteIcon: {
      width: deleteIconSize,
      height: deleteIconSize,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    inputsGroup: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    formControl: {
      paddingBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: formControlWidth,
      '&:last-child': {
        marginRight: 0,
      },
    },
    formControlLong: {
      width: formControlWidth + deleteIconSize,
    },
    datePickers: {
      marginTop: 0,
      marginBottom: 0,
      '& > div': {
        paddingRight: 0,
        '& > div': {
          marginLeft: 0,
        },
      },
    },
    formControlAdd: {
      marginRight: 0,
    },
    formControlAddLong: {
      width: formControlWidth * 2 + deleteIconSize + theme.spacing(1),
    },
  };
});

export default Filters;
