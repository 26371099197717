import * as React from 'react';

import { Labeled } from 'react-admin';
import { Box } from '@material-ui/core';

import * as hb from '~/types/Handbook';
import IndividualDocumentParameterTable from './IndividualDocumentParameterTable';

export interface Props {
  document: hb.IndividualDocument;
  setDocument: hb.SetIndividualDocument;
}

const IndividualDocumentDetails = ({ document, setDocument }: Props) => {
  return (
    <div style={{ backgroundColor: '#fafafa', paddingLeft: '56px' }}>
      <Box p={1} style={{ paddingRight: 0 }}>
        <Labeled label='Parameters'>
          <IndividualDocumentParameterTable document={document} setDocument={setDocument} />
        </Labeled>
      </Box>
    </div>
  );
};

export default IndividualDocumentDetails;
