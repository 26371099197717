import React, { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LocationEdit from './LocationEdit';
import InfoIcon from '@material-ui/icons/Info';
import PhotoIcon from '@material-ui/icons/InsertPhoto';
import { RibbonIcon, AddressIcon } from '~/img';
import { Drawer, TextField, StatusField, H3, BooleanField, ChipField } from '~/components';
import {
  AddressView,
  AvatarField,
  DeleteButton,
  MerchantCategoryCodeField,
  ImageField,
} from '~/components/ra';
import { Location, Business } from '~/types';
import WorkingHoursField from './components/WokingHoursField';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

interface Props {
  showData: Location | null;
  onClose: () => void;
  businessRecord: Business;
  permissions: string[];
}

const LocationShow = (props: Props) => {
  const { showData, onClose, businessRecord, permissions } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.locations.${key}`);

  const classes = useStyles();

  return (
    <Drawer
      heading={
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <AvatarField source='branding.icon' record={showData} />
          </Grid>
          <Grid item>{showData?.name || ''}</Grid>
        </Grid>
      }
      open={!!showData}
      onClose={onClose}
    >
      {showData && (
        <Fragment>
          <div className={classes.bottomSpacing}>
            <TextField label={t('status._')} icon={<RibbonIcon />}>
              <StatusField status={showData.status} />
            </TextField>
          </div>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <TextField label={t('MCC')}>
                <MerchantCategoryCodeField record={showData} />
              </TextField>
            </Grid>
            <Grid item>
              <TextField label={t('groups')} textWrap>
                {showData?.groups?.map((t: string) => (
                  <ChipField key={t} label={t} />
                ))}
              </TextField>
            </Grid>
          </Grid>

          <H3 icon={<AddressIcon />}>{t('address._')}</H3>
          {showData.address && (
            <AddressView address={showData.address} withIcon className={classes.container} />
          )}

          {!!showData.branding?.photos?.length && (
            <>
              <H3 icon={<PhotoIcon />}>{t('photos')}</H3>
              <Grid container spacing={1} className={classes.container}>
                {showData.branding?.photos?.map((item) => {
                  return (
                    <Grid item xs={6}>
                      <ImageField
                        key={item.id}
                        record={item}
                        noText
                        width={150}
                        height={150}
                        objectFit='contain'
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}

          {showData.schedule && (
            <>
              <H3 icon={<ScheduleOutlinedIcon />}>{t('workingHours')}</H3>
              <WorkingHoursField schedule={showData.schedule} country={businessRecord.country} />
            </>
          )}

          <H3 icon={<InfoIcon />}>{t('additionalProperties._')}</H3>
          <Grid container direction='column' spacing={2} className={classes.container}>
            <Grid item>
              <BooleanField
                label={t('additionalProperties.headOffice')}
                value={!!showData.additionalProperties?.headOffice}
              />
            </Grid>
          </Grid>

          <Grid container item spacing={2} justify='flex-end'>
            <Grid item>
              <DeleteButton
                resource='locations'
                target='businesses'
                targetId={businessRecord.id}
                record={showData}
                disabled={!permissions?.includes('business.location.delete')}
                onClose={onClose}
              />
            </Grid>
            <Grid item>
              <LocationEdit
                locationRecord={showData}
                businessRecord={businessRecord}
                closeParent={onClose}
                permissions={permissions}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(4),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
}));

export default LocationShow;
