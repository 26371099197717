export default {
  externalSources: 'External funds sources',
  empty: 'No external sources found.',
  balanceNotAvailable: 'Balance not available',
  card: {
    number: 'Card number',
    expirationDate: 'Date of expiry',
    paymentSystem: 'Payment system',
  },
  account: {
    number: 'Account number',
  },
  linkedOn: 'Linked on',
  sourceType: 'Type',
  sourceSystem: 'System',
  parameter: 'Parameter',
  data: 'Data',
  status: 'Status',
  externalDetails: 'External details',
  emptyDetails: 'External details not available',
};
