import * as React from 'react';

import { Labeled } from 'react-admin';
import { Box } from '@material-ui/core';

import * as hb from '~/types/Handbook';
import BusinessDocumentTable from './BusinessDocumentTable';

export interface Props {
  legalType: hb.BusinessLegalType;
  setLegalType: hb.SetLegalType;
}

const BusinessLegalTypeDetails = ({ legalType, setLegalType }: Props) => {
  return (
    <div style={{ backgroundColor: '#fafafa', paddingLeft: '56px' }}>
      <Box p={1} style={{ paddingRight: 0 }}>
        <Labeled label='Business documents'>
          <BusinessDocumentTable legalType={legalType} setLegalType={setLegalType} />
        </Labeled>
      </Box>
    </div>
  );
};

export default BusinessLegalTypeDetails;
