import * as React from 'react';

import { Labeled } from 'react-admin';
import { Box } from '@material-ui/core';

import * as hb from '~/types/Handbook';
import { SelectInput } from '~/components';
import IndividualDocumentTable from './IndividualDocumentTable';
import BusinessLegalTypeTable from './BusinessLegalTypeTable';
import { useUpdateHanbookLoading } from './HandbookField';

export interface Props {
  country: hb.Country;
  setCountry: hb.SetCountry;
}

const CountryDetails = ({ country, setCountry }: Props) => {
  const [isLoading] = useUpdateHanbookLoading();
  return (
    <div style={{ backgroundColor: '#fafafa', paddingLeft: '56px' }}>
      <Box p={1} style={{ paddingRight: 0, maxWidth: 400 }}>
        <Labeled label='Config'>
          <SelectInput
            label='First day of week'
            value={country.firstDayOfWeek}
            options={[
              { value: 'MO', text: 'Monday' },
              { value: 'SU', text: 'Sunday' },
            ]}
            onChange={(e) => setCountry({ ...country, firstDayOfWeek: e.target.value })}
            disabled={isLoading}
          />
        </Labeled>
      </Box>
      <Box p={1} style={{ paddingRight: 0 }}>
        <Labeled label='Individual documents'>
          <IndividualDocumentTable country={country} setCountry={setCountry} />
        </Labeled>
      </Box>
      <Box p={1} style={{ paddingRight: 0 }}>
        <Labeled label='Business legal types'>
          <BusinessLegalTypeTable country={country} setCountry={setCountry} />
        </Labeled>
      </Box>
    </div>
  );
};

export default CountryDetails;
