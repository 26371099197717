import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  FormDataConsumer,
  SelectInput,
  NumberInput,
  number,
  minValue,
  useTranslate,
  required,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { isEmpty } from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionDetails,
  Grid,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { AmountField, BottomToolbar, TextField, AccordionSummary } from '~/components';
import { AmountInput, CurrencySelect, HiddenInput } from '~/components/ra';
import { l } from '~/resources/pricings/PricingShow';
import { ByFeature } from '..';
import lodashGet from 'lodash/get';
import { Tier } from '~/types/pricings';

const INITIAL_TIER_BOUNDARY_VALUE = 0;
const INITIAL_TIER_AMOUNT_VALUE = 0;

export const compareTiers = (firstTier: Tier, secondTier: Tier) =>
  firstTier.boundary.value - secondTier.boundary.value;

interface TierBasedProps {
  formData: any;
  getSource: (key: string) => string;
  schema: ByFeature;
  permanentSelectedCurrency: string;
}

const TierBased = (props: TierBasedProps) => {
  const { formData, getSource, schema, permanentSelectedCurrency } = props;
  const translate = useTranslate();
  const t = (key: string): string => translate(l(key), 1);
  const classes = useStyles();
  const form = useForm();
  useEffect(() => {
    form.destroyOnUnregister = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const feeNameKey: string = schema.propertyKey;

  const tierBasedCurrency = formData[feeNameKey]?.currency || permanentSelectedCurrency || '';

  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const [editTierIndex, setEditTierIndex] = useState<number>(-1);

  // Setting 0 value tier when currency is predefined.
  useEffect(() => {
    if (isEmpty(tiers) && permanentSelectedCurrency) {
      form.change(`${[feeNameKey]}.tiers`, [
        {
          boundary: { currency: permanentSelectedCurrency, value: INITIAL_TIER_BOUNDARY_VALUE },
          type: 'fixed',
          amount: { currency: permanentSelectedCurrency, value: INITIAL_TIER_AMOUNT_VALUE },
        },
      ]);
      form.change(`${[feeNameKey]}.currency`, permanentSelectedCurrency);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tiers: Tier[] = lodashGet(formData, `${[feeNameKey]}.tiers`);

  const handleTierBasedCurrency = (e: any) => {
    const newCurrency = e.target.value;
    if (isEmpty(tiers) && newCurrency) {
      form.change(`${[feeNameKey]}.tiers`, [
        {
          boundary: { currency: newCurrency, value: INITIAL_TIER_BOUNDARY_VALUE },
          type: 'fixed',
          amount: { currency: newCurrency, value: INITIAL_TIER_AMOUNT_VALUE },
        },
      ]);
    } else if (!newCurrency) {
      form.change(`${[feeNameKey]}.tiers`, undefined);
    } else {
      const oldTiers = [...tiers];
      const newTiers = oldTiers.map((tier) => {
        if (tier.type === 'fixed') {
          return {
            ...tier,
            boundary: { currency: newCurrency, value: tier.boundary.value },
            amount: { currency: newCurrency, value: tier.amount.value },
          };
        } else {
          return {
            ...tier,
            boundary: { currency: newCurrency, value: tier.boundary.value },
            percent: tier.percent,
            minAmount: { currency: newCurrency, value: tier.minAmount.value },
            maxAmount: { currency: newCurrency, value: tier.maxAmount.value },
          };
        }
      });
      form.change(`${[feeNameKey]}.tiers`, [...newTiers]);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    editTierIndex > -1 && setEditTierIndex(-1);
    setExpanded({});
    setOpen(false);
  };

  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  const handleEdit = (index: number) => {
    index > -1 && setEditTierIndex(index);
    handleOpen();
  };

  const handleDelete = (index: number) => {
    if (tiers) {
      form.change(
        `${[feeNameKey]}.tiers`,
        tiers.filter((_item, i) => i !== index)
      );
    }
  };

  const newTierIndex: number = tiers?.length || 0;

  const getTierSource = (index: number, source: string): string => `tiers[${index}].${source}`;

  const handleSubmit = (newRecord: any): void => {
    if (newRecord[feeNameKey].tiers) {
      form.change(`${[feeNameKey]}.tiers`, [...newRecord[feeNameKey].tiers]);
      handleClose();
    }
  };

  const MAX_TIERS = 10;
  const isMaxCountOfTiers = formData[feeNameKey]?.tiers?.length >= MAX_TIERS;
  return (
    <>
      <CurrencySelect
        onChange={handleTierBasedCurrency}
        label={t('currency')}
        record={formData}
        source={getSource('currency')}
        validate={required()}
        disabled={permanentSelectedCurrency}
      />
      <Grid container spacing={2}>
        {formData[feeNameKey]?.tiers &&
          !isEmpty(formData[feeNameKey]?.tiers) &&
          formData[feeNameKey]?.tiers
            ?.sort((firstTier: Tier, secondTier: Tier) => {
              return compareTiers(firstTier, secondTier);
            })
            ?.map((item: Tier, index: number) => {
              const isExpanded = Boolean(expanded[`panel-${index}`]);
              return (
                <Grid key={index} item xs={12}>
                  <Accordion
                    expanded={isExpanded}
                    onChange={handleChange(`panel-${index}`, isExpanded)}
                    key={index}
                  >
                    <AccordionSummary
                      aria-controls={`${index}-content`}
                      id={`${index}-header`}
                      expanded={isExpanded}
                      heading={
                        <>
                          <div className={classes.tierHeader}>{t('tierStartsWith')}</div>
                          <AmountField amount={item.boundary} />
                        </>
                      }
                      actionNode={
                        <>
                          <IconButton
                            type='button'
                            size='small'
                            onClick={() => handleEdit(index)}
                            className={classes.actionButton}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            type='button'
                            size='small'
                            disabled={item.boundary.value === 0}
                            onClick={() => handleDelete(index)}
                            className={classes.actionButton}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      }
                    />
                    <AccordionDetails
                      key='external-sources-details'
                      className={classes.AccordionDetails}
                    >
                      <TierBasedFormField tierItem={item} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
        {tierBasedCurrency && (
          <Grid item xs={12}>
            <Tooltip title={t('tenTiersOnly')} disableHoverListener={!isMaxCountOfTiers}>
              <span>
                <Button
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  onClick={handleOpen}
                  disabled={isMaxCountOfTiers}
                  color='primary'
                  startIcon={<AddIcon />}
                >
                  {t('addNewTier')}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        )}
        <Dialog onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
          <DialogTitle id='simple-dialog-title'>{t('addNewTier')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <SimpleForm
              redirect={false}
              margin='none'
              className={classes.form}
              record={formData}
              toolbar={<BottomToolbar onSave={handleSubmit} onClose={handleClose} />}
            >
              <FormDataConsumer>
                {({ formData }: any) => {
                  return (
                    <TierBasedFormInput
                      getSource={getSource}
                      getTierSource={getTierSource}
                      newTierIndex={newTierIndex}
                      tierBasedCurrency={tierBasedCurrency}
                      feeNameKey={feeNameKey}
                      formData={formData}
                      editTierIndex={editTierIndex}
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleForm>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};

interface TierBasedFormInputProps {
  formData: any;
  getSource: (key: string) => string;
  getTierSource: (index: number, source: string) => string;
  newTierIndex: number;
  tierBasedCurrency: string;
  feeNameKey: string;
  editTierIndex: number;
}

const TierBasedFormInput = (props: TierBasedFormInputProps) => {
  const {
    getSource,
    getTierSource,
    newTierIndex,
    tierBasedCurrency,
    feeNameKey,
    formData,
    editTierIndex,
  } = props;

  const form = useForm();
  useEffect(() => {
    form.destroyOnUnregister = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const translate = useTranslate();
  const t = (key: string, variant: any = 1): string => translate(l(key), variant);

  const itemIndex = editTierIndex > -1 ? editTierIndex : newTierIndex;
  const selectedType = lodashGet(formData, getSource(getTierSource(itemIndex, 'type')));

  const validateBoundary = (value: number) => {
    const tiersBoundaries = formData[feeNameKey]?.tiers
      .filter((_item: Tier, index: number) => index !== itemIndex)
      .map((item: Tier) => item?.boundary?.value);
    if (itemIndex !== 0 && tiersBoundaries?.includes(value)) {
      return t('boundaryAlreadyExists', { value: value });
    }
  };
  return (
    <>
      <NumberInput
        source={getSource(getTierSource(itemIndex, 'boundary.value'))}
        label={t('tierStartsWith')}
        validate={[number(), minValue(0), required(), validateBoundary]}
        disabled={itemIndex === 0}
      />
      <HiddenInput
        defaultValue={tierBasedCurrency}
        source={getSource(getTierSource(itemIndex, 'boundary.currency'))}
      />
      <SelectInput
        source={getSource(getTierSource(itemIndex, 'type'))}
        label={t('feeAlgorithm')}
        choices={[
          { id: 'fixed', name: t('fixed') },
          { id: 'percent', name: t('percent', 2) },
        ]}
        value={selectedType}
        validate={required()}
      />
      {selectedType === 'fixed' && (
        <AmountInput
          source={getSource(getTierSource(itemIndex, 'amount'))}
          amountLabel={t('amount')}
          permanentSelectedCurrency={tierBasedCurrency}
          required
          fullWidth
          positiveOnly
          currencyDisabled
        />
      )}
      {selectedType === 'percent' && (
        <Grid container>
          <Grid item xs={12}>
            <NumberInput
              source={getSource(getTierSource(itemIndex, 'percent'))}
              label={t('percent', 2)}
              validate={[number(), minValue(0), required()]}
            />
          </Grid>
          <Grid item xs={12}>
            <AmountInput
              source={getSource(getTierSource(itemIndex, 'minAmount'))}
              amountLabel={t('minAmount')}
              permanentSelectedCurrency={tierBasedCurrency}
              fullWidth
              positiveOnly
              currencyDisabled
            />
            <AmountInput
              source={getSource(getTierSource(itemIndex, 'maxAmount'))}
              amountLabel={t('maxAmount')}
              permanentSelectedCurrency={tierBasedCurrency}
              fullWidth
              positiveOnly
              currencyDisabled
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

interface TierBasedFormFieldProps {
  tierItem: Tier;
}

const TierBasedFormField = (props: TierBasedFormFieldProps) => {
  const { tierItem } = props;
  const translate = useTranslate();
  const t = (key: string, variant: number = 1): string => translate(l(key), variant);
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12}>
        <TextField label={t('feeAlgorithm')}>{t(tierItem.type, 2)}</TextField>
      </Grid>
      {tierItem.type === 'fixed' && (
        <Grid item xs={12}>
          <TextField label={t('amount')}>
            <AmountField amount={tierItem?.amount} />
          </TextField>
        </Grid>
      )}
      {tierItem.type === 'percent' && (
        <>
          <Grid item xs={12}>
            <TextField label={t('percent', 2)}>{tierItem?.percent?.toString() + '%'}</TextField>
          </Grid>
          <Grid item>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={6}>
                <TextField label={t('minAmount')}>
                  <AmountField amount={tierItem?.minAmount} />
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField label={t('maxAmount')}>
                  <AmountField amount={tierItem?.maxAmount} />
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  AccordionDetails: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
    display: 'block',
  },
  form: {
    '& > div': {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: 360,
    boxSizing: 'border-box',
  },
  actionButton: {
    color: theme.palette.text.primary,
  },
  tierHeader: {
    fontSize: 10,
    fontWeight: 'normal',
    marginBottom: 4,
  },
}));

export default TierBased;
