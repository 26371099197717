import React, { FC, useState } from 'react';
import { resetAccountError } from '~/api/accounts';
import Confirmation from './Confirmation';
import CloseButton from './CloseButton';
import Alert from '@material-ui/lab/Alert';
import TextInput from './TextInput';
import { useTranslate } from 'react-admin';
import { useNotify } from '~/hooks';
import { useMutation } from 'react-query';
import { AlertTitle } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { usePermissions } from 'react-admin';

interface AccountWithErrorWarningProps {
  accountId: string;
  error: string;
  refetch: () => void;
}

const label = (key: string): string => `components.AccountWithErrorWarning.${key}`;

const AccountWithErrorWarning: FC<AccountWithErrorWarningProps> = ({
  accountId,
  error,
  refetch,
}) => {
  const [comment, setComment] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));
  const notify = useNotify();

  const { permissions } = usePermissions();
  const hasPermissionToClear = permissions?.includes('account.resetError');

  const handleComment = ({ target }: any) => {
    setErrorMessage(undefined);
    setComment(target?.value);
  };

  const resetAccountErrorMutation = useMutation(resetAccountError, {
    onSuccess: () => {
      setComment(undefined);
      setErrorMessage(undefined);
      refetch();
      notify({ type: 'success', message: t('errorResetSuccess') });
    },
  });

  const handleResetAccountError = () => {
    if (!comment) {
      setErrorMessage(translate('ra.validation.required'));
      throw new Error();
    }
    if (comment) {
      resetAccountErrorMutation.mutateAsync({ accountId, comment });
    }
  };
  return (
    <Alert
      // Use stopPropagation to stop accordion from opening and closing on click.
      onClick={(event) => event?.stopPropagation()}
      severity='error'
      action={
        hasPermissionToClear && (
          <Confirmation
            onConfirm={handleResetAccountError}
            confirmButtonProps={{
              loading: resetAccountErrorMutation.isLoading,
            }}
            confirmationSettings={{
              variant: 'modal',
              modal: {
                heading: t('attention'),
                content: (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Alert severity='warning'>{t('alert')}</Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        autoFocus
                        label={t('comment')}
                        onChange={handleComment}
                        value={comment}
                        multiline
                        rows={5}
                        rowsMax={20}
                        error={!!errorMessage}
                        helperText={errorMessage}
                        required
                      />
                    </Grid>
                  </Grid>
                ),
              },
            }}
          >
            <CloseButton red label='clear' disabled={resetAccountErrorMutation.isLoading} />
          </Confirmation>
        )
      }
    >
      <AlertTitle>{t('warning')}</AlertTitle>
      {error}
    </Alert>
  );
};

export default AccountWithErrorWarning;
