import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AddFilterButton from './AddFilterButton';

type Props = {
  filters: {
    key: string;
    label: string;
  }[];
  onFilterSelected: (filterKey: string) => void;
};

const AddFilterAction = (props: Props) => {
  const { filters, onFilterSelected } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (filterKey: string | undefined = undefined) => {
    setAnchorEl(null);
    filterKey && setTimeout(() => onFilterSelected(filterKey), 200);
  };

  return (
    <>
      <AddFilterButton disabled={filters.length === 0} onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {filters.map((filter) => (
          <MenuItem
            key={filter.key}
            onClick={() => {
              handleClose(filter.key);
            }}
          >
            {filter.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddFilterAction;
