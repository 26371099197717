import React, { Fragment, useState } from 'react';
import {
  Datagrid,
  Filter,
  TextField as RaTextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { List } from '~/layout';
import { NoPermissions } from '~/components/ra';

import { useRecordContext } from 'react-admin';
import { Code } from '~/components';
import EditDrawer from './components/EditDrawer';
import LocaleSelect from '~/components/ra/LocaleSelect';
import LocalizationCreateAction from './components/LocalizationCreateAction';
import LocalizationExportAction from './components/LocalizationExportAction';
import LocalizationImportAction from './components/LocalizationImportAction';

const l = (key: string): string => `resources.localization.list.${key}`;

const LocalizationListActions = (props: any) => {
  const { permissions } = usePermissions();

  if (!permissions) return null;

  return (
    <TopToolbar>
      <LocalizationCreateAction />
      <LocalizationImportAction />
      <LocalizationExportAction filterValues={props.filterValues} />
    </TopToolbar>
  );
};

const ValueField = (props: any) => {
  const classes = useStyles();

  const record = useRecordContext(props);
  return (
    <Code
      code={record?.value || record?.defaultValue}
      language='factor'
      className={cx(classes.InlineCode, record?.value ? null : classes.Default)}
      inline
    />
  );
};

const LocaleField = (props: any) => {
  const { record } = props;
  const locale = record.locale;

  const translate = useTranslate();

  return <span>{translate(`locales.${locale}`)}</span>;
};

const LocalizationList = (props: any) => {
  const { permissions } = usePermissions();

  const [editRecord, setEditRecord] = useState();

  const [filter, setFilter] = useState<any>({});

  const onFilterChange = (property: string) => (e: any) => {
    setFilter({
      ...filter,
      [property]: e.target.value,
    });
  };

  if (!permissions) return null;
  if (!permissions?.includes('localization.list')) return <NoPermissions />;

  return (
    <Fragment>
      <List
        bulkActionButtons={false}
        {...props}
        actions={<LocalizationListActions {...props} />}
        filters={
          <Filter>
            <TextInput
              label={l('key')}
              source='key'
              alwaysOn
              style={{ minWidth: 200 }}
              onChange={onFilterChange('key')}
            />
            <LocaleSelect
              label={l('locale')}
              source='locale'
              alwaysOn
              style={{ minWidth: 200 }}
              onChange={onFilterChange('locale')}
            />
          </Filter>
        }
      >
        <>
          <Datagrid
            rowClick={(id: any, resource: any, record: any) => {
              setEditRecord(record);
            }}
            size='medium'
            selectedIds={null}
          >
            <RaTextField source='key' label={l('key')} sortable={false} />
            {/*<RaTextField source="group" label={l('group')} sortable={false} />*/}
            <LocaleField label={l('locale')} sortable={false} />
            <RaTextField source='templateEngine' label={l('templateEngine')} sortable={false} />
            <ValueField label={l('value')} sortable={false} />
          </Datagrid>
          {editRecord && (
            <EditDrawer
              record={editRecord}
              open={!!editRecord}
              onClose={() => {
                setEditRecord(undefined);
              }}
            />
          )}
        </>
      </List>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  InlineCode: {
    padding: '0 !important',
    margin: 0,
    background: 'none !important',
    lineHeight: '1em !important',
    'white-space': 'pre-wrap !important',
  },
  Default: {
    opacity: 0.5,
    filter: 'grayscale(50%)',
  },
}));

export default LocalizationList;
