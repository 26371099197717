import React, { useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';

import { ImportButton } from '~/components';
import { Button } from '~/components/ra';
import onError from '~/errorsHandler';
import { importCsv } from '~/api/localization';
import { useNotify } from '~/hooks';
import { crudGetList } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useListPaginationContext } from 'react-admin';
import usePath from '~/hooks/usePath';

const useImportTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.localization.import.${key}`);
};

interface ImportDialogProps {
  open: boolean;
  onClose: () => void;
}

const ImportDialog = (props: ImportDialogProps) => {
  const { open, onClose } = props;

  const t = useImportTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();

  const listPaginationContext = useListPaginationContext(props);
  const {
    params: { page, perPage },
  } = usePath();
  const resultPagination = {
    page: Number(listPaginationContext?.page || page || 1),
    perPage: Number(listPaginationContext?.perPage || perPage || 25),
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    multiple: false,
  });

  console.log(`acceptedFiles:`, acceptedFiles);

  const file = acceptedFiles.length > 0 ? acceptedFiles[0] : null;

  const [loading, setLoading] = useState<boolean>(false);

  const onImport = () => {
    if (file) {
      setLoading(true);
      importCsv(file)
        .then(() => {
          dispatch(
            crudGetList('localization', resultPagination, { field: 'id', order: 'DESC' }, {})
          );
          setTimeout(() => {
            setLoading(false);
            onClose();
            notify({
              type: 'success',
              message: t('successMessage'),
            });
          }, 500);
        })
        .catch((e) => {
          setLoading(false);
          onError(e);
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('dialog.title')}</DialogTitle>
      <DialogContent>
        <section className={classes.root}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress color='primary' />
            </div>
          ) : (
            <section>
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {file && (
                  <p>
                    {t('fileInputResult')} <strong>{file.name}</strong>
                  </p>
                )}
                <p>{t('fileInputMessage')}</p>
                <p className={classes.fileInputHint}>({t('fileInputHint')})</p>
              </div>
            </section>
          )}
        </section>
      </DialogContent>
      <DialogActions>
        <ImportButton onClick={onImport} disabled={!file || loading} />
        <Button label='ra.action.close' disabled={loading} onClick={onClose} />
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dropzone: {
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    cursor: 'pointer',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.getContrastText(theme.palette.background.default),
  },
  fileInputHint: {
    // fontStyle: 'italic',
    opacity: 0.5,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 350,
  },
  loadingContainer: {
    width: 64,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const LocalizationImportAction = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { permissions } = usePermissions();
  if (!permissions?.includes('localization.list')) return null;

  return (
    <>
      <ImportButton onClick={handleOpen} />
      <ImportDialog open={open} onClose={handleClose} />
    </>
  );
};

export default LocalizationImportAction;
