import React from 'react';
import { usePermissions } from 'react-admin';
import { Grid } from '@material-ui/core';

import { Confirmation, DeleteButton } from '~/components';
import { PaymentGroup } from '~/types/paymentServices';
import { useDeletePaymentGroup } from '~/hooks/paymentServices';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { useNotify } from '~/hooks';
import { useHistory } from 'react-router-dom';

interface Props {
  record?: PaymentGroup;
}

const useDeleteTranslate = () => {
  const translate = useTranslate();
  return (key: string) => translate(`resources.paymentServices/groups.delete.${key}`);
};

const PaymentGroupDeleteAction = (props: Props) => {
  const { record } = props;
  const { permissions } = usePermissions();
  const t = useDeleteTranslate();
  const history = useHistory();

  const deletePaymentGroupMutation = useDeletePaymentGroup({
    onSuccess: () => {
      history.push('/paymentServices/groups?page=1&perPage=10');
    },
  });
  const handleDelete = () => {
    if (record?.id) {
      return deletePaymentGroupMutation.mutateAsync(record.id);
    }
  };

  const notify = useNotify();

  const onConfirm = async () => {
    if (record?.id) {
      return deletePaymentGroupMutation.mutateAsync(record.id).then(() => {
        notify({ message: t('success'), type: 'success' });
      });
    }
  };

  if (!permissions?.includes('paymentService.delete')) return null;

  return (
    <Confirmation
      onConfirm={onConfirm}
      confirmButtonProps={{
        loading: deletePaymentGroupMutation.isLoading,
      }}
      confirmationSettings={{
        variant: 'modal',
        modal: {
          heading: t('attention'),
          content: (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity='warning'>{t('alert')}</Alert>
              </Grid>
            </Grid>
          ),
        },
      }}
    >
      <DeleteButton onClick={handleDelete} />
    </Confirmation>
  );
};

export default PaymentGroupDeleteAction;
