import axios from '~/utils/axios';
import { HeadTransaction } from '~/types/HeadTransaction';

interface createConsentProps {
  transactionId: string;
  revertTenantFees: boolean;
}

export const createConsent = ({ transactionId, revertTenantFees }: createConsentProps) => {
  const url = `/transactionReversal/consent`;
  return axios.post<HeadTransaction>(url, { transactionId, parameters: { revertTenantFees } });
};

interface createTransactionProps {
  consentId: string;
  reason: string;
}

export const createTransaction = ({ consentId, reason }: createTransactionProps) => {
  const url = `/transactionReversal`;
  return axios.post<HeadTransaction>(url, { consentId, reason });
};
