import React from 'react';
import {
  blockAccount as apiBlockAccount,
  unblockAccount as apiUnblockAccount,
  closeAccount as apiCloseAccount,
} from '~/api/accounts';
import { Account } from '~/types';
import { BlockButton, CloseButton, Confirmation, UnblockButton } from '.';
import { usePermissions } from 'react-admin';
import onError from '~/errorsHandler';

interface Props {
  status: 'ACTIVE' | 'BLOCKED' | 'CLOSED';
  resource?: 'businesses' | 'individuals';
  record: Account;
  refetch: () => void;
}

const AccountsActions = (props: Props) => {
  const { status, resource, record, refetch } = props;

  const { permissions } = usePermissions();
  const hasAccountBlockPermission = permissions?.includes('account.block');
  let hasBlockPermission = false;
  let hasUnblockPermission = false;
  let hasClosePermission = false;
  if (hasAccountBlockPermission) {
    hasBlockPermission = true;
    hasUnblockPermission = true;
    hasClosePermission = true;
  } else if (resource === 'businesses') {
    hasBlockPermission = permissions?.includes('business.account.block');
    hasUnblockPermission = permissions?.includes('business.account.unblock');
    hasClosePermission = permissions?.includes('business.account.close');
  } else if (resource === 'individuals') {
    hasBlockPermission = permissions?.includes('individual.account.block');
    hasUnblockPermission = permissions?.includes('individual.account.unblock');
    hasClosePermission = permissions?.includes('individual.account.close');
  } else if (resource === 'partners') {
    hasBlockPermission = false; //permissions?.includes('partner.account.block');
    hasUnblockPermission = false; //permissions?.includes('partner.account.unblock');
    hasClosePermission = false; //permissions?.includes('partner.account.close');
  } else {
    hasBlockPermission = false;
    hasUnblockPermission = false;
    hasClosePermission = false;
  }

  const blockAccount = (record: Account) => {
    apiBlockAccount(record.id).catch(onError).finally(refetch);
  };

  const unblockAccount = (record: Account) => {
    apiUnblockAccount(record.id).catch(onError).finally(refetch);
  };

  const closeAccount = (record: Account) => {
    apiCloseAccount(record.id).catch(onError).finally(refetch);
  };
  if (!resource) return null;
  return (
    <>
      {status === 'ACTIVE' && hasBlockPermission && (
        <Confirmation
          onConfirm={() => {
            blockAccount(record);
          }}
        >
          <BlockButton />
        </Confirmation>
      )}
      {status === 'BLOCKED' && hasUnblockPermission && (
        <Confirmation
          onConfirm={() => {
            unblockAccount(record);
          }}
        >
          <UnblockButton />
        </Confirmation>
      )}
      {status !== 'CLOSED' && hasClosePermission && (
        <Confirmation
          onConfirm={() => {
            closeAccount(record);
          }}
        >
          <CloseButton />
        </Confirmation>
      )}
    </>
  );
};

export default AccountsActions;
