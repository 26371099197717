import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  ListContextProvider,
  ListController,
  ReferenceField,
  TextField,
  TextInput,
  usePermissions,
  useListContext,
} from 'react-admin';

import { withStyles, createStyles } from '@material-ui/core/styles';

import { ListView } from '~/layout';

import BackgroundTaskExport from '~/components/BackgroundTaskCSVExport';
import { KycLevelField, NoPermissions, ProfileTagsFilter, StatusField } from '~/components/ra';
import { isEmpty } from 'lodash';
import BusinessCreateAction from './BusinessCreateAction';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';

const label = (field: string) => `resources.businesses.fields.${field}`;

const listBodyStyles = (theme: any) => {
  return createStyles({
    content: {
      fontSize: 14,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      paddingTop: 16,
      paddingBottom: 16,
    },
  });
};

const BusinessFilter = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // permanentFilter,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Filter {...props} className={classes.form}>
      <TextInput
        label={label('legalName')}
        source='legalName'
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        label={label('tradeName')}
        source='tradeName'
        alwaysOn
        className={classes.formControl}
      />
      <TextInput
        label={label('primaryDocumentNumber')}
        className={classes.formControl}
        source='primaryDocumentNumber'
        alwaysOn
      />
      <TextInput
        label={label('accountNumber')}
        source='accountNumber'
        alwaysOn
        className={classes.formControl}
      />
      <ProfileTagsFilter resource='businesses' className={classes.formControlLong} alwaysOn />
    </Filter>
  );
};

const filterIsEmpty = (props: any) => {
  const { filterValues } = props;
  const { emailAddress, legalName, tradeName, ...rest } = filterValues;
  if (!isEmpty(rest)) return false;
  return !emailAddress && !((legalName || '').length > 2) && !((tradeName || '').length > 2);
};

const ListBody = withStyles(listBodyStyles)((props: any) => {
  const { total, loading, classes } = props;
  const translate = useTranslate();
  const t = (key: any) => translate(label(key));
  if (loading) {
    return <div className={classes.content}>{t('searchInProgress')}</div>;
  } else if (filterIsEmpty(props)) {
    return <div className={classes.content}>{t('setupFilter')}</div>;
  } else if (total === 0) {
    return <div className={classes.content}>{t('noResultsFound')}</div>;
  } else {
    return (
      <Datagrid {...props} classes={undefined} rowClick='show' size='medium' selectedIds={null}>
        <FunctionField
          label={label('legalName')}
          render={(record: any) => [record.legalName, record.legalType].filter(Boolean).join(', ')}
          sortable={false}
        />
        <FunctionField
          label={label('tradeName')}
          render={(record: any) =>
            [record.tradeName, record.tradeNameLocalized].filter(Boolean).join(' / ')
          }
          sortable={false}
        />
        <TextField
          source='primaryDocument.number'
          label={label('primaryDocumentNumber')}
          sortable={false}
        />
        <ReferenceField
          source='solutionId'
          reference='solutions'
          link={false}
          label={label('solutionId')}
          sortable={false}
        >
          <ChipField source='name' />
        </ReferenceField>
        <KycLevelField source='kycLevel' label={label('kycLevel')} sortable={false} />
        <StatusField source='status' sortable={false} />
      </Datagrid>
    );
  }
});

const handleFilters = (filters: any) => {
  if (!filters) return {};
  return filters;
};

const ExportAction = () => {
  const { filterValues } = useListContext();
  return (
    <BackgroundTaskExport type='BUSINESS_EXPORT' queryArguments={handleFilters(filterValues)} />
  );
};

const BusinessList = (props: any) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  if (!permissions.includes('business.list')) return <NoPermissions />;
  return (
    <ListController {...props}>
      {(controllerProps: any) => {
        return (
          <ListContextProvider value={controllerProps}>
            <ListView
              filters={<BusinessFilter />}
              {...controllerProps}
              pagination={
                controllerProps.loading ||
                filterIsEmpty(controllerProps) ||
                controllerProps.total === 0
                  ? null
                  : undefined
              }
              bulkActionButtons={false}
              actions={
                <div style={{ minWidth: 240, marginTop: 'auto', textAlign: 'right' }}>
                  <BusinessCreateAction />
                  <ExportAction {...props} />
                </div>
              }
            >
              <ListBody />
            </ListView>
          </ListContextProvider>
        );
      }}
    </ListController>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    alignItems: 'flex-start',
    '& > div': {
      '& > div:last-child': {
        width: theme.spacing(1),
      },
    },
  },
  formControl: { width: 210 },
  formControlLong: {
    width: 210 * 2 + theme.spacing(1),
  },
}));

export default BusinessList;
