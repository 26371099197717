import React, { FC, useState } from 'react';
import { usePermissions } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { AddressForm } from '~/components/ra';
import CreateAndEditForm from '../CreateAndEditForm';
import { Individual } from '~/types';
import { useTCards } from '.';

interface Props {
  record: Individual;
  resource: 'individuals' | 'businesses';
}

const CardEditDeliveryAddress: FC<Props> = (props) => {
  const { record, resource } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const t = useTCards();
  const { permissions } = usePermissions();
  const permissionLevel =
    resource === 'individuals'
      ? 'individual.update'
      : resource === 'businesses'
      ? 'business.update'
      : '';

  return (
    <>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes(permissionLevel)} />
      <Drawer heading={t('editDeliveryAddress')} open={open} onClose={handleClose}>
        <CreateAndEditForm
          type='update'
          resource={resource}
          record={record}
          closeParent={handleClose}
        >
          <AddressForm source='deliveryAddress' excludeInputs={['country']} fullWidth />
        </CreateAndEditForm>
      </Drawer>
    </>
  );
};

export default CardEditDeliveryAddress;
