import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import cx from 'classnames';
import theme from '~/theme';
import Skeleton from '@material-ui/lab/Skeleton';

interface Props {
  children: JSX.Element | string;
  icon?: JSX.Element;
  topSpacing?: number;
  bottomSpacing?: number;
  noPaddingLeft?: boolean;
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const H3 = (props: Props) => {
  const {
    children,
    icon,
    topSpacing = 2,
    bottomSpacing = 1,
    noPaddingLeft,
    loading,
    className = '',
    style = {},
  } = props;
  const classes = useStyles();

  return (
    <h3
      style={{
        marginTop: theme.spacing(topSpacing),
        marginBottom: theme.spacing(bottomSpacing),
        ...style,
      }}
      className={cx(className, classes.root, {
        [classes.iconLabel]: !!icon,
        [classes.noPaddingLeft]: noPaddingLeft,
      })}
    >
      <span>{loading ? <Skeleton width={100} height={16} /> : children}</span>
      {icon && (
        <div className={cx(classes.icon, { [classes.noPaddingLeftIcon]: noPaddingLeft })}>
          {loading ? (
            <Skeleton variant='circle' width={20} height={20} />
          ) : (
            <SvgIcon>{icon}</SvgIcon>
          )}
        </div>
      )}
    </h3>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: 15,
    fontWeight: 500,
    margin: 0,
  },
  iconLabel: {
    position: 'relative',
    paddingLeft: theme.spacing(4),
  },
  icon: {
    position: 'absolute',
    left: 0,
    top: -3,
    width: 24,
    height: 24,
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  noPaddingLeftIcon: {
    left: theme.spacing(4) * -1,
  },
}));

export default H3;
