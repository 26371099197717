import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';

import PaymentDetailForm from './PaymentDetailForm';
import { CreateButton, Drawer } from '~/components';
import { CreateForm } from '~/components/ra';
import { Business } from '~/types';

interface Props {
  businessRecord: Business;
  permissions: string[];
}

const PaymentDetailEdit = (props: Props) => {
  const { businessRecord, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.paymentDetails.${key}`);

  return (
    <Fragment>
      <CreateButton
        variant='add'
        onClick={handleOpen}
        disabled={!permissions.includes('business.paymentDetails.create')}
      />
      <Drawer heading={t('addPaymentDetail')} open={open} onClose={handleClose}>
        <CreateForm
          resource='paymentDetails'
          target='businesses'
          id={businessRecord.id}
          record={{}}
          closeParent={handleClose}
          recordCleanupMode='hard'
        >
          <PaymentDetailForm businessRecord={businessRecord} />
        </CreateForm>
      </Drawer>
    </Fragment>
  );
};

export default PaymentDetailEdit;
