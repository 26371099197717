import React, { FC } from 'react';
import { Button } from 'react-admin';
import { ButtonProps } from 'ra-ui-materialui';

import ExportIcon from '@material-ui/icons/GetApp';

const ExportButton: FC<ButtonProps> = (props) => {
  return (
    <Button label='ra.action.export' {...props}>
      <ExportIcon />
    </Button>
  );
};

export default ExportButton;
