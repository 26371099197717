import React, { Fragment, useState } from 'react';
import { useTranslate } from 'react-admin';
import TerminalForm from './TerminalForm';
import { EditButton, Drawer } from '~/components';
import { EditForm } from '~/components/ra';
import { Business, Terminal } from '~/types';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  uuid: {
    color: theme.palette.grey[500],
  },
}));

export const sanitize = (record: Terminal): Terminal => {
  const { device } = record;
  const sanitizedRecord = { ...record };
  if (!device) delete sanitizedRecord.device;
  return sanitizedRecord;
};

interface Props {
  terminalRecord: Terminal;
  businessRecord: Business;
  permissions: string[];
}

const TerminalEdit = (props: Props) => {
  const { terminalRecord, businessRecord, permissions } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string =>
    translate(`resources.businesses.terminals.${key}`);

  const classes = useStyles();
  return (
    <Fragment>
      <EditButton
        onClick={handleOpen}
        disabled={!permissions.includes('business.terminal.update')}
      />
      <Drawer
        heading={`${translateLabel('editTerminal')} "${terminalRecord.label}"`}
        open={open}
        onClose={handleClose}
      >
        {terminalRecord?.id && (
          <div className={cx(classes.bottomSpacing, classes.uuid)}>{terminalRecord?.id}</div>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EditForm resource='terminals' record={sanitize(terminalRecord)}>
              <TerminalForm businessRecord={businessRecord} terminalRecord={terminalRecord} />
            </EditForm>
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
};

export default TerminalEdit;
