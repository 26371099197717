import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Section } from '~/layout';
import { H2 } from '~/components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useApiIdentityTranslate } from '..';
import DevicesAndKeysShow from './DevicesAndKeysShow';

interface DevicesAndKeysFieldProps {
  apiIdentityId: string;
}

const DevicesAndKeysField: FC<DevicesAndKeysFieldProps> = (props) => {
  const { apiIdentityId } = props;
  const t = useApiIdentityTranslate();
  const classes = useStyles();
  return (
    <Section noPaddings>
      <Grid container justify='space-between' className={classes.bottomSpacing2}>
        <Grid item xs={8}>
          <H2 bottomSpacing={0} topSpacing={0}>
            {t(`devices`)}
          </H2>
        </Grid>
      </Grid>
      <DevicesAndKeysShow apiIdentityId={apiIdentityId} />
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default DevicesAndKeysField;
