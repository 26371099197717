import * as React from 'react';
import { useState } from 'react';

import { useLocale, usePermissions } from 'react-admin';

import { Paper } from '@material-ui/core';

import { CurrencyAutocomplete, MaterialTable } from '~/components';
import i18n from '~/i18n';

import * as hb from '~/types/Handbook';
import { validateId } from './utils';
import { useApi } from '~/hooks';
import { CurrencyType, getCurrencies } from '~/api/currencies';

const CurrencyTable = ({
  handbook,
  setState,
}: {
  handbook: Partial<hb.Handbook>;
  setState: hb.SetState;
}) => {
  const locale: keyof any = useLocale();
  const allCurrencies: { [key: string]: string } = (i18n as any)[locale].currencies;

  const currencies: hb.Currency[] = (handbook.currencies || []).slice();
  const [{ data, loading }] = useApi<CurrencyType[]>(getCurrencies);
  const options = data && data.map((item) => item.code);

  const isValid = (newRecord: hb.Currency, oldRecord: hb.Currency | undefined = undefined) =>
    !validateId(currencies, newRecord.id, (oldRecord || {}).id, true);

  const { permissions } = usePermissions();
  if (!permissions) return null;

  return (
    <MaterialTable
      columns={[
        {
          title: 'Currency',
          field: 'id',
          required: true,
          validate: validateId,
          render: (rowData: hb.Currency) => (
            <>
              <span style={{ minWidth: '50px', display: 'inline-block' }}>{rowData.id}</span>
              <span style={{ opacity: 0.5 }}>{allCurrencies[rowData.id]}</span>
            </>
          ),
          width: 400,
        },
      ]}
      components={{
        Container: (props: any) => <Paper variant='outlined'>{props.children}</Paper>,
        EditField: (props: any) => {
          const [initialState] = useState(props.value);

          const errorMessage =
            (props.columnDef.validate
              ? props.columnDef.validate(currencies, props.value, initialState)
              : null) || '';
          return (
            <CurrencyAutocomplete
              value={props.value || ''}
              label={props.columnDef.title}
              onChange={(event) => {
                props.onChange(event.target.value);
              }}
              options={options}
              disabled={loading}
              required={props.columnDef.required}
              helperText={errorMessage || '\u00A0'}
              error={errorMessage.length > 0}
            />
          );
        },
      }}
      data={currencies}
      editable={
        permissions?.includes('solution.handbook.update') && {
          onRowAdd: (newCurrency: hb.Currency) => {
            if (isValid(newCurrency)) {
              newCurrency.name = allCurrencies[newCurrency.id];
              currencies.push(newCurrency);
              return setState({ currencies });
            } else {
              return Promise.reject();
            }
          },
          onRowUpdate: (newCurrency: hb.Currency, oldCurrency: hb.Currency) => {
            if (isValid(newCurrency, oldCurrency)) {
              newCurrency.name = allCurrencies[newCurrency.id];
              const index = currencies.indexOf(oldCurrency);
              currencies[index] = newCurrency;
              return setState({ currencies });
            } else {
              return Promise.reject();
            }
          },
          onRowDelete: (oldRecord: hb.Currency) => {
            const index = currencies.indexOf(oldRecord);
            currencies.splice(index, 1);
            return setState({ currencies });
          },
        }
      }
      options={{
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        showTitle: false,
        paging: false,
        addRowPosition: 'first',
      }}
    />
  );
};

export default CurrencyTable;
