import { useQuery } from 'react-query';
import { getTransaction } from '~/api/headTransactions';

export const useGetTransaction = (id?: string) => {
  return useQuery(
    ['headTransactions', id],
    () => getTransaction({ id: id! }).then((res) => res.data),
    {
      enabled: !!id,
    }
  );
};
