import React, { useState } from 'react';
import { useTranslate, usePermissions } from 'react-admin';
import UndoIcon from '@material-ui/icons/Undo';
import { makeStyles } from '@material-ui/core/styles';

import { Drawer, EditButton } from '~/components';
import { ActionReport } from '~/types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { SimpleForm } from '~/components/ra';
import { useRevokeActionReport } from '~/hooks/actionReports';
import Toolbar from '~/components/ra/Toolbar';
import { SaveButton } from 'react-admin';
import { required } from 'react-admin';
import { TextInput } from 'react-admin';
import { Button } from 'react-admin';
import { useNotify } from '~/hooks';

interface Props {
  report: ActionReport;
}

type FormValues = {
  reason: string;
};

const RevokeAction = (props: Props) => {
  const { report } = props;

  const [open, setOpen] = useState<boolean>(false);

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.actionReports.revokeAction.${key}`);
  const notify = useNotify();
  const classes = useStyles();

  const onActionButtonClick = () => {
    setOpen(true);
  };

  const { mutateAsync, isLoading } = useRevokeActionReport();
  const onSave = async ({ reason }: FormValues) => {
    console.log(`reason=${reason}`);
    try {
      await mutateAsync({
        report,
        reason,
      });
      setOpen(false);
      notify({ type: 'success', message: t('revokedSuccess') });
    } catch (e) {
      setOpen(false);
    }
  };

  const { permissions } = usePermissions();

  if (!permissions) return null;
  if (!permissions.includes('report.create')) return null;

  return (
    <>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        heading={t('revoke')}
      >
        <Alert severity='warning' style={{ marginBottom: '2em' }}>
          <AlertTitle>{t('warning.title')}</AlertTitle>
          {t('warning.message')}
        </Alert>
        <SimpleForm
          initialValues={{}}
          saving={isLoading}
          toolbar={
            <Toolbar className={classes.ButtonContainer}>
              <SaveButton onSave={onSave} icon={<UndoIcon />} label={t('revokeShort')} />
              <Button
                label='ra.action.cancel'
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Toolbar>
          }
        >
          <TextInput
            source='reason'
            label={t('reason')}
            multiline
            rows={5}
            rowsMax={20}
            validate={required()}
            fullWidth
          />
        </SimpleForm>
      </Drawer>
      <EditButton onClick={onActionButtonClick} label={t('revoke')} icon={<UndoIcon />} red />
    </>
  );
};

const useStyles = makeStyles(() => ({
  ButtonContainer: {
    justifyContent: 'flex-end',
    padding: 0,
    gap: '1em',
  },
}));

export default RevokeAction;
