import React, { FC, useState } from 'react';
import { makeStyles, Tabs, Tab, Theme, Typography, Grid } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import Accordion from '@material-ui/core/Accordion';
import { AccordionDetails, AccordionSummary } from '~/components';
import { EditForm } from '~/components/ra';
import { useGetNotifications } from '~/hooks/solutions';
import { SelectInput } from 'react-admin';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginLeft: '-16px',
  },
  accordion: {
    width: '100%',
    minWidth: 350,
    paddingRight: 100,
  },
  container: {
    width: '100%',
    minWidth: 300,
  },
  inputWidth: {
    width: 256,
  },
}));

const a11yProps = (index: any) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Grid container>{value === index && <div style={{ paddingLeft: 7 }}>{children}</div>}</Grid>
    </Typography>
  );
};

interface NotificationsProps {
  permissions: string[];
  solutionId: string;
  features: string[];
}

const NotificationsField: FC<NotificationsProps> = (props) => {
  const { permissions, solutionId, features } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.notifications.${key}`);

  const [tabsValue, setTabsValue] = React.useState(0);

  // Check for veengu_individual_profile feature to show 'Individual notifications' tab.
  const hasIndividualProfileFeature = features?.includes('veengu_individual_profile');

  const handleTabsChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };
  const canUpdate = permissions?.includes('solution.settings.notification.update');
  return (
    <div className={classes.root}>
      <Tabs
        orientation='vertical'
        variant='scrollable'
        value={tabsValue}
        onChange={handleTabsChange}
        className={classes.tabs}
      >
        <Tab label={t('transactionNotififcations')} {...a11yProps(0)} />
        {hasIndividualProfileFeature && (
          <Tab label={t('individualNotfications')} {...a11yProps(1)} />
        )}
      </Tabs>
      <TabPanel value={tabsValue} index={0}>
        <Grid container>
          <Grid item xs={12}>
            <TransactionalNotififcations solutionId={solutionId} canUpdate={canUpdate} />
          </Grid>
        </Grid>
      </TabPanel>
      {hasIndividualProfileFeature && (
        <TabPanel value={tabsValue} index={1}>
          <IndividualNotfications solutionId={solutionId} canUpdate={canUpdate} />
        </TabPanel>
      )}
    </div>
  );
};

interface TransactionalNotififcationsProps {
  solutionId: string;
  canUpdate: boolean;
}

const TransactionalNotififcations: FC<TransactionalNotififcationsProps> = (props) => {
  const { solutionId, canUpdate } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.notifications.${key}`);
  const classes = useStyles();

  const [expanded, setExpanded] = useState<{ [x: string]: boolean }>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };
  const isPayerExpanded = Boolean(expanded[`panel-payer`]);
  const isPayeeExpanded = Boolean(expanded[`panel-payee`]);

  const { data: transactional, isLoading: transactionalLoading, refetch } = useGetNotifications(
    solutionId,
    'transaction'
  );
  return (
    <EditForm
      resource={'solutions'}
      record={transactional}
      resourceId={solutionId}
      subresource='notifications/transaction'
      disabled={!canUpdate || transactionalLoading}
      updateAfterSubmit={refetch}
    >
      <Accordion
        expanded={isPayerExpanded}
        onChange={handleChange(`panel-payer`, isPayerExpanded)}
        className={classes.accordion}
      >
        <AccordionSummary
          aria-controls={'payerNotififcation-content'}
          id={'payerNotififcation-header'}
          expanded={isPayerExpanded}
          heading={t('payerNotififcations')}
        />
        <AccordionDetails>
          <TransactionalForm type='payer' />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isPayeeExpanded}
        onChange={handleChange(`panel-payee`, isPayeeExpanded)}
        className={classes.accordion}
      >
        <AccordionSummary
          aria-controls={'payeeNotififcation-content'}
          id={'payeeNotififcation-header'}
          expanded={isPayeeExpanded}
          heading={t('payeeNotififcations')}
        />
        <AccordionDetails>
          <TransactionalForm type='payee' />
        </AccordionDetails>
      </Accordion>
    </EditForm>
  );
};

interface TransactionalFormProps {
  type: 'payer' | 'payee';
}

const TransactionalForm: FC<TransactionalFormProps> = (props) => {
  const { type } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.notifications.${key}`);
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item>
        <SelectInput
          label={t('MOBILE')}
          source={`${type}.MOBILE.rule`}
          choices={[
            { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
            { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
            { id: 'SMS_ONLY', name: t('SMS_ONLY') },
            { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
          ]}
          className={classes.inputWidth}
        />
        <SelectInput
          label={t('USSD')}
          source={`${type}.USSD.rule`}
          choices={[
            { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
            { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
            { id: 'SMS_ONLY', name: t('SMS_ONLY') },
            { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
          ]}
          className={classes.inputWidth}
        />
        <SelectInput
          label={t('WEB')}
          source={`${type}.WEB.rule`}
          choices={[
            { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
            { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
            { id: 'SMS_ONLY', name: t('SMS_ONLY') },
            { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
          ]}
          className={classes.inputWidth}
        />
        <SelectInput
          label={t('WhatsApp')}
          source={`${type}.WHATSAPP.rule`}
          choices={[
            { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
            { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
            { id: 'SMS_ONLY', name: t('SMS_ONLY') },
            { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
          ]}
          className={classes.inputWidth}
        />
        <SelectInput
          label={t('middleware')}
          source={`${type}.MIDDLEWARE.rule`}
          choices={[
            { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
            { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
            { id: 'SMS_ONLY', name: t('SMS_ONLY') },
            { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
          ]}
          className={classes.inputWidth}
        />
        <SelectInput
          label={t('OTHER')}
          source={`${type}.OTHER.rule`}
          choices={[
            { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
            { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
            { id: 'SMS_ONLY', name: t('SMS_ONLY') },
            { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
          ]}
          className={classes.inputWidth}
        />
      </Grid>
    </Grid>
  );
};

interface IndividualNotficationsProps {
  solutionId: string;
  canUpdate: boolean;
}

const IndividualNotfications: FC<IndividualNotficationsProps> = (props) => {
  const { solutionId, canUpdate } = props;
  const { data: profile, isLoading: profileLoading, refetch } = useGetNotifications(
    solutionId,
    'profile'
  );
  return (
    <Grid container xs={6}>
      <Grid item>
        <EditForm
          resource={'solutions'}
          record={profile}
          resourceId={solutionId}
          subresource='notifications/profile'
          disabled={!canUpdate || profileLoading}
          updateAfterSubmit={refetch}
        >
          <IndividualForm />
        </EditForm>
      </Grid>
    </Grid>
  );
};

const IndividualForm: FC = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resources.solutions.notifications.${key}`);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <SelectInput
        label={t('individualKYCLevelUpdated')}
        source='individualKYCLevelUpdated'
        choices={[
          { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
          { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
          { id: 'SMS_ONLY', name: t('SMS_ONLY') },
          { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
        ]}
        className={classes.inputWidth}
      />
      <SelectInput
        label={t('individualKYCLevelUpdateRejected')}
        source='individualKYCLevelUpdateRejected'
        choices={[
          { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
          { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
          { id: 'SMS_ONLY', name: t('SMS_ONLY') },
          { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
        ]}
        className={classes.inputWidth}
      />
      <SelectInput
        label={t('individualStatusUpdated')}
        source='individualStatusUpdated'
        choices={[
          { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
          { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
          { id: 'SMS_ONLY', name: t('SMS_ONLY') },
          { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
        ]}
        className={classes.inputWidth}
      />
      <SelectInput
        label={t('beneficiaryStatusUpdated')}
        source='beneficiaryStatusUpdated'
        choices={[
          { id: 'PROFILE_DEFAULT', name: t('PROFILE_DEFAULT') },
          { id: 'NO_NOTIFICATION', name: t('NO_NOTIFICATION') },
          { id: 'SMS_ONLY', name: t('SMS_ONLY') },
          { id: 'PUSH_WITH_FALLBACK_TO_SMS', name: t('PUSH_WITH_FALLBACK_TO_SMS') },
        ]}
        className={classes.inputWidth}
      />
    </div>
  );
};

export default NotificationsField;
