import React, { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';

import { Drawer, EditButton } from '~/components';
import { EditForm } from '~/components/ra';
import PnlAccountForm from './PnlAccountForm';
import { l } from './PnlAccountList';

const PnlAccountEdit = (props: any) => {
  const { permissions, record } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const t = (key: string): string => translate(l(key));

  return (
    <Fragment>
      <EditButton onClick={handleOpen} disabled={!permissions?.includes('tenant.account.update')} />
      <Drawer heading={t('editPnl')} open={open} onClose={handleClose}>
        <EditForm resource='accounts' record={record} closeParent={handleClose}>
          <PnlAccountForm edit />
        </EditForm>
      </Drawer>
    </Fragment>
  );
};

export default PnlAccountEdit;
