import React, { useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import { H2, TableCell, EditButton } from '~/components';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import makeStyles from '@material-ui/core/styles/makeStyles';
import PermissionsEdit from './PermissionsEdit';

const PermissionsGrid = (props: any) => {
  const { record, permissions } = props;

  const rolePermissions: string[] = (record?.permissions || []).sort((a: string, b: string) =>
    a.localeCompare(b)
  );

  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.roles.permissions.${key}`);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  const editAllowed = permissions?.includes('dashboardRole.update') && !record?.readonly;

  return (
    <Grid container xs={4} className={classes.table} item direction='column' spacing={1}>
      <Grid item container justify='space-between' alignItems='center'>
        <Grid item>
          <H2 bottomSpacing={0} topSpacing={0}>
            {t('_')}
          </H2>
        </Grid>
        {editAllowed && (
          <Grid item>
            {!open ? (
              <EditButton onClick={handleOpen} />
            ) : (
              <Button label={'ra.action.cancel'} onClick={handleClose} />
            )}
          </Grid>
        )}
      </Grid>
      <Grid item>
        {open ? (
          <PermissionsEdit {...props} handleClose={handleClose} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isEmpty(rolePermissions) ? (
                <TableRow>
                  <TableCell>{t('emptyMessage')}</TableCell>
                </TableRow>
              ) : (
                rolePermissions.map((p) => {
                  return (
                    <TableRow>
                      <TableCell>{t(p)}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Grid>
  );
};

export default PermissionsGrid;

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 400,
  },
  checkbox: {
    margin: -16,
    marginLeft: -11,
  },
  checkboxCell: {
    paddingRight: 0,
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));
