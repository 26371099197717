import React, { FC } from 'react';
import { Tab, usePermissions } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import LinkIcon from '@material-ui/icons/Link';

import { Show, PaperTabbedShowLayout } from '~/layout';
import Details from './components/Details';
import { NoPermissions } from '~/components/ra';
import { useExternalProfileLinkTranslate } from '.';
import { AuditLogWidget } from '~/components';
import AuthData from './components/AuthData';
import EventLogWidget from '~/components/Events/EventLogWidget';

const LayoutWithContext = (props: any) => {
  const { history, resource, record } = props;

  const currentPathname = history?.location.pathname;
  const detailsPathname = `/${resource}/${record?.id || ''}/show`;
  const systemLogPathname = `/${resource}/${record?.id || ''}/show/systemLog`;
  const { permissions } = usePermissions();

  return (
    <PaperTabbedShowLayout
      {...props}
      bottomContent={
        currentPathname === detailsPathname &&
        permissions?.includes?.('externalProfileLink.event.list') ? (
          <EventLogWidget
            objectId={record.id}
            objectType='externalProfileLinks'
            history={history}
          />
        ) : currentPathname === systemLogPathname ? (
          <AuditLogWidget objectId={record.id} history={history} />
        ) : null
      }
    />
  );
};

const ExternalProfileLinkShow: FC<any> = (props) => {
  const { permissions } = usePermissions();
  const t = useExternalProfileLinkTranslate();

  if (!permissions) return null;
  if (!permissions?.includes('externalProfileLink.view')) return <NoPermissions />;

  return (
    <Show
      icon={
        <Avatar>
          <LinkIcon />
        </Avatar>
      }
      {...props}
    >
      <LayoutWithContext history={props.history} permissions={permissions}>
        <Tab label={t('tabs.details')}>
          <Details permissions={permissions} />
        </Tab>
        {permissions?.includes('externalProfileLink.viewAuthData') && (
          <Tab label={t('tabs.authData')} path='authData'>
            <AuthData />
          </Tab>
        )}
        {permissions?.includes('auditLog.list') && (
          <Tab label={t('tabs.systemLog')} path='systemLog' />
        )}
      </LayoutWithContext>
      <div>{/* fake child for correct styling */}</div>
    </Show>
  );
};

export default ExternalProfileLinkShow;
