import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import TableCell from '~/components/TableCell';

import { H2, JsonField } from '~/components';
import { Section } from '~/layout';

import { chooseEntityWithMinIndex, useTranslateEventsShow } from '~/resources/events';
import { EventType } from '~/types/Event';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import ReferenceFiled from '~/components/ReferenceFiled';
import { FlagIcon } from '~/img';
import TextField from '~/components/TextField';

const DetailsTab: FC<any> = (props) => {
  const { record } = props;
  const t = useTranslateEventsShow();

  const classes = useStyles();

  if (!record) return null;

  const event = record as EventType;
  const primaryActorId = event?.actors?.reduce(chooseEntityWithMinIndex, undefined)?.id;
  const primaryObjectId = event?.objects?.reduce(chooseEntityWithMinIndex, undefined)?.id;

  return (
    <Section noPaddings className={classes.section}>
      {!isEmpty(event?.actors) && (
        <Grid>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('actors')}
          </H2>
          <Section>
            <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('id')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {event.actors.map((actor, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {t(actor.type)} {actor.id && actor.id === primaryActorId && <FlagIcon />}
                    </TableCell>
                    <TableCell>
                      <ReferenceFiled record={actor} label=' ' />
                    </TableCell>
                    <TableCell>
                      <TextField textWrap>{actor.id}</TextField>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </Grid>
      )}
      {!isEmpty(event?.objects) && (
        <Grid>
          <H2 topSpacing={3} bottomSpacing={2}>
            {t('objects')}
          </H2>
          <Section>
            <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('type')}</TableCell>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('id')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {event.objects.map((object, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {t(object.type)} {object.id && object.id === primaryObjectId && <FlagIcon />}
                    </TableCell>
                    <TableCell>
                      <ReferenceFiled record={object} label=' ' />
                    </TableCell>
                    <TableCell>
                      <TextField textWrap>{object.id}</TextField>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </Grid>
      )}
      {!isEmpty(event?.references) && (
        <Grid>
          <H2 topSpacing={3} bottomSpacing={2}>
            {t('references')}
          </H2>
          <Section>
            <Table className={cx(classes.table, { [classes.autoWidth]: true })}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('objectType')}</TableCell>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('id')}</TableCell>
                  <TableCell>{t('linkType')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {event?.references?.map((reference, i) => (
                  <TableRow key={i}>
                    <TableCell>{t(reference.type)}</TableCell>
                    <TableCell>
                      <ReferenceFiled record={reference} label=' ' />
                    </TableCell>
                    <TableCell>
                      <TextField textWrap>{reference.id}</TextField>
                    </TableCell>
                    <TableCell>{reference.linkType}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        </Grid>
      )}
      {!isEmpty(event.context) && (
        <Grid>
          <H2 topSpacing={3} bottomSpacing={2}>
            {t('contextData')}
          </H2>
          <Section>
            <JsonField
              json={event.context}
              reactJsonOptions={{
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
                style: { wordBreak: 'break-all' },
              }}
            />
          </Section>
        </Grid>
      )}
      {!isEmpty(event.processing?.data) && (
        <Grid>
          <H2 topSpacing={3} bottomSpacing={2}>
            {t('processingData')}
          </H2>
          <Section>
            <JsonField
              json={event.processing?.data}
              reactJsonOptions={{
                name: null,
                collapsed: false,
                enableClipboard: false,
                displayDataTypes: false,
                style: { wordBreak: 'break-all' },
              }}
            />
          </Section>
        </Grid>
      )}
      <Section children={<></>} className={classes.bottomSpacing} />
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    '& > tbody': {
      '& > tr': {
        '& > td:first-child': {
          width: '20%',
        },
        '& > td:nth-child(2)': {
          width: '25%',
        },
        '& > td:nth-child(3)': {
          width: '35%',
        },
      },
    },
  },
  autoWidth: {},
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  section: {
    '& header #section-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover': {
      '& header #section-actions': {
        opacity: 1,
      },
    },
  },
}));

export default DetailsTab;
