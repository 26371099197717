import React, { FC, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '~/components/TableCell';

import { H2 } from '~/components';
import { Section } from '~/layout';
import { Longdash } from '~/utils';
import { ApiIdentity } from '~/types/apiIdentity';
import IdentityLocksDrawer from './IdentityLocksDrawer';
import AddIdentityLock from './AddIdentityLock';
import { useApiIdentityTranslate } from '..';

type Props = {
  record?: ApiIdentity;
  loading?: boolean;
};

const ActiveIdentityLocks: FC<Props> = (props) => {
  const { record, loading } = props;
  const [showRecordId, setShowRecordId] = useState<string | null>(null);
  const handleOpen = (id: string) => () => setShowRecordId(id);
  const handleClose = () => setShowRecordId(null);
  const showRecord = record?.data?.blocks.find((item) => item.id === showRecordId);

  const t = useApiIdentityTranslate();
  const classes = useStyles();

  return (
    <>
      <IdentityLocksDrawer identityId={record?.id} record={showRecord} onClose={handleClose} />
      <Section noPaddings>
        <Grid container justify='space-between' className={classes.bottomSpacing2}>
          <Grid item xs={8}>
            <H2 bottomSpacing={0} topSpacing={0} loading={loading}>
              {t(`activeIdentityLocks`)}
            </H2>
          </Grid>
          {record && (
            <Grid item container xs={4} id='section-actions' spacing={2} justify='flex-end'>
              <Grid item>
                <AddIdentityLock identityId={record.id} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell loading={loading}>{t('startDatetime')}</TableCell>
              <TableCell loading={loading}>{t('endDatetime')}</TableCell>
              <TableCell loading={loading}>{t('reason')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record?.data?.blocks.length ? (
              record.data.blocks.map((blockInfo) => {
                return (
                  <TableRow key={blockInfo.id} hover onClick={handleOpen(blockInfo.id)}>
                    <TableCell loading={loading}>
                      {blockInfo.from ? moment(blockInfo.from).format('ll, LTS') : Longdash}
                    </TableCell>
                    <TableCell loading={loading}>
                      {blockInfo.till ? moment(blockInfo.till).format('ll, LTS') : Longdash}
                    </TableCell>
                    <TableCell loading={loading}>{blockInfo.reason || Longdash}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell loading={loading}>{Longdash}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Section>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
  teamMemberLinkWrapper: {
    marginTop: -5,
    marginBottom: -5,
  },
}));

export default ActiveIdentityLocks;
