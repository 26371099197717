import { ProfileTag } from '~/types/profileTags';
import axios from '~/utils/axios';
import { getProfileType } from '~/utils/getProfileType';

export const mutateProfileTags = (params: any) => {
  const { resource, profileId, tags } = params;
  return axios.put<string[]>(`/${resource}/${profileId}/tags`, tags).then((res) => res.data);
};

export const getAllProfileTags = () => {
  return axios.get<ProfileTag[]>(`/profileTags?perPage=9999&page=1`).then((res) => res.data);
};

export const getProfileTagsByResource = (resource: 'businesses' | 'individuals' | 'partners') => {
  const profileType = getProfileType(resource);
  return axios
    .get<ProfileTag[]>(`/profileTags?perPage=9999&page=1&profileTypes=${profileType}`)
    .then((res) => res.data);
};
