import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';

import { selectors, loadHandbook } from '~/ducks/solutions';
import { selectors as context } from '~/ducks/context';
import * as hb from '~/types/Handbook';
import { fromPairs, isEmpty, memoize } from 'lodash';
import { useAllSolutions } from './useSolution';
import { Solution } from '~/types';
import { Handbook } from '~/types/Handbook';

export interface Result {
  loading: boolean;
  data?: hb.Handbook;
  error?: any;
}

const useHandbook = (solutionId?: string) => {
  const solutionIdFromStore = useSelector(context.get('solutionId'));
  const actualSolutionId = solutionId || solutionIdFromStore;

  const result = useSelector((state) => {
    if (actualSolutionId) {
      return selectors.getHandbookForSolution(actualSolutionId)(state);
    } else {
      return undefined;
    }
  }) as Result | undefined;

  const dispatch = useDispatch();

  const throttledLoadHandbook = useCallback(
    throttle((actualSolutionId: any): any => {
      dispatch(loadHandbook(actualSolutionId));
    }, 1000),
    [dispatch]
  );

  useEffect(() => {
    if (actualSolutionId) {
      throttledLoadHandbook(actualSolutionId);
    }
  }, [actualSolutionId, throttledLoadHandbook]);

  return result || { loading: false };
};

export const useLoadFullHandbook = (): { data: hb.Handbook[]; loading: boolean } => {
  const dispatch = useDispatch();
  const allSolutionsQury = useAllSolutions({
    staleTime: Infinity,
  });
  useEffect(() => {
    if (allSolutionsQury.isSuccess) {
      allSolutionsQury.data.data.forEach((item) => {
        dispatch(loadHandbook(item.id));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSolutionsQury.isSuccess]);
  const handbooks = useSelector(selectors.getFullHandbook) as {
    data: hb.Handbook[] | undefined;
    loading: boolean;
    error: any;
  }[];
  const loading =
    isEmpty(handbooks) || !isEmpty(handbooks?.filter((item) => (item ? item.loading : true)));
  return {
    data: (handbooks.map((item) => item.data).filter(Boolean) as any) as hb.Handbook[],
    loading,
  };
};

export const useLoadHandbooksBySolutions = (
  solutions: Solution[]
): { data: (Handbook & { solutionId: string })[]; loading: boolean } => {
  const dispatch = useDispatch();
  useEffect(() => {
    solutions.forEach((item) => {
      dispatch(loadHandbook(item.id));
    });
    // eslint-disable-next-line
  }, [solutions]);
  const handbooks = useSelector(selectors.getFullHandbookWithSolutionId) as {
    data: hb.Handbook[] | undefined;
    loading: boolean;
    error: any;
    solutionId: string;
  }[];

  const loading =
    isEmpty(handbooks) || !isEmpty(handbooks?.filter((item) => (item ? item.loading : true)));

  const solutionIds = solutions.map((solution) => solution.id);
  const selectedHandbooks = handbooks.filter((handbook) =>
    solutionIds.includes(handbook.solutionId)
  );

  return {
    data: selectedHandbooks.map((item) => {
      return { ...item.data, solutionId: item.solutionId };
    }) as (Handbook & { solutionId: string })[],
    loading,
  };
};

const getDict = memoize(
  (fullHandbook: hb.Handbook[]) => {
    const result: Record<string, string> = {};
    fullHandbook.forEach((hb) => {
      hb.feedback?.forEach(({ id, name }) => {
        result[id] = name;
      });
    });
    return result;
  },
  (f) => f.length
);

export const useAllFeedbackTags = () => {
  const fullHandbook = useLoadFullHandbook();
  const data = useMemo(() => {
    let result: Record<string, string> = {};
    if (!fullHandbook.loading) {
      result = getDict(fullHandbook.data);
    }
    return result;
  }, [fullHandbook.data, fullHandbook.loading]);

  return { data, loading: fullHandbook.loading };
};

export const useFeedbackTags = (solutionId: string | undefined) => {
  const hb = useHandbook(solutionId);
  const arr = hb.data?.feedback;
  const record = fromPairs(arr?.map((item) => [item.id, item.name]));
  return { ...hb, data: { arr, record } };
};

export default useHandbook;
