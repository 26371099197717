import React, { useState } from 'react';
import {
  TextField,
  FunctionField,
  Datagrid,
  usePermissions,
  Filter,
  TextInput,
  BooleanInput,
  ListController,
  ListContextProvider,
} from 'react-admin';
import { ListView } from '~/layout';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { NoPermissions } from '~/components/ra';
import { SelectInput } from 'react-admin';
import ProfileTagCreate from './components/ProfileTagCreate';
import ProfileTagEdit from './components/ProfileTagEdit';
import CheckIcon from '@material-ui/icons/Check';
import { SelectArrayInput } from 'react-admin';
import { ChipField, StatusField, TooltipIcon } from '~/components';
import { ProfileTag } from '~/types/profileTags';
import { useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import cx from 'classnames';

const label = (key: string) => `resources.profileTags.fields.${key}`;

const listBodyStyles = (theme: any) => {
  return createStyles({
    content: {
      fontSize: 14,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
      paddingTop: 16,
      paddingBottom: 16,
    },
  });
};

const ProfileTagsList = (props: any) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.profileTags.${key}`);
  if (!permissions) return null;
  if (!permissions?.includes('profileTag.list')) return <NoPermissions />;
  return (
    <ListController {...props}>
      {(controllerProps: any) => {
        return (
          <ListContextProvider value={controllerProps}>
            <ListView
              title={
                <div className={classes.headline}>
                  <Typography variant='h2'>{t('name')}</Typography>
                  <TooltipIcon title={t('profileTagsTooltip')} />
                </div>
              }
              filters={<ProfileTagsFilter />}
              {...controllerProps}
              pagination={controllerProps.loading || controllerProps.total === 0 ? null : undefined}
              bulkActionButtons={false}
              actions={<ProfileTagCreate />}
            >
              <ListBody />
            </ListView>
          </ListContextProvider>
        );
      }}
    </ListController>
  );
};

const ListBody = withStyles(listBodyStyles)((props: any) => {
  const translate = useTranslate();
  const t = (key: string): string => translate(label(key));

  const [showData, setShowData] = useState<ProfileTag | null>(null);
  const handleOpen = (record: ProfileTag) => {
    setShowData(record);
  };
  const handleClose = () => setShowData(null);
  const { total, loading, classes } = props;
  if (loading) {
    return <div className={classes.content}>{t('searchInProgress')}</div>;
  } else if (total === 0) {
    return <div className={classes.content}>{t('noResultsFound')}</div>;
  }
  return (
    <>
      <ProfileTagEdit record={showData} onClose={handleClose} />
      <Datagrid
        {...props}
        rowClick={(_id: any, _basePath: any, record: ProfileTag) => handleOpen(record)}
        size='small'
        selectedIds={null}
      >
        <TextField source='name' label={label('tagName')} sortable={false} />
        <TextField source='description' label={label('description')} sortable={false} />
        <FunctionField
          label={label('profileTypes')}
          sortable={false}
          render={(record: ProfileTag) => {
            return (
              <>
                {record?.profileTypes?.map((type: string) => {
                  return <ChipField label={t(type)} />;
                })}
              </>
            );
          }}
        />
        <FunctionField
          label={label('populateToTransactionTags')}
          render={(record: ProfileTag) => {
            return record.shouldPromoteToTransactionTag ? <CheckIcon /> : null;
          }}
        />
        <FunctionField
          label={label('status')}
          render={(record: ProfileTag) => {
            return <StatusField status={record?.deleted ? 'ARCHIVED' : 'ACTIVE'} />;
          }}
        />
      </Datagrid>
    </>
  );
});

const ProfileTagsFilter = (props: any) => {
  const classes = useStyles();
  return (
    <Filter {...props} className={classes.form}>
      <TextInput
        className={classes.formControl}
        label={label('tagName')}
        source='nameContains'
        alwaysOn
      />
      <SelectArrayInput
        className={cx(classes.formControl, classes.profileTypes)}
        label={label('profileTypes')}
        choices={[
          { id: 'INDIVIDUAL', name: label('INDIVIDUAL') },
          { id: 'BUSINESS', name: label('BUSINESS') },
          { id: 'PARTNER', name: label('PARTNER') },
        ]}
        source='profileTypes'
        alwaysOn
      />
      <SelectInput
        className={classes.formControl}
        label={label('populateToTransactionTags')}
        source='shouldPromoteToTransactionTag'
        allowEmpty={false}
        choices={[
          { id: '', name: label('allTags') },
          { id: 'false', name: label('nonTransactionalOnly') },
          { id: 'true', name: label('transactionalOnly') },
        ]}
        alwaysOn
      />
      <BooleanInput
        className={classes.formControl}
        label={label('withArchived')}
        source='withDeleted'
        alwaysOn
      />
    </Filter>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    '& > div': {
      '& > div:last-child': {
        width: theme.spacing(1),
      },
    },
  },
  formControl: {
    width: 200,
  },
  headline: {
    marginTop: '8px',
    display: 'flex',
  },
  profileTypes: {
    width: 300,
    '& > .MuiInputBase-root ': {
      minHeight: 46,
      '& > .MuiSelect-root': {
        paddingTop: 12,
        paddingBottom: 0,
        marginBottom: -4,
      },
    },
  },
}));

export default ProfileTagsList;
