import React from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { H2, TextField } from '~/components';
import { TextField as RaTextField } from '~/components/ra';
import { Section } from '~/layout';
import PaymentGroupEditAction from './PaymentGroupEditAction';
import PaymentGroupDeleteAction from './PaymentGroupDeleteAction';
import { PaymentGroup } from '~/types/paymentServices';
import { ReferenceField } from 'react-admin';
import { Longdash } from '~/utils';

interface Props {
  loading?: boolean;
  record?: PaymentGroup;
  resource?: string;
  permissions?: string[];
}

const l = (key: string): string => `resources.paymentServices/groups.${key}`;

const Details = (props: Props) => {
  const { loading, record } = props;

  const translate = useTranslate();
  const t = (key: string): string => translate(l(`fields.${key}`));

  const classes = useStyles();

  return (
    <Section noPaddings>
      <Grid container justify='space-between' className={classes.bottomSpacing2}>
        <Grid item xs={8}>
          <H2 bottomSpacing={0} topSpacing={0} loading={loading}>
            {translate(l(`show.detailsHeading`))}
          </H2>
        </Grid>
        <Grid item container xs={4} id='section-actions' spacing={2} justify='flex-end'>
          <Grid item>
            <PaymentGroupEditAction record={record} />
          </Grid>
          <Grid item>
            <PaymentGroupDeleteAction record={record} />
          </Grid>
        </Grid>
      </Grid>
      <Section>
        <Grid container spacing={4}>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('id')} loading={loading}>
              {record?.id}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <TextField label={t('name')} loading={loading}>
              {record?.name}
            </TextField>
          </Grid>
          <Grid item spacing={2} direction='column' xs={4}>
            <ReferenceField
              record={record}
              source='parentId'
              reference='paymentServices/groups'
              link={false}
            >
              <RaTextField
                label={t('parentId')}
                source='name'
                allowEmpty
                emptyText={Longdash}
                showLink={(record: any) => `/paymentServices/groups/${record.id}/show`}
              />
            </ReferenceField>
          </Grid>
        </Grid>
      </Section>
    </Section>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomSpacing2: {
    marginBottom: theme.spacing(2),
  },
}));

export default Details;
