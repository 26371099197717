import React, { FC, useState } from 'react';
import { usePermissions } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';

import { ApiIdentity } from '~/types/apiIdentity';
import { AccordionSummary, AccordionDetails, StatusField, TextField, H2 } from '..';
import ClearSecretWordAction from './ClearSecretWordAction';
import ResetAttemptsCounter from './ResetAttemptsCounter';
import { useApiIdentityTranslate } from '.';
import { useApiKeyByIdentityId } from '~/hooks/apiIdentity';
import { time } from '~/utils';

const maxAttempts = {
  PASSWORD: 7,
  SMS_OTP: 7,
  SECRET_WORD: 7,
  RESET_SECRET_WORD: 7,
};

interface Props {
  record?: ApiIdentity;
  loading?: boolean;
}

const AuthenticationMethods: FC<Props> = (props) => {
  const { record, loading } = props;
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const handleChange = (key: string, isExpanded: boolean) => () => {
    setExpanded((prevProps) => ({ ...prevProps, [key]: !isExpanded }));
  };

  const t = useApiIdentityTranslate();

  const { permissions } = usePermissions();
  const hasClearSecretWordPermission = permissions?.includes('apiIdentity.update');

  const classes = useStyles();

  const { data } = useApiKeyByIdentityId(record?.id, record?.hasApiKey);

  if (!record) return null;
  return (
    <>
      <H2 bottomSpacing={2} topSpacing={0} loading={loading}>
        {t(`authenticationMethods`)}
      </H2>
      {record &&
        Object.entries(record).map(([key, value]) => {
          const isExpanded = Boolean(expanded[key]);
          if (key === 'hasPassword' && value) {
            const wrongAttemptsCount = record.data?.authFailures.PASSWORD || 0;
            return (
              <Accordion
                key={key}
                className={classes.accordion}
                expanded={isExpanded}
                onChange={handleChange(key, isExpanded)}
              >
                <AccordionSummary
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={t('staticPassword')}
                  content={
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={4}>
                        <TextField label={t('login')} loading={loading}>
                          {record.username}
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <WrongAttemptsCounter
                          wrongAttemptsCount={wrongAttemptsCount}
                          maxAttemptsCount={maxAttempts.PASSWORD}
                          loading={loading}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('status')} loading={loading}>
                          <StatusField status='ACTIVE' />
                        </TextField>
                      </Grid>
                    </Grid>
                  }
                  actionNode={
                    wrongAttemptsCount ? (
                      <div className='accordion-actions'>
                        <ResetAttemptsCounter
                          identityId={record.id}
                          authenticationType='PASSWORD'
                          disabled={!value}
                        />
                      </div>
                    ) : undefined
                  }
                />
              </Accordion>
            );
          }
          if (key === 'phoneNumber' && value) {
            const wrongAttemptsCount = record.data?.authFailures.SMS_OTP || 0;
            return (
              <Accordion
                key={key}
                className={classes.accordion}
                expanded={isExpanded}
                onChange={handleChange(key, isExpanded)}
              >
                <AccordionSummary
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={t('otpBySms')}
                  content={
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={4}>
                        <TextField label={t('phone')} loading={loading}>
                          {record?.phoneNumber}
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <WrongAttemptsCounter
                          wrongAttemptsCount={wrongAttemptsCount}
                          maxAttemptsCount={maxAttempts.SMS_OTP}
                          loading={loading}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('status')} loading={loading}>
                          <StatusField status='ACTIVE' />
                        </TextField>
                      </Grid>
                    </Grid>
                  }
                  actionNode={
                    wrongAttemptsCount ? (
                      <div className='accordion-actions'>
                        <ResetAttemptsCounter
                          identityId={record.id}
                          authenticationType='SMS_OTP'
                          disabled={!value}
                        />
                      </div>
                    ) : undefined
                  }
                />
              </Accordion>
            );
          }
          if (key === 'hasSecretWord' && value) {
            const wrongSecretWordCount = record.data?.authFailures.SECRET_WORD || 0;
            const wrongResetSecretWordCount = record.data?.authFailures.RESET_SECRET_WORD || 0;
            return (
              <Accordion
                key={key}
                className={classes.accordion}
                expanded={isExpanded}
                onChange={handleChange(key, isExpanded)}
              >
                <AccordionSummary
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={t('secretWord')}
                  content={
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={4}>
                        <WrongAttemptsCounter
                          wrongAttemptsCount={wrongSecretWordCount}
                          maxAttemptsCount={maxAttempts.SECRET_WORD}
                          loading={loading}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <WrongAttemptsCounter
                          title={t('wrongResetAttempts')}
                          wrongAttemptsCount={wrongResetSecretWordCount}
                          maxAttemptsCount={maxAttempts.RESET_SECRET_WORD}
                          loading={loading}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('status')} loading={loading}>
                          <StatusField status='ACTIVE' />
                        </TextField>
                      </Grid>
                    </Grid>
                  }
                  actionNode={
                    <Grid container justify='flex-end' spacing={1} className='accordion-actions'>
                      {wrongSecretWordCount ? (
                        <Grid item>
                          <ResetAttemptsCounter
                            identityId={record.id}
                            authenticationType='SECRET_WORD'
                            disabled={!value}
                          />
                        </Grid>
                      ) : undefined}
                      {wrongResetSecretWordCount ? (
                        <Grid item>
                          <ResetAttemptsCounter
                            title={t('clearResetAttemptsCounter')}
                            identityId={record.id}
                            authenticationType='RESET_SECRET_WORD'
                            disabled={!value}
                          />
                        </Grid>
                      ) : undefined}
                      {hasClearSecretWordPermission && (
                        <Grid item>
                          <ClearSecretWordAction identityId={record.id} disabled={!value} />
                        </Grid>
                      )}
                    </Grid>
                  }
                />
                <AccordionDetails>
                  <Grid container spacing={2} xs={12}>
                    <Grid item xs={4}>
                      <TextField label={t('secretWord')} loading={loading}>
                        {value && '*****'}
                      </TextField>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          }
          if (key === 'hasApiKey' && value) {
            return (
              <Accordion
                key={key}
                className={classes.accordion}
                expanded={isExpanded}
                onChange={handleChange(key, isExpanded)}
              >
                <AccordionSummary
                  aria-controls={`${key}-content`}
                  id={`${key}-header`}
                  expanded={isExpanded}
                  heading={t('apiKey')}
                  content={
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={4}>
                        <TextField label={t('apiKeyPrefix')} loading={loading}>
                          {data?.keyPrefix}
                        </TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <TextField label={t('createdAt')} loading={loading}>
                          {time(data?.createdAt).format('ll, LT')}
                        </TextField>
                      </Grid>
                      <Grid item>
                        <TextField label={t('status')} loading={loading}>
                          <StatusField status='ACTIVE' />
                        </TextField>
                      </Grid>
                    </Grid>
                  }
                />
              </Accordion>
            );
          }
          return null;
        })}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  accordion: {
    '& .accordion-actions': {
      opacity: 0,
      transition: `opacity ${theme.transitions.easing.easeIn}`,
      transitionDuration: `${theme.transitions.duration.shortest}ms`,
    },
    '&:hover .accordion-actions': {
      opacity: 1,
    },
  },
}));

interface WrongAttemptsCounterProps {
  wrongAttemptsCount: number;
  maxAttemptsCount: number;
  loading?: boolean;
  title?: string;
}

const WrongAttemptsCounter: FC<WrongAttemptsCounterProps> = (props) => {
  const { wrongAttemptsCount, maxAttemptsCount, loading, title } = props;
  const t = useApiIdentityTranslate();
  const classes = useAttemptsStyles({ wrongAttemptsCount, maxAttemptsCount });
  return (
    <TextField label={title ?? t('wrongAttempts')} loading={loading}>
      <Tooltip title={t('wrongAttemptsTooltip')} placement='top'>
        <div className={classes.textColor}>{`${wrongAttemptsCount} ${t(
          'outOf'
        )} ${maxAttemptsCount}`}</div>
      </Tooltip>
    </TextField>
  );
};

const useAttemptsStyles = makeStyles<
  Theme,
  { wrongAttemptsCount: number; maxAttemptsCount: number }
>((theme) => ({
  textColor: {
    color: ({ wrongAttemptsCount, maxAttemptsCount }) => {
      const isLimitReached = wrongAttemptsCount === maxAttemptsCount;
      const hasWrongAttempts = wrongAttemptsCount > 0;
      if (isLimitReached) {
        return theme.palette.error.main;
      }
      if (hasWrongAttempts) {
        return theme.palette.warning.main;
      }
      return theme.palette.text.primary;
    },
  },
}));

export default AuthenticationMethods;
