import React, { Fragment } from 'react';
import { useTranslate, TextInput, required, SelectInput } from 'react-admin';
import { Grid } from '@material-ui/core';

import { H3, StatusField } from '~/components';
import { PhoneNumberInput, HiddenInput, ReferenceInput, ModalOrSelectInput } from '~/components/ra';
import { Business, Location, Terminal } from '~/types';
import { Longdash } from '~/utils';
import { useLinkedToLocalion } from '~/hooks';

interface Props {
  businessRecord: Business;
  terminalRecord?: Terminal;
  record?: any;
  create?: boolean;
}

const TerminalForm = (props: Props) => {
  const { businessRecord, terminalRecord, create } = props;
  const { solutionId, id: businessId } = businessRecord;
  const translate = useTranslate();
  const t = (key: string): string => translate(`resources.businesses.terminals.${key}`);

  // Check if location is required in feature config.
  const requireLinkedToLocalion = useLinkedToLocalion(solutionId);
  return (
    <Fragment>
      {create && (
        <Fragment>
          <HiddenInput source='solutionId' defaultValue={solutionId} />
          <HiddenInput
            source='businessId'
            defaultValue={businessId || '00000000-0000-0000-0000-000000000000'}
          />
        </Fragment>
      )}
      <TextInput source='label' label={t('label')} validate={required()} fullWidth />
      <SelectInput
        source='terminalType'
        label={t('type')}
        validate={required()}
        f
        choices={[
          { id: 'MPOS', name: t('MPOS') },
          { id: 'ECOMMERCE', name: t('ECOMMERCE') },
        ]}
        disabled={terminalRecord?.terminalType && !create}
      />
      {create && (
        <ReferenceInput
          source='locationId'
          reference='locations'
          filter={{ businessId }}
          allowEmpty={!requireLinkedToLocalion}
          emptyText={Longdash}
          label={t('location')}
          validate={requireLinkedToLocalion && required()}
        >
          <ModalOrSelectInput<Location> optionText='name' fullWidth>
            {(item) => {
              return (
                <Grid container spacing={1} alignItems='center' wrap='nowrap'>
                  <Grid item>
                    <StatusField status={item.status} variant='dot' />
                  </Grid>
                  <Grid item>{item.name}</Grid>
                </Grid>
              );
            }}
          </ModalOrSelectInput>
        </ReferenceInput>
      )}
      <ReferenceInput source='accountId' reference='accounts' filter={{ businessId }}>
        <SelectInput validate={required()} optionText='number' fullWidth />
      </ReferenceInput>
      <PhoneNumberInput source='linkedPhone' fullWidth />
      <H3>{t('additionalData')}</H3>
      <TextInput
        label={t('additionalProperties.NFCTerminal')}
        source='additionalProperties.NFCTerminal'
        fullWidth
      />
    </Fragment>
  );
};

export default TerminalForm;
