import React from 'react';
import { Button } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Cancel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  red: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  onClick?: () => void;
  warning?: boolean;
  red?: boolean;
  label?: string;
  loading?: boolean;
  [x: string]: any;
}

const CloseButton = (props: Props) => {
  const { onClick, warning, red, label, loading, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      label={label || `components.ra.fields.CloseButton`}
      className={cx({ [classes.warning]: warning, [classes.red]: red })}
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <CircularProgress size={18} thickness={2} color='inherit' /> : <CloseIcon />}
    </Button>
  );
};

export default CloseButton;
