export default {
  EXPIRED: 'Expired',
  INITIAL: 'Initial',
  IN_PROGRESS: 'In progress',
  RETRY_IS_SCHEDULED: 'Retry is scheduled',
  FAILED: 'Failed',
  DONE: 'Done',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  TERMINATED: 'Terminated',
};
