import React, { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Skeleton } from '@material-ui/lab';
import { useGetProfileTagsByResource } from '~/hooks/profileTags';
import { AutocompleteArrayTextInput } from '.';

interface ProfileTagsFilter {
  className: string;
  resource: 'businesses' | 'individuals' | 'partners';
  [x: string]: any;
}

const ProfileTagsFilter: FC<ProfileTagsFilter> = (props) => {
  const { className, resource } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`components.ra.fields.ProfileTags.${key}`);
  const { data: allTags, isLoading } = useGetProfileTagsByResource(resource);
  const autocompleteOptions = allTags?.map((tag) => tag?.name);
  if (isLoading) return <Skeleton width={210} height={24} />;
  return (
    <AutocompleteArrayTextInput
      label={t('profileTags')}
      source='tags'
      options={autocompleteOptions}
      className={className}
      disableHelperText
      freeSolo={false}
    />
  );
};

export default ProfileTagsFilter;
