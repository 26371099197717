import React, { useState, Fragment } from 'react';
import {
  useTranslate,
  ImageInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  useMutation,
  FormDataConsumer,
} from 'react-admin';
import { useField } from 'react-final-form';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { ColorInput } from 'react-admin-color-input';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-final-form';

import { Drawer, AddDeleteButton } from '~/components';
import { FileField } from '~/components/ra';
import ImageField from './ImageField';
import { CSSProperties } from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > div': {
      '& > div': {
        display: 'flex',
      },
    },
  },
  editableAvatar: {
    position: 'relative',
    '&:hover': {
      '& #avatarEditArea': {
        bottom: 0,
      },
    },
  },
  avatarEditArea: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, .5)',
    height: '30%',
    width: '100%',
    bottom: '-30%',
    transition: `bottom ${theme.transitions.easing.easeOut}`,
    transitionDuration: `${theme.transitions.duration.shortest}ms`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      fontSize: '70%',
      color: 'rgba(256, 256, 256, .8)',
    },
  },
  form: {
    '& > div': {
      padding: 0,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
  toolbar: {
    backgroundColor: 'transparent',
    marginTop: 0,
    minHeight: 'auto',
  },
  fileInput: {
    '& .previews': {
      display: 'none',
    },
  },
}));

const label = (key: string): string => `components.ra.fields.AvatarField.${key}`;

const EmptyComponent = () => <div></div>;

const FileCard = (props: { resource?: string; source: string; formData: any }) => {
  const { resource, source, formData } = props;
  const form = useForm();

  return (
    <AddDeleteButton onDeleteClick={() => form.change(source, undefined)}>
      <FileField
        record={formData}
        resource={resource}
        source={source}
        withPreview
        imageFieldProps={{
          width: '100%',
        }}
      />
    </AddDeleteButton>
  );
};

interface BottomToolbarProps {
  onSave: (newRecord: any) => void;
  handleClose: () => void;
  source: string;
  [x: string]: any;
}

const BottomToolbar = (props: BottomToolbarProps) => {
  const classes = useStyles();
  const { onSave, handleClose, source, ...rest } = props; // eslint-disable-line
  const iconField = useField(source);
  const colorField = useField('branding.color');

  return (
    <Toolbar className={classes.toolbar} {...rest}>
      <SaveButton
        onSave={onSave}
        disabled={!iconField.meta.modified && !colorField.meta.modified}
      />
    </Toolbar>
  );
};

interface Props {
  size?: number | string;
  source: string;
  resource?: string;
  record?: any;
  updatable?: boolean;
  colorSource?: string;
  canDownload?: boolean;
  fullScreenView?: boolean;
  style?: CSSProperties;
  [x: string]: any;
}

const AvatarField = (props: Props) => {
  const {
    size = 40,
    resource,
    source,
    record,
    updatable = true,
    colorSource,
    fullScreenView = true,
    canDownload = true,
    style = {},
  } = props;
  const isUpdatable = (updatable && resource && source && record) || false;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const translate = useTranslate();
  const translateLabel = (key: string): string => translate(label(key));

  const [mutate] = useMutation();
  const update = (newRecord: any) =>
    mutate(
      {
        type: 'update',
        resource: resource,
        payload: { id: record.id, data: newRecord },
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );

  const handleSubmit = (newRecord: any) => {
    update(newRecord);
  };

  return (
    <Avatar
      className={cx(classes.root, { [classes.editableAvatar]: isUpdatable })}
      style={{ width: size, height: size, ...style }}
    >
      <ImageField
        fullScreenView={fullScreenView}
        canDownload={canDownload}
        noText
        {...props}
        objectFit='cover'
        width={size}
        height={size}
      />
      {isUpdatable && (
        <div id='avatarEditArea' className={classes.avatarEditArea} onClick={handleOpen}>
          <EditIcon />
        </div>
      )}
      <Drawer heading={translateLabel('editAvatar')} open={open} onClose={handleClose}>
        <SimpleForm
          redirect={false}
          className={classes.form}
          record={record}
          toolbar={
            <BottomToolbar source={source} onSave={handleSubmit} handleClose={handleClose} />
          }
          margin='none'
        >
          <FormDataConsumer>
            {({ formData }: any) => {
              return (
                <Fragment>
                  {(resource === 'businesses' || colorSource) && (
                    <ColorInput
                      source={colorSource || 'branding.color'}
                      record={record}
                      picker='Twitter'
                      label='components.ra.inputs.ColorInput'
                      className='MuiFilledInput'
                      variant='filled'
                    />
                  )}
                  <Grid item xs={12}>
                    <FileCard resource={resource} formData={formData} source={source} />
                  </Grid>
                  <ImageInput
                    source={source}
                    className={classes.fileInput}
                    accept='image/*'
                    children={<EmptyComponent />}
                    label='components.ra.inputs.ImageInput'
                  />
                </Fragment>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Drawer>
    </Avatar>
  );
};

AvatarField.defaultProps = {
  addLabel: true,
};

export default AvatarField;
