import React from 'react';
import { Button } from 'react-admin';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslate } from 'react-admin';

interface Props {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  label?: string;
  disabled?: boolean;
}

const AddFilterButton = (props: Props) => {
  const { onClick, label, disabled } = props;
  const translate = useTranslate();

  return (
    <Button
      label={label || translate('components.AddFilterButton.label')}
      onClick={onClick}
      disabled={disabled}
    >
      <FilterListIcon />
    </Button>
  );
};

export default AddFilterButton;
