import React, { Fragment, useState, useCallback, useEffect, FC } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { time } from '~/utils';
import cx from 'classnames';

import { SelectInput, TextInput, AddFilterAction } from '~/components';
import { filterChange, selectors, cleanupFilters } from '~/ducks/virtualizedList';
import { resource } from './ActionReportsList';
import { ActionReport, Moment } from '~/types';
import { statusLabel } from '~/components/StatusField';
import { useAllReportSystemsAndTypes } from '~/hooks/actionReports';
import { useTranslateActionReports } from '.';
import { isEmpty } from 'lodash';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';

interface FiltersProps {
  cleanFilters?: boolean;
}

const Filters: FC<FiltersProps> = (props) => {
  const { cleanFilters = true } = props;
  const filters = useSelector(selectors.getFilters(resource));
  const [localValues, setLocalValues] = useState(filters);

  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslateActionReports();

  useEffect(() => {
    setLocalValues({ ...filters });
  }, [filters]);

  const dispatchFilterChange = (name: string, value: string | Moment | undefined) => {
    dispatch(filterChange({ resource, filters: { [name]: value || null } }));
  };

  const debouncedDispatchFilterChange = useCallback(debounce(dispatchFilterChange, 1000), []);
  const debouncedHandleInput = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });

    debouncedDispatchFilterChange(name, value);
  };
  const handleSelectInput = (e: any) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });

    dispatchFilterChange(name, value);
  };

  const [addedFilters, setAddedFilters] = useState<string[]>(
    Object.keys(filters).filter((k) => {
      return !!(filters as any)[k];
    })
  );

  const hideFilter = (filter: string) => {
    let inputName = filter;
    debouncedHandleInput({
      target: { name: inputName, value: null },
    });
    setAddedFilters(addedFilters.filter((f) => f !== filter));
  };

  const handleSystemChange = (e: any) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
      reportType: '',
    });
    dispatchFilterChange(name, value);
    dispatchFilterChange('reportType', '');
  };
  const handleDateTimeChange = (data: Moment, inputType: string) => {
    setLocalValues({
      ...localValues,
      [inputType]: data,
    });
    dispatchFilterChange(inputType, data);
  };

  const schemaQuery = useAllReportSystemsAndTypes();
  const getSystemOptions = () => {
    return (
      schemaQuery.data?.map(({ system }) => ({
        value: system,
        text: system,
      })) || []
    );
  };
  const getReportTypeOptions = (system: string | undefined) => {
    if (!system) return [];
    return (
      schemaQuery.data
        ?.find((item) => item.system === system)
        ?.reportTypes.map((reportType) => ({
          value: reportType,
          text: reportType,
        })) || []
    );
  };

  const filtersAreEmpty = isEmpty(filters);
  useEffect(() => {
    if (cleanFilters || filtersAreEmpty) {
      dispatch(cleanupFilters({ resource }));
      dispatch(
        filterChange({
          resource,
          filters: {
            createdFrom: time().subtract(1, 'months'),
            createdTo: time(),
          },
        })
      );
    }
  }, [dispatch, cleanFilters, filtersAreEmpty]);

  const allFilters = ['referenceId', 'objectType', 'objectId', 'hasWarnings'];

  const renderAddedFilter = (key: string) => {
    switch (key) {
      case 'objectType':
        return (
          <SelectInput<ActionReport['objectType'] | undefined>
            label={t('objectType')}
            name='objectType'
            value={localValues.objectType}
            className={cx(classes.formControl, classes.formControlAdd)}
            options={[{ value: 'HEAD_TRANSACTION', text: t('HEAD_TRANSACTION') }]}
            onChange={handleSelectInput}
            allowEmpty
          />
        );
      case 'objectId':
        return (
          <TextInput
            name='objectId'
            onChange={debouncedHandleInput}
            value={localValues.objectId}
            label={t('objectId')}
            className={cx(classes.formControl, classes.formControlAdd)}
            disableHelperText
          />
        );
      case 'hasWarnings':
        return (
          <SelectInput<string>
            label={t('hasWarnings')}
            name='hasWarnings'
            value={localValues.hasWarnings + ''}
            className={cx(classes.formControl, classes.formControlAdd)}
            options={[
              {
                value: 'true',
                text: t('hasSomeWarnings'),
              },
              {
                value: 'false',
                text: t('hasNoWarnings'),
              },
            ]}
            onChange={handleSelectInput}
            allowEmpty
          />
        );
      case 'referenceId':
        return (
          <TextInput
            name='referenceId'
            onChange={debouncedHandleInput}
            value={localValues.referenceId}
            label={t('referenceId')}
            className={cx(classes.formControl, classes.formControlAdd)}
            disableHelperText
          />
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      {filters && (
        <div className={classes.container}>
          <div className={classes.leftSide}>
            <div className={classes.inputsGroup}>
              <DateTimePicker
                className={cx(classes.formControl, classes.formControlLong)}
                format='L - LT'
                label={t('createdFrom')}
                value={localValues.createdFrom || null}
                onChange={(date) => handleDateTimeChange(date as Moment, 'createdFrom')}
                maxDate={localValues.createdTo || time().format()}
                clearable
              />
              <DateTimePicker
                className={cx(classes.formControl, classes.formControlLong)}
                format='L - LT'
                label={t('createdTo')}
                value={localValues.createdTo || null}
                onChange={(date) => handleDateTimeChange(date as Moment, 'createdTo')}
                maxDate={time().format()}
                minDate={localValues.createdFrom || undefined}
                clearable
              />
              <SelectInput<ActionReport['system'] | undefined>
                label={t('system')}
                name='system'
                value={localValues.system}
                className={cx(classes.formControl, classes.formControlLong)}
                options={getSystemOptions()}
                onChange={handleSystemChange}
                disabled={schemaQuery.isLoading}
                allowEmpty
              />
              <SelectInput<ActionReport['reportType'] | undefined>
                label={t('reportType')}
                name='reportType'
                value={localValues.reportType}
                className={cx(classes.formControl, classes.formControlLong)}
                options={getReportTypeOptions(localValues.system)}
                onChange={handleSelectInput}
                disabled={schemaQuery.isLoading || !localValues.system}
                allowEmpty
                defaultValue=''
              />
              <ReportStatusSelectInput
                name='status'
                value={localValues.status}
                className={cx(classes.formControl, classes.formControlLong)}
                onChange={handleSelectInput}
              />
              {addedFilters.map((filter) => {
                const component = renderAddedFilter(filter);
                if (component) {
                  return (
                    <div className={classes.flexContainer} key={filter}>
                      {component}
                      <IconButton
                        aria-label='delete'
                        className={classes.deleteIcon}
                        onClick={() => hideFilter(filter)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className={classes.rightSide}>
            <AddFilterAction
              filters={allFilters
                .filter((f) => !addedFilters.includes(f))
                .sort()
                .map((filterKey) => ({
                  key: filterKey,
                  label: t(filterKey),
                }))}
              onFilterSelected={(filter: string) => {
                setAddedFilters(addedFilters.concat([filter]));
              }}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export const ReportStatusSelectInput: FC<{
  name: string;
  label?: string;
  value: ActionReport['status'] | undefined;
  onChange: (e: any) => void;
  className?: string;
  [x: string]: any;
}> = (props) => {
  const { name, value, onChange, className, label, ...rest } = props;
  const translate = useTranslate();
  const t = useTranslateActionReports();

  return (
    <SelectInput<ActionReport['status'] | undefined>
      label={label || t('status')}
      name={name}
      value={value}
      className={cx(className)}
      options={[
        { value: 'ACCEPTED', text: translate(statusLabel('ACCEPTED')) },
        { value: 'FAILED', text: translate(statusLabel('FAILED')) },
        { value: 'INITIAL', text: translate(statusLabel('INITIAL')) },
        { value: 'REJECTED', text: translate(statusLabel('REJECTED')) },
        { value: 'SUBMITTED', text: translate(statusLabel('SUBMITTED')) },
        { value: 'INVALID', text: translate(statusLabel('INVALID')) },
        { value: 'REVOKED', text: translate(statusLabel('REVOKED')) },
      ]}
      allowEmpty
      onChange={onChange}
      {...rest}
    />
  );
};

const useStyles = makeStyles((theme) => {
  const formControlWidth = 200;
  const deleteIconSize = theme.spacing(1) * 4;
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      paddingBottom: theme.spacing(1),
    },
    flexContainer: {
      display: 'flex',
    },
    leftSide: {
      maxWidth: 'calc(100% - 150px)',
    },
    // Can change width to fit export or other buttons on the right side
    rightSide: { minWidth: 150, textAlign: 'right' },
    deleteIcon: {
      width: deleteIconSize,
      height: deleteIconSize,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    inputsGroup: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
    },
    formControl: {
      paddingBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: formControlWidth,
      '&:last-child': {
        marginRight: 0,
      },
    },
    formControlLong: {
      width: formControlWidth + deleteIconSize,
    },
    formControlAdd: {
      marginRight: 0,
    },
    // Use this style for long dynamic filters if needed
    formControlAddLong: {
      width: formControlWidth * 2 + deleteIconSize + theme.spacing(1),
    },
  };
});

export default Filters;
