import React from 'react';
import { Datagrid, TextField, Filter, TextInput, usePermissions } from 'react-admin';

import { List } from '~/layout';
import {
  LocalSort,
  NoPermissions,
  ProfileTagsFilter,
  StatusField,
  TranslateField,
} from '~/components/ra';
import PartnerCreate from './PartnerCreate';
import { makeStyles } from '@material-ui/core/styles';

const PartnersList = (props: any) => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  if (!permissions) return null;
  if (!permissions?.includes('partner.list')) return <NoPermissions />;
  return (
    <List
      bulkActionButtons={false}
      {...props}
      sort={{ field: 'name', order: 'ASC' }}
      actions={<PartnerCreate permissions={permissions} />}
      filters={
        <Filter className={classes.form}>
          <TextInput
            label='Account number'
            source='accountNumber'
            alwaysOn
            className={classes.formControl}
          />
          <ProfileTagsFilter resource='partners' className={classes.formControlLong} alwaysOn />
        </Filter>
      }
    >
      <LocalSort>
        <Datagrid rowClick='show' size='medium' selectedIds={null}>
          <TextField source='name' sortable={false} />
          <TextField source='description' sortable={false} />
          <TranslateField
            source='transfersDirection'
            i18nPath='resources.partners.fields'
            sortable={false}
          />
          <TextField source='externalId' sortable={false} />
          <TextField source='accountNumber' sortable={false} />
          <TextField source='accountAlias' sortable={false} />
          <StatusField source='status' sortable={false} />
        </Datagrid>
      </LocalSort>
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    alignItems: 'flex-start',
    '& > div': {
      '& > div:last-child': {
        width: theme.spacing(1),
      },
    },
  },
  formControl: { width: 210 },
  formControlLong: {
    width: 210 * 2 + theme.spacing(1),
  },
}));

export default PartnersList;
