import React, { useState, useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import TextInput from '@material-ui/core/TextField';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

import validatePhoneNumber from '~/utils/validatePhoneNumber';
import { format, parse } from '~/components/ra/PhoneNumberInput';

interface Props {
  value?: string;
  onChange: (value: string) => void;
  [x: string]: any;
}

const PhoneNumberInput = (props: Props) => {
  const { onChange, value, ...rest } = props;
  const inputValue = useRef<TextFieldProps>(null);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    handleChange({ target: { value } });
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (errorMessage) setErrorMessage('');
    inputValue.current!.value = format(value);
    onChange(parse(value));
  };

  const handleValidation = () => {
    if (!value) return;
    const obj = validatePhoneNumber(value);
    if (obj) setErrorMessage(t(obj.message));
  };

  const t = useTranslate();

  return (
    <TextInput
      inputRef={inputValue}
      label={t('components.ra.inputs.PhoneNumberInput')}
      onChange={handleChange}
      error={!!errorMessage}
      helperText={errorMessage}
      onBlur={handleValidation}
      {...rest}
    />
  );
};

export default PhoneNumberInput;
