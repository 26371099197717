import React from 'react';
import { DateField, TextField, NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

import { FeeField, FileField } from '~/components/ra';

const useStyles = makeStyles({
  defaultValue: { color: 'rgba(0,0,0,0.25)' },
});

const withDefaultValue = (BaseField) => {
  const FieldWrapper = ({ source, defaultValue, record }) => {
    const classes = useStyles();
    const actualValue = get(record, source);
    if (!actualValue) {
      return (
        <BaseField
          source='value'
          record={{ value: defaultValue }}
          className={classes.defaultValue}
        />
      );
    } else {
      return <BaseField source={source} record={record} />;
    }
  };
  FieldWrapper.defaultProps = BaseField.defaultProps;

  return FieldWrapper;
};

const wrappedTextField = withDefaultValue(TextField);
const wrappedNumberField = withDefaultValue(NumberField);

export {
  DateField,
  wrappedTextField as TextField,
  wrappedNumberField as NumberField,
  FeeField,
  FileField,
};
