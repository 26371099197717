import React from 'react';
import { Query, Loading } from 'react-admin';

import JsonSchemaForm from '@rjsf/material-ui';
import fields from './fields';
import uiSchemaOf from './uiSchemaOf';
import useLocalizer from './useLocalizer';
//import { UpdateContext } from '~/components';

export interface Props {
  schema: any;
  edit?: boolean;
  [key: string]: any;
}

interface ViewBodyProps {
  schema: any;
  [key: string]: any;
}

const ViewBody = ({ data, schema, ...rest }: ViewBodyProps) => {
  const localizer = useLocalizer(rest.resource);
  const uiSchema = uiSchemaOf(schema, localizer);

  return (
    <JsonSchemaForm
      schema={schema}
      formData={data}
      fields={fields}
      uiSchema={uiSchema}
      tagName='div'
      noHtml5Validate
    >
      <br />
    </JsonSchemaForm>
  );
};

const View = ({ schema, edit = false, record, ...rest }: Props) => {
  if (record) {
    if (record.parentId) {
      return (
        <Query
          type='getOne'
          resource={`configurations/${record.type}`}
          payload={{ id: record.parentId }}
        >
          {({ loading, error }: { loading: boolean; error?: any }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              // FIXME provide valid error message
              return <p>Failed to load parent configuration</p>;
            }
            return null;
          }}
        </Query>
      );
    } else if (edit) {
      /*
      return buildInputs({
        context: 'configuration',
        objectSchema: configurationSchema,
        sourcePrefix: 'data',
      });
        */
      return (
        <>
          {/* <UpdateContext data={{ solutionId: record.solutionId }} /> */}
          <ViewBody data={record.data} schema={schema} {...rest} />
        </>
      );
    } else {
      // FIXME provide valid message
      return <p>Choose parent configuration first.</p>;
    }
  } else {
    // FIXME handle this?
    return null;
  }
};

export default View;
