import React, { FC, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { JSONSchemaType } from 'ajv';

import ajv from '~/utils/ajv';
import { time } from '~/utils';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

import TableCell from '~/components/TableCell';

import {
  AmountField,
  CountryField,
  H2,
  JsonSchemaView,
  Link,
  StatusField,
  TextField,
} from '~/components';
import { Section } from '~/layout';
import { Deal } from '~/types/Deal';
import OperationTypeField from '~/components/OperationTypeField';

import { OpenIcon } from '~/img';

import ProfileField from '~/components/ProfileField';
import { HeadTransaction } from '~/types/HeadTransaction';
import { getTransaction } from '~/api/headTransactions';
import ShowCodeAction from './ShowCodeAction';
import { useConfiguration, useDeepEffect } from '~/hooks';
import { Alert, Skeleton } from '@material-ui/lab';
import { Longdash } from '~/utils';

interface AdditionalPropertiesSchema {
  schema?: JSONSchemaType<Record<string, any>>;
  error?: boolean;
  noSchema?: boolean;
}

const ShowDetails: FC<any> = (props) => {
  const { permissions, record } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`resources.deals.show.details.${key}`);

  const classes = useStyles();

  const deal = record as Deal;

  // const [senderTransaction, setSenderTransaction] = useState<HeadTransaction | null>(null);
  // const [recipientTransaction, setRecipientTransaction] = useState<HeadTransaction | null>(null);
  // const [reversalTransaction, setReversalTransaction] = useState<HeadTransaction | null>(null);
  const [transactions, setTransactions] = useState<HeadTransaction[]>([]);

  /*
  useEffect(() => {
    if (deal.senderHeadTransactionId) {
      getTransaction({ id: deal.senderHeadTransactionId })
        .then(({ data: senderTransaction }) => {
          setSenderTransaction(senderTransaction);
        })
        .catch((e) => {
          console.error(
            `failed to load sender head transaction for id=${deal.senderHeadTransactionId}; error:`,
            e
          );
          setSenderTransaction(null);
        });
    }
  }, [deal.senderHeadTransactionId]);
  useEffect(() => {
    if (deal.recipientHeadTransactionId) {
      getTransaction({ id: deal.recipientHeadTransactionId })
        .then(({ data: recipientTransaction }) => {
          setRecipientTransaction(recipientTransaction);
        })
        .catch((e) => {
          console.error(
            `failed to load recipient head transaction for id=${deal.recipientHeadTransactionId}; error:`,
            e
          );
          setRecipientTransaction(null);
        });
    }
  }, [deal.recipientHeadTransactionId]);
  useEffect(() => {
    if (deal.reversalHeadTransactionId) {
      getTransaction({ id: deal.reversalHeadTransactionId })
        .then(({ data: reversalTransaction }) => {
          setReversalTransaction(reversalTransaction);
        })
        .catch((e) => {
          console.error(
            `failed to load reversal head transaction for id=${deal.reversalHeadTransactionId}; error:`,
            e
          );
          setReversalTransaction(null);
        });
    }
  }, [deal.reversalHeadTransactionId]);
   */
  useEffect(() => {
    Promise.all(
      deal.transactions.map((id) => {
        return getTransaction({ id });
      })
    )
      .then((responses) => {
        setTransactions(responses.map((response) => response.data));
      })
      .catch((e) => {
        console.error(`failed to load transactions for ids=${deal.transactions}; error:`, e);
        setTransactions([]);
      });
  }, [deal.transactions]);

  const remitterSolutionId = deal?.remitter?.senderData?.solutionId;
  const { data: remitterConfiguration } = useConfiguration(remitterSolutionId);

  const [
    additionalPropertiesSchema,
    setAdditionalPropertiesSchema,
  ] = useState<AdditionalPropertiesSchema>();

  useDeepEffect(() => {
    if (remitterConfiguration && deal?.operationType) {
      let schemaRaw: string | undefined;
      switch (deal?.operationType) {
        case 'REMITTANCE':
          schemaRaw =
            remitterConfiguration?.veengu_individual_remittance?.additionalPropertiesSchema;
          break;
        default:
          break;
      }

      if (schemaRaw) {
        try {
          const anySchema = JSON.parse(schemaRaw) as any;
          if (anySchema.type !== 'object') {
            throw new Error('Json schema type !== object');
          }
          const typedSchema = anySchema as JSONSchemaType<Record<string, any>>;
          ajv.compile(typedSchema);
          setAdditionalPropertiesSchema({ schema: typedSchema });
        } catch (e) {
          console.error(`failed to parse configuration schema`, e);
          setAdditionalPropertiesSchema({ error: true });
        }
      } else {
        setAdditionalPropertiesSchema({ noSchema: true });
      }
    }
  }, [remitterConfiguration, deal?.operationType]);

  /*
  const transactions: HeadTransaction[] = [];
  if (senderTransaction) {
    transactions.push(senderTransaction);
  }
  if (recipientTransaction) {
    transactions.push(recipientTransaction);
  }
  if (reversalTransaction) {
    transactions.push(reversalTransaction);
  }
   */

  if (!record) return null;

  const fullDeal = {
    ...deal,
    senderHeadTransaction: transactions.find((trx) => trx.id === deal.senderHeadTransactionId),
    recipientHeadTransaction: transactions.find(
      (trx) => trx.id === deal.recipientHeadTransactionId
    ),
    reversalHeadTransaction: transactions.find((trx) => trx.id === deal.reversalHeadTransactionId),
  };

  // Collect all senderData and add to variables.
  const remitterSenderData = deal?.remitter?.senderData;
  const beneficiarySenderData = deal?.beneficiary?.senderData;

  // Make an array out of senderData to render it later.
  const senderDataArray = [
    {
      key: 'accountNumber',
      remitterValue: remitterSenderData ? remitterSenderData.accountNumber : undefined,
      beneficiaryValue: beneficiarySenderData ? beneficiarySenderData.accountNumber : undefined,
    },
    {
      key: 'financial.code',
      remitterValue: remitterSenderData?.financialInstitution
        ? remitterSenderData.financialInstitution.code
        : undefined,
      beneficiaryValue: beneficiarySenderData?.financialInstitution
        ? beneficiarySenderData.financialInstitution.code
        : undefined,
    },
    {
      key: 'financial.country',
      remitterValue: remitterSenderData?.financialInstitution ? (
        <CountryField>{remitterSenderData.financialInstitution.country}</CountryField>
      ) : undefined,
      beneficiaryValue: beneficiarySenderData?.financialInstitution ? (
        <CountryField>{beneficiarySenderData.financialInstitution.country}</CountryField>
      ) : undefined,
    },
    {
      key: 'financial.name',
      remitterValue: remitterSenderData?.financialInstitution
        ? remitterSenderData.financialInstitution.name
        : undefined,
      beneficiaryValue: beneficiarySenderData?.financialInstitution
        ? beneficiarySenderData.financialInstitution.name
        : undefined,
    },
    {
      key: 'financial.address',
      remitterValue: remitterSenderData?.financialInstitution
        ? remitterSenderData.financialInstitution.address
        : undefined,
      beneficiaryValue: beneficiarySenderData?.financialInstitution
        ? beneficiarySenderData.financialInstitution.address
        : undefined,
    },
  ];

  // Check if any rows are gonna render.
  const anyRowsRendered: boolean = senderDataArray.some(
    (item) => item.remitterValue || item.beneficiaryValue
  );

  return (
    <Section noPaddings>
      <Grid container justify='space-between'>
        <Grid item>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('routingDetails')}
          </H2>
        </Grid>
        <Grid item id='section-actions'>
          <ShowCodeAction record={deal} permissions={permissions} />
        </Grid>
      </Grid>
      <Section className={`${classes.bottomSpacing}`}>
        {anyRowsRendered ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('parameter')}</TableCell>
                <TableCell>{t('remitter')}</TableCell>
                <TableCell>{t('beneficiary')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {senderDataArray.map((item, index) => {
                const remitterValue = item.remitterValue;
                const beneficiaryValue = item.beneficiaryValue;
                if (remitterValue || beneficiaryValue) {
                  return (
                    <TableRow key={index}>
                      <TableCell>{t(item.key)}</TableCell>
                      <TableCell>{remitterValue || Longdash}</TableCell>
                      <TableCell>{beneficiaryValue || Longdash}</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        ) : (
          t('noData')
        )}
      </Section>
      {remitterSolutionId && (
        <>
          <Grid container justify='space-between'>
            <Grid item>
              <H2 topSpacing={0} bottomSpacing={2}>
                {t('additionalProperties._')}
              </H2>
            </Grid>
          </Grid>
          <Section className={`${classes.bottomSpacing}`}>
            {additionalPropertiesSchema?.schema ? (
              Object.keys(additionalPropertiesSchema.schema.properties || {}).length > 0 ? (
                <JsonSchemaView
                  data={fullDeal}
                  schema={additionalPropertiesSchema?.schema}
                  displayMissingProperties
                  // className={classes.noneMaxWidth}
                />
              ) : (
                <>
                  {t('additionalProperties.empty')}
                  <br />
                  <br />
                </>
              )
            ) : additionalPropertiesSchema?.error ? (
              <Alert severity='warning' style={{ marginBottom: '1em' }}>
                {t('additionalProperties.badSchema')}
              </Alert>
            ) : additionalPropertiesSchema?.noSchema ? (
              <>
                {t('additionalProperties.empty')}
                <br />
                <br />
              </>
            ) : (
              <Skeleton variant='text' width={200} height={24} />
            )}
          </Section>
        </>
      )}
      <Grid container justify='space-between' className={`${classes.bottomSpacing}`}>
        <Grid item xs={12}>
          <H2 topSpacing={0} bottomSpacing={2}>
            {t('financialSummary')}
          </H2>
          <Section className={`${classes.bottomSpacing}`}>
            <Grid container spacing={2} className={`${classes.bottomSpacing}`}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField label={t('senderAccount')}>{deal.sender.accountNumber}</TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ProfileField actor={deal.sender} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {deal.recipient ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField label={t('recipientAccount')}>
                        {deal.recipient.accountNumber}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <ProfileField actor={deal.recipient} />
                    </Grid>{' '}
                  </Grid>
                ) : (
                  <div>{t('noRecipient')}</div>
                )}
              </Grid>
            </Grid>
          </Section>
          <Section>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('transaction.created')}</TableCell>
                  <TableCell>{t('transaction.postedAt')}</TableCell>
                  <TableCell>{t('transaction.baseAmount')}</TableCell>
                  <TableCell>{t('transaction.operationType')}</TableCell>
                  <TableCell>{t('transaction.status')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((trx) => {
                  return (
                    <TableRow key={trx.id}>
                      <TableCell>{time(trx.created).format('ll, LT')}</TableCell>
                      <TableCell>
                        <TextField>{trx.postedAt && time(trx.postedAt).format('ll, LT')}</TextField>
                      </TableCell>
                      <TableCell>
                        <AmountField amount={trx.details.baseAmount || trx.amount} />
                      </TableCell>
                      <TableCell>
                        <OperationTypeField operationType={trx.operationType} />
                      </TableCell>
                      <TableCell>
                        <StatusField status={trx.status} />
                      </TableCell>
                      <TableCell>
                        <Link href={`/headTransactions/${trx.id}/show`} target='_blank'>
                          <OpenButton />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Section>
        </Grid>
      </Grid>
    </Section>
  );
};

const OpenButton = (props: { handleOpen?: () => void; [x: string]: any }) => {
  const { handleOpen, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={classes.openIcon}>
      <IconButton onClick={handleOpen} {...rest}>
        <SvgIcon>
          <OpenIcon title='show' />
        </SvgIcon>
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 420,
  },
  amountCell: {
    minWidth: 90,
  },
  bottomSpacing: {
    paddingBottom: theme.spacing(2),
  },
  labelBottomSpacing: {
    paddingBottom: theme.spacing(1),
  },
  button: {
    padding: 3,
    color: theme.palette.grey[500],
  },
  icon: {
    fontSize: '1rem',
  },
  openIcon: {
    position: 'relative',
    width: 14,
    height: 14,
    '& button': {
      position: 'absolute',
      top: -6,
      width: 14,
      height: 14,
      '& svg': {
        width: 14,
        height: 14,
      },
    },
  },
}));

export default ShowDetails;
