import React, { Children, CSSProperties } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&:hover': {
      '& #deleteButton': {
        transition: `opacity ${theme.transitions.easing.easeIn}`,
        transitionDuration: `${theme.transitions.duration.shortest}ms`,
        opacity: 1,
      },
    },
  },
  deleteButton: {
    position: 'absolute',
    opacity: 0,
    top: 8,
    right: 3,
    padding: 3,
    color: theme.palette.error.main,
    zIndex: 1,
  },
}));

interface DeleteButtonProps {
  onDeleteClick: () => void;
  deleteButtonStyle?: CSSProperties;
}

const DeleteButton = (props: any) => {
  const { onDeleteClick, deleteButtonStyle } = props;
  const classes = useStyles();

  return (
    <IconButton
      id='deleteButton'
      className={classes.deleteButton}
      style={deleteButtonStyle}
      onClick={onDeleteClick}
      tabIndex={-1}
    >
      <DeleteIcon />
    </IconButton>
  );
};

interface AddDeleteButtonProps extends DeleteButtonProps {
  children: JSX.Element;
}

const AddDeleteButton = (props: AddDeleteButtonProps) => {
  const { children, onDeleteClick, deleteButtonStyle } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DeleteButton onDeleteClick={onDeleteClick} deleteButtonStyle={deleteButtonStyle} />
      {Children.only(children)}
    </div>
  );
};

export default AddDeleteButton;
