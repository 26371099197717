import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePermissions } from 'react-admin';

import {
  clearSecretWord,
  createBlock,
  getApiIdentities,
  resetAttemptsCounter,
  clearBlock,
  getApiIdentityById,
  getApiKeyByIdentityId,
  getApiIdentityDevicesById,
  deleteDeviceById,
} from '~/api/apiIdentity';

const getApiIdentitiesKey = (profileId: string) => ['apiIdentities', profileId];

interface ApiIdentitiesByIdProps {
  identityId: string | undefined;
}

export const useApiIdentityById = (props: ApiIdentitiesByIdProps) => {
  const { identityId } = props;
  const { permissions } = usePermissions();

  return useQuery(getApiIdentitiesKey(identityId!), () => getApiIdentityById(identityId!), {
    enabled: !!identityId && !!permissions?.includes('apiIdentity.view'),
  });
};

interface ApiIdentitiesProps {
  profileId: string | undefined;
}

export const useApiIdentityByProfileId = (props: ApiIdentitiesProps) => {
  const { profileId } = props;
  const { permissions } = usePermissions();

  return useQuery(getApiIdentitiesKey(profileId!), () => getApiIdentities({ profileId }), {
    enabled: !!profileId && !!permissions?.includes('apiIdentity.list'),
  });
};

export const useApiIdentityByTerminalId = (terminalId: string) => {
  const { permissions } = usePermissions();

  return useQuery(
    getApiIdentitiesKey(terminalId!),
    () => getApiIdentities({ username: terminalId }),
    {
      enabled: !!terminalId && !!permissions?.includes('apiIdentity.list'),
    }
  );
};

const useRefetchApiIdentities = () => {
  const queryCLient = useQueryClient();
  return () => queryCLient.invalidateQueries('apiIdentities');
};

export const useClearSecretWord = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(clearSecretWord, {
    onSuccess() {
      refetch();
    },
  });
};

export const useResetAttemptsCounter = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(resetAttemptsCounter, {
    onSuccess() {
      refetch();
    },
  });
};

export const useCreateBlock = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(createBlock, {
    onSuccess() {
      refetch();
    },
  });
};

export const useClearBlock = () => {
  const refetch = useRefetchApiIdentities();
  return useMutation(clearBlock, {
    onSuccess() {
      refetch();
    },
  });
};

export const useApiKeyByIdentityId = (identityId: string | undefined, enabled = true) => {
  return useQuery(['ApiKeyByIdentityId', identityId], () => getApiKeyByIdentityId(identityId!), {
    enabled: !!identityId && enabled,
  });
};

export const useApiIdentityDevicesById = (identityId: string) => {
  return useQuery(
    ['ApiIdentityDevices', identityId],
    () => getApiIdentityDevicesById(identityId!),
    {
      enabled: !!identityId,
    }
  );
};

export const useDeleteDevice = () => {
  return useMutation(deleteDeviceById);
};
